import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import NavbarAdminMex from '../components/navbar';

const { Option } = Select;

const UserForm = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  return (
    <>
    <NavbarAdminMex title={"Nuevo Usuario"}/>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '2%' }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Nombre de Usuario"
            name="username"
            rules={[{ required: true, message: 'Por favor, ingresa el nombre de usuario' }]}
          >
            <Input placeholder="Ingresa tu nombre de usuario" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Identificación"
            name="identificacion"
            rules={[{ required: true, message: 'Por favor, ingresa la identificación' }]}
          >
            <Input placeholder="Ingresa tu identificación" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Dirección"
            name="direccion"
            rules={[{ required: true, message: 'Por favor, ingresa la dirección' }]}
          >
            <Input placeholder="Ingresa tu dirección" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Correo"
            name="correo"
            rules={[{ required: true, message: 'Por favor, ingresa el correo electrónico', type: 'email' }]}
          >
            <Input placeholder="Ingresa tu correo electrónico" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Número de Celular"
            name="numero_celular"
            rules={[{ required: true, message: 'Por favor, ingresa tu número de celular' }]}
          >
            <Input placeholder="Ingresa tu número de celular" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Código Sucursal"
            name="codigo_sucursal"
            rules={[{ required: true, message: 'Por favor, ingresa el código de sucursal' }]}
          >
            <Input placeholder="Ingresa el código de sucursal" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Usuario Llave"
            name="usuario_llave"
            rules={[{ required: true, message: 'Por favor, ingresa el usuario llave' }]}
          >
            <Input placeholder="Ingresa el usuario llave" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Contraseña Inter"
            name="contrasena_inter"
            rules={[{ required: true, message: 'Por favor, ingresa la contraseña inter' }]}
          >
            <Input.Password
              placeholder="Ingresa tu contraseña inter"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Contraseña Global"
            name="contrasena_global"
            rules={[{ required: true, message: 'Por favor, ingresa la contraseña global' }]}
          >
            <Input.Password
              placeholder="Ingresa tu contraseña global"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Ciudad"
            name="ciudad"
            rules={[{ required: true, message: 'Por favor, selecciona tu ciudad' }]}
          >
            <Select placeholder="Selecciona tu ciudad">
              <Option value="ciudad1">Ciudad 1</Option>
              <Option value="ciudad2">Ciudad 2</Option>
              <Option value="ciudad3">Ciudad 3</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="ID Ciudad"
            name="id_ciudad"
            rules={[{ required: true, message: 'Por favor, ingresa el ID de la ciudad' }]}
          >
            <Input placeholder="Ingresa el ID de la ciudad" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#8c8cff', maxWidth:'20%', marginLeft:'35%', marginTop:' 2%'}}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};

export default UserForm;
