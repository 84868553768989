import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Estilos/EsperaStyle.css';
import { message } from 'antd';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente principal para gestionar los pedidos no asignados.
 * @component
 */
function NoAsignados() {
    // Estado para almacenar los pedidos
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate(); // Hook para usar la navegación

    /**
     * Hook de efecto para obtener los pedidos no asignados.
     * Se ejecuta una vez al montar el componente.
     */
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://api.99envios.app/api/pedidos-no-asignados');
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    /**
     * Actualiza el estado del pedido a "sin asignar".
     * @param {number} orderId - ID del pedido.
     */
    const updateOrderToUnassigned = async (orderId) => {
        const id = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/pedidos/${orderId}/actualizar-sin-asignar/${id}`;
        try {
            const response = await axios.put(url);
            if (response.status === 200) {
                message.success('Pedido marcado como en espera');
                // Actualiza el estado solo si la solicitud PUT fue exitosa
                navigate('/conductor?refresh=true');
                setOrders(orders.map(order => {
                    if (order.ID_pedido === orderId) {
                        return { ...order, estado_pedido: 'Sin asignar' };
                    }
                    return order;
                }));
            } else {
                throw new Error('Failed to update the order status');
            }
        } catch (error) {
            console.error('Error marking the order as returned:', error);
            message.error('No se pudo marcar el pedido como en espera');
        }
    };

    /**
     * Maneja el clic en el botón de detalles del pedido.
     * @param {number} orderId - ID del pedido.
     */
    function handleDetailsClick(orderId) {
        navigate(`/conductor/pedidos/${orderId}`);
    }

    return (
        <div>
            <Navbar title={"No asignado"} />
            <div className="app-container">
                <Head title={"No asignados"} />
                <p className="instructions">
                    <b>Marcar pedidos como listos para entrega y salir a entregar</b>
                    <br />
                    Elija pedidos y haga clic en el botón En espera
                </p>
                <div className="orden">
                    <div className="orders-list">
                        {orders.map((order) => (
                            <div key={order.ID_pedido} className="order-card">
                                <div className="order-details">
                                    <h2>Pedido #{order.ID_pedido}</h2>
                                    <p>Cliente: {order.id_cliente}</p>
                                    <p>{order.direccion_entrega}</p>
                                    <p>Ciudad: {order.ciudad}</p>
                                </div>
                                <button className="details-button" onClick={() => handleDetailsClick(order.ID_pedido)}>Detalles</button>
                                <button className="deliver-button" onClick={() => updateOrderToUnassigned(order.ID_pedido)}> En espera</button>
                            </div>
                        ))}
                    </div>
                    {/* <button className="deliver-button">
                        <FontAwesomeIcon icon={faTruck} /> Salir a entregar
                    </button> */}
                </div>
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
}

export default NoAsignados;
