// useRole.js
import { useState, useEffect } from 'react';

const useRole = () => {
  const [role, setRole] = useState(localStorage.getItem('rol_id') ); 

  useEffect(() => {
    const storedRole = localStorage.getItem('rol_id');
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);

  return role;
};

export default useRole;
