import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Input, Select } from 'antd';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import './TableComponent.css';
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';

const { Option } = Select;

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false); // Estado para el modal
  const [form] = Form.useForm(); // Formulario de antd
  const codigo_sucursal = localStorage.getItem('codigoSucursal');
  const [selectedPreenvio, setSelectedPreenvio] = useState(''); // Estado para el número de preenvío seleccionado

  useEffect(() => {
    axios.get(`https://api.99envios.app/public/api/online/novedades-beta/${codigo_sucursal}`)
      .then(response => {
        const novedades = response.data.map(novedad => ({
          id: novedad.id, // Add id field
          numero_preenvio: novedad.numero_preenvio,
          novedad_sucursal: novedad.novedad_sucursal,
          tipo_novedad: novedad.tipo_novedad,
          dice_contener: novedad.dice_contener,
          nombre_destinatario: novedad.nombre_destinatario,
          direccion_destinatario: novedad.direccion_destinatario,
          telefono_destinatario: novedad.telefono_destinatario,
          ciudad_destinatario: novedad.ciudad_destinatario,
          updated_at: novedad.updated_at
        }));
        setData(novedades);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    (item.numero_preenvio || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.novedad_sucursal || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.tipo_novedad || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.dice_contener || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.nombre_destinatario || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.direccion_destinatario || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.telefono_destinatario || '').toLowerCase().includes(search.toLowerCase()) ||
    (item.ciudad_destinatario || '').toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const showModal = (id) => {
    form.resetFields(); // Reset form fields
    const selectedItem = data.find(item => item.id === id);
    if (selectedItem) {
      setSelectedPreenvio(selectedItem.numero_preenvio);
      form.setFieldsValue({ numero_guia: selectedItem.numero_preenvio }); // Update form field
    }
    setIsModalVisible(true);
    
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      const selectedItem = data.find(item => item.numero_preenvio === selectedPreenvio);
      if (selectedItem) {
        axios.put(`https://99envios.app/api/online/novedades-beta/${selectedItem.id}`, {
          numero_guia: values.numero_guia,
          novedad_sucursal: values.accion,
          observaciones: values.observaciones
        })
        .then(response => {
          console.log('Response:', response);
          setIsModalVisible(false);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    // Crear una copia de los datos filtrados excluyendo la columna pdf
    const exportData = filteredData.map(item => {
      const { pdf, ...rest } = item;
      return rest;
    });

    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Envíos Completos");

    writeFile(workbook, `Novedades_${formatDateForDisplay(new Date())}.xlsx`);
  };

  return (
    <>
      <NavbarMex title="Novedades" />
      <div className="table-containerNO" style={{ marginLeft: "20%" }}>
        <div className="table-controls">
          
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          <div className="export-group">
            <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('numero_preenvio')}>Número de Preenvío</th>
              <th onClick={() => handleSort('novedad_sucursal')}>Novedad Sucursal</th>
              <th onClick={() => handleSort('tipo_novedad')}>Tipo de Novedad</th>
              <th onClick={() => handleSort('dice_contener')}>Dice Contener</th>
              <th onClick={() => handleSort('nombre_destinatario')}>Nombre Destinatario</th>
              <th onClick={() => handleSort('direccion_destinatario')}>Dirección Destinatario</th>
              <th onClick={() => handleSort('telefono_destinatario')}>Teléfono Destinatario</th>
              <th onClick={() => handleSort('ciudad_destinatario')}>Ciudad Destinatario</th>
              <th onClick={() => handleSort('updated_at')}>Fecha Actualización</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((item, index) => (
              <tr key={index}>
                <td>{item.numero_preenvio}</td>
                <td>{item.novedad_sucursal}</td>
                <td>{item.tipo_novedad}</td>
                <td>{item.dice_contener}</td>
                <td>{item.nombre_destinatario}</td>
                <td>{item.direccion_destinatario}</td>
                <td>{item.telefono_destinatario}</td>
                <td>{item.ciudad_destinatario}</td>
                <td>{formatDateForDisplay(item.updated_at)}</td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => showModal(item.id)}
                    style={{
                      backgroundColor: "#7C89F3",
                      color: "white",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                      height: "40px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#6C7BE4"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#7C89F3"}
                  >
                    Gestionar Novedad
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <Modal
        title={<strong>Formulario de Trámite</strong>}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        closable={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Número de preenvío"
            name="numero_guia"
            rules={[{ required: true }]}
          >
            <Input value={selectedPreenvio} readOnly />
          </Form.Item>
          <Form.Item
            label="Indique la acción a realizar"
            name="accion"
            rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
          >
            <Select placeholder="Seleccione una opción">
              <Option value="nuevo_intento_entrega">Nuevo intento de entrega</Option>
              <Option value="devolver">Devolver</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Observaciones adicionales"
            name="observaciones"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          
          {/* Botón de "Procesar" personalizado */}
          <Button
            type="primary"
            onClick={handleOk}
            style={{
              backgroundColor: "#7C89F3",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              height: "40px", // Ajusta la altura del botón
              width: "auto", // Ajusta el ancho automáticamente según el contenido
              textAlign: "center",
              display: "flex",
              alignItems: "center", // Centra el contenido verticalmente
              justifyContent: "center", // Centra el contenido horizontalmente
              margin: "0 auto", // Centra el botón horizontalmente dentro del contenedor
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#6C7BE4"}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#7C89F3"}
          >
            Procesar
          </Button>
        </Form>
      </Modal>
      <BotonWhatsapp />
    </>
  );
};

export default TableComponent;
