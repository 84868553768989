import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import NavbarSales from '../components/navbar/navbarSales';
import { FileExcelOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './SucursalesComisionadas.css';
import * as XLSX from 'xlsx';
import axios from 'axios';

const SucursalesComisionadas = () => {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/vendedor-sucursales-comisionadas/${userId}`);
        setData(response.data);
      } catch (error) {
        console.error('Error al obtener los datos de la API', error);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Código Sucursal',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo.localeCompare(b.codigo),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SucursalesComisionadas');
    XLSX.writeFile(workbook, 'SucursalesComisionadas.xlsx');
  };

  return (
    <>
      <NavbarSales title="Sucursales Comisionadas" />
      <div className="sucursales-comisionadas-container">
        <div className="sucursales-comisionadas-header">
          <h2>Sucursales comisionadas</h2>
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleExportToExcel}
            style={{ height: '44px'}}
            className="sucursales-comisionadas-export-btn"
          >
            Exportar a Excel
          </Button>
        </div>

        <h3>Tabla comisiones</h3>
        
        <div className="sucursales-comisionadas-table">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            title={() => 'Tabla comisiones'}
            rowKey="codigo"
          />
        </div>
      </div>
    </>
  );
};

export default SucursalesComisionadas;
