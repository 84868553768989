import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faBars,
    faClipboardList,
    faHourglassHalf,
    faDollyFlatbed,
    faTimesCircle,
    faCheckCircle,
    faCog,
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import './headerConductor.css';

export default function Component({ title }) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [orderData, setOrderData] = useState({
        En_Espera: 0,
        Entregado: 0,
        En_proceso: 0,
        Devuelto: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            const usuario_id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/panel-conductores/${usuario_id}/pedidos`
            try {
                const response = await axios.get(url);
                setOrderData(response.data.estados_pedido); 
            } catch (error) {
                console.error('Error al obtener los datos de la API:', error);
            }
        };

        fetchData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const toggleMenu = (event) => {
        event.preventDefault(); // Previene el comportamiento por defecto del evento
        setIsMenuOpen(!isMenuOpen); // Cambia el estado del menú
    };

    const handleNavigation = (path) => {
        setIsMenuOpen(false); // Cierra el menú
        navigate(path); // Navega a la ruta deseada
    };

    return (
        <>
            <div className="app-header">
                <button className="back-button" onClick={handleBack}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <h1>{title}</h1>
                <button className={`menu-button ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            <nav className={`menu-responsive ${isMenuOpen ? 'open' : ''}`}>
                <button className="close-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>

                <a href="#!" onClick={toggleMenu}>
                    Juan Ruiz
                </a>
                <a onClick={(event) => {handleNavigation('/conductor'); toggleMenu(event)}} >
                    <FontAwesomeIcon icon={faClipboardList} /> Tablero
                </a>
                <a onClick={(event) => {handleNavigation('/conductor/ruta-en-espera'); toggleMenu(event)}} >
                    <FontAwesomeIcon icon={faHourglassHalf} /> En espera ({orderData.En_Espera})
                </a>
                <a onClick={(event) => {handleNavigation('/conductor/ruta-salir-entregar'); toggleMenu(event)}} >
                    <FontAwesomeIcon icon={faDollyFlatbed} /> Salir a entregar ({orderData.En_proceso})
                </a>
                <a onClick={(event) => {handleNavigation('/conductor/entrega-fallida'); toggleMenu(event)}} >
                    <FontAwesomeIcon icon={faTimesCircle} /> Entrega Fallida ({orderData.Devuelto})
                </a>
                <a onClick={(event) => {handleNavigation('/conductor/ruta-entregado'); toggleMenu(event)}} >
                    <FontAwesomeIcon icon={faCheckCircle} /> Entregado ({orderData.Entregado})
                </a>
                <a href="#!" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faCog} /> Configuraciones
                </a>
                <a href="#!" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión
                </a>
            </nav>
        </>
    )

}
