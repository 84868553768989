import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import NavbarAdminMex from '../components/navbar';

const { Option } = Select;

const NewAdmin = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  return (
    <>
    <NavbarAdminMex title={"Nuevo Admin"}/>
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '2%' }}
    >
        <Form.Item
            label="Nombre Admin"
            name="username"
            rules={[{ required: true, message: 'Por favor, ingresa el nombre del admin' }]}
        >
            <Input placeholder="Ingresa el nombre del admin" />
        </Form.Item>
        <Form.Item
            label="Correo Admin"
            name="correo"
            rules={[{ required: true, message: 'Por favor, ingresa el correo electrónico del admin', type: 'email' }]}
        >
            <Input placeholder="Ingresa tu correo electrónico" />
        </Form.Item>
        <Form.Item
            label="Código Sucursal"
            name="codigo_sucursal"
            rules={[{ required: true, message: 'Por favor, ingresa el código de sucursal' }]}
        >
            <Input placeholder="Ingresa el código de sucursal" />
        </Form.Item>
        <Form.Item
            label="Contraseña Admin"
            name="contrasena"
            rules={[{ required: true, message: 'Por favor, ingresa la contraseña del admin' }]}
        >
            <Input.Password
            placeholder="Ingresa la contraseña del admin"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
        </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#8c8cff', marginTop:'2%', height:'44px', width:'120px', marginLeft:'110px'}}>
          Registrarse
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};

export default NewAdmin;
