import React, { useState, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import './maps.css';

const { Meta } = Card;

const libraries = ['places'];
const mapContainerStyle = {
    height: '300px',
    width: '100%'
};
const center = {
    lat: 19.432608,
    lng: -99.133209
};

const Rutas = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAbErc-9C5rqyrGudtyKlk7LJOfqhbu9c0', // Reemplaza con tu API key real
        libraries
    });

    const [directionsResponses, setDirectionsResponses] = useState([]);
    const [pedidosData, setPedidosData] = useState([]);
    
    const location = useLocation();
    const { pedidoIds } = location.state || { pedidoIds: [] };

    useEffect(() => {
        if (pedidoIds && pedidoIds.length > 0) {
            Promise.all(pedidoIds.map(id => fetch(`https://api.99envios.app/api/pedidos/${id}`).then(res => res.json())))
                .then(data => {
                    setPedidosData(data);
                    calculateRoutes(data);
                })
                .catch(error => {
                    console.error("Error fetching pedidos data:", error);
                    alert("No se pudo obtener los datos de los pedidos.");
                });
        }
    }, [pedidoIds]);

    const calculateRoutes = async (pedidos) => {
        const directionsService = new window.google.maps.DirectionsService();
        const routePromises = pedidos.map(pedido => {
            if (pedido.direccion_recogida && pedido.direccion_entrega) {
                return directionsService.route({
                    origin: pedido.direccion_recogida,
                    destination: pedido.direccion_entrega,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                });
            }
            return null;
        });

        try {
            const results = await Promise.all(routePromises);
            setDirectionsResponses(results.filter(result => result !== null));
        } catch (error) {
            console.error("Error fetching directions", error);
            alert("No se pudo encontrar una ruta. Verifique que las direcciones sean correctas y completas.");
        }
    };

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "Loading Maps";

    const now = new Date();
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const currentDate = now.toLocaleDateString('es-ES', options);

    return (
        <>
            <Navbar />
            <div className="navbarMap" style={{ marginTop: '20px', padding: '10px' }}>
                <div className="navbar-date">{currentDate}</div>
            </div>
            <div className="containerMap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {pedidosData.map((pedidoData, index) => (
                    <Card
                        key={index}
                        title={`Pedido Confirmado ${index + 1}`}
                        style={{ width: '80%', marginBottom: '20px' }}
                    >
                        <Meta
                            description={(
                                <div>
                                    <Row>
                                        <Col span={12}><strong>ID del Pedido:</strong> {pedidoData.ID_pedido}</Col>
                                        <Col span={12}><strong>Cliente:</strong> {pedidoData.id_cliente}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Estado:</strong> {pedidoData.estado_pedido}</Col>
                                        <Col span={12}><strong>Fecha de Pedido:</strong> {pedidoData.fecha_pedido}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Dirección de Recogida:</strong> {pedidoData.direccion_recogida}</Col>
                                        <Col span={12}><strong>Dirección de Entrega:</strong> {pedidoData.direccion_entrega}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Ciudad:</strong> {pedidoData.ciudad}</Col>
                                        <Col span={12}><strong>País:</strong> {pedidoData.pais}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Costo de Envío:</strong> {pedidoData.costo_envio}</Col>
                                        <Col span={12}><strong>Valor del Producto:</strong> {pedidoData.valor_producto}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Fecha de Entrega:</strong> {pedidoData.fecha_entrega}</Col>
                                        <Col span={12}><strong>Código de Ciudad:</strong> {pedidoData.codigo_ciudad}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><strong>Hora inicio:</strong> {pedidoData.hora_inicio}</Col>
                                        <Col span={12}><strong>Hora fin:</strong> {pedidoData.hora_fin}</Col>
                                    </Row>  
                                    <Row>
                                        <Col span={24}><strong>Id Repartidor:</strong> {pedidoData.id_repartidor}</Col> 
                                    </Row>
                                </div>
                            )}
                        />
                    </Card>
                ))}
                <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
                    {directionsResponses.map((directionsResponse, index) => (
                        <DirectionsRenderer key={index} directions={directionsResponse} />
                    ))}
                </GoogleMap>
            </div>
            <Footer />
        </>
    );
};

export default Rutas;
