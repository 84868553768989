import React, { useState } from 'react';
import NavbarAdminMex from '../components/navbar';
import { Form, Button, Card, Radio, InputNumber } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Modal = () => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState('');

  // Función para remover etiquetas HTML
  const stripHtml = (html) => {
    // Crear un elemento temporal
    const temp = document.createElement('div');
    temp.innerHTML = html;
    // Retornar solo el texto, eliminando espacios extra
    return temp.textContent || temp.innerText || '';
  };

  const onFinish = (values) => {
    // Limpia el HTML del mensaje antes de enviarlo
    const plainTextMessage = stripHtml(message);

    const dataToSend = {
      activo: values.estado === 'activo' ? 1 : 0,
      dias_activo: values.dias_actividad,
      mensaje: plainTextMessage // Ahora enviamos el texto sin HTML
    };

    console.log('Datos a enviar:', dataToSend);

    fetch('https://99envios.app/api/online/modals/1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Respuesta de la API:', data);
      })
      .catch(error => {
        console.error('Error al enviar los datos:', error);
      });
  };

  const modules = {
    toolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]
  };

  return (
    <>
      <NavbarAdminMex title={"Modal"}/>
      <Card style={{ width: '60%', marginLeft: '20%', marginTop: '2%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
        <Form
          form={form}
          name="estado_modal"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="estado" label="Estado del Modal">
            <Radio.Group>
              <Radio value="activo">Activo</Radio>
              <Radio value="inactivo">Inactivo</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="dias_actividad" label="Días de actividad:">
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            name="mensaje"
            label="Mensaje"
          >
            <ReactQuill 
              value={message}
              onChange={setMessage}
              modules={modules}
              theme="snow"
              style={{ height: '250px', marginBottom: '42px' }}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#8c8cff', height: '44px' }}>
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default Modal;