import React, { useState, useEffect } from 'react';
import NavbarAdminMex from '../components/navbar';
import { Table, Button, Input, Typography, message } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';
import './pagos.css';

const Pagos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/online/transaccion');
      const sortedData = response.data.map(item => ({ ...item, key: item.id })).sort((a, b) => b.id - a.id);
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data');
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "pagos_data.xlsx");
  };

  const handleUpdateSelected = async () => {
    if (selectedRows.length === 0) {
      message.warning('Por favor seleccione al menos un registro para actualizar');
      return;
    }

    setLoading(true);
    let successCount = 0;
    let errorCount = 0;

    try {
      // Update each selected row sequentially
      for (const id of selectedRows) {
        try {
          await axios.put(`https://99envios.app/api/online/transaccion/${id}`);
          successCount++;
        } catch (error) {
          console.error(`Error updating ID ${id}:`, error);
          errorCount++;
        }
      }

      // Show results
      if (successCount > 0) {
        message.success(`${successCount} registros actualizados exitosamente`);
      }
      if (errorCount > 0) {
        message.error(`${errorCount} registros fallaron al actualizar`);
      }

      // Refresh data and clear selection
      await fetchData();
      setSelectedRows([]);
    } catch (error) {
      console.error('Error en actualización masiva:', error);
      message.error('Error al realizar la actualización masiva');
    } finally {
      setLoading(false);
    }
  };

  const handleRejectSelected = async () => {
    if (selectedRows.length === 0) {
      message.warning('Por favor seleccione al menos un registro para rechazar');
      return;
    }

    setLoading(true);
    let successCount = 0;
    let errorCount = 0;

    try {
      // Reject each selected row sequentially
      for (const id of selectedRows) {
        try {
          await axios.post(`https://99envios.app/api/online/rechazar-pago/${id}`);
          successCount++;
        } catch (error) {
          console.error(`Error rejecting ID ${id}:`, error);
          errorCount++;
        }
      }

      // Show results
      if (successCount > 0) {
        message.success(`${successCount} registros rechazados exitosamente`);
      }
      if (errorCount > 0) {
        message.error(`${errorCount} registros fallaron al rechazar`);
      }

      // Refresh data and clear selection
      await fetchData();
      setSelectedRows([]);
    } catch (error) {
      console.error('Error en rechazo masivo:', error);
      message.error('Error al realizar el rechazo masivo');
    } finally {
      setLoading(false);
    }
  };

  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="transaccion-pagos-filter-dropdown">
        <div className="transaccion-pagos-filter-input-container">
          <Input
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="transaccion-pagos-filter-input"
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            className="transaccion-pagos-filter-search-button"
          />
        </div>
        <Button
          onClick={clearFilters}
          size="small"
          className="transaccion-pagos-filter-reset-button"
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#3F51B5' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });
  
  const handleUpdateStatus = async (id) => {
    try {
      setLoading(true);
      const response = await axios.put(`https://99envios.app/api/online/transaccion/${id}`);
      
      if (response.status === 200) {
        message.success(`Estado actualizado con éxito para el ID: ${id}`);
        await fetchData();
      } else {
        message.error(`Error al actualizar el estado para el ID: ${id}`);
      }
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      message.error(`Error al actualizar el estado para el ID: ${id}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRejectStatus = async (id) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://99envios.app/api/online/rechazar-pago/${id}`);

      if (response.status === 200) {
        message.success(`Estado rechazado con éxito para el ID: ${id}`);
        await fetchData();
      } else {
        message.error(`Error al rechazar el estado para el ID: ${id}`);
      }
    } catch (error) {
      console.error('Error al rechazar el estado:', error);
      message.error(`Error al rechazar el estado para el ID: ${id}`);
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "Registro",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("ID", "id"),
    },
    {
      title: "Código Inter",
      dataIndex: "codigo_inter",
      key: "codigo_inter",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("Código Inter", "codigo_inter"),
    },
    {
      title: "Código Sucursal",
      dataIndex: "id_sucursal",
      key: "id_sucursal",
      sorter: (a, b) => a.id_sucursal - b.id_sucursal,
      ...getColumnSearchProps("ID Sucursal", "id_sucursal"),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      ...getColumnSearchProps("Nombre", "nombre"),
    },
    {
      title: "Tipo de Identificación",
      dataIndex: "tipo_identificacion",
      key: "tipo_identificacion",
      ...getColumnSearchProps("Tipo de Identificación", "tipo_identificacion"),
    },
    {
      title: "Número de Identificación",
      dataIndex: "numero_identificacion",
      key: "numero_identificacion",
      ...getColumnSearchProps("Número de Identificación", "numero_identificacion"),
    },
    {
      title: "Tipo de Transacción",
      dataIndex: "tipo_transaccion",
      key: "tipo_transaccion",
      ...getColumnSearchProps("Tipo de Transacción", "tipo_transaccion"),
    },
    {
      title: "Tipo de Cuenta",
      dataIndex: "tipo_cuenta",
      key: "tipo_cuenta",
      ...getColumnSearchProps("Tipo de Cuenta", "tipo_cuenta"),
    },
    {
      title: "Nombre del Banco",
      dataIndex: "nombre_banco",
      key: "nombre_banco",
      sorter: (a, b) => a.nombre_banco.localeCompare(b.nombre_banco),
      ...getColumnSearchProps("Nombre del Banco", "nombre_banco"),
    },
    {
      title: "Código del Banco",
      dataIndex: "codigo_banco",
      key: "codigo_banco",
      ...getColumnSearchProps("Código del Banco", "codigo_banco"),
    },
    {
      title: "Número de Cuenta",
      dataIndex: "numero_cuenta",
      key: "numero_cuenta",
      ...getColumnSearchProps("Número de Cuenta", "numero_cuenta"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      sorter: (a, b) => new Date(a.Fecha) - new Date(b.Fecha),
      ...getColumnSearchProps("Fecha", "Fecha"),
    },
    {
      title: "Monto",
      dataIndex: "Monto",
      key: "Monto",
      sorter: (a, b) => parseFloat(a.Monto) - parseFloat(b.Monto),
      render: (text) => {
        const formattedAmount = parseFloat(text).toLocaleString('es-CO', {
          style: 'currency',
          currency: 'COP',
        });
        return formattedAmount;
      },
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      sorter: (a, b) => a.estado.localeCompare(b.estado),
      ...getColumnSearchProps("Estado", "estado"),
    },
    {
      title: "Fecha de Pago o Rechazo",
      dataIndex: "fecha_pago",
      key: "fecha_pago",
      sorter: (a, b) => new Date(a.fecha_pago || 0) - new Date(b.fecha_pago || 0),
      render: (text) => text || 'N/A',
    },
    {
      title: "Actualizar Estado",
      key: "actualizar_estado",
      render: (_, record) => (
        <>
          <Button onClick={() => handleUpdateStatus(record.id)} loading={loading}>
            Actualizar
          </Button>
          <Button onClick={() => handleRejectStatus(record.id)} loading={loading} type="danger" style={{ marginTop: 8 }}>
            Rechazar
          </Button>
        </>
      ),
    },
  ];
  
  return (
    <>
      <NavbarAdminMex title={"Pagos"} />
      <div className="transaccion-pagos-container">
        <Typography.Title level={2} className="transaccion-pagos-title">
          Pagos
        </Typography.Title>
        <div className="transaccion-pagos-actions">
          <Button 
            onClick={exportToExcel} 
            style={{ height: 46 }}
            className="transaccion-pagos-button transaccion-pagos-export-button"
          >
            Exportar a Excel
          </Button>
          <Button
            onClick={handleUpdateSelected}
            loading={loading}
            disabled={selectedRows.length === 0}
            style={{ height: 48 }}
            type="primary"
            className="transaccion-pagos-button"
          >
            Actualizar Seleccionados ({selectedRows.length})
          </Button>
          <Button
            onClick={handleRejectSelected}
            loading={loading}
            disabled={selectedRows.length === 0}
            style={{ height: 48 }}
            type="danger"
            className="transaccion-pagos-button"
          >
            Rechazar Seleccionados ({selectedRows.length})
          </Button>
        </div>
        <Typography.Title level={3} className="transaccion-pagos-subtitle">
          Tabla Pagos
        </Typography.Title>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
          }}
          locale={{
            sortTitle: "Ordenar",
            triggerAsc: "Orden ascendente",
            triggerDesc: "Orden descendente",
            cancelSort: "Cancelar orden",
          }}
          scroll={{ x: 'max-content' }}
          className="transaccion-pagos-table custom-table"
        />
      </div>
    </>
  );
};

export default Pagos;