import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css'; // Import styles for ag-Grid
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Import a theme for ag-Grid
import { Button, Modal, Box, Typography, makeStyles } from '@material-ui/core';
import { message } from 'antd';
import { AttachFile as AttachFileIcon } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '90%',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fileInput: {
        display: 'none'
    },
    button: {
        margin: theme.spacing(1),
    },
    table: {
        minWidth: 750,
        width: '100%'
    },
}));

const ExcelEditor = () => {
    const classes = useStyles();
    const [rowData, setRowData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    
    const [columnDefs] = useState([
        { headerName: 'Origen', field: 'origin', editable: true },
        { headerName: 'Dirección de entrega', field: 'direccion', editable: true },
        { headerName: 'Nombre de destinatario', field: 'nombre', editable: true },
        { headerName: 'Teléfono del Destinatario', field: 'telefono', editable: true },
        { headerName: 'Email del Destinatario', field: 'email', editable: true },
        { headerName: 'Fecha de Entrega', field: 'fecha_de_entrega', editable: true },
        { headerName: 'Id del Producto', field: 'id_producto', editable: true },
        { headerName: 'Cantidad', field: 'cantidad', editable: true },
        { headerName: 'Costo de Envío', field: 'costo_envio', editable: true },
        { headerName: 'Id del Repartidor', field: 'id_repartidor', editable: true },
    ]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const headers = rawData[0];
            const newData = rawData.slice(1).map(row => {
                let obj = {};
                row.forEach((value, index) => {
                    const key = headers[index];
                    if (key && value) {
                        obj[key] = key === 'fecha_de_entrega' ? new Date((value - (25567 + 2)) * 86400 * 1000).toISOString().slice(0, 10) : value;
                    }
                });
                return obj;
            });

            console.log('Datos cargados:', newData); // Log de los datos cargados
            setRowData(newData);
            setOpenModal(true);
        };
        reader.readAsBinaryString(file);
    };

    const handleSendData = async () => {
        const requiredFields = ['origin', 'direccion', 'nombre', 'telefono', 'email', 'fecha_de_entrega', 'id_producto', 'cantidad', 'costo_envio', 'id_repartidor'];
        const filteredData = rowData.filter(item =>
            requiredFields.every(field =>
                item[field] !== undefined && item[field] !== null && item[field] !== ''
            )
        );
    
        if (filteredData.length === 0) {
            message.error('No hay datos válidos para enviar a la API.');
            return;
        }
    
        const usuario_id = localStorage.getItem('usuario_id');
        let successfulOrders = [];
    
        for (const data of filteredData) {
            const dataToSend = { ...data, estado_pedido: "En espera" };
            console.log('Datos que se enviarán a la API:', dataToSend);
    
            try {
                const response = await fetch(`https://api.99envios.app/api/generar-pedido/${usuario_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(dataToSend)
                });
    
                if (response.ok) {
                    const result = await response.json();
                    successfulOrders.push(result.pedido_id);  // Asegúrate de usar el campo correcto para el ID del pedido
                    console.log('Pedido generado con ID:', result.pedido_id);
                } else {
                    const error = await response.json();
                    console.error('Error al enviar los datos:', error);
                    message.error('Error al enviar los datos: ' + error.message);
                }
            } catch (error) {
                console.error('Error en la conexión con la API:', error);
                message.error('Error en la conexión con la API: ' + error.message);
            }
        }
    
        if (successfulOrders.length > 0) {
            message.success(`Pedidos guardados con éxito con IDs: ${successfulOrders.join(', ')}`);
            navigate('/pedido/rutas', { state: { pedidoIds: successfulOrders } });
        } else {
            message.error('No se pudieron generar pedidos.');
        }
    };
    

    return (
        <>
            <input
                accept=".xlsx, .xls"
                className={classes.fileInput}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span" startIcon={<AttachFileIcon />}>
                    Cargar archivo Excel
                </Button>
            </label>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box className={classes.modal}>
                    <Typography variant="h6" gutterBottom>
                        Editar Datos Pedidos Masivos
                    </Typography>
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 100,
                                resizable: true,
                                editable: true,
                            }}
                        />
                    </div>
                    <Button onClick={handleSendData} color="primary" variant="contained" className={classes.button}>
                        Enviar Pedidos
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default ExcelEditor;
