import React, { useState } from "react";
import NavbarAdminMex from '../components/navbar';
import FormPQRterminado from './formpqrterminado';
import { Table, Button, Input, Modal, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import './pqrfinalizado.css';

const PqrFinalizado = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "data.xlsx");
  };

  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="pqrfinalizado-filter-dropdown">
        <div className="pqrfinalizado-filter-input-container">
          <Input
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="pqrfinalizado-filter-input"
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            className="pqrfinalizado-filter-search-button"
          />
        </div>
        <Button
          onClick={clearFilters}
          size="small"
          className="pqrfinalizado-filter-reset-button"
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#3F51B5' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });   

  const columns = [
    {
      title: "Número Ticket",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      sorter: (a, b) => a.ticketNumber - b.ticketNumber,
      className: "fixed-column",
      ...getColumnSearchProps("Número Ticket", "ticketNumber"),
    },
    {
      title: "Código Sucursal",
      dataIndex: "branchCode",
      key: "branchCode",
      sorter: (a, b) => a.branchCode.localeCompare(b.branchCode),
      ...getColumnSearchProps("Código Sucursal", "branchCode"),
    },
    {
      title: "Estado Solicitud",
      dataIndex: "requestStatus",
      key: "requestStatus",
      sorter: (a, b) => a.requestStatus.localeCompare(b.requestStatus),
      ...getColumnSearchProps("Estado Solicitud", "requestStatus"),
    },
    {
      title: "Número de Guía",
      dataIndex: "guideNumber",
      key: "guideNumber",
      sorter: (a, b) => a.guideNumber.localeCompare(b.guideNumber),
      ...getColumnSearchProps("Número de Guía", "guideNumber"),
    },
    {
      title: "Transportadora",
      dataIndex: "carrier",
      key: "carrier",
      sorter: (a, b) => a.carrier.localeCompare(b.carrier),
      ...getColumnSearchProps("Transportadora", "carrier"),
    },
    {
      title: "Fecha Ingreso",
      dataIndex: "entryDate",
      key: "entryDate",
      sorter: (a, b) => new Date(a.entryDate) - new Date(b.entryDate),
      ...getColumnSearchProps("Fecha Ingreso", "entryDate"),
    },
    {
      title: "Categoría o Motivo",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      ...getColumnSearchProps("Categoría o Motivo", "category"),
    },
    {
      title: "Fecha PQR",
      dataIndex: "pqrDate",
      key: "pqrDate",
      sorter: (a, b) => new Date(a.pqrDate) - new Date(b.pqrDate),
      ...getColumnSearchProps("Fecha PQR", "pqrDate"),
    },
    {
      title: "Datos Sucursal",
      dataIndex: "branchData",
      key: "branchData",
      sorter: (a, b) => a.branchData.localeCompare(b.branchData),
      ...getColumnSearchProps("Datos Sucursal", "branchData"),
    },
    {
      title: "Motivo devolución",
      dataIndex: "returnReason",
      key: "returnReason",
      sorter: (a, b) => a.returnReason.localeCompare(b.returnReason),
      ...getColumnSearchProps("Motivo devolución", "returnReason"),
    },
    {
      title: "Dimensiones",
      dataIndex: "dimensions",
      key: "dimensions",
      sorter: (a, b) => a.dimensions.localeCompare(b.dimensions),
      ...getColumnSearchProps("Dimensiones", "dimensions"),
    },
    {
      title: "Datos Banco",
      dataIndex: "bankData",
      key: "bankData",
      sorter: (a, b) => a.bankData.localeCompare(b.bankData),
      ...getColumnSearchProps("Datos Banco", "bankData"),
    },
    {
      title: "Información adicional",
      dataIndex: "additionalInfo",
      key: "additionalInfo",
      sorter: (a, b) => a.additionalInfo.localeCompare(b.additionalInfo),
      ...getColumnSearchProps("Información adicional", "additionalInfo"),
    },
    {
      title: "Respuesta 99",
      dataIndex: "response99",
      key: "response99",
      sorter: (a, b) => a.response99.localeCompare(b.response99),
      ...getColumnSearchProps("Respuesta 99", "response99"),
      render: text => <a href="#">{text}</a>,
    },
    {
      title: "Respuesta PQR",
      dataIndex: "pqrResponse",
      key: "pqrResponse",
      sorter: (a, b) => a.pqrResponse.localeCompare(b.pqrResponse),
      ...getColumnSearchProps("Respuesta PQR", "pqrResponse"),
    },
    {
      title: "Adjunto",
      dataIndex: "file",
      key: "file",
      sorter: (a, b) => a.file.localeCompare(b.file),
      ...getColumnSearchProps("Adjunto", "file"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    },
    {
      title: "Adjunto embalaje",
      dataIndex: "packingAttachment",
      key: "packingAttachment",
      sorter: (a, b) => a.packingAttachment.localeCompare(b.packingAttachment),
      ...getColumnSearchProps("Adjunto embalaje", "packingAttachment"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    },
    {
      title: "Adjunto contenido",
      dataIndex: "contentAttachment",
      key: "contentAttachment",
      sorter: (a, b) => a.contentAttachment.localeCompare(b.contentAttachment),
      ...getColumnSearchProps("Adjunto contenido", "contentAttachment"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    },
    {
      title: "Adjunto embalaje fin",
      dataIndex: "packingAttachmentEnd",
      key: "packingAttachmentEnd",
      sorter: (a, b) => a.packingAttachmentEnd.localeCompare(b.packingAttachmentEnd),
      ...getColumnSearchProps("Adjunto embalaje fin", "packingAttachmentEnd"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    },
    {
      title: "Finalizar",
      dataIndex: "finish",
      key: "finish",
      sorter: (a, b) => a.finish.localeCompare(b.finish),
      ...getColumnSearchProps("Finalizar", "finish"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    },
    {
      title: "Ingreso al sistema",
      dataIndex: "systemEntry",
      key: "systemEntry",
      sorter: (a, b) => a.systemEntry.localeCompare(b.systemEntry),
      ...getColumnSearchProps("Ingreso al sistema", "systemEntry"),
      render: text => text ? <a href="#">{text}</a> : "N/A",
    }
  ];

  const data = [
    {
      
      key: "1",
      ticketNumber: "1",
      branchCode: "106154",
      requestStatus: "Cerrado",
      guideNumber: "99 envios Administrativo",
      carrier: "Interrapidisimo",
      entryDate: "13/12/2023",
      category: "actualizar_datos",
      pqrDate: "13/12/2023",
      branchData: "edward ismael beltran sanchez 3183253280 dg 13 #8-75 panamericano",
      returnReason: "facturacion",
      dimensions: "longitud = 45, altura = 154, ancho = 49 = 545",
      bankData: "Banco XYZ, cuenta: 1234567890",
      additionalInfo: "Se requiere actualización urgente",
      response99: "Descargar adjunto 99envios",
      pqrResponse: "Respuesta de prueba PQR",
      file: "Archivo1.pdf",
      packingAttachment: "Embalaje1.jpg",
      contentAttachment: "Contenido1.jpg",
      packingAttachmentEnd: "EmbalajeFin1.jpg",
      finish: "Finalizar PQR",
      systemEntry: "Ingresado al sistema",
    },
    {
      key: "2",
      ticketNumber: "2",
      branchCode: "106155",
      requestStatus: "Cerrado",
      guideNumber: "99 envios Administrativo",
      carrier: "Interrapidisimo",
      entryDate: "13/12/2023",
      category: "actualizar_datos",
      pqrDate: "13/12/2023",
      branchData: "edward ismael beltran sanchez 3183253280 dg 13 #8-75 panamericano",
      returnReason: "facturacion",
      dimensions: "longitud = 45, altura = 154, ancho = 49 = 545",
      bankData: "Banco XYZ, cuenta: 1234567890",
      additionalInfo: "Se requiere actualización urgente",
      response99: "Descargar adjunto 99envios",
      pqrResponse: "Respuesta de prueba PQR",
      file: "Archivo1.pdf",
      packingAttachment: "Embalaje1.jpg",
      contentAttachment: "Contenido1.jpg",
      packingAttachmentEnd: "EmbalajeFin1.jpg",
      finish: "Finalizar PQR",
      systemEntry: "Ingresado al sistema",
    },
    {
      key: "3",
      ticketNumber: "3",
      branchCode: "106156",
      requestStatus: "Cerrado",
      guideNumber: "99 envios Administrativo",
      carrier: "Interrapidisimo",
      entryDate: "13/12/2023",
      category: "actualizar_datos",
      pqrDate: "13/12/2023",
      branchData: "edward ismael beltran sanchez 3183253280 dg 13 #8-75 panamericano",
      returnReason: "facturacion",
      dimensions: "longitud = 45, altura = 154, ancho = 49 = 545",
      bankData: "Banco XYZ, cuenta: 1234567890",
      additionalInfo: "Se requiere actualización urgente",
      response99: "Descargar adjunto 99envios",
      pqrResponse: "Respuesta de prueba PQR",
      file: "Archivo1.pdf",
      packingAttachment: "Embalaje1.jpg",
      contentAttachment: "Contenido1.jpg",
      packingAttachmentEnd: "EmbalajeFin1.jpg",
      finish: "Finalizar PQR",
      systemEntry: "Ingresado al sistema",
    },
    {
      key: "4",
      ticketNumber: "4",
      branchCode: "106157",
      requestStatus: "Cerrado",
      guideNumber: "99 envios Administrativo",
      carrier: "Interrapidisimo",
      entryDate: "13/12/2023",
      category: "actualizar_datos",
      pqrDate: "13/12/2023",
      branchData: "edward ismael beltran sanchez 3183253280 dg 13 #8-75 panamericano",
      returnReason: "facturacion",
      dimensions: "longitud = 45, altura = 154, ancho = 49 = 545",
      bankData: "Banco XYZ, cuenta: 1234567890",
      additionalInfo: "Se requiere actualización urgente",
      response99: "Descargar adjunto 99envios",
      pqrResponse: "Respuesta de prueba PQR",
      file: "Archivo1.pdf",
      packingAttachment: "Embalaje1.jpg",
      contentAttachment: "Contenido1.jpg",
      packingAttachmentEnd: "EmbalajeFin1.jpg",
      finish: "Finalizar PQR",
      systemEntry: "Ingresado al sistema",
    },
    // Agrega más datos si es necesario
  ];

  return (
    <>
      <NavbarAdminMex title={"PQR Finalizados"} />
      <div className="pqrfinalizado-container">
        <Typography.Title level={2} className="pqrfinalizado-title">
          Respuesta de PQR
        </Typography.Title>
        <Button 
          onClick={exportToExcel} 
          className="pqrfinalizado-button pqrfinalizado-export-button"
        >
          Exportar a Excel
        </Button>
        <Button 
          className="pqrfinalizado-button pqrfinalizado-manage-button"
          onClick={showModal}
        >
          Gestionar PQR
        </Button>
        <Typography.Title level={3} className="pqrfinalizado-subtitle">
          Tabla PQR
        </Typography.Title>
        <Table
          size="small" 
          columns={columns}
          dataSource={data}
          
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
          }}
          locale={{
            sortTitle: "Ordenar",
            triggerAsc: "Orden ascendente",
            triggerDesc: "Orden descendente",
            cancelSort: "Cancelar orden",
          }}
          scroll={{ x: 'max-content' }}
          className="compact-table pqrfinalizado-table"
          rowClassName={() => "custom-row-height"}
        />
        <Modal
          title={<strong>Formulario de Respuesta</strong>}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={700}
          className="pqrfinalizado-modal"
        >
          <FormPQRterminado />
        </Modal>
      </div>
    </>
  );
};

export default PqrFinalizado;