import React, { useState, useEffect } from 'react';
import { Table, Button, message } from 'antd';
import NavbarAdminMex from '../components/navbar';
import './ListaDeBodegas.css';

const ListaDeBodegas = () => {
  const [bodegas, setBodegas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBodegas();
  }, []);

  const fetchBodegas = async () => {
    try {
      const userId = localStorage.getItem('usuario_id');
      if (!userId) {
        message.error('No se encontró el ID de usuario');
        setLoading(false);
        return;
      }

      const response = await fetch(`https://api.99envios.app/api/picking/bodega/listar-bodegas/${userId}`);
      
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const data = await response.json();
      
      // Transformamos los datos para que coincidan con la estructura de la tabla
      const bodegasFormateadas = data.map((bodega, index) => ({
        key: (index + 1).toString(),
        idBodega: bodega.id_bodega || bodega.idBodega,
        nombreBodega: bodega.nombre_bodega || bodega.nombreBodega,
        ubicacion: bodega.ubicacion,
        capacidad: bodega.capacidad,
        estado: bodega.estado
      }));

      setBodegas(bodegasFormateadas);
    } catch (error) {
      console.error('Error al obtener las bodegas:', error);
      message.error('No se pudieron cargar las bodegas');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: '#', dataIndex: 'key', key: 'key' },
    { title: 'ID Bodega', dataIndex: 'idBodega', key: 'idBodega' },
    { title: 'Nombre Bodega', dataIndex: 'nombreBodega', key: 'nombreBodega' },
    { title: 'Ubicación', dataIndex: 'ubicacion', key: 'ubicacion' },
    { title: 'Capacidad (Unidades)', dataIndex: 'capacidad', key: 'capacidad' },
    { title: 'Estado', dataIndex: 'estado', key: 'estado' },
    {
      title: 'Acción',
      key: 'action',
      render: (text, record) => (
        <Button 
          type="primary" 
          onClick={() => handleRemitir(record.idBodega)}
          disabled={record.estado !== 'Operativa'}
        >
          Remitir a la Bodega
        </Button>
      ),
    }
  ];

  const handleRemitir = (idBodega) => {
    console.log(`Remitiendo a la bodega con ID: ${idBodega}`);
    // Aquí se puede agregar la lógica para remitir a la bodega
  };

  return (
    <>
      <NavbarAdminMex title="Lista de Bodegas" />
      <div className="lista-de-bodegas-container">
        <Table 
          columns={columns} 
          dataSource={bodegas} 
          pagination={{ pageSize: 5 }}
          loading={loading}
          locale={{
            emptyText: 'No hay bodegas disponibles'
          }}
        />
      </div>
    </>
  );
};

export default ListaDeBodegas;