import React, { useState } from 'react';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import './RechargeForm.css';

const RechargeForm = () => {
  const [branchCode, setBranchCode] = useState('');
  const [transactionPhoto, setTransactionPhoto] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('branchCode', branchCode);
    formData.append('transactionPhoto', transactionPhoto);

    try {
      const response = await fetch('https://tu-endpoint.com/api/recargas', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Manejar la respuesta exitosa
        alert('Recarga realizada con éxito.');
      } else {
        // Manejar errores
        alert('Hubo un error en la recarga.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error en la recarga.');
    }
  };

  return (
    <>
      <NavbarMex title={"Recargas"} />
      <div className="recharge-form-container">
        <h2>Recargas</h2>
        <p>Para recargar tu cuenta solo debes realizar una transferencia o consignación a nuestra cuenta Bancolombia con los siguientes datos:</p>
        <div className="account-details">
          <p><strong>I Love Group SAS</strong></p>
          <p><strong>Nit:</strong> 901217397-1</p>
          <p><strong>Cuenta de Ahorros</strong></p>
          <p><strong>Numero:</strong> 46700012263</p>
          <p><strong>Bancolombia</strong></p>
        </div>
        <p>Después de realizar el envío del dinero envíanos el soporte de la transacción con tu código de sucursal, tu nuevo saldo se verá reflejado en tu cuenta de 1 a 2 días hábiles</p>
        <form className="recharge-form" onSubmit={handleSubmit}>
          <label htmlFor="branchCode">Código sucursal</label>
          <input
            type="text"
            id="branchCode"
            name="branchCode"
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
            required
          />
          
          <label htmlFor="transactionPhoto">Foto transacción</label>
          <input
            type="file"
            id="transactionPhoto"
            name="transactionPhoto"
            onChange={(e) => setTransactionPhoto(e.target.files[0])}
            required
          />
          
          <button type="submit">ENVIAR</button>
        </form>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default RechargeForm;
