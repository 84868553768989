import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
  faTruck,
  faTimesCircle,
  faCheckCircle,
  faBox
} from '@fortawesome/free-solid-svg-icons';
import './Estilos/Dashboard.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import ToggleButton from '../../../components/toggle';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente funcional de React que muestra un panel de control para operaciones de conductor.
 * Maneja estados como disponibilidad, datos obtenidos y ubicación del usuario,
 * además de proporcionar elementos de UI para alternar la disponibilidad y ver el estado de órdenes.
 *
 * @function
 * @returns {JSX.Element} El panel de control para conductores.
 */
const DashboardDriver = () => {
  const [isAvaible, setIsAvaible] = useState(false);
  const [data, setData] = React.useState(null);
  const [unassignedOrdersCount, setUnassignedOrdersCount] = useState(0);
  const [userLocation, setUserLocation] = useState(null);

  /**
   * Hook useEffect que, al activarse, actualiza la ubicación del conductor en el servidor
   * si el usuario está disponible y localStorage contiene token/usuario_id válido.
   *
   * @returns {void}
   */
  useEffect(() => {
    if (userLocation && isAvaible) {
      const updateLocation = async () => {
        const token = localStorage.getItem('token');
        const usuario_id = localStorage.getItem('usuario_id');
        if (token && usuario_id) {
          try {
            const url = `https://api.99envios.app/api/repartidores/${usuario_id}`;
            await axios.put(url, {
              
              ubicacion: `${userLocation.latitude},${userLocation.longitude}`,
            });
            console.log('Ubicación actualizada:', userLocation);
          } catch (error) {
            console.error('Error al actualizar la ubicación:', error);
          }
        }
      };
      updateLocation();
    }
  }, [userLocation, isAvaible]);

  /**
   * Hook useEffect que obtiene datos desde el servidor y establece el estado del componente.
   * Recupera token y usuario_id de localStorage para solicitudes autorizadas.
   *
   * @returns {void}
   */
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const usuario_id = localStorage.getItem('usuario_id');
      if (token && usuario_id) {
        console.log("Token disponible", token);
        console.log("Usuario ID", usuario_id);
        try {
          const url = `https://api.99envios.app/api/panel-conductores/${usuario_id}/pedidos`;
        
          const responseDashboard = await axios.get(url, {
            // headers: {
            //   Authorization: `Bearer ${token}`
            // }}
          });
          setData(responseDashboard.data);
          console.log("usuario_id", usuario_id)

          // Llamada para obtener los pedidos no asignados
          const responseUnassigned = await axios.get(`https://api.99envios.app/api/pedidos-no-asignados/privado/${usuario_id}`);
          setUnassignedOrdersCount(responseUnassigned.data.length);
        } catch (error) {
          console.error('Error al obtener los datos de la API:', error);
        }
      } else {
        console.log('Token no disponible.');
      }
    };

    fetchData();
  }, []);

  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  });

  // ...
  const earnings = data?.estados_pedido.Ganancias_dia || 0;
  const todayEarnings = formatter.format(earnings);
  const onHold = data?.estados_pedido.En_Espera || 0;
  const toDeliver = data?.estados_pedido.En_proceso || 0;
  const failedDeliveries = data?.estados_pedido.Devuelto || 0;
  const delivered = data?.estados_pedido.Entregado || 0;
  // ...

  /**
   * Obtiene los datos de ubicación del navegador y actualiza el estado userLocation.
   * Registra errores cuando los servicios de ubicación no están disponibles o fallan.
   *
   * @function
   * @returns {void}
   */
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        setUserLocation(location);
        console.log('Ubicación obtenida:', location);
      }, (error) => {
        console.error('Error al obtener la ubicación:', error);
      });
    } else {
      console.error('Geolocalización no soportada por este navegador.');
    }
  };
  

  useEffect(() => {
    const intervalId = setInterval(getUserLocation, 15 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [isAvaible]);
  
  /**
   * Alterna la disponibilidad del conductor, actualiza este estado en el servidor
   * y opcionalmente informa la ubicación actual si el conductor está disponible.
   *
   * @async
   * @function
   * @returns {Promise<void>}
   */
  const toggleAvailability = async () => {
    console.log("Toggling availability");
    const newState = !isAvaible;
    setIsAvaible(newState);
  
    const token = localStorage.getItem('token');
    const usuario_id = localStorage.getItem('usuario_id');
  
    if (token && usuario_id) {
      try {
        const locationString = userLocation ? `${userLocation.latitude},${userLocation.longitude}` : null;
        const url = `https://api.99envios.app/api/repartidores/${usuario_id}`;
        const response = await axios.put(url, {
          estado: newState ? 1 : 0,
          ubicacion: newState ? locationString : null,
        });
        console.log("Estado actualizado", response.data.ubicacion);
      } catch (error) {
        console.error('Error al actualizar el estado:', error);
      }
    } else {
      console.error('No se encontró token en el almacenamiento local.');
      console.error('No se encontró el usuario_id en el almacenamiento local.');
    }
  };
  
  return (
    <div>

      <Navbar title={"Tablero"} />
      <div className="dashboard">
        <div className="dashboard-header">

          <h1>Tablero de Control</h1>
          <ToggleButton isToggled={isAvaible} onToggle={toggleAvailability} />
        </div>

        <div className="earnings">
          <span>💰 Ganancias de hoy:   {todayEarnings}</span>
        </div>

        <div className="stats-grid">
          <a href="/conductor/ruta-en-espera" className="stat-item">
            <FontAwesomeIcon icon={faHourglassHalf} />
            <div className="stat-number">{onHold}</div>
            <div className="stat-label">En espera</div>
          </a>
          <a href="/conductor/ruta-salir-entregar" className="stat-item">
            <FontAwesomeIcon icon={faTruck} />
            <div className="stat-number">{toDeliver}</div>
            <div className="stat-label">Por Entregar</div>
          </a>
          <a href="/conductor/entrega-fallida" className="stat-item">
            <FontAwesomeIcon icon={faTimesCircle} />
            <div className="stat-number">{failedDeliveries}</div>
            <div className="stat-label">Entregas Fallidas</div>
          </a>
          <a href="/conductor/ruta-entregado" className="stat-item">
            <FontAwesomeIcon icon={faCheckCircle} />
            <div className="stat-number">{delivered}</div>
            <div className="stat-label">Entregados</div>
          </a>
          <a href="/conductor/ruta-no-asignado" className="stat-item">
            <FontAwesomeIcon icon={faBox} />
            <div className="stat-number">{unassignedOrdersCount}</div>
            <div className="stat-label">No Asignados</div>
          </a>
        </div>




      </div>
      <BotonWhatsapp />
      <Footer />
    </div>


  );
}

export default DashboardDriver;