import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './navbarAdmin';
import './FeeManager.css';

const FeeManager = () => {
  const [fees, setFees] = useState([]);
  const [editFee, setEditFee] = useState(null);

  useEffect(() => {
    fetchFees();
  }, []);

  const fetchFees = async () => {
    try {
      const response = await axios.get('https://api.99envios.app/api/configuraciones');
      setFees(response.data);
    } catch (error) {
      console.error('Error fetching fees:', error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFee({ ...editFee, [name]: value });
  };

  const handleEdit = (fee) => {
    setEditFee(fee);
  };

  const handleSave = async () => {
    try {
      await axios.put(`https://api.99envios.app/api/configuraciones/${editFee.id}`, editFee);
      fetchFees();
      setEditFee(null);
    } catch (error) {
      console.error('Error updating fee:', error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="fee-manager">
        <h1 className='titleFee'>Fee Manager</h1>
        <table className="fee-table">
          <thead>
            <tr>
              <th>Pais</th>
              <th>FEE</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee) => (
              <tr key={fee.id}>
                <td>{fee.pais}</td>
                <td>{editFee && editFee.id === fee.id ? (
                  <input type="text" name="FEE" value={editFee.FEE} onChange={handleEditChange} />
                ) : fee.FEE}</td>
                <td>
                  {editFee && editFee.id === fee.id ? (
                    <button className="save-btn" onClick={handleSave}>Save</button>
                  ) : (
                    <button className="edit-btn" onClick={() => handleEdit(fee)}>Edit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FeeManager;
