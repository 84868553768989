import React, { useState, useEffect } from 'react';
import { Modal, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import './tablaInterrapidisimo.css';

const TablaInterrapidisimo = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/sucursales_inter');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Ocurrió un error al obtener los datos. Por favor intente de nuevo.');
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFileUpload = async () => {
    if (!file) {
      message.error('Por favor seleccione un archivo primero.');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post('https://99envios.app/api/sucursales_inter_import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Archivo cargado exitosamente');
      setModalVisible(false);
      fetchData(); // Recargar los datos después de la carga exitosa
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('Ocurrió un error al cargar el archivo. Por favor intente de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {

    const filteredData = data.map(({ id_users, codigo_sucursal, ...rest }) => rest);

    const worksheet = utils.json_to_sheet(filteredData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "data.xlsx");
  };


  const uploadProps = {
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    fileList: file ? [file] : [],
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    Object.values(item).some(val =>
      val && val.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  return (
    <>
      <NavbarAdminMex title="Tabla Interrapidisimo" />
      <div className="tabla-interrapidisimo-table-container">
        <div className="tabla-interrapidisimo-table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="tabla-interrapidisimo-search-input"
          />
        </div>
        <div className="tabla-interrapidisimo-button-group">
          <button onClick={() => setModalVisible(true)} className="tabla-interrapidisimo-upload-button">
            Cargar archivo Excel
          </button>
          <button onClick={exportToExcel} className="tabla-interrapidisimo-download-button">
            Descargar archivo Excel
          </button>
        </div>
        <div className="tabla-interrapidisimo-table-wrapper">
          <table className="tabla-interrapidisimo-custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('id')}>ID</th>
                <th onClick={() => handleSort('codigo_inter')}>Código Inter</th>
                <th onClick={() => handleSort('id_pais')}>ID País</th>
                <th onClick={() => handleSort('id_ciudad')}>ID Ciudad</th>
                <th onClick={() => handleSort('nombre_sucursal')}>Nombre Sucursal</th>
                <th onClick={() => handleSort('direccion')}>Dirección</th>
                <th onClick={() => handleSort('telefono')}>Teléfono</th>
                <th onClick={() => handleSort('nombre')}>Nombre</th>
                <th onClick={() => handleSort('tipo_identificacion')}>Tipo Identificación</th>
                <th onClick={() => handleSort('numero_identificacion')}>Número Identificación</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td> {/* Cambia codigo_sucursal por id */}
                  <td>{item.codigo_inter}</td>
                  <td>{item.id_pais}</td>
                  <td>{item.id_ciudad}</td>
                  <td>{item.nombre_sucursal}</td>
                  <td>{item.direccion}</td>
                  <td>{item.telefono}</td>
                  <td>{item.nombre}</td>
                  <td>{item.tipo_identificacion}</td>
                  <td>{item.numero_identificacion}</td>
                </tr>
              ))}
            </tbody>

            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.codigo_sucursal}</td>
                  <td>{item.codigo_inter}</td>
                  <td>{item.id_pais}</td>
                  <td>{item.id_ciudad}</td>
                  <td>{item.nombre_sucursal}</td>
                  <td>{item.direccion}</td>
                  <td>{item.telefono}</td>
                  <td>{item.nombre}</td>
                  <td>{item.tipo_identificacion}</td>
                  <td>{item.numero_identificacion}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="tabla-interrapidisimo-pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`tabla-interrapidisimo-page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <Modal
          title="Cargar archivo Excel"
          visible={modalVisible}
          onOk={handleFileUpload}
          onCancel={() => setModalVisible(false)}
          confirmLoading={loading}
        >
          <Upload {...uploadProps}>
            <button icon={<UploadOutlined />}>Seleccionar archivo</button>
          </Upload>
        </Modal>
      </div>
    </>
  );
};

export default TablaInterrapidisimo;