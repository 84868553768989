

import React from 'react';
import { Layout, Menu, Breadcrumb, Card, Row, Col } from 'antd';
import { DatePicker, Button, Statistic, Table } from 'antd';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import {
  ArrowUpOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CarOutlined,
  UserOutlined,
} from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment'
import Navbar from './navbarAdmin';
import { useEffect, useState } from 'react';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Meta } = Card;

function AdminPanel() {

  const [sucursalData, setSucursalData] = useState(null);
  const [userCounts, setUserCounts] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  useEffect(() => {
    fetchSucursalData();
  }, []);

  const fetchSucursalData = async () => {
    try {
      const response = await axios.get('https://api.99envios.app/api/datos-completos');
      const data = response.data;
      const estadisticas_pedidos = data.estado_counts.reduce((acc, item) => {
        acc[item.estado_pedido] = parseInt(item.count);
        return acc;
      }, { total: 0 });
  
      estadisticas_pedidos.total = Object.values(estadisticas_pedidos).reduce((sum, value) => sum + value, 0);
  
      setSucursalData({
        estadisticas_pedidos,
        destinos_comunes: [], // Ajusta esto según tus necesidades si hay datos de destinos comunes en la API
        registros_No_Asignados: { registros: [] }, // Ajusta esto según tus necesidades si hay datos de registros no asignados en la API
      });
  
      const userCounts = data.user_counts.reduce((acc, item) => {
        acc[item.id_rol] = parseInt(item.user_count);
        return acc;
      }, {});
      setUserCounts(userCounts);
    } catch (error) {
      console.error('Error fetching sucursal data:', error);
    }
  };
  



  const columns = [
    {
      title: 'ID pedido',
      dataIndex: 'ID_pedido',
      key: 'id',
    },
    {
      title: 'Fecha pedido',
      dataIndex: 'fecha_pedido',
      key: 'fecha',
      render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
  ];

  const datePickerColStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  };

  const datePickerStyle = {
    width: '100%',
    maxWidth: '250px',
  };



  const containerStyle = {
    padding: '10px',
    maxWidth: '600px',
    margin: 'auto',
    textAlign: 'center',
  };

  const cardStyle = {
    borderRadius: '40px',
    overflow: 'hidden',
    marginTop: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '400PX',

  };

  const ant = {
   backgroundColor: 'rgb(241, 249, 251)'
}


  const cardBodyStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  const iconAboveTitleStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4px',
    fontSize: '1em',
  }; 

  
  return (
    <>
    <Navbar />
    <div style={{
      padding: '15px',
      background: '#ececec',
      backgroundColor: '#F1EFEF',
      borderRadius: '8px',
      minHeight: '50vh',
    }}>
      <div style={containerStyle}>
        <h1 style={{ marginBottom: '20px', marginTop:"10%"}}>Tablero</h1>
        <Row gutter={[16, 32]} justify="center">
          <Col xs={24} sm={12} style={datePickerColStyle}>
            <label style={{ paddingRight: '8px', whiteSpace: 'nowrap', }}>Fecha inicio:</label>
            <DatePicker placeholder="Fecha de inicio" style={{ ...datePickerStyle, border: '1px solid green', borderRadius: '10px', }} onChange={setFechaDesde} />
          </Col>
          <Col xs={24} sm={12} style={datePickerColStyle}>
            <label style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>Fecha de corte:</label>
            <DatePicker placeholder="Fecha de corte" style={{ ...datePickerStyle, border: '1px solid red', borderRadius: '10px' }} onChange={setFechaHasta} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: '24px' }}>
          {/* <Col xs={24} sm={12}>
            <Button type="primary" style={buttonStyle} onClick={fetchData}>Buscar Resultados</Button>
          </Col> */}
        </Row>
      </div>

      <Row gutter={[16, 16]} justify="center">
      <Col xs={20} sm={5} md={5} lg={5}>
          <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
            <Statistic
              title={
                <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                  <CarOutlined style={{ color: '#03b1fc', fontSize: '2.5em' }} />
                  <span>Total Envíos</span>
                </div>
              }
              valueStyle={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333' }}
              value={sucursalData?.estadisticas_pedidos.total}
              suffix={<ArrowUpOutlined style={{ color: '#03b1fc' }} />}
              style={cardBodyStyle}
            />
          </Card>
        </Col>

        <Col xs={20} sm={5} md={5} lg={5}>
          <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
            <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
              <FaCheckCircle style={{ color: 'orange', fontSize: '2.5em' }} />
              <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Total Entregado</span>
            </div>
            <div style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
              {sucursalData ? sucursalData.estadisticas_pedidos.Entregado : 'Cargando...'} <ArrowUpOutlined style={{ color: '#faad14' }} />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <CheckCircleOutlined style={{ color: 'green', marginRight: '5px' }} />
              <span style={{ fontSize: '1em', color: 'grey' }}>Tasa de Entrega</span>
            </div>
            <div style={{ fontSize: '2em', color: 'green', fontWeight: 'bold' }}>
              {sucursalData ? (
                sucursalData.estadisticas_pedidos.Entregado > 0 ?
                  ((sucursalData.estadisticas_pedidos.Entregado / (sucursalData.estadisticas_pedidos.total - sucursalData.estadisticas_pedidos.Devuelto)) * 100).toFixed(2) + '%' :
                  '0 %'
              ) : (
                'Calculando...'
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <div style={{ margin: '20px 0' }}></div>

      <Row gutter={[16, 16]} justify="center">
        <Col xs={20} sm={5} md={5} lg={5}>
          <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
            <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
              <SyncOutlined style={{ color: '#f759ab', fontSize: '2.5em' }} />
              <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Devoluciones</span>
            </div>
            <div style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
              {sucursalData ? sucursalData.estadisticas_pedidos.Devuelto : 'Cargando...'}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <FaTimes style={{ color: '#ff4d4f', marginRight: '5px' }} />
              <span style={{ fontSize: '1em', color: 'grey' }}>Tasa de Devolución</span>
            </div>
            <div style={{ fontSize: '2em', color: '#ff4d4f', fontWeight: 'bold' }}>
              {sucursalData ? (
                sucursalData.estadisticas_pedidos.Devuelto > 0 ?
                  ((sucursalData.estadisticas_pedidos.Devuelto / sucursalData.estadisticas_pedidos.total) * 100).toFixed(2) + '%' :
                  '0 %'
              ) : (
                'Calculando...'
              )}
            </div>
          </Card>
        </Col>

        <Col xs={20} sm={5} md={5} lg={5}>
          <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
            <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
              <CheckCircleOutlined style={{ color: '#03b1fc', fontSize: '2.5em' }} />
              <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Estado Envios</span>
            </div>
            <div style={{ fontSize: '1.2em', color: '#333', marginBottom: '20px' }}>
              <p>Entregada: <strong>{sucursalData ? sucursalData.estadisticas_pedidos.Entregado : 'Cargando...'}</strong></p>
              <p>Devolución ratificada: <strong>{sucursalData ? sucursalData.estadisticas_pedidos.Devuelto : 'Cargando...'}</strong></p>
              <p>En proceso: <strong>{sucursalData ? sucursalData.estadisticas_pedidos['En proceso'] : 'Cargando...'}</strong></p>
              <p>En espera: <strong>{sucursalData ? sucursalData.estadisticas_pedidos['En espera'] : 'Cargando...'}</strong></p>
            </div>
          </Card>
        </Col>
        <Col xs={20} sm={5} md={5} lg={5}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                <UserOutlined style={{ color: '#03b1fc', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Usuarios Registrados</span>
              </div>
              <div style={{ fontSize: '1.2em', color: '#333', marginBottom: '20px' }}>
                <p>Sucursales: <strong>{userCounts ? userCounts[3] : 'Cargando...'}</strong></p>
                <p>Repartidores: <strong>{userCounts ? userCounts[4] : 'Cargando...'}</strong></p>
              </div>
            </Card>
          </Col>
      </Row>
    </div>
    </>
  );
}

export default AdminPanel;