import React, { useEffect, useState } from 'react'
import confetti from 'canvas-confetti';
import { Form, Input, Button, Select, Typography, message, Modal, Radio, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Ciudades from '../../ciudades/ciudades';
import axios from 'axios';
import './SendClient.css';

const { Option } = Select;
const { Text } = Typography;

const SendClientPage = ({ selectedOrder }) => {
    const [form] = Form.useForm();
    const [costo, setCosto] = useState(null);
    const [precioProveedor, setPrecioProveedor] = useState(null);
    const [transportadora, setTransportadora] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [inputValue, setInputValue] = useState(1);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [idsucursal, setIdsucursal] = useState('');
    // Estados adicionales para preenvío y PDF
    const [numeroPreenvio, setNumeroPreenvio] = useState('');
    const [generarGuiaDisabled, setGenerarGuiaDisabled] = useState(false);
    const sucursalId = localStorage.getItem('codigoSucursal');
    const [selectedTransportadora, setSelectedTransportadora] = useState(null);
    const idShopy = selectedOrder.id;
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false); // Nuevo estado para el spinner de envío





    const handleGuiaSubmit = async () => {
        setLoadingSubmit(true); // Mostrar spinner
        try {
            const token = localStorage.getItem('token'); // Obtener el token desde el localStorage

            // Configuración de los encabezados
            const config = {
                headers: {
                    'Authorization': token ? `Bearer ${token}` : '',  // Incluir el token si existe
                    'Content-Type': 'application/json'
                }
            };

            // Datos de la orden para enviar en la solicitud
            const guiaData = {
                IdProducto: producto.id_producto,
                IdVariacion: selectedVariation || '',
                Cantidad: formValues.quantity,
                AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
                valorDeclarado: formValues.salePrice,
                pesoProducto: 1,
                nombreProducto: formValues.product,
                Destinatario: {
                    nombre: formValues.firstName,
                    primerApellido: formValues.lastName.split(' ')[0] || '',
                    segundoApellido: formValues.lastName.split(' ')[1] || '',
                    telefono: formValues.phone,
                    direccion: formValues.address,
                    idLocalidad: formValues.city,
                    correo: formValues.email
                },
                Observaciones: formValues.notes,
                transportadora: {
                    pais: 'colombia',
                    nombre: envios[selectedImage] || ''
                },
                origenCreacion: 6,
                idShopy: idShopy,
            };

            // Realizar la solicitud POST
            const response = await axios.post(
                `https://99envios.app/api/orden_save/${localStorage.getItem('usuario_id')}`,
                guiaData,
                config
            );

            if (response.status === 200) {
                setNumeroPreenvio(response.data.numeroPreenvio);
                message.success(`Guía generada con éxito, número de preenvío: ${response.data.numeroPreenvio}`);

                // Descarga automática del PDF
                if (response.data.pdf) {
                    const base64String = response.data.pdf.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');
                    const pdfBlob = new Blob([Uint8Array.from(atob(base64String), c => c.charCodeAt(0))], {
                        type: 'application/pdf',
                    });
                    const blobUrl = URL.createObjectURL(pdfBlob);

                    // Crear enlace para descarga
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `Guia_${response.data.numeroPreenvio || 'SinNumero'}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    message.success('PDF descargado exitosamente');
                } else {
                    message.warning('No se encontró un PDF en la respuesta');
                }
            } else {
                throw new Error('Error al generar la guía');
            }
        } catch (error) {
            console.error(error);
            message.error('Error al generar la guía');
        } finally {
            setLoadingSubmit(false); // Ocultar spinner
        }
    };








    useEffect(() => {
        if (selectedOrder) {
            form.setFieldsValue({
                firstName: selectedOrder.customer_first_name || '',
                lastName: selectedOrder.customer_last_name || '',
                phone: selectedOrder.customer_phone || '',
                email: selectedOrder.customer_email || '',
                address: selectedOrder.shipping_address || '',
                city: selectedOrder.shipping_city || '',
                salePrice: selectedOrder.product_price || 0,
                product: selectedOrder.product_title || '',
                notes: selectedOrder.fulfillment_status || '',
                quantity: selectedOrder.product_quantity || 1,
            });
            setCosto(parseFloat(selectedOrder.product_price) || 0);
            setPrecioProveedor(parseFloat(selectedOrder.product_price) || 0);
            setSubtotal((selectedOrder.product_price || 0) * (selectedOrder.product_quantity || 1));
        }
    }, [selectedOrder, form]);





    const usuario_id = localStorage.getItem('usuario_id');

    useEffect(() => {
        const fetchSucursalId = async () => {
            try {
                const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
                setIdsucursal(response.data);
            } catch (error) {
                console.error('Error al obtener el ID de la sucursal', error);
            }
        };

        if (usuario_id) {
            fetchSucursalId();
        }
    }, [usuario_id]);

    const [cotizaciones, setCotizaciones] = useState({
        coordinadora: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
        interrapidisimo: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
        servientrega: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
        tcc: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 }
    });
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        notes: '',
        payment: '',
        product: '',
        quantity: 1,
        salePrice: 0,
        store: '',
        aplicaContrapago: true  // Nuevo campo para AplicaContrapago
    });
    const [producto, setProducto] = useState({
        id_producto: '',
        id_sucursal: '',
        id_categoria: '',
        precio_proveedor: '',
        precio_sugerido: '',
        cantidad_disponible: '',
        created_at: '',
        descripcion_producto: '',
        estado_variacion: '',
        imagenes: [],
        informacion_adicional: '',
        nombre_producto: '',
        peso_producto: 0, // valor por defecto de peso_producto
        sku: '',
        updated_at: '',
        variaciones: [] // Inicializado como un array vacío
    });


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [orderResponse, setOrderResponse] = useState(null);
    const pesoTotal = (producto.peso_producto ?? 0) * Number(formValues.quantity);
    const subtotalString = String(subtotal);

    const cotizacionData = {

        destino: {
            nombre: "",
            codigo: formValues.city,
        },
        origen: {
            nombre: "",
            codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal, // aquí se usa el peso total calculado
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: formValues.aplicaContrapago,
        valorDeclarado: subtotalString,
    };

    const images = [
        '/Images/coordi.png',
        '/Images/inter.png',
        '/Images/servi.png',
        '/Images/tcc.svg'
    ];

    const envios = [
        'coordinadora',
        'interrapidisimo',
        'servientrega',
        'tcc'
    ];

    const handleChange = value => {
        if (value < 1 || value > producto.cantidad_disponible) {
            message.error(`La cantidad debe estar entre 1 y ${producto.cantidad_disponible}`);
        }
        setInputValue(value);
        form.setFieldsValue({ quantity: value });
    };

    function formatCurrency(value, locale = 'es-CO', currency = 'COP') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    }

    useEffect(() => {
        const fetchProductData = async () => {
            setLoading(true);
            const sucursalId = localStorage.getItem('codigoSucursal');
            if (!sucursalId) {
                console.error("El ID de la sucursal no se encuentra en el localStorage.");
                setLoading(false);
                return;
            }

            try {
                // Realiza la solicitud a la API con el ID de la sucursal
                const response = await axios.get(`https://integration.99envios.app/api/ordersShopify/${sucursalId}`);
                const data = response.data;

                // Filtra el producto usando el `order_id` proporcionado en selectedOrder
                const foundProduct = data.find(prod => prod.order_id === selectedOrder.order_id);

                if (foundProduct) {
                    // Asigna los datos obtenidos al estado
                    setProducto({
                        id_producto: foundProduct.product_id,
                        nombre_producto: foundProduct.product_title,
                        precio_sugerido: parseFloat(foundProduct.product_price) || 0,
                        peso_producto: parseFloat(foundProduct.product_weight) || 0,
                        cantidad_disponible: foundProduct.product_quantity || 1,
                        descripcion_producto: foundProduct.product_title,
                        ...foundProduct
                    });

                    setCosto(parseFloat(foundProduct.product_price) || 0);
                    setPrecioProveedor(parseFloat(foundProduct.product_price) || 0);
                    form.setFieldsValue({
                        salePrice: foundProduct.product_price || 0,
                        product: foundProduct.product_title,
                        notes: foundProduct.product_title
                    });
                } else {
                    console.error("Producto no encontrado en la respuesta de la API.");
                }
            } catch (error) {
                console.error('Error al obtener los datos de la API:', error);
            } finally {
                setLoading(false);
            }
        };

        if (selectedOrder) {
            fetchProductData();
        }
    }, [selectedOrder]);




    const onValuesChangePedido = (changedValues, allValues) => {
        // Asegúrate de que los valores sean numéricos
        const salePrice = parseFloat(allValues.salePrice || 0);
        const quantity = parseInt(allValues.quantity || 1, 10);

        // Calcula el nuevo subtotal
        const newSubtotal = salePrice * quantity;

        // Calcula el total incluyendo transporte y costos
        const shippingCost = transportadora || 0;
        const supplierCost = precioProveedor * quantity || 0;
        const newTotal = newSubtotal + shippingCost - supplierCost;

        // Actualiza los estados correspondientes
        setSubtotal(newSubtotal);
        setFormValues({ ...allValues });
        form.setFieldsValue({ total: newTotal }); // Actualiza el campo de total en el formulario
    };



    const handleAutomaticRequote = async (newSubtotal, currentFormValues) => {
        setLoading(true);
        try {
            const pesoTotal = producto.peso_producto * Number(currentFormValues.quantity);
            const subtotalString = String(subtotal);
            const cotizacionData = {
                destino: {
                    nombre: "",
                    codigo: currentFormValues.city,
                },
                origen: {
                    nombre: "",
                    codigo: "",
                },
                IdTipoEntrega: 1,
                IdServicio: 1,
                peso: pesoTotal,
                largo: 1,
                ancho: 1,
                alto: 1,
                fecha: "28-06-2024",
                AplicaContrapago: currentFormValues.aplicaContrapago,
                valorDeclarado: subtotalString,
            };

            console.log('Datos para la nueva cotización:', cotizacionData);

            const response = await axios.post(
                `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
                cotizacionData
            );
            console.log('Respuesta de la API (requote):', response.data);
            setCotizaciones(response.data);
        } catch (error) {
            console.error('Error al generar la nueva cotización:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateTotal = (values) => {
        const subtotal = values.salePrice * values.quantity;
        const shippingCost = transportadora; // Este es el costo de envío seleccionado
        const supplierCost = producto.precio_proveedor * values.quantity;
        const total = subtotal - supplierCost - shippingCost;
        return total;
    };

    const handleImageClick = (index) => {
        setSelectedImage(index);
        const cotizacion = cotizaciones[envios[index]];
        const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
        const totalCotizacion = cotizacion.valor + cotizacion.valor_contrapago + comisionInterna + cotizacion.sobreflete;

        setTransportadora(totalCotizacion);
        setSelectedTransportadora(envios[index]); // Aquí se configura el nombre de la transportadora
        form.setFieldsValue({ total: calculateTotal({ ...formValues, salePrice: formValues.salePrice, quantity: inputValue }) });
    };


    const handleCotizarClick = async () => {
        setLoading(true);
        try {
            // Mostrar valores antes de la validación
            console.log('Valores antes de la validación:', form.getFieldsValue());

            // Convertir valores a números antes de validar
            form.setFieldsValue({
                salePrice: Number(formValues.salePrice),
                quantity: Number(formValues.quantity),
            });

            // Mostrar valores después de convertir
            console.log('Valores después de la conversión:', form.getFieldsValue());

            // Validar todos los campos antes de cotizar
            await form.validateFields();

            // Calcular el subtotal con valores numéricos
            const subtotal = Number(formValues.salePrice) * Number(formValues.quantity);
            console.log('Subtotal calculado:', subtotal);

            const pesoTotal = producto.peso_producto * Number(formValues.quantity);

            // Actualizar cotizacionData con el valor del subtotal
            const cotizacionData = {
                destino: {
                    nombre: "",
                    codigo: formValues.city,
                },
                origen: {
                    nombre: "",
                    codigo: "",
                },
                IdTipoEntrega: 1,
                IdServicio: 1,
                peso: pesoTotal,
                largo: 1,
                ancho: 1,
                alto: 1,
                fecha: "28-06-2024",
                AplicaContrapago: formValues.aplicaContrapago, // Usar el valor del formulario
                valorDeclarado: subtotalString, // Usar el valor del subtotal
            };

            // Mostrar datos que se enviarán en la petición
            console.log('Datos para la cotización:', cotizacionData);

            // Intentar la petición POST a la API
            const response = await axios.post(
                `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
                cotizacionData
            );
            console.log('Respuesta de la API:', response.data);
            setCotizaciones(response.data);
            message.success('Cotización generada con éxito');
        } catch (error) {
            // Mostrar mensaje específico si la validación es la que falla
            if (error.errorFields) {
                message.error('Error de validación: revise los campos del formulario.');
                console.error('Error de validación:', error.errorFields);
                console.log('Campos que fallaron la validación:', error.errorFields);
            } else {
                message.error('Error al generar la cotización');
                console.error('Error al generar la cotización:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isModalVisible) {
            confetti({
                particleCount: 150,
                spread: 70,
                origin: { y: 0.5 }, // Ajustar la altura para que el confeti aparezca en el modal
                zIndex: 9999, // Asegúrate de que el confeti esté por encima del modal
            });
        }
    }, [isModalVisible]);

    const downloadPDF = async () => {
        setLoadingPDF(true); // Mostrar spinner
        try {
            const pdfData = {
                transportadora: { pais: "colombia", nombre: selectedTransportadora },
                guia: numeroPreenvio,
                AplicaContrapago: formValues.aplicaContrapago,
                origenCreacion: 6,
                idShopy: selectedOrder.id,
            };

            const response = await axios.post(
                `https://integration.99envios.app/api/sucursal/pdf/${localStorage.getItem('codigoSucursal')}`,
                pdfData,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            );

            if (response.status === 200) {
                const base64String = response.data.replace(/\s/g, '').replace(/-/g, '+').replace(/_/g, '/');
                const pdfBlob = new Blob([Uint8Array.from(atob(base64String), c => c.charCodeAt(0))], {
                    type: 'application/pdf',
                });
                const blobUrl = URL.createObjectURL(pdfBlob);
                window.open(blobUrl);
                message.success('PDF descargado exitosamente');
                setIsModalVisible(false);
            } else {
                throw new Error('Error al generar el PDF');
            }
        } catch (error) {
            message.error('Error al descargar el PDF');
        } finally {
            setLoadingPDF(false); // Ocultar spinner
        }
    };



    useEffect(() => {
        if (numeroPreenvio) {
            downloadPDF();
        }
    }, [numeroPreenvio]);





    const handleConfirmarClick = async () => {
        setLoading(true);
        try {
            const totalGanar = calculateTotal(formValues);

            if (totalGanar < 0) {
                message.error('El monto a ganar es negativo. No puedes confirmar y enviar el pedido.');
                return;
            }

            await form.validateFields();
            const idShopy = selectedOrder.id;
            console.log("id recuperado", idShopy);
            const orderData = {
                IdProducto: producto.id_producto,
                IdVariacion: selectedVariation || '',
                Cantidad: formValues.quantity,
                AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
                valorDeclarado: formValues.salePrice,
                Destinatario: {
                    nombre: formValues.firstName,
                    primerApellido: formValues.lastName.split(' ')[0] || '',
                    segundoApellido: formValues.lastName.split(' ')[1] || '',
                    telefono: formValues.phone,
                    direccion: formValues.address,
                    idLocalidad: formValues.city,
                    correo: formValues.email
                },
                Observaciones: formValues.notes,
                transportadora: {
                    pais: 'colombia',
                    nombre: envios[selectedImage] || ''
                },
                origenCreacion: 6,
                idShopy: idShopy,
            };

            const id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token'); // Obtener el token del localStorage

            // Configurar los encabezados
            const config = {
                headers: {
                    'Authorization': token ? `Bearer ${token}` : '',  // Incluir el token si existe
                    'Content-Type': 'application/json'
                }
            };

            // Realizar la solicitud POST con los datos de la orden y la configuración de encabezados
            const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);

            if (response.status === 200) {
                setOrderResponse(response.data);
                setIsModalVisible(true);
                message.success('Pedido confirmado y enviado con éxito');
            } else {
                throw new Error('Error al enviar el pedido');
            }
        } catch (error) {
            message.error('Error al enviar el pedido');
        } finally {
            setLoading(false);
        }
    };


    const handleVariationChange = (value) => {
        setSelectedVariation(value);
        const selectedVar = producto.variaciones.find(variation => variation.id === value);
        setInputValue(1);  // Reinicia la cantidad a 1 al cambiar de variación
        form.setFieldsValue({ quantity: 1, salePrice: producto.precio_sugerido });
        message.info(`Seleccionaste la variación con ID: ${value}, Talla: ${selectedVar.talla}, Color: ${selectedVar.color}`);
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        setOrderResponse(null); // Reseteamos la respuesta al cerrar el modal
    };

    return (
        <Spin spinning={loading}>
            <div className="enviosDrop">
                <div className="formularioEnvio">
                    <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
                        <Form.Item
                            name="firstName"
                            label="Nombre(s) destinatario"
                            rules={[{ required: true, message: 'Por favor ingrese el nombre del destinatario' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            label="Apellido(s) destinatario"
                            rules={[{ required: true, message: 'Por favor ingrese el apellido del destinatario' }]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            name="phone"
                            label="Teléfono destinatario"
                            rules={[{ required: true, message: 'Por favor ingrese el teléfono del destinatario' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="Email destinatario (Opcional)"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            label="Dirección destinatario"
                            rules={[{ required: true, message: 'Por favor ingrese la dirección del destinatario' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item
                            name="city"
                            label="Ciudad destinatario"
                            rules={[{ required: true, message: 'Seleccione la ciudad del destinatario' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Seleccione una ciudad"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }

                            >


                                {Ciudades.map((ciudad) => (
                                    <Option key={ciudad.value} value={ciudad.value}>
                                        {ciudad.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                        <Form.Item
                            name="notes"
                            label="Notas para el Proveedor"
                            rules={[{ required: true, message: 'Por favor ingrese alguna nota' }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Button type="primary" onClick={handleCotizarClick} block>
                            Cotizar
                        </Button>
                    </Form>
                </div>

                <div className="transportadora">
                    {images.map((image, index) => {
                        const cotizacion = cotizaciones[envios[index]];
                        const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
                        const totalCotizacion = cotizacion.valor + cotizacion.valor_contrapago + comisionInterna + cotizacion.sobreflete;

                        return (
                            <div
                                key={index}
                                className="flete"
                                style={{
                                    border: selectedImage === index ? '1px solid rgba(247, 118, 67, 0.5)' : '1px solid rgba(128, 128, 128, 0.5)',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleImageClick(index)}
                            >
                                <img src={image} alt={`Imagen ${index + 1}`} />
                                <p>{formatCurrency(totalCotizacion)}</p>
                            </div>
                        );
                    })}
                </div>

                <div className="pedidoEnvio">
                    <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
                        <Form.Item
                            label="Producto"
                            name="product"
                            rules={[{ required: true, message: 'Por favor selecciona un producto' }]}
                        >
                            <Select placeholder="Selecciona un producto">
                                <Option value={producto.nombre_producto}>{producto.nombre_producto}</Option>
                            </Select>
                        </Form.Item>

                        {/* Select para Variaciones */}
                        {Array.isArray(producto.variaciones) && producto.variaciones.length > 0 && (
                            <Form.Item
                                name="variation"
                                label="Selecciona una variación"
                                rules={[{ required: true, message: 'Por favor selecciona una variación' }]}
                            >
                                <Select placeholder="Selecciona una variación" onChange={handleVariationChange}>
                                    {Array.isArray(producto.variaciones) &&
                                        producto.variaciones.map(variation => (
                                            <Option key={variation.id} value={variation.id}>
                                                {`ID: ${variation.id}, Talla: ${variation.talla}, Color: ${variation.color}, Stock: ${variation.cantidad_disponible}`}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Text>Precio sugerido: ${costo}</Text>
                        <Form.Item
                            label="Precio de venta"
                            name="salePrice"
                            initialValue={producto.precio_sugerido}
                            rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
                        >
                            <Input type="number" />
                        </Form.Item>

                        <Text>Existencia: {producto.cantidad_disponible}</Text>
                        <Form.Item
                            label="Cantidad"
                            name="quantity"
                            initialValue="1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa la cantidad',
                                },
                                {
                                    validator: (_, value) => {
                                        if (value < 1 || value > producto.cantidad_disponible) {
                                            return Promise.reject(`La cantidad debe estar entre 1 y ${producto.cantidad_disponible}`);
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                value={inputValue}
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item
                            name="aplicaContrapago"
                            label="¿Aplica Contrapago?"
                            initialValue={true}
                        >
                            <Radio.Group disabled>
                                <Radio value={true}>Sí</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Form>
                    <div className="order-summary">
                        <div className="order-summary-header">Resumen de la orden</div>
                        <div className="order-summary-content">
                            <div className="order-summary-item">
                                <div className="item-description">{formValues.product}</div>
                                <div className="item-price">
                                    {formatCurrency(formValues.salePrice)} x {formValues.quantity}
                                </div>
                            </div>
                            <div className="order-summary-total">
                                <div className="total-label">Sub Total :</div>
                                <div className="total-amount">{formatCurrency(subtotal)}</div>
                            </div>
                            <div className="order-summary-total">
                                <div className="total-label">Precio Proveedor :</div>
                                <div className="total-amount">{formatCurrency(precioProveedor)}</div>
                            </div>
                            <div className="order-summary-total">
                                <div className="total-label">Precio de Envío :</div>
                                <div className="total-amount">{formatCurrency(transportadora)}</div>
                            </div>
                            <div className="order-summary-total">
                                <div className="total-label">Comisión de la plataforma :</div>
                                <div className="total-amount">$ 0</div>
                            </div>
                            <div className="order-summary-total">
                                <div className="total-label">Total:</div>
                                <div className="total-amount">{formatCurrency(subtotal + transportadora)}</div>
                            </div>
                            <Spin spinning={loadingSubmit}>
                                <div className="order-summary-footer">
                                    <Button
                                        type="primary"
                                        onClick={handleGuiaSubmit}
                                        block
                                        disabled={calculateTotal(formValues) < 0} // Deshabilita el botón si el total es negativo
                                    >
                                        Confirmar y Enviar Pedido
                                    </Button>
                                </div>
                            </Spin>
                        </div>
                    </div>

                </div>

                <Modal
                    title="Generando PDF"
                    visible={isModalVisible}
                    footer={null}
                    onCancel={() => setIsModalVisible(false)}
                >
                    <p>Por favor, espera mientras se genera y descarga el PDF...</p>
                </Modal>
                <Modal
                    title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Orden</span>}
                    visible={isModalVisible}
                    onCancel={handleModalOk}
                    footer={null}
                    centered
                    width={600}
                    bodyStyle={{
                        padding: '20px',
                        backgroundColor: '#f7f7f7',
                        borderRadius: '8px',
                    }}
                >
                    {selectedOrder && (
                        <div style={{ lineHeight: '1.6', fontSize: '16px' }}>
                            <div style={{ marginBottom: '16px', textAlign: 'center' }}>
                                <strong style={{ fontSize: '18px', textDecoration: 'underline' }}>Resumen de la Orden</strong>
                            </div>
                            <div style={{ marginBottom: '16px' }}>
                                <p><strong>Número de Orden:</strong> {selectedOrder.order_number}</p>
                                <p><strong>Email del Cliente:</strong> {selectedOrder.customer_email || 'N/A'}</p>
                                <p><strong>Nombre del Cliente:</strong> {selectedOrder.customer_first_name} {selectedOrder.customer_last_name}</p>
                                <p><strong>Teléfono:</strong> {selectedOrder.customer_phone || 'N/A'}</p>
                                <p><strong>Dirección de Envío:</strong> {selectedOrder.shipping_address}, {selectedOrder.shipping_city}, {selectedOrder.shipping_country}</p>
                                <p><strong>Valor Total:</strong> {formatCurrency(selectedOrder.total_price)}</p>
                                <p><strong>Subtotal:</strong> {formatCurrency(selectedOrder.subtotal_price)}</p>
                                <p><strong>Estado Financiero:</strong> {selectedOrder.financial_status}</p>
                                <p><strong>Estado de Envío:</strong> {selectedOrder.fulfillment_status}</p>
                            </div>
                        </div>
                    )}
                </Modal>





            </div>
        </Spin>
    );
};

export default SendClientPage;