import React from 'react';
import './sidebar.css';

// Imagino que tendrás algo así en un archivo CSS:

// ... más estilos ...

const orders = [
  {
    id: '172435',
    name: 'Alexandra Pelaez',
    address: 'Carrera 17 A No 113 36 barrio santa barbara',
    city: 'Bogota, 110256',
  },
  {
    id: '173515',
    name: 'Frank Escobar',
    address: 'Calle 63 A 50 Parque lago los novios',
    city: 'Bogota, 110260',
  },
  {
    id: '172713',
    name: 'Rubilma Zabala Hincapié',
    address: 'CRR 98A 30',
    city: 'Bogota, 110256',
  },
  // ... más pedidos ...
];

const Sidebar = () => (
  <div className="sidebarV">
    <div className="search-bar">
      <input type="text" placeholder="Buscar por título, dirección o referencia" />
      <button>🔍</button>
    </div>
    <ul className="order-list">
      {orders.map((order) => (
        <li key={order.id} className="order-item">
          <div className="order-header">Pedido #{order.id}</div>
          <div>{order.name}</div>
          <div>{order.address}</div>
          <div>{order.city}</div>
          <div className="order-actions">
            <button className="details-btn">Order details</button>
            {/* Más acciones si son necesarias */}
          </div>
        </li>
      ))}
    </ul>
    <div className="pagination">
      <button>Anterior</button>
      <button>automatico</button>
      {/* Más botones de paginación si son necesarios */}
    </div>
    <div className="create-route">
      <button>Crear rutas</button>
    </div>
  </div>
);

export default Sidebar;
