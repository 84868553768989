import React from 'react';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import { useNavigate } from 'react-router-dom';
import './Estilos/entregaFail.css';

/**
 * Componente para mostrar la confirmación de una entrega fallida.
 * @component
 */
const EntregaFail = () => {
    const navigate = useNavigate();

    /**
     * Maneja el clic en el botón para volver al tablero.
     */
    function handleDetailsClick() {
        navigate('/conductor');
    }

    return (
        <>
            <Navbar title={"Entrega Fallida"} />
            <div className="fail-animation-container">
                <div className="error-circle">
                    <svg className="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="error-circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="error-x" fill="none" d="M16 16l20 20m0-20L16 36"/>
                    </svg>
                </div>
                <h1 className='titulosF'>Entrega Fallida</h1>
                <h2 className='SubTitulo'>Lo sentimos, </h2>
                <button className="btn btn-primary" onClick={handleDetailsClick}>¡Vayamos a la siguiente entrega!</button>
            </div>
            <Footer />
        </>
    );
};

export default EntregaFail;