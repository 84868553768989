import React from 'react';
import './toggle.css';

// Añade props para recibir el estado y la función de cambio del componente padre
export default function ToggleButton({ isToggled, onToggle }) {
  
  return (
    <div className="availability-toggle">
      <span>Yo estoy Disponible</span>
      <label className="switch">
        {/* Utiliza isToggled y onToggle del componente padre */}
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
