import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import PedidosTablas from '../../components/tabletool'
import './pedidos.css'; 
import * as FileSaver from 'file-saver'; // Librería para exportar CSV
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';

function exportToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n'; // Crea los encabezados
  
    const csvRows = data.map(row => Object.values(row).join(',')).join('\n');
  
    const blob = new Blob([header + csvRows], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'pedidos.csv');
  }
  


const PedidosTabla = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchInput = useRef();

    useEffect(() => {
        setLoading(true);
        const id_usuario = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/reporte-pedidos/${id_usuario}`;
        axios.get(url)
            .then(response => {
                console.log('Response:', response.data);
                setPedidos(response.data.pedidos); 
                setTableData(response.data.pedidos); // Actualiza tableData con los datos obtenidos
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al obtener los pedidos:', error);
                setLoading(false);
            });
    }, []);
    
    const handleExportClick = () => {
        exportToCSV(tableData); 
      };


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };



    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const setRowClassName = (record) => {
        if (record.estado_pedido === 'Devuelto') {
          return 'row-devuelto'; // Clase CSS para pedidos devueltos
        } else if (record.estado_pedido === 'En proceso') {
          return 'row-en-proceso'; // Clase CSS para pedidos en proceso
        }
        return '';
      };
      

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reiniciar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

   

    const columns = [
        {
            title: 'ID Pedido',
            dataIndex: 'ID_pedido',
            key: 'ID_pedido',
            ...getColumnSearchProps('ID_pedido'),
        },
        {
            title: 'Pedido',
            dataIndex: 'nombre_cliente',
            key: 'nombre_cliente',
            ...getColumnSearchProps('nombre_cliente'),
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha_pedido',
            key: 'fecha_pedido',
            ...getColumnSearchProps('fecha_pedido'),
        },
        {
            title: 'Estado',
            dataIndex: 'estado_pedido',
            key: 'estado_pedido',
            ...getColumnSearchProps('estado_pedido'),
        },
        {
            title: 'Repartidor',
            dataIndex: 'nombre_repartidor',
            key: 'nombre_repartidor',
            ...getColumnSearchProps('nombre_repartidor'),
        },
        {
            title: 'Total Pedido',
            dataIndex: 'total',  // Cambiado de 'total_pedido' a 'total' para coincidir con la respuesta de la API
            key: 'total',
            ...getColumnSearchProps('total'),
            render: (text) => {
                // Convierte el valor a un número
                const total = parseFloat(text);
                // Formatea el número a pesos colombianos
                const formattedTotal = new Intl.NumberFormat('es-CO', {
                    style: 'currency',
                    currency: 'COP',
                    minimumFractionDigits: 2,
                }).format(total);
                // Retorna el valor formateado
                return formattedTotal;
            },
        },
    ];
    



    return (
        <>
            <Navbar title={"Tabla de pedidos"} pedidos={"Pedidos"} crearPedido={"Crear Pedidos"} crearConductor={"Crear conductor"} reportes={"Reportes"} />
            <div className="pedidos-tabla">
                <PedidosTablas />
                <Button onClick={handleExportClick}>Exportar a CSV</Button>
                <Table
                    columns={columns}
                    dataSource={pedidos}
                    loading={loading}
                    pagination={{ pageSize: 10 }}
                    scroll={{ y: 240 }}
                    rowClassName={setRowClassName}
                />
            </div>
            <BotonWhatsapp />
            <Footer />
        </>
    );
};

export default PedidosTabla;