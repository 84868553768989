import React, { useState } from 'react';
import { Button } from 'antd';

const ExpandableText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // Si el texto es más largo que maxLength, truncarlo y mostrar "Ver más"
  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  return (
    <div>
      <p>
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </p>
      <Button type="link" onClick={toggleExpansion}>
        {isExpanded ? 'Ver menos' : 'Ver más'}
      </Button>
    </div>
  );
};

export default ExpandableText;
