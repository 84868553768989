import React, { useState } from 'react';
import { FaBars, FaHome, FaTachometerAlt, FaUser, FaCogs, FaSignOutAlt } from 'react-icons/fa';
import { message } from 'antd'; 
import './Navbar.css'; // Asegúrate de crear y ajustar este archivo CSS

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
  
    // Redirige al usuario a la página de inicio o de inicio de sesión
    window.location.href = '/';
  
    // Opcionalmente, puedes mostrar un mensaje de confirmación
    message.success('Sesión cerrada exitosamente');
  };

  return (
    <nav className="navbara">
      <div className="navbara-logo">
        AdminPanel
      </div>
      <div className="navbara-toggle" onClick={toggleMenu}>
        <FaBars />
      </div>
      <ul className={`navbara-menu ${isOpen ? 'open' : ''}`}>
        
        <li className="navbara-item">
          <a href="/admin" className="navbara-link">
            <FaTachometerAlt className="navbara-icon" /> Dashboard
          </a>
        </li>
        <li className="navbara-item">
          <a href="#" className="navbara-link">
            <FaUser className="navbara-icon" /> Profile
          </a>
        </li>
        <li className="navbara-item">
          <a href="/admin/fee" className="navbara-link">
            <FaCogs className="navbara-icon" /> Fee
          </a>
        </li>
        <li className="navbara-item">
          <a onClick={handleLogout} className="navbara-link">
            <FaSignOutAlt className="navbara-icon" /> Logout
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
