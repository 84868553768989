import './navbar.css';
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export default function Component({ title }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
  const [email, setEmail] = useState('');
  const rol_id = localStorage.getItem('rol_id');
  const [isAvaible, setIsAvaible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const toggleAvailability = async () => {
    const newState = !isAvaible;
    setIsAvaible(newState);

    const token = localStorage.getItem('token');
    const usuario_id = localStorage.getItem('usuario_id');


    if (token && usuario_id) {
      try {
        const url = `https://api.99envios.app/api/repartidores/${usuario_id}`
        const response = await axios.put(url, {
          estado: newState ? 0 : 1,
        }, {
        });
        console.log("Estado actualizado", response.data);
      } catch (error) {
        console.error('Error al actualizar el estado:', error);
      }
    } else {
      console.error('No se encontró token en el almacenamiento local.');
      console.error('no se encontro el usuario_id en el almacenamiento local.');

    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');

    // Redirige al usuario a la página de inicio o de inicio de sesión
    window.location.href = '/';

    // Opcionalmente, puedes mostrar un mensaje de confirmación
    message.success('Sesión cerrada exitosamente');
  };

  // Esta función se invoca cuando el botón de hamburguesa es clickeado
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Cambia el estado de visibilidad del sidebar
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <>
      <nav key="1" className="navbar">
        <div className="container">
          <div className="left-section">
            <button className="hamburger" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <span className="material-icons">menu</span>
            </button>
            <span className="dashboard-title">{title}</span>
          </div>
          <div className="right-section">
            <div className="greeting-container">
              <span className="greeting">Hola, {email}</span>
              <a href="https://api.99envios.app/user/profile" className="panel-link">99Panel</a>
              <a className="logout-link" onClick={handleLogout}>Cerrar Sesión</a>
            </div>
          </div>
        </div>
      </nav>
      <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <a href="https://api.99envios.app/user/profile" className="sidebar-item-nav">
          <img alt="ENVÍOS" className="logo-navbar-comp" src="/images/download.png" />
        </a>
        {rol_id === '3' && (
          <a href="/tablero" className={`sidebar-item-nav ${isActive('/tablero')}`}>
            <span className="material-icons">dashboard</span> Tablero
          </a>
        )}
        {rol_id === '4' && (
          <a href="/conductor" className={`sidebar-item-nav ${isActive('/conductor')}`}>
            <span className="material-icons">directions_car</span> Tablero
          </a>
        )}
        {rol_id === '3' && (
          <>
            <div
              className={`sidebar-item-nav with-submenu ${
                isActive('/crear/pruebas') ||
                isActive('/pedidos') ||
                isActive('/crear/conductor') ||
                isActive('/inventario') ||
                isActive('/dropshippingGlobal')
                  ? 'active'
                  : ''
              }`}
              onClick={() => {
                setIsSubMenuOpen(!isSubMenuOpen);
                setIsSubMenuOpen2(false);
              }}
            >
              <span className="material-icons">business</span> Administrar Sucursal
              <span className="material-icons">{isSubMenuOpen ? 'expand_more' : 'chevron_right'}</span>
            </div>
            <div className={`submenu ${isSubMenuOpen ? 'open' : ''}`}>
              <a href="/crear/pruebas" className={`sidebar-item-nav ${isActive('/crear/pruebas')}`}>
                <span className="material-icons">assessment</span> Reportes
              </a>
              <a href="/pedidos" className={`sidebar-item-nav ${isActive('/pedidos')}`}>
                <span className="material-icons">receipt</span> Pedidos
              </a>
              <a href="/crear/conductor" className={`sidebar-item-nav ${isActive('/crear/conductor')}`}>
                <span className="material-icons">person_add</span> Crear Conductor
              </a>
              <a href="/inventario" className={`sidebar-item-nav ${isActive('/inventario')}`}>
                <span className="material-icons">inventory</span> Inventario
              </a>
              <a
                href="/dropshippingGlobal"
                className={`sidebar-item-nav ${isActive('/dropshippingGlobal')}`}
                onClick={() => {
                  localStorage.setItem('/tablero', '/dropshippingGlobal');
                }}
              >
                <span className="material-icons">store</span> Dropshipping
              </a>
            </div>
          </>
        )}
        {rol_id === '3' && (
          <>
            <div
              className={`sidebar-item-nav with-submenu ${
                isActive('/pedido/visitas') ||
                isActive('/pedido/vehiculo') ||
                isActive('/pedidos/editar')
                  ? 'active'
                  : ''
              }`}
              onClick={() => {
                setIsSubMenuOpen2(!isSubMenuOpen2);
                setIsSubMenuOpen(false);
              }}

            >
              <span className="material-icons">shopping_cart</span> Pedidos
              <span className="material-icons">{isSubMenuOpen2 ? 'expand_more' : 'chevron_right'}</span>
            </div>
            <div className={`submenu ${isSubMenuOpen2 ? 'open' : ''}`}>
              <a href="/pedido/visitas" className={`sidebar-item-nav ${isActive('/pedido/visitas')}`}>
                <span className="material-icons">assignment_ind</span> Pedido individual
              </a>
              <a href="/pedido/vehiculo" className={`sidebar-item-nav ${isActive('/pedido/vehiculo')}`}>
                <span className="material-icons">dynamic_feed</span> Pedido masivo
              </a>
              <a href="/pedidos/editar" className={`sidebar-item-nav ${isActive('/pedidos/editar')}`}>
                <span className="material-icons">edit</span> Editar pedidos
              </a>
            </div>
          </>
        )}
        
        <a onClick={handleLogout} className="sidebar-item-nav">
          <span className="material-icons">exit_to_app</span>Logout
        </a>
        {/* <a href="/novedades" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faBullhorn} /> Novedades
        </a>
        <a href="/recoleccion" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faRecycle} /> Recolección
        </a>
        <a href="/guias" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faClipboardList} /> Guias
        </a>
        <a href="/guias-masivo" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faBoxes} /> Guías Masivo
        </a>
        <a href="/Transporte" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faTruck} /> Transporte
        </a>
        <a href="/wallet" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faWallet} /> Wallet
        </a>
        <a href="/pagos" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faHandHoldingUsd} /> Pagos
        </a>
        <a href="/recargas" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faBatteryFull} /> Recargas
        </a>
        <a href="/auto-pqr" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faBook} /> Auto PQR
        </a>
        <a href="/tutoriales" className="sidebar-item-nav">
          <FontAwesomeIcon icon={faBook} /> Tutoriales
        </a> */}

      </aside>
    </>
  )
}