import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import axios from 'axios';
import NavbarPicking from '../components/navbarPicking/navbarPicking';
import './OrdenesyBodega.css';  // Estilos personalizados

const { Option } = Select;

const OrdenesyBodega = () => {
  const [data, setData] = useState([]); // Aquí se almacenan los datos de la tabla
  const [filteredData, setFilteredData] = useState([]); // Para almacenar los datos filtrados
  const [search, setSearch] = useState('');
  const [transportadoraFilter, setTransportadoraFilter] = useState('');
  const [estadoEnvioFilter, setEstadoEnvioFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/picking/bodega/listar-ordenes-sucursal/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: index + 1,
          id_variacion: item.id_variacion,
          talla: item.talla,
          color: item.color,
          nombre_producto: item.nombre_producto,
          fecha_estado: item.fecha_estado,
          estado_envio: item.estado_envio,
          transportadora: item.transportadora,
          valor_proveedor: item.valor_producto,
          valor_total: item.valor_producto,
          valor_utilidad: item.valor_utilidad,
          numero_guia: item.numero_guia,
          nombre_destinatario: item.nombre_bodega,
          telefono_destinatario: item.telefono_bodega,
          direccion_destinatario: item.direccion_bodega,
          selected: false,
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  // Función para manejar la búsqueda
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filtered = data.filter(item => item.nombreProducto.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  // Función para manejar el filtro de transportadora
  const handleTransportadoraFilter = (value) => {
    setTransportadoraFilter(value);
    const filtered = data.filter(item => item.transportadora === value || !value);
    setFilteredData(filtered);
  };

  // Función para manejar el filtro de estado del envío
  const handleEstadoEnvioFilter = (value) => {
    setEstadoEnvioFilter(value);
    const filtered = data.filter(item => item.estadoEnvio === value || !value);
    setFilteredData(filtered);
  };

  // Columnas de la tabla
  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Fecha de estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Valor de Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    // agregar numero de guia
    {
      title: ' Numero de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Destinatario',
      dataIndex: 'nombre_destinatario',
      key: 'nombre_destinatario',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono_destinatario',
      key: 'telefono_destinatario',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion_destinatario',
      key: 'direccion_destinatario',
    },
  ];

  const handleCheckboxChange = (key) => {
    const updatedData = data.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setFilteredData(updatedData);
    setSelectedItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  return (
    <>
    <NavbarPicking title="Órdenes y Bodega" />
    <div className="ordenes-y-bodega-container">
      <Space style={{ marginBottom: 16 }}>
        <Button>Acciones</Button>
        <Select
          placeholder="Filtrar por Transportadora"
          value={transportadoraFilter}
          onChange={handleTransportadoraFilter}
        >
          <Option value="">Todas</Option>
          <Option value="interrapidisimo">interrapidisimo</Option>
          <Option value="tcc">tcc</Option>
        </Select>

        <Select
          placeholder="Filtrar por Estado de Envío"
          value={estadoEnvioFilter}
          onChange={handleEstadoEnvioFilter}
        >
          <Option value="">Todos</Option>
          <Option value="creado">creado</Option>
          <Option value="anulado">anulado</Option>
        </Select>

        <Input.Search
          placeholder="Buscar"
          value={search}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </Space>

      <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 5 }} />
    </div>
    </>
  );
};

export default OrdenesyBodega;
