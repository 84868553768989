import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button } from 'antd';
import NavbarSales from '../../components/navbar/navbarSales';
import 'antd/dist/antd.css';
import { Tag } from 'antd';
import './DatosSucursales.css';
import { SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const DatosSucursales = () => {
  const [sucursalesData, setSucursalesData] = useState([]);
  const userId = localStorage.getItem('usuario_id');

  const fetchSucursalesData = async () => {
    try {
      const response = await axios.get(`https://99envios.app/api/online/vendedor-sucursales/${userId}`);
      setSucursalesData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de las sucursales', error);
    }
  };

  useEffect(() => {
    fetchSucursalesData();
  }, []);

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo.localeCompare(b.codigo),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Total Envíos',
      dataIndex: 'total_preenvios',
      key: 'total_preenvios',
      sorter: (a, b) => a.total_preenvios - b.total_preenvios,
      render: (total) => (
        <Tag color={total === 0 ? 'red' : total > 10 ? 'green' : 'orange'}>
          {total}
        </Tag>
      ),
    },
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(sucursalesData); // Use fetched data
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DatosSucursales');
    XLSX.writeFile(workbook, 'DatosSucursales.xlsx');
  };

  const sucursalesPorComisionarData = sucursalesData.filter(sucursal => sucursal.total_preenvios > 20);

  const sucursalesColumns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo - b.codigo,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
      filters: [...new Set(sucursalesData.map(item => item.ciudad))].map(ciudad => ({
        text: ciudad,
        value: ciudad,
      })),
      onFilter: (value, record) => record.ciudad.includes(value),
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Total Envíos',
      dataIndex: 'total_preenvios',
      key: 'total_preenvios',
      sorter: (a, b) => a.total_preenvios - b.total_preenvios,
      render: (total) => (
        <Tag color={total === 0 ? 'red' : total > 10 ? 'green' : 'orange'}>
          {total}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <NavbarSales title="Datos Sucursales" />
      <div className="datos-sucursales-container">
        <div className="datos-sucursales-header">
          <h2>Tabla de datos de Sucursales a cargo</h2>
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleExportToExcel}
            className="datos-sucursales-export-btn"
            style={{ height: '44px'}}
          >
            Exportar a Excel
          </Button>
        </div>
        <div className="datos-sucursales-table">
          <Table
            columns={columns}
            dataSource={sucursalesData} // Use fetched data
            pagination={{ pageSize: 5 }}
            bordered
            title={() => 'Datos Sucursales'}
            rowKey="codigoSucursal"
          />
        </div>
        <div className="datos-sucursales-table">
          <Table 
            columns={sucursalesColumns}
            dataSource={sucursalesPorComisionarData} // Use filtered data
            rowKey="codigo"
            title={() => 'Sucursales por Comisionar'}
            pagination={{ 
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} sucursales`
            }}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </>
  );
};

export default DatosSucursales;
