import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import './LandingDrop.css';
import SendClientPage from "../SendClientPage/SendClientPage";
import CloseIcon from '@mui/icons-material/Close';
const LandingDrop = () => {

  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [name, setName] = useState(null);
  const [stock, setStock] = useState(null);
  const [prodID, setProdID] = useState(null);
  const handleButtonClick = (prodID) => {
    setMostrarEnvios(true); 
    setProdID(prodID)
  };

  const handleCloseModal = () => {
    setMostrarEnvios(false); // Cierra el modal
  };

  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    const fetchProducts = async () => {
      const id = localStorage.getItem('usuario_id');
      const url = `https://api.99envios.app/api/inventarios/${id}`;
      console.log(url)
      try {
        const { data } = await axios.get(url);
        
        const mappedProducts = data.map(product => ({
          id: product.id_producto,
          name: product.nombre_producto,
          category: product.id_categoria,
          stock: product.cantidad_disponible,
          provider: 'Desconocido', // Si tienes proveedor en tus datos, lo mapeas aquí
          supplierPrice: parseFloat(product.costo),
          suggestedPrice: parseFloat(product.costo) * 1.5, 
          price: parseFloat(product.costo),
          images: [
            product.foto_1 || '/logo.png', // Mostrar logo si no hay foto
            product.foto_2 || '/logo.png', // Mostrar logo si no hay foto
          ],
          link: `/product/${product.id_producto}`
        }));
        setProducts(mappedProducts);
      } catch (error) {
        console.error('Error al cargar productos:', error);
      }
    };

    fetchProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <>
      
      <NavbarDropshipping />
      <div className="landing-drop-container">
        <h1>Productos</h1>
        <div className="product-grid">
          {products.map(product => (
            <div className="product-cardL" key={product.id}>
              <div className="product-carousel">
                <Slider {...settings}>
                  {product.images.map((image, index) => (
                    <div key={index}>
                      <Link to={product.link}>
                        <img src={image} alt={product.name} />
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="product-details">
                <p className="category">{product.category}</p>
                <p className="stock">Stock <span>{product.stock}</span></p>
                <h3>{product.name}</h3>
                <p className="provider">Provider: <span>{product.provider}</span></p>
                <p className="supplier-price">Precio proveedor: ${product.supplierPrice.toLocaleString()}</p>
                <p className="suggested-price">Precio sugerido: <strong>${product.suggestedPrice.toLocaleString()}</strong></p>
              </div>
              <button className="add-to-cart-btn"  onClick={() => handleButtonClick(product.id)}>Enviar al cliente</button>
            </div>
          ))}
        </div>
      </div>
      {mostrarEnvios && (
          <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <SendClientPage prodID={prodID}/>
              <button className="close-modal-btn" onClick={handleCloseModal}>
                <CloseIcon/>
              </button>
            </div>
          </div>
      )}
    </>
  );
};

export default LandingDrop;
