import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './bitacoraCartera.css';

const BitacoraCartera = () => {
  const [dashboardData, setDashboardData] = useState({
    "0": {
      sucursal: "",
      codigo_sucursal: "",
      Valor_disponible: 0,
      Payments: 0,
      preenvios: {
        total_consignado_preenvios: { valor: 0, cantidad: 0 },
        total_servicio_SinContrapago: { valor: 0, cantidad: 0, desactivadas: 0, reintegros: 0 },
        total_devolucion_preenvios: { valor: 0, cantidad: 0 },
        total_servicio_contrapago: { valor: 0, cantidad: 0, desactivadas: 0 },
        total_valor_declarado: { valor: 0, cantidad: 0 },
        cantidad_preenvios_novedades: 0,
        cantidad_preenvios_reclamo_oficina: 0
      },
      transacciones: {
        total_devoluciones: { valor: 0, cantidad: 0 },
        total_comision_retiro: { valor: 0, cantidad: 0 },
        total_retiros: { valor: 0, cantidad: 0 },
        total_envios_sin_contrapago: { valor: 0, cantidad: 0, desactivadas: 0 }
      },
      recargas: {
        total_recargas: { valor: 0, cantidad: 0 },
        total_bono: 0
      },
      ordenes: {
        total_dueño: 0,
        total_vendedor: 0
      }
    },
    saldo_disponible: 0,
    saldo_disponible_new: 0,
    saldo_disponible_old: 0
  });
  const [availableBalance, setAvailableBalance] = useState(null);

  const updateAvailableBalance = async () => {
    const user_id = localStorage.getItem('usuario_id');
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch balance');

      const data = await response.json();
      setAvailableBalance(data.saldo_disponible);
    } catch (err) {
      console.error('Error al actualizar el saldo disponible:', err);
    }
  };
  
  useEffect(() => {
    const fetchDashboardData = async () => {
      const codigo_sucursal = localStorage.getItem('codigoSucursal');
      try {
        const response = await axios.get(`https://api.99envios.app/public/api/online/listar_estado_cuenta/${codigo_sucursal}`);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
    updateAvailableBalance(); // Fetch available balance when component mounts
  }, []);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const formatBalance = (balance) => {
    if (balance === null) return 'Cargando...';
    const absBalance = Math.abs(balance);
    const formattedBalance = absBalance.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return balance < 0 ? `-${formattedBalance}` : formattedBalance;
  };
  
  const calculateDescuentosRetiros = (data) => {
    // Defensively check each level of the object
    if (!data || !data["0"].transacciones || !data["0"].transacciones.total_devoluciones || !data["0"].transacciones.total_comision_retiro || !data["0"].transacciones.total_retiros || !data["0"].transacciones.total_envios_sin_contrapago) {
      return 0;
    }
    return data["0"].transacciones.total_devoluciones.valor + 
           data["0"].transacciones.total_comision_retiro.valor +
           data["0"].transacciones.total_retiros.valor +
           data["0"].transacciones.total_envios_sin_contrapago.valor;
  };

  return (
    <>
      <div className="dashboard-containerBCU">
        <div className="cards-containerBCU">
          <div className="rowBCU">
            <div className="cardBCU">
              <div className="icon-circle-BCU local-shipping-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px' }}> payments </span>
              </div>
              <h3>Total Pagos</h3>
              <p className="main-number">{formatBalance(dashboardData["0"].Payments)}</p>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU check-circle-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 1 }}> receipt_long </span>
              </div>
              <h3>Descuentos y Retiros</h3>
              <p className="main-number">{formatBalance(calculateDescuentosRetiros(dashboardData))}</p>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU report-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> account_balance_wallet </span>
              </div>
              <h3>Saldo Disponible</h3>
              <p className="main-number">{formatBalance(availableBalance)}</p>
            </div>
          </div>
          <div className="rowBCU">
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> local_shipping </span>
              </div>
              <h3>Preenvíos</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Consignado Preenvíos</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].preenvios?.total_consignado_preenvios?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].preenvios?.total_consignado_preenvios?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Servicio Sin Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].preenvios?.total_servicio_SinContrapago?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].preenvios?.total_servicio_SinContrapago?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Devolución Preenvíos</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].preenvios?.total_devolucion_preenvios?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].preenvios?.total_devolucion_preenvios?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Servicio Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].preenvios?.total_servicio_contrapago?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].preenvios?.total_servicio_contrapago?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Valor Declarado</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].preenvios?.total_valor_declarado?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].preenvios?.total_valor_declarado?.cantidad ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> swap_horiz </span>
              </div>
              <h3>Transacciones</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Devoluciones</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].transacciones?.total_devoluciones?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].transacciones?.total_devoluciones?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Comisión Retiro</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].transacciones?.total_comision_retiro?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].transacciones?.total_comision_retiro?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Retiros</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].transacciones?.total_retiros?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].transacciones?.total_retiros?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Envíos Sin Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].transacciones?.total_envios_sin_contrapago?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].transacciones?.total_envios_sin_contrapago?.cantidad ?? 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> attach_money </span>
              </div>
              <h3>Recargas</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Recargas</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].recargas?.total_recargas?.valor ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData["0"].recargas?.total_recargas?.cantidad ?? 0}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Bono</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].recargas?.total_bono ?? 0)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="icon-circle-BCU track-changes-icon" style={{ marginTop: "30px" }}>
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> shopping_cart </span>
              </div>
              <h3>Órdenes</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td>Total Dueño</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].ordenes?.total_dueño ?? 0)}</td>
                  </tr>
                  <tr>
                    <td>Total Vendedor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData["0"].ordenes?.total_vendedor ?? 0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>            
          </div>
        </div>
      </div>
    </>
  );
}

export default BitacoraCartera;