import React, { useState } from "react";
import { Button, Input, Select, Upload, Form, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const FormPQR = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const buttonStyle = {
    backgroundColor: '#3F51B5',
    borderColor: '#3F51B5',
    color: 'white',
    fontSize: '16px',
    height: '40px',
    padding: '0 20px',
    borderRadius: '8px',
  };

  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append('respuesta', values.pqrResponse);
    formData.append('estado', values.action);
    
    // Solo adjuntar el archivo si existe uno en el fileList
    if (fileList.length > 0) {
      formData.append('adjunto', fileList[0].originFileObj);
    }

    const ticketNumber = values.ticketNumber;

    try {
      await axios.post(`https://99envios.app/api/online/pqr_update/${ticketNumber}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error al actualizar PQR:', error);
      message.error('Error al actualizar. Por favor, intente de nuevo.');
    }
  };

  const handleUploadChange = ({ file, fileList }) => {
    setFileList(fileList);
    console.log('Archivo capturado:', file.originFileObj);
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Número de ticket"
          name="ticketNumber"
          rules={[{ required: true, message: 'Por favor ingrese el número de ticket' }]}
        >
          <Input placeholder="Ingrese el número de ticket" />
        </Form.Item>

        <Form.Item
          label="Indique la acción a realizar"
          name="action"
          rules={[{ required: true, message: 'Por favor seleccione una acción' }]}
        >
          <Select>
            <Option value="En proceso">En proceso</Option>
            <Option value="Resuelto">Resuelto</Option>
            <Option value="Rechazado">Rechazado</Option>
            <Option value="Correo Enviado">Correo Enviado</Option>
            <Option value="Cerrado">Cerrado</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Respuesta PQR"
          name="pqrResponse"
          rules={[{ required: true, message: 'Por favor escriba la respuesta PQR' }]}
        >
          <Input.TextArea rows={4} placeholder="Escriba la respuesta PQR" />
        </Form.Item>

        <Form.Item
          label="Adjuntar Archivo (PDF, foto, jpg)"
          name="adjunto"
        >
          <Upload 
            beforeUpload={() => false}
            onChange={handleUploadChange}
            fileList={fileList}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" style={buttonStyle} htmlType="submit">
            Procesar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormPQR;