import React, { useState } from 'react';
import { Upload, message, Card, Button, Modal } from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import NavbarUsuarioBodega from '../interfazBodega/navbarUsuarioBodega';
import './archivosExternos.css';
import excelExample1 from './ordenes_productos-example.xlsx';
import excelExample2 from './import 2.xlsx';

const { Dragger } = Upload;

const ArchivosExternos = () => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const usuario_id = localStorage.getItem('usuario_id');

  const handleUpload = (info, type) => {
    // Guardamos el archivo completo incluyendo el objeto File
    setFileToUpload({
      file: info.file,
      name: info.file.name,
      originFileObj: info.file // Aseguramos que tenemos el objeto File original
    });
    setUploadType(type);
    setIsModalVisible(true);
  };

  const handleConfirmUpload = async () => {
    if (!fileToUpload || !fileToUpload.file) {
      message.error('No hay archivo para cargar');
      return;
    }

    const formData = new FormData();
    formData.append('archivo', fileToUpload.file); // Usamos el archivo original

    try {
      const url = uploadType === 1 
        ? `https://api.99envios.app/api/picking/bodega/orden-bodega-externa-1/${usuario_id}`
        : `https://api.99envios.app/api/picking/bodega/orden-bodega-externa-3/${usuario_id}`;

      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        message.success(`${fileToUpload.name} archivo subido exitosamente.`);
      } else {
        const errorData = await response.json();
        message.error(`Error al subir ${fileToUpload.name}: ${errorData.message || 'Error desconocido'}`);
      }
    } catch (error) {
      console.error('Error en la carga:', error);
      message.error('Error en la carga del archivo');
    }

    setIsModalVisible(false);
    setFileToUpload(null);
  };

  const uploadProps = (type) => ({
    name: 'file',
    multiple: false,
    accept: '.xlsx, .xls',
    beforeUpload: (file) => {
      handleUpload({ 
        file: file,  // Pasamos el archivo directamente
        name: file.name 
      }, type);
      return false;
    },
    onRemove: () => {
      setFileToUpload(null);
      return true;
    }
  });

  const handleDownloadExample1 = () => {
    const link = document.createElement('a');
    link.href = excelExample1;
    link.download = 'ordenes_productos-example.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExample2 = () => {
    const link = document.createElement('a');
    link.href = excelExample2;
    link.download = 'import 2.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <NavbarUsuarioBodega title="Archivos Externos" />
      <div className="archivos-externos-container">
        <div className="upload-section">
          <Card title="Cargar Archivo Shopify" className="upload-card">
            <Dragger {...uploadProps(1)}>
              <InboxOutlined className="upload-icon" />
              <p className="upload-text">Click o arrastra el archivo</p>
            </Dragger>
            <Button icon={<DownloadOutlined />} onClick={handleDownloadExample1} className="download-button">
              Ejemplo
            </Button>
          </Card>
        </div>

        <div className="upload-section">
          <Card title="Cargar Archivo Effi" className="upload-card">
            <Dragger {...uploadProps(2)}>
              <InboxOutlined className="upload-icon" />
              <p className="upload-text">Click o arrastra el archivo</p>
            </Dragger>
            <Button icon={<DownloadOutlined />} onClick={handleDownloadExample2} className="download-button">
              Ejemplo
            </Button>
          </Card>
        </div>

        <Modal
          title="Confirmar carga de archivo"
          open={isModalVisible}
          onOk={handleConfirmUpload}
          onCancel={() => setIsModalVisible(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>¿Está seguro de cargar el archivo {fileToUpload?.name}?</p>
        </Modal>
      </div>
    </>
  );
};

export default ArchivosExternos;
