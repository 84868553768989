import React, { useState, useEffect } from 'react';
import { Spin } from 'antd'; // Importa Spin de Ant Design
import axios from 'axios';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import './dashboard.css';
import moment from 'moment';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente principal para mostrar el tablero de control con estadísticas y datos relevantes.
 * @component
 */
const Dashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [idsucursal, setIdsucursal] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  // Datos simulados para el tablero
  const dashboardData = {
    data: {
      estados_envios_cartera: [
        { estado_del_envio: "Entregado", total_envios: 150 },
        { estado_del_envio: "Creado", total_envios: 50 },
        { estado_del_envio: "Transito nacional", total_envios: 30 },
        { estado_del_envio: "Centro acopio", total_envios: 20 },
        { estado_del_envio: "Devolución ratificada", total_envios: 10 }
      ],
      porcentajeFormateado: "75.00%",
      porcentajeFormateadoDevoluciones: "5.00%",
      destinos_mas_usados: [
        { ciudad_destino: "Ciudad A", total_envios: 40 },
        { ciudad_destino: "Ciudad B", total_envios: 35 },
        { ciudad_destino: "Ciudad C", total_envios: 30 },
        { ciudad_destino: "Ciudad D", total_envios: 25 },
        { ciudad_destino: "Ciudad E", total_envios: 20 },
        { ciudad_destino: "Ciudad F", total_envios: 15 }
      ],
      resultado_en_movimiento: [
        { telefono: "3001234567", num_ocurrencias: 5 },
        { telefono: "3007654321", num_ocurrencias: 3 },
        { telefono: "3009876543", num_ocurrencias: 2 }
      ]
    }
  };

  const ordenDeseado = [
    "Entregada",
    "Devolución ratificada",
    "Devolucion Regional",
    "Creado",
    "Transito nacional",
    "Centro acopio",
    "AUMitida",
    "Mercancía no despachada por el"
  ];

  const estadosOrdenados = dashboardData?.data.estados_envios_cartera
    ? dashboardData.data.estados_envios_cartera.sort((a, b) =>
      ordenDeseado.indexOf(a.estado_del_envio) - ordenDeseado.indexOf(b.estado_del_envio)
    )
    : [];

  const usuario_id = localStorage.getItem('usuario_id');

  /**
   * Hook de efecto para obtener el ID de la sucursal del usuario actual.
   * Se ejecuta una vez al montar el componente.
   */
  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  /**
   * Unifica los estados de envíos entregados.
   */
  const estadosUnificados = dashboardData?.data.estados_envios_cartera.reduce((acumulador, estado) => {
    const estadoNombre = estado.estado_del_envio;

    if (estadoNombre === "Entregada" || estadoNombre === "Entregado") {
      const entregadoExistente = acumulador.find(e => e.estado_del_envio === "Entregado");
      if (entregadoExistente) {
        entregadoExistente.total_envios += estado.total_envios;
      } else {
        acumulador.push({ estado_del_envio: "Entregado", total_envios: estado.total_envios });
      }
    } else {
      // Mantiene el resto de los estados tal como están
      acumulador.push(estado);
    }

    return acumulador;
  }, []);

  const totalEnvios = dashboardData?.data.estados_envios_cartera
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  const totalEntregado = dashboardData?.data.estados_envios_cartera
    .filter((estado) => ["Entregada", "Entregado"].includes(estado.estado_del_envio))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  const devoluciones = dashboardData?.data.estados_envios_cartera
    .filter((estado) => ["Devolución ratificada", "Devolucion Regional"].includes(estado.estado_del_envio))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  const destinosUsados = dashboardData?.data.destinos_mas_usados || [];
  const devolucionesFrecuentes = dashboardData?.data.resultado_en_movimiento || [];

  return (
    <>
      <Navbar title={"Tablero"} />
      <div className="dashboard-containerM">
        <div className="date-pickerM-containerM">
          <div className="date-pickerM-row">
            <div className="date-pickerM">
              <label>Fecha inicio:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-pickerM">
              <label>Fecha de corte:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="button-container">
            <button /*onClick={fetchData}*/ className="search-buttonM">Buscar Resultados</button>
          </div>
        </div>

        {loading ? (
          <div className="loading-container">
            <Spin size="large" /> {/* Rueda de carga de Ant Design */}
          </div>
        ) : (
          dashboardData && (
            <div className="cards-containerUM">
              {/* Tarjetas principales */}
              <div className="rowUM">
                <div className="cardUM">
                  <div className="icon-circle-UM local-shipping-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>local_shipping</span>
                  </div>
                  <h3>Total Envíos</h3>
                  <p className="main-number">{totalEnvios}</p>
                </div>
                <div className="cardUM">
                  <div className="icon-circle-UM check-circle-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>trending_up</span>
                  </div>
                  <h3>Total Entregado</h3>
                  <p className="main-number">{totalEntregado}</p>
                  <div className="rate-container">
                    <span className="material-icons success-icon">check_circle</span>
                    <p className="rate">Tasa de Entrega</p>
                  </div>
                  <p className="success" style={{ color: "#4caf50", textAlign: "left", fontSize: "18px" }}>
                    {dashboardData?.data.porcentajeFormateado || '0.00%'}
                  </p>
                </div>
                <div className="cardUM">
                  <div className="icon-circle-UM report-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>repartition</span>
                  </div>
                  <h3>Devoluciones</h3>
                  <p className="main-number">{devoluciones}</p>
                  <div className="rate-container">
                    <span className="material-icons error-icon">cancel</span>
                    <p className="rate">Tasa de Devolución</p>
                  </div>
                  <p className="success" style={{ color: "#F44336", textAlign: "left", fontSize: "18px" }}>
                    {dashboardData?.data.porcentajeFormateadoDevoluciones || '0.00%'}
                  </p>
                </div>
              </div>

              <div className="rowUM">
                {/* Tarjeta: Estado envíos */}
                <div className="cardUM">
                  <div className="icon-circle-UM local-shipping-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>stacked_line_chart</span>
                  </div>
                  <h3>Estado Envios</h3>
                  <table className="card-table-UM">
                    <tbody>
                      {estadosUnificados.map((estado, index) => (
                        <tr key={index}>
                          <td>{estado.estado_del_envio || "Desconocido"}</td>
                          <td className="number-cell-UM">{estado.total_envios}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Tarjeta: Destinos más usados */}

                <div className="cardUM">
                  <div className="icon-circle-UM check-circle-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>scale</span>
                  </div>
                  <h3>Destinos Más Usados</h3>
                  <table className="card-table-UM">
                    <tbody>
                      {(showMore ? destinosUsados : destinosUsados.slice(0, 5)).map((destino, index) => (
                        <tr key={index}>
                          <td>{destino.ciudad_destino}</td>
                          <td className="number-cell-UM">{destino.total_envios}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {destinosUsados.length > 5 && (
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{ color: "green", cursor: "pointer", textDecoration: "underline", marginLeft: "40%", marginTop: "2%" }}
                    >
                      {showMore ? "Ver menos" : "Ver más"}
                    </span>
                  )}
                </div>

                {/* Tarjeta: Devoluciones frecuentes */}
                <div className="cardUM">
                  <div className="icon-circle-UM report-icon">
                    <span className="material-icons-sharp" style={{ fontSize: '28px' }}>warning</span>
                  </div>
                  <h3>Devoluciones Frecuentes</h3>
                  <table className="card-table-UM">
                    <tbody>
                      {devolucionesFrecuentes.map((movimiento, index) => (
                        <tr key={index}>
                          <td>{movimiento.telefono}</td>
                          <td className="number-cell-UM">{movimiento.num_ocurrencias}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          )
        )}
        <Footer />
        <BotonWhatsapp />
      </div>
    </>
  );

};

export default Dashboard;