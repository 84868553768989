import React from 'react';
import { Link } from 'react-router-dom';
import './404.css';




const ErrorPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-header">404 - Página no encontrada</h1>
      <p className="not-found-text">Lo sentimos, la página que estás buscando no existe.</p>
      <Link to="/" className="not-found-link">Volver a la página de inicio</Link>
    </div>
  );
};

export default ErrorPage;
