import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import './bitacoraCartera.css';

const BitacoraCarteraAdmin = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(null);
  const [searchId, setSearchId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateAvailableBalance = async () => {
    const user_id = localStorage.getItem('usuario_id');
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch balance');

      const data = await response.json();
      setAvailableBalance(data.saldo_disponible);
    } catch (err) {
      console.error('Error al actualizar el saldo disponible:', err);
    }
  };

  const sumTotalsByKey = (data, keyPath) => {
    return data.reduce((acc, item) => {
      const value = keyPath.split('.').reduce((obj, key) => obj?.[key], item) || 0;
      return acc + (typeof value === 'number' ? value : 0);
    }, 0);
  };

  const aggregateData = (data) => {
    if (!Array.isArray(data)) return null;
    
    return {
      Payments: sumTotalsByKey(data, 'Payments'),
      preenvios: {
        total_consignado_preenvios: {
          valor: sumTotalsByKey(data, 'preenvios.total_consignado_preenvios.valor'),
          cantidad: sumTotalsByKey(data, 'preenvios.total_consignado_preenvios.cantidad')
        },
        total_servicio_SinContrapago: {
          valor: sumTotalsByKey(data, 'preenvios.total_servicio_SinContrapago.valor'),
          cantidad: sumTotalsByKey(data, 'preenvios.total_servicio_SinContrapago.cantidad')
        },
        total_devolucion_preenvios: {
          valor: sumTotalsByKey(data, 'preenvios.total_devolucion_preenvios.valor'),
          cantidad: sumTotalsByKey(data, 'preenvios.total_devolucion_preenvios.cantidad')
        },
        total_servicio_contrapago: {
          valor: sumTotalsByKey(data, 'preenvios.total_servicio_contrapago.valor'),
          cantidad: sumTotalsByKey(data, 'preenvios.total_servicio_contrapago.cantidad')
        },
        total_valor_declarado: {
          valor: sumTotalsByKey(data, 'preenvios.total_valor_declarado.valor'),
          cantidad: sumTotalsByKey(data, 'preenvios.total_valor_declarado.cantidad')
        }
      },
      transacciones: {
        total_devoluciones: {
          valor: sumTotalsByKey(data, 'transacciones.total_devoluciones.valor'),
          cantidad: sumTotalsByKey(data, 'transacciones.total_devoluciones.cantidad')
        },
        total_comision_retiro: {
          valor: sumTotalsByKey(data, 'transacciones.total_comision_retiro.valor'),
          cantidad: sumTotalsByKey(data, 'transacciones.total_comision_retiro.cantidad')
        },
        total_retiros: {
          valor: sumTotalsByKey(data, 'transacciones.total_retiros.valor'),
          cantidad: sumTotalsByKey(data, 'transacciones.total_retiros.cantidad')
        },
        total_envios_sin_contrapago: {
          valor: sumTotalsByKey(data, 'transacciones.total_envios_sin_contrapago.valor'),
          cantidad: sumTotalsByKey(data, 'transacciones.total_envios_sin_contrapago.cantidad')
        }
      },
      recargas: {
        total_recargas: {
          valor: sumTotalsByKey(data, 'recargas.total_recargas.valor'),
          cantidad: sumTotalsByKey(data, 'recargas.total_recargas.cantidad')
        },
        total_bono: sumTotalsByKey(data, 'recargas.total_bono')
      },
      ordenes: {
        total_dueño: sumTotalsByKey(data, 'ordenes.total_dueño'),
        total_vendedor: sumTotalsByKey(data, 'ordenes.total_vendedor')
      }
    };
  };

  const fetchDashboardData = async (sucursalId = '') => {
    setLoading(true);
    setError(null);
    try {
      const url = sucursalId 
        ? `https://api.99envios.app/api/online/listar_estado_cuenta/${sucursalId}`
        : 'https://api.99envios.app/api/online/listar_estado_cuenta';
      
      const response = await axios.get(url);
      const data = sucursalId ? response.data : aggregateData(response.data);
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('No se encontraron datos para esta sucursal');
      setDashboardData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchId.trim()) {
      fetchDashboardData(searchId.trim());
    } else {
      fetchDashboardData();
    }
  };

  useEffect(() => {
    fetchDashboardData();
    updateAvailableBalance();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
        <div className="loading-text">Cargando datos, por favor espere...</div>
      </div>
    );
  }

  const formatBalance = (balance) => {
    if (balance === null) return 'Cargando...';
    const absBalance = Math.abs(balance);
    const formattedBalance = absBalance.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return balance < 0 ? `-${formattedBalance}` : formattedBalance;
  };
  
  const calculateDescuentosRetiros = (data) => {
    return (data?.transacciones?.total_devoluciones?.valor || 0) + 
           (data?.transacciones?.total_comision_retiro?.valor || 0) +
           (data?.transacciones?.total_retiros?.valor || 0) +
           (data?.transacciones?.total_envios_sin_contrapago?.valor || 0);
  };

  return (
    <>
      <NavbarAdminMex title={"Bitácora Cartera"} />
      <div className="bitacora-cartera-dashboard-container">
        <form onSubmit={handleSearch} className="bitacora-cartera-search">
          <input
            type="text"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            placeholder="Buscar por código de sucursal..."
            className="bitacora-cartera-search-input"
          />
          <button type="submit" className="bitacora-cartera-search-button">
            <span className="material-icons-sharp">search</span>
          </button>
          {/* {searchId && (
            <button 
              type="button" 
              onClick={() => {
                setSearchId('');
                fetchDashboardData();
              }}
              className="bitacora-cartera-clear-button"
            >
              Ver Totales
            </button>
          )} */}
        </form>
        
        {error && <div className="bitacora-cartera-error">{error}</div>}
        
        {dashboardData && (
          <div className="bitacora-cartera-cards-container">
            <div className="bitacora-cartera-row">
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-local-shipping-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px' }}> payments </span>
                </div>
                <h3>Total Pagos</h3>
                <p className="main-number">{formatBalance(dashboardData?.Payments)}</p>
              </div>
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-check-circle-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 1 }}> receipt_long </span>
                </div>
                <h3>Descuentos y Retiros</h3>
                <p className="main-number">{formatBalance(calculateDescuentosRetiros(dashboardData))}</p>
              </div>
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-report-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> account_balance_wallet </span>
                </div>
                <h3>Saldo Disponible</h3>
                <p className="main-number">{formatBalance(availableBalance)}</p>
              </div>
            </div>
            <div className="bitacora-cartera-row">
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-track-changes-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> local_shipping </span>
                </div>
                <h3>Preenvíos</h3>
                <table className="bitacora-cartera-card-table">
                  <tbody>
                    <tr>
                      <td><strong>Total Consignado Preenvíos</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.preenvios?.total_consignado_preenvios?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.preenvios?.total_consignado_preenvios?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Servicio Sin Contrapago</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.preenvios?.total_servicio_SinContrapago?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.preenvios?.total_servicio_SinContrapago?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Devolución Preenvíos</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.preenvios?.total_devolucion_preenvios?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.preenvios?.total_devolucion_preenvios?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Servicio Contrapago</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.preenvios?.total_servicio_contrapago?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.preenvios?.total_servicio_contrapago?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Valor Declarado</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.preenvios?.total_valor_declarado?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.preenvios?.total_valor_declarado?.cantidad}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-track-changes-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> swap_horiz </span>
                </div>
                <h3>Transacciones</h3>
                <table className="bitacora-cartera-card-table">
                  <tbody>
                    <tr>
                      <td><strong>Total Devoluciones</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.transacciones?.total_devoluciones?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.transacciones?.total_devoluciones?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Comisión Retiro</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.transacciones?.total_comision_retiro?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.transacciones?.total_comision_retiro?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Retiros</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.transacciones?.total_retiros?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.transacciones?.total_retiros?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Envíos Sin Contrapago</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.transacciones?.total_envios_sin_contrapago?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.transacciones?.total_envios_sin_contrapago?.cantidad}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bitacora-cartera-card">
                <div className="bitacora-cartera-icon-circle bitacora-cartera-track-changes-icon">
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> attach_money </span>
                </div>
                <h3>Recargas</h3>
                <table className="bitacora-cartera-card-table">
                  <tbody>
                    <tr>
                      <td><strong>Total Recargas</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.recargas?.total_recargas?.valor)}</td>
                    </tr>
                    <tr>
                      <td>Cantidad</td>
                      <td className="bitacora-cartera-number-cell">{dashboardData?.recargas?.total_recargas?.cantidad}</td>
                    </tr>
                    <tr>
                      <td><strong>Total Bono</strong></td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.recargas?.total_bono)}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="bitacora-cartera-icon-circle bitacora-cartera-track-changes-icon" style={{ marginTop: "30px" }}>
                  <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> shopping_cart </span>
                </div>
                <h3>Órdenes</h3>
                <table className="bitacora-cartera-card-table">
                  <tbody>
                    <tr>
                      <td>Total Dueño</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.ordenes?.total_dueño)}</td>
                    </tr>
                    <tr>
                      <td>Total Vendedor</td>
                      <td className="bitacora-cartera-number-cell">{formatBalance(dashboardData?.ordenes?.total_vendedor)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>            
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BitacoraCarteraAdmin;