import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Spin, Select, Upload, Row, Col, Checkbox } from 'antd';
import axios from 'axios';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './gestionInventarioBodega.css';
import ExpandableText from './expandible';

const { Option } = Select;

const GestionInventarioBodega = () => {
    const [productos, setProductos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [form] = Form.useForm();
    const [warehouses, setWarehouses] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const filteredProducts = productos.filter((product) => {
        const matchesSearch = (
            (product.nombre_producto && product.nombre_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.descripcion_producto && product.descripcion_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.sku && product.sku.toLowerCase().includes(searchText.toLowerCase()))
        );
    
        const matchesCategory = selectedCategory === '' || product.id_categoria === Number(selectedCategory);
    
        return matchesSearch && matchesCategory;
    });

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            try {
                const { data } = await axios.get(url);
                setProductos(data);
            } catch (error) {
                message.error('Error al cargar productos');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchWarehouses = async () => {
            const id_user = localStorage.getItem('usuario_id');
            const url = `https://99envios.app/api/picking/bodega/listar-bodegas/${id_user}`;
            try {
                const { data } = await axios.get(url);
                setWarehouses(data);
            } catch (error) {
                message.error('Error al cargar bodegas');
            }
        };

        fetchProducts();
        fetchWarehouses();
    }, []);

    const handleQuantityChange = (value) => {
        const cantidad = parseInt(value);
        const stockDisponible = selectedProduct?.cantidad_disponible || 0;
        
        if (cantidad <= 0) {
            form.setFields([{
                name: 'cantidad',
                errors: ['La cantidad debe ser mayor a 0']
            }]);
        } else if (cantidad > stockDisponible) {
            form.setFields([{
                name: 'cantidad',
                errors: ['La cantidad excede el stock disponible']
            }]);
        } else {
            form.setFields([{
                name: 'cantidad',
                errors: []
            }]);
        }
    };

    const handleTransferSubmit = () => {
        form.validateFields()
            .then(async (values) => {
                const cantidad = parseInt(values.cantidad);
                const stockDisponible = selectedProduct?.cantidad_disponible || 0;

                if (cantidad <= 0) {
                    message.error('La cantidad debe ser mayor a 0');
                    return;
                }

                if (cantidad > stockDisponible) {
                    message.error('La cantidad excede el stock disponible');
                    return;
                }

                const selectedWarehouse = warehouses.find(warehouse => warehouse.id === values.bodega);
                const id_users = selectedWarehouse?.id_users;
                const token = localStorage.getItem('token');
                const url = `https://99envios.app/api/picking/bodega/orden-bodega/${id_users}`;
                const payload = {
                    IdProducto: selectedProduct.id_producto,
                    IdVariacion: selectedProduct.variaciones[values.variacion] || "",
                    Cantidad: cantidad, // Ensure Cantidad is an integer
                    Observaciones: values.observaciones,
                    transportadora: {
                        pais: "colombia",
                        nombre: values.transportadora,
                    },
                };

                setIsSubmitting(true);
                try {
                    const response = await axios.post(url, payload, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token ? `Bearer ${token}` : ''
                        }
                    });
                    if (response.data.exito) {
                        message.success('Producto enviado exitosamente');
                        window.open(response.data.pdf, '_blank');
                    } else {
                        message.error('Error al enviar producto');
                    }
                    setIsTransferModalVisible(false);
                    form.resetFields();
                } catch (error) {
                    message.error('Error al enviar producto');
                } finally {
                    setIsSubmitting(false);
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const renderVariations = (variaciones) => {
        if (!variaciones.length) return 'Sin variaciones';
        return variaciones.map((variation, index) => (
            <div key={index}>
                <strong>Talla:</strong> {variation.talla} &nbsp;
                <strong>Color:</strong> {variation.color} &nbsp;
                <strong>Cantidad:</strong> {variation.cantidad_disponible} &nbsp;
                <strong>SKU:</strong> {variation.sku}
            </div>
        ));
    };

    const columns = [
        { title: 'ID', dataIndex: 'id_producto', key: 'id_producto' },
        { title: 'Nombre', dataIndex: 'nombre_producto', key: 'nombre_producto' },
        {
            title: 'Descripción',
            dataIndex: 'descripcion_producto',
            key: 'descripcion_producto',
            render: (text) => <ExpandableText text={text} maxLength={100} />
        },
        { title: 'Precio Sugerido', dataIndex: 'precio_proveedor', key: 'precio_proveedor' },
        { title: 'Cantidad Disponible', dataIndex: 'cantidad_disponible', key: 'cantidad_disponible' },
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        {
            title: 'Variaciones',
            key: 'variaciones',
            render: (text, record) => renderVariations(record.variaciones),
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (text, record) => (
                <Button 
                    type="link" 
                    onClick={() => {
                        setSelectedProduct(record);
                        setIsTransferModalVisible(true);
                        form.resetFields();
                    }}
                >
                    Seleccionar
                </Button>
            ),
        },
    ];

    if (isLoading) return <Spin size="large" center />;

    return (
        <>
            <NavbarPicking title="Gestión Inventario Bodega" />
            <div className="GIB-table-container" style={{ fontSize: "13px" }}>
                <Col>
                    <Input
                        placeholder="Buscar producto"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Select
                        placeholder="Filtrar por categoría"
                        value={selectedCategory}
                        onChange={(value) => setSelectedCategory(value)}
                        style={{ width: 200 }}
                    >
                        <Option value="">Todas las categorías</Option>
                        <Option value="1">Bisuteria</Option>
                        <Option value="2">Ropa Deportiva</Option>
                        <Option value="3">Vaporizadores</Option>
                        <Option value="4">Mascotas</Option>
                        <Option value="5">Moda</Option>
                        <Option value="6">Tecnologia</Option>
                        <Option value="7">Cocina</Option>
                        <Option value="8">Belleza</Option>
                        <Option value="9">Salud</Option>
                        <Option value="10">Hogar</Option>
                        <Option value="11">Natural Home</Option>
                        <Option value="12">Deportes</Option>
                        <Option value="13">Sex Shop</Option>
                        <Option value="14">Bebé</Option>
                        <Option value="15">Aseo</Option>
                        <Option value="16">Bienestar</Option>
                        <Option value="17">Camping</Option>
                        <Option value="18">Pesca</Option>
                        <Option value="19">Defensa Personal</Option>
                        <Option value="20">Vehiculos</Option>
                        <Option value="21">Jugueteria</Option>
                        <Option value="22">Otros</Option>
                    </Select>
                </Col>
                <Table 
                    dataSource={filteredProducts} 
                    columns={columns} 
                    rowKey="id_producto" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                />
            </div>
            <Modal
                title="Enviar Producto"
                visible={isTransferModalVisible}
                onCancel={() => {
                    setIsTransferModalVisible(false);
                    form.resetFields();
                }}
                footer={[
                    <Button style={{height: 44}} key="cancel" onClick={() => {
                        setIsTransferModalVisible(false);
                        form.resetFields();
                    }}>
                        Cancelar
                    </Button>,
                    <Button style={{height: 44}} key="submit" type="primary" onClick={handleTransferSubmit} loading={isSubmitting}>
                        Enviar
                    </Button>
                ]}
            >
                <Spin spinning={isSubmitting}>
                    <Form form={form} layout="vertical">
                        <Form.Item 
                            label="Nombre del Producto"
                            style={{ marginBottom: '15px' }}
                        >
                            <Input 
                                value={selectedProduct?.nombre_producto} 
                                disabled 
                                style={{ width: '270px' }}
                            />
                        </Form.Item>

                        <Form.Item 
                            label="Variación"
                            style={{ marginBottom: '15px' }}
                        >
                            {selectedProduct?.variaciones?.length > 0 ? (
                                <Select style={{ width: '270px', marginLeft: '-10px' }}>
                                    {selectedProduct.variaciones.map((variation, index) => (
                                        <Option key={index} value={index}>
                                            {`Talla: ${variation.talla} - Color: ${variation.color}`}
                                        </Option>
                                    ))}
                                </Select>
                            ) : (
                                <Input 
                                    value="Sin variaciones"
                                    disabled 
                                    style={{ background: '#f5f5f5', width: '270px' }}
                                />
                            )}
                        </Form.Item>

                        <Form.Item 
                            required
                            label="Cantidad a Enviar"
                            name="cantidad"
                            style={{ marginBottom: '15px' }}
                            rules={[
                                { required: true, message: 'Por favor ingrese la cantidad' },
                                {
                                    validator: (_, value) => {
                                        const cantidad = parseInt(value);
                                        const stockDisponible = selectedProduct?.cantidad_disponible || 0;
                                        
                                        if (!value) return Promise.reject();
                                        if (cantidad <= 0) return Promise.reject('La cantidad debe ser mayor a 0');
                                        if (cantidad > stockDisponible) return Promise.reject('La cantidad excede el stock disponible');
                                        return Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Input 
                                type="number"
                                min={1}
                                max={selectedProduct?.cantidad_disponible}
                                onChange={(e) => handleQuantityChange(e.target.value)}
                                style={{ width: '270px' }}
                            />
                        </Form.Item>

                        <Form.Item 
                            required
                            label="Seleccionar Bodega"
                            name="bodega"
                            rules={[{ required: true, message: 'Por favor seleccione una bodega' }]}
                        >
                            <Select placeholder="Seleccione una bodega" style={{ width: '270px', marginLeft: '-10px' }}>
                                {warehouses.map(warehouse => (
                                    <Option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.nombre_sucursal}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item 
                            required
                            label="Observaciones"
                            name="observaciones"
                            rules={[{ required: true, message: 'Por favor ingrese observaciones' }]}
                        >
                            <Input 
                                placeholder="Ingrese observaciones"
                                style={{ width: '270px' }}
                            />
                        </Form.Item>

                        <Form.Item 
                            required
                            label="Transportadora"
                            name="transportadora"
                            rules={[{ required: true, message: 'Por favor seleccione una transportadora' }]}
                        >
                            <Select placeholder="Seleccione una transportadora" style={{ width: '270px', marginLeft: '-10px' }}>
                                <Option value="interrapidisimo">Interrapidisimo</Option>
                                <Option value="servientrega">Servientrega</Option>
                                <Option value="coordinadora">Coordinadora</Option>
                                <Option value="tcc">Tcc</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default GestionInventarioBodega;