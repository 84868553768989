import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Col, message, Row, Modal } from 'antd';
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './listarInventarioBodega.css';

const ListarInventarioBodega = () => {
    const [bodegas, setBodegas] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalBarcodeVisible, setIsModalBarcodeVisible] = useState(false);
    const [isVariationsModalVisible, setIsVariationsModalVisible] = useState(false);
    const [inputCode, setInputCode] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedVariations, setSelectedVariations] = useState([]);

    const fetchBodegas = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/picking/bodega/listar-inventario-bodega/${userId}`);
            
            if (!response.ok) {
                throw new Error('Error al cargar las bodegas');
            }
            
            const data = await response.json();
            setBodegas(data);
        } catch (error) {
            console.error('Error fetching bodegas:', error);
            message.error('No se pudieron cargar las bodegas');
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = async (bodegaId) => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/picking/bodega/confirmacion-bodega/${bodegaId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId }),
            });

            if (!response.ok) {
                throw new Error('Error al confirmar la bodega');
            }

            message.success('Entrega confirmada exitosamente');
            fetchBodegas(); // Update the page
        } catch (error) {
            console.error('Error confirmando entrega:', error);
            message.error('No se pudo confirmar la entrega');
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmEntrante = async (bodegaId) => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/picking/bodega/confirmacion-cantidad-entrante/${bodegaId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId }),
            });

            if (!response.ok) {
                throw new Error('Error al confirmar las unidades entrantes');
            }

            message.success('Unidades entrantes confirmadas exitosamente');
            fetchBodegas(); // Update the page
        } catch (error) {
            console.error('Error confirmando unidades entrantes:', error);
            message.error('No se pudieron confirmar las unidades entrantes');
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateSku = (record) => {
        setSelectedRecord(record);
        setIsModalVisible(true);
    };

    const handleGenerateBarcode = (record) => {
        setSelectedRecord(record);
        setIsModalBarcodeVisible(true);
        
        setTimeout(() => {
            const canvas = document.getElementById('barcodeCanvas');
            if (canvas) {
                const scale = 4;
                canvas.style.width = '320px';
                canvas.style.height = '150px';
                canvas.width = 320 * scale;
                canvas.height = 150 * scale;

                JsBarcode(canvas, record.codigo_barras, {
                    format: "CODE128",
                    width: 1,
                    height: 30 * scale,
                    displayValue: true,
                    fontSize: 16,          // Tamaño de fuente fijo
                    marginTop: 5 * scale,
                    marginBottom: 5 * scale,
                    textMargin: 2 * scale,
                    font: "monospace",     // Fuente monoespaciada
                    lineColor: "#000",
                    background: "#FFFFFF"
                });
            }
        }, 100);
    };

    const handleGenerateBarcodeApi = async (record) => {
        console.log('Generar Código de Barras API called for record:', record);
        const userId = parseInt(localStorage.getItem('usuario_id'));
        const response = await fetch(`https://99envios.app/api/picking/bodega/inventario-bodega-codigo-barras/${record.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_bodega: userId }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.error === "Producto ya cuenta con codigo de barras.") {
                message.error('Producto ya cuenta con código de barras.');
            } else {
                console.error('Error generating barcode:', response.statusText);
                message.error('No se pudo generar el código de barras');
            }
        } else {
            message.success('Código de barras generado exitosamente');
            fetchBodegas(); // Update the table data
        }
    };

    const handleDownloadBarcode = () => {
        const canvas = document.getElementById('barcodeCanvas');
        if (canvas) {
            // Convertir 32mm x 15mm a puntos (1mm = 2.83465 puntos)
            const WIDTH_IN_POINTS = 32 * 2.83465;  // ≈ 90.7088 puntos
            const HEIGHT_IN_POINTS = 15 * 2.83465; // ≈ 42.51975 puntos
    
            // Crear PDF con las dimensiones exactas
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'pt',
                format: [WIDTH_IN_POINTS, HEIGHT_IN_POINTS]
            });
    
            const imgData = canvas.toDataURL("image/png", 1.0);
            
            // Calcular dimensiones para centrar el código de barras
            const barcodeWidth = WIDTH_IN_POINTS - 10;  // Dejar 5pt de margen a cada lado
            const barcodeHeight = HEIGHT_IN_POINTS - 6; // Dejar 3pt de margen arriba y abajo
            
            // Centrar el código de barras
            const xOffset = (WIDTH_IN_POINTS - barcodeWidth) / 2;
            const yOffset = (HEIGHT_IN_POINTS - barcodeHeight) / 2;
    
            // Agregar el código de barras al PDF
            pdf.addImage(imgData, 'PNG', xOffset, yOffset, barcodeWidth, barcodeHeight);
    
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
        }
        setIsModalBarcodeVisible(false);
    };

    const handleOk = async () => {
        try {
            setLoading(true);
            const userId = parseInt(localStorage.getItem('usuario_id'));
            const response = await fetch(`https://99envios.app/api/picking/bodega/inventario-modificar-sku/${selectedRecord.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId, sku: inputCode }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === "no se puede modificar el sku ya q este producto contiene un codigo de barras asociado.") {
                    throw new Error('No se puede modificar el SKU ya que este producto contiene un código de barras asociado.');
                } else {
                    throw new Error('Error al generar el SKU');
                }
            }

            message.success('SKU generado exitosamente');
            fetchBodegas(); // Update the page
        } catch (error) {
            console.error('Error generando SKU:', error);
            message.error(error.message);
        } finally {
            setLoading(false);
            setIsModalVisible(false);
            setInputCode('');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setInputCode('');
    };

    const handleCancelBarcode = () => {
        setIsModalBarcodeVisible(false);
    };

    const handleViewVariations = (record) => {
        setSelectedRecord(record);
        setSelectedVariations(record.variaciones_bodega.map(variation => ({
            ...variation,
            nombre_producto: record.nombre_producto
        })));
        setIsVariationsModalVisible(true);
    };

    const handleGenerateSkuVariation = (variation) => {
        setSelectedRecord(variation);
        setIsModalVisible(true);
    };

    const handleGenerateBarcodeVariation = async (variation) => {
        try {
            const userId = parseInt(localStorage.getItem('usuario_id'));
            const response = await fetch(`https://99envios.app/api/picking/bodega/variacion-bodega-codigo-barras/${variation.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === "Producto ya cuenta con codigo de barras.") {
                    message.error('Producto ya cuenta con código de barras.');
                } else {
                    console.error('Error generating barcode:', response.statusText);
                    message.error('No se pudo generar el código de barras');
                }
            } else {
                message.success('Código de barras generado exitosamente');
                fetchBodegas(); // Update the table data
            }
        } catch (error) {
            console.error('Error generating barcode:', error);
            message.error('No se pudo generar el código de barras');
        }
    };

    const handleViewBarcodeVariation = (variation) => {
        setSelectedRecord(variation);
        setIsModalBarcodeVisible(true);
        
        setTimeout(() => {
            const canvas = document.getElementById('barcodeCanvas');
            if (canvas) {
                const scale = 4;
                canvas.style.width = '320px';
                canvas.style.height = '150px';
                canvas.width = 320 * scale;
                canvas.height = 150 * scale;

                JsBarcode(canvas, variation.codigo_barras, {
                    format: "CODE128",
                    width: 1,
                    height: 30 * scale,
                    displayValue: true,
                    fontSize: 16,          // Tamaño de fuente fijo
                    marginTop: 5 * scale,
                    marginBottom: 5 * scale,
                    textMargin: 2 * scale,
                    font: "monospace",     // Fuente monoespaciada
                    lineColor: "#000",
                    background: "#FFFFFF"
                });
            }
        }, 100);
    };

    useEffect(() => {
        fetchBodegas();
    }, []);

    const filteredBodegas = bodegas.filter((bodega) => 
        bodega.nombre_producto?.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        { 
            title: 'Nombre Producto', 
            dataIndex: 'nombre_producto', 
            key: 'nombre_producto' 
        },
        { 
            title: 'SKU', 
            dataIndex: 'sku', 
            key: 'sku',
            render: (sku) => sku || 'N/A' // Handle null SKU
        },
        { 
            title: 'Código de Barras', 
            dataIndex: 'codigo_barras', 
            key: 'codigo_barras',
            render: (codigo_barras) => codigo_barras || 'N/A' // Handle null barcode
        },
        { 
            title: 'Confirmación Bodega', 
            dataIndex: 'confirmacion_bodega', 
            key: 'confirmacion_bodega',
            render: (confirmacion_bodega) => `${confirmacion_bodega}`
        },
        { 
            title: 'Estado Variación', 
            dataIndex: 'estado_variacion', 
            key: 'estado_variacion',
            render: (estado_variacion) => `${estado_variacion}`
        },
        { 
            title: 'Cantidad Disponible', 
            dataIndex: 'cantidad_disponible', 
            key: 'cantidad_disponible',
            render: (cantidad_disponible) => `${cantidad_disponible} unidades`
        },
        { 
            title: 'Cantidad Entrante', 
            dataIndex: 'cantidad_entrante', 
            key: 'cantidad_entrante',
            render: (cantidad_entrante) => `${cantidad_entrante} unidades`
        },
        { 
            title: 'Fecha de Creación', 
            dataIndex: 'created_at', 
            key: 'created_at',
            render: (created_at) => new Date(created_at).toLocaleString()
        },
        { 
            title: 'Fecha de Actualización', 
            dataIndex: 'updated_at', 
            key: 'updated_at',
            render: (updated_at) => new Date(updated_at).toLocaleString()
        },
        {
            title: 'Variaciones',
            key: 'variaciones_bodega',
            render: (_, record) => (
                record.variaciones_bodega.length > 0 ? (
                    <Button onClick={() => handleViewVariations(record)}>
                        Ver Variaciones
                    </Button>
                ) : (
                    <span>Sin Variaciones</span>
                )
            ),
        },
        {
            title: 'Acciones Entregas',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button 
                        type="link" 
                        onClick={() => handleConfirm(record.id)}
                    >
                        Confirmar Entrega
                    </Button>
                    {record.cantidad_entrante > 0 && (
                        <Button 
                            type="link" 
                            onClick={() => handleConfirmEntrante(record.id)}
                        >
                            Confirmar Entrantes
                        </Button>
                    )}
                </>
            ),
        },
        {
            title: 'Acciones Código de Barras',
            key: 'acciones_qr',
            render: (_, record) => (
                <>
                    {record.sku === null && (
                        <Button onClick={() => handleGenerateSku(record)}>
                            Generar SKU
                        </Button>
                    )}
                    {record.codigo_barras === null && (
                        <Button 
                            onClick={() => handleGenerateBarcodeApi(record)} 
                            disabled={!record.sku}
                        >
                            Generar Código de Barras
                        </Button>
                    )}
                    {record.codigo_barras !== null && (
                        <Button 
                            onClick={() => handleGenerateBarcode(record)} 
                            disabled={!record.sku}
                        >
                            Ver Código Barras
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const variationsColumns = [
        { 
            title: 'Nombre Producto', 
            dataIndex: 'nombre_producto', 
            key: 'nombre_producto'
        },
        { 
            title: 'SKU', 
            dataIndex: 'sku', 
            key: 'sku' 
        },
        { 
            title: 'Código de Barras', 
            dataIndex: 'codigo_barras', 
            key: 'codigo_barras',
            render: (codigo_barras) => codigo_barras ? codigo_barras : 'N/A'
        },
        { 
            title: 'Cantidad Disponible', 
            dataIndex: 'cantidad_disponible', 
            key: 'cantidad_disponible' 
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (_, variation) => (
                <>
                    {variation.sku === null && (
                        <Button onClick={() => handleGenerateSkuVariation(variation)}>
                            Generar SKU
                        </Button>
                    )}
                    {variation.codigo_barras === null && (
                        <Button 
                            onClick={() => handleGenerateBarcodeVariation(variation)} 
                            disabled={!variation.sku}
                            style={{ marginLeft: 8 }}
                        >
                            Generar Código de Barras
                        </Button>
                    )}
                    {variation.codigo_barras !== null && (
                        <Button 
                            onClick={() => handleViewBarcodeVariation(variation)} 
                            disabled={!variation.sku}
                            style={{ marginLeft: 8 }}
                        >
                            Ver Código de Barras
                        </Button>
                    )}
                </>
            ),
        },
    ];

    return (
        <>
            <NavbarUsuarioBodega title="Inventario Entrante" />
            <div className="GB-table-container" style={{ fontSize: "13px" }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                    <Col>
                        <Input
                            placeholder="Buscar producto"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: 200, marginRight: 10 }}
                        />
                    </Col>
                </Row>
                <Table 
                    dataSource={filteredBodegas} 
                    columns={columns} 
                    rowKey="id" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                    loading={loading}
                />
            </div>
            <Modal
                title={selectedRecord?.sku ? "Editar SKU" : "Generar SKU"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: { height: '44px' } }}
                cancelButtonProps={{ style: { height: '44px' } }}
            >
                <Input
                    placeholder="Ingrese código"
                    value={inputCode}
                    onChange={(e) => setInputCode(e.target.value)}
                    style={{ width: 200 }}
                />
            </Modal>
            <Modal
                title="Código de Barras"
                visible={isModalBarcodeVisible}
                onOk={handleDownloadBarcode}
                onCancel={handleCancelBarcode}
                okText="Descargar"
                cancelText="Cerrar"
                okButtonProps={{ style: { height: '44px' } }}
                cancelButtonProps={{ style: { height: '44px' } }}
            >
                <div style={{ textAlign: 'center' }}>
                    <canvas id="barcodeCanvas"></canvas>
                </div>
            </Modal>
            <Modal
                title="Variaciones"
                visible={isVariationsModalVisible}
                onCancel={() => setIsVariationsModalVisible(false)}
                footer={null}
                width={800}
            >
                <Table 
                    dataSource={selectedVariations} 
                    columns={variationsColumns} 
                    rowKey="id_variacion" 
                    pagination={{ pageSize: 5 }} 
                />
            </Modal>
        </>
    );
};

export default ListarInventarioBodega;