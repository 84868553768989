
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__contactanos">
                    {/* <img src="logo.png" alt="Logo" /> */}
                    <span class="titulos">Contáctanos</span>
                    <p>   </p>
                    <p>   </p>
                    <p class="descripcion">Número: (1) 6331002 – 3162519103,</p>
                    <p class="descripcion">De lunes a viernes de 8:00 a. M. A 5:00 p. M.</p>
                </div>
                <div className="footer__ubicados">
                    <span class="titulos">¿Dónde estamos ubicados?</span>
                    <p>   </p>
                    <p>   </p>
                    <p class="descripcion">Carrera 105 a 70 a 09   -   Bogotá - Colombia</p>
                    <p>   </p>
                    <p class="descripcion">Mar tirreno 64 Popotla   -   Ciudad de México</p>

                </div>
                <div className="footer__links">
                    <Link to="/">Inicio</Link>
                    <Link to="/tablero">Dashboard</Link>
                    <Link to="/create/usuario">Registro</Link>
                </div>
            </div>
            <div className="footer__copyright">
                <p>&copy; 2024 99envios   -   Powered by 99envios</p>
            </div>
        </footer>
    );
};

export default Footer;