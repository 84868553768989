import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';
import Ciudades from '../../ciudades/ciudades';
import NavbarAdminMex from '../components/navbar';

const { Option } = Select;

const CrearSucursal = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Ajusta la URL según sea necesario
      const response = await axios.post('https://api.99envios.app/api/online/sucursal', values, {
        headers: {
          'Content-Type': 'application/json', // Asegúrate de usar el tipo de contenido correcto
        },
      });

      if (response.status === 201 || response.status === 200) {
        message.success('Sucursal creada exitosamente');
        form.resetFields();
      } else {
        message.error('Hubo un problema al crear la sucursal');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(`Error: ${error.response.data.message || 'Error al crear la sucursal'}`);
      } else {
        message.error('Error de conexión con el servidor');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Crear Sucursal" />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{
          maxWidth: '800px',
          margin: '2% auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Campos del formulario */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Nombre y Apellidos"
              name="nombre_apellidos"
              rules={[{ required: true, message: 'Por favor, ingresa el nombre y apellidos' }]}
            >
              <Input placeholder="Ingresa el nombre y apellidos" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Tipo de Identificación"
              name="tipo_identificacion"
              rules={[{ required: true, message: 'Por favor, selecciona el tipo de identificación' }]}
            >
              <Select placeholder="Selecciona el tipo de identificación">
                <Option value="CC">Cédula de Ciudadanía</Option>
                <Option value="CE">Cédula de Extranjería</Option>
                <Option value="NIT">NIT</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Número de Identificación"
              name="num_identificacion"
              rules={[{ required: true, message: 'Por favor, ingresa el número de identificación' }]}
            >
              <Input placeholder="Ingresa el número de identificación" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Dirección"
              name="direccion"
              rules={[{ required: true, message: 'Por favor, ingresa la dirección' }]}
            >
              <Input placeholder="Ingresa la dirección" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Departamento"
              name="departamento"
              rules={[{ required: true, message: 'Por favor, ingresa el departamento' }]}
            >
              <Input placeholder="Ingresa el departamento" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="País"
              name="pais"
              rules={[{ required: true, message: 'Por favor, ingresa el código del país' }]}
            >
              <Input placeholder="Ingresa el código del país" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="ciudad_id"
              label="Ciudad"
              rules={[{ required: true, message: 'Seleccione una ciudad' }]}
            >
              <Select
                showSearch
                placeholder="Seleccione una ciudad"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {Ciudades.map((ciudad) => (
                  <Option key={ciudad.value} value={ciudad.value}>
                    {ciudad.label}
                  </Option>
                ))}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[{ required: true, message: 'Por favor, ingresa el número de teléfono' }]}
            >
              <Input placeholder="Ingresa el número de teléfono" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Correo Electrónico"
              name="correo"
              rules={[
                { required: true, message: 'Por favor, ingresa el correo electrónico' },
                { type: 'email', message: 'Por favor, ingresa un correo electrónico válido' },
              ]}
            >
              <Input placeholder="Ingresa el correo electrónico" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Contraseña"
              name="contrasena"
              rules={[{ required: true, message: 'Por favor, ingresa la contraseña' }]}
            >
              <Input.Password
                placeholder="Ingresa la contraseña"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre de la Sucursal"
              name="nombre_sucursal"
              rules={[{ required: true, message: 'Por favor, ingresa el nombre de la sucursal' }]}
            >
              <Input placeholder="Ingresa el nombre de la sucursal" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            style={{ backgroundColor: '#8c8cff', maxWidth: '20%', margin: '2% auto 0' }}
          >
            Crear Sucursal
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CrearSucursal;
