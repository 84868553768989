import React from 'react';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import './Tutoriales.css';

const videos = [
  { src: "https://www.youtube.com/embed/OoIAsbi4zeo", title: "Cómo funciona la plataforma de 99 envíos" },
  { src: "https://www.youtube.com/embed/teZ8zhbWOKA?si=9YEGdL4vKEfcrOdY", title: "Generar guías 99envios.com" },
  { src: "https://www.youtube.com/embed/_Z8ipnfD-_I?si=IfbgOIOWfWE390Pl", title: "Recolección 99envios.com" },
  { src: "https://www.youtube.com/embed/GzKzA_SGiig?si=ER0P1VRjyFmJEsSg", title: "Novedades 99envios.com" },
  { src: "https://www.youtube.com/embed/m3dmQobGeFw?si=NAYQqBu433VQ1C0p", title: "Envios 99envios.com" },
  { src: "https://www.youtube.com/embed/DV9MZFtdWxo?si=RbM3zA7RmPVZLNeF", title: "Cartera Completa 99envios.com" },
  { src: "https://www.youtube.com/embed/HAdLXKLF0eI?si=GsopzTWh8VaPYQyx", title: "Retiros 99envios.com" },
  { src: "https://www.youtube.com/embed/BF5vkAbzcSY?si=gcqxCMcBc08Ex1oH", title: "MODULO AUTOGESTIÓN 99envios.com" },
  { src: "https://www.youtube.com/embed/kTJW_rBgE6o", title: "¿Cómo te inscribes? 99envios.com" },
  { src: "https://www.youtube.com/embed/wc_Q37mE8XM", title: "Tutorial subir y enviar paquetes Bogotá a Bogotá" },
  { src: "https://www.youtube.com/embed/wSDLo4-E2iA?si=G4_4J-Cp2d-20lE3", title: "GUIAS MASIVAS 99 ENVIOS" },
  { src: "https://www.youtube.com/embed/sXIK4Wj14gY?si=LWl3y_C6YEfJ0xqN", title: "TUTORIAL dropshipping 99 ENVIOS" },
  { src: "https://www.youtube.com/embed/290mET2ZW6E", title: "Error 403 99envios.com" }
];

const Tutoriales = () => {
  return (
    <>
    <NavbarMex title={"Tutoriales"}/>
    <div className="tutoriales-container">
    <h2>Tutoriales</h2>
      <div className="videos-grid">
      {videos.map((video, index) => (
          <div key={index} className="video-item">
            <iframe
              src={video.src}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <div className="video-title">{video.title}</div>
          </div>
        ))}
      </div>
    </div>
    <BotonWhatsapp />
    </>
  );
};

export default Tutoriales;
