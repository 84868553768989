import React, { useState, useEffect } from "react";
import NavbarAdminMex from '../components/navbar';
import { Table, Button, Input, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const VerSucursal = () => {
  const [branchData, setBranchData] = useState([]);

  useEffect(() => {
    fetchBranchData();
  }, []);

  const fetchBranchData = async () => {
    try {
      const response = await axios.get('https://api.99envios.app/public/api/online/sucursal');
      setBranchData(response.data);
    } catch (error) {
      console.error('Error fetching branch data:', error);
    }
  };

  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <Input
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ marginRight: 8 }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ padding: '0', width: 'auto' }}
          />
        </div>
        <Button
          onClick={clearFilters}
          size="small"
          style={{ width: '100%' }}
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("ID", "id"),
    },
    {
      title: "Código Sucursal",
      dataIndex: "codigo_sucursal",
      key: "codigo_sucursal",
      sorter: (a, b) => a.codigo_sucursal - b.codigo_sucursal,
      ...getColumnSearchProps("Código Sucursal", "codigo_sucursal"),
    },
    {
      title: "Nombre Sucursal",
      dataIndex: "nombre_sucursal",
      key: "nombre_sucursal",
      sorter: (a, b) => a.nombre_sucursal.localeCompare(b.nombre_sucursal),
      ...getColumnSearchProps("Nombre Sucursal", "nombre_sucursal"),
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      key: "direccion",
      sorter: (a, b) => a.direccion.localeCompare(b.direccion),
      ...getColumnSearchProps("Dirección", "direccion"),
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      sorter: (a, b) => a.telefono.localeCompare(b.telefono),
      ...getColumnSearchProps("Teléfono", "telefono"),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      ...getColumnSearchProps("Nombre", "nombre"),
    },
    {
      title: "Tipo Identificación",
      dataIndex: "tipo_identificacion",
      key: "tipo_identificacion",
      sorter: (a, b) => a.tipo_identificacion.localeCompare(b.tipo_identificacion),
      ...getColumnSearchProps("Tipo Identificación", "tipo_identificacion"),
    },
    {
      title: "Número Identificación",
      dataIndex: "numero_identificacion",
      key: "numero_identificacion",
      sorter: (a, b) => a.numero_identificacion.localeCompare(b.numero_identificacion),
      ...getColumnSearchProps("Número Identificación", "numero_identificacion"),
    },
  ];

  return (
    <div>
      <NavbarAdminMex title={"Listar Sucursales"} />
      <Typography.Title level={2} style={{ marginLeft: '270px', textAlign: 'center', marginTop: '2%', marginRight: '20px' }}>
        Información de Sucursales
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={branchData}
        pagination={{
          pageSize: 10,
          position: ["bottomCenter"],
        }}
        locale={{
          sortTitle: "Ordenar",
          triggerAsc: "Orden ascendente",
          triggerDesc: "Orden descendente",
          cancelSort: "Cancelar orden",
        }}
        scroll={{ x: 'max-content' }}
        style={{
          marginLeft: '270px',
          marginRight: '20px',
        }}
      />
    </div>
  );
};

export default VerSucursal;