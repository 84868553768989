import React, { useState } from 'react';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import { Modal, message } from 'antd';
import './eliminarGuia.css';

const DesactivarGuiaUser = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('interrapidisimo');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCarrierChange = (event) => {
    setCarrier(event.target.value);
  };

  const handleDelete = async () => {
    if (!trackingNumber.trim()) {
      message.error('Por favor ingrese un número de guía');
      return;
    }

    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      }
    };

    Modal.confirm({
      title: 'Confirmación',
      content: '¿Estás seguro de que deseas desactivar esta guía?',
      okText: 'Sí, desactivar',
      cancelText: 'Cancelar',
      onOk: async () => {
        setLoading(true);
        try {
          const usuario_id = localStorage.getItem('usuario_id');
          const token = localStorage.getItem('token');

          const response = await fetch(`https://integration.99envios.app/api/desactivar-preenvio/${usuario_id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestBody)
          });

          const data = await response.json();

          // Mostrar siempre el mensaje devuelto por la API
          message.info(data.message || 'No se recibió ningún mensaje de la API');
        } catch (error) {
          // Mostrar error si ocurre algo inesperado
          message.error(`Error inesperado: ${error.message}`);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <NavbarMex title="Desactivar guía" />
      <div className="user-eliminar-guia-tracking-container-container">
        <div className="user-eliminar-guia-tracking-container">
          <h2>DESACTIVAR GUÍA</h2>
          <p>Coloca el número de guía que deseas desactivar</p>

          <div className="user-eliminar-guia-search-bar">
            <input
              type="text"
              value={trackingNumber}
              onChange={handleInputChange}
              placeholder="Ingrese guía"
              className="user-eliminar-guia-search-input"
            />
            <select
              value={carrier}
              onChange={handleCarrierChange}
              className="user-eliminar-guia-carrier-select"
            >
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
          </div>
          <div className="user-eliminar-guia-search-bar">
            <button
              onClick={handleDelete}
              disabled={loading}
              className="user-eliminar-guia-search-button"
            >
              {loading ? 'Procesando...' : 'Desactivar'}
            </button>
          </div>
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default DesactivarGuiaUser;
