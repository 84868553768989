import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import axios from 'axios';
import NavbarPicking from '../components/navbarPicking/navbarPicking';
import './inventarioBodega.css';  // Estilos personalizados

const { Option } = Select;

const InventariosBodega = () => {
  const [data, setData] = useState([]); // Aquí se almacenan los datos de la tabla
  const [filteredData, setFilteredData] = useState([]); // Para almacenar los datos filtrados
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/picking/bodega/listar-inventario-sucursal/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: index + 1,
          id_producto: item.id_producto,
          nombre_producto: item.nombre_producto,
          cantidad: item.cantidad_entrante,
          cantidad_disponible: item.cantidad_disponible, // Nueva columna
          confirmacion_bodega: item.confirmacion_bodega,
          estado_variacion: item.estado_variacion,
          created_at: item.created_at,
          updated_at: item.updated_at,
          variaciones_bodega: item.variaciones_bodega,
          selected: false,
          ubicacion: `${item.sucursal_bodega.nombre_sucursal}\n${item.sucursal_bodega.ciudad.ciudad}`, // Nueva columna combinada con salto de línea
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  // Función para manejar la búsqueda
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filtered = data.filter(item => item.nombre_producto.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  // Columnas de la tabla
  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Producto',
      dataIndex: 'id_producto',
      key: 'id_producto',
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Cantidad Entrante',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Cantidad Disponible',
      dataIndex: 'cantidad_disponible',
      key: 'cantidad_disponible',
    },
    {
      title: 'Bodega',
      dataIndex: 'ubicacion',
      key: 'ubicacion',
      render: (text) => <span style={{ whiteSpace: 'pre-line' }}>{text}</span>, // Render con salto de línea
    },
    {
      title: 'Confirmación Bodega',
      dataIndex: 'confirmacion_bodega',
      key: 'confirmacion_bodega',
      render: (value) => value ? 'Confirmado' : 'No Confirmado',
    },
    {
      title: 'Estado Variación',
      dataIndex: 'estado_variacion',
      key: 'estado_variacion',
      render: (value) => value ? 'Con Variación' : 'Sin Variación',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Fecha de Actualización',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => value ? value : 'No Actualizado',
    },
  ];

  const handleCheckboxChange = (key) => {
    const updatedData = data.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setFilteredData(updatedData);
    setSelectedItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  return (
    <>
    <NavbarPicking title="Inventarios Bodega" />
    <div className="inventarios-bodega-container">
      <Space style={{ marginBottom: 16 }}>
        <Button>Acciones</Button>
        <Input.Search
          placeholder="Buscar"
          value={search}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </Space>

      <Table 
        columns={columns} 
        dataSource={filteredData} 
        pagination={{ pageSize: 5 }} 
        scroll={{ x: 'max-content' }} 
      />
    </div>
    </>
  );
};

export default InventariosBodega;
