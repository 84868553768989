import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Spin, Select, Upload, Row, Col, Checkbox } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import Footer from '../../components/footer';
import ExpandableText from './expandible';
import './inventariocrud.css';

const { Option } = Select;

const InventoryDrop = () => {
    const [productos, setProductos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [searchText, setSearchText] = useState(''); // Para la búsqueda global
    const [selectedCategory, setSelectedCategory] = useState(''); // Para el filtro por categoría

    const [newProductData, setNewProductData] = useState({
        id_categoria: '',
        nombre_producto: '',
        descripcion_producto: '',
        cantidad_disponible: '',
        precio_proveedor: '',
        precio_sugerido: '',
        sku: '',
        imagenes: [],
        variaciones: [],
        estado_variacion: '',
    });

    const filteredProducts = productos.filter((product) => {
        const matchesSearch = (
            (product.nombre_producto && product.nombre_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.descripcion_producto && product.descripcion_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.sku && product.sku.toLowerCase().includes(searchText.toLowerCase()))
        );
    
        const matchesCategory = selectedCategory === '' || product.id_categoria === Number(selectedCategory);
    
        return matchesSearch && matchesCategory;
    });

    const [form] = Form.useForm();

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            try {
                const { data } = await axios.get(url);
                setProductos(data);
            } catch (error) {
                message.error('Error al cargar productos');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const resetNewProductData = () => {
        setNewProductData({
            id_categoria: '',
            nombre_producto: '',
            descripcion_producto: '',
            cantidad_disponible: '',
            precio_proveedor: '',
            precio_sugerido: '',
            sku: '',
            imagenes: [],
            variaciones: [],
            estado_variacion: "",
            peso_producto: ''  // Asegúrate de incluir este campo
        });
    };

    const resetForm = () => {
        form.resetFields();
        resetNewProductData();
    };

    const showModal = (product) => {
        const tieneVariaciones = product.variaciones && product.variaciones.length > 0;

        const formattedImages = product.imagenes ? product.imagenes.map(img => ({
            uid: img.id ? img.id.toString() : `temp-${Math.random()}`,
            name: img.url_imagen.split('/').pop() || 'image.png',
            status: 'done',
            url: img.url_imagen,
        })) : [];

        setEditingProduct(product);
        setNewProductData({
            ...product,
            imagenes: formattedImages,
            peso_producto: product.peso_producto || '',  // Asegúrate de que el peso esté incluido aquí
            variaciones: product.variaciones.map(variation => ({
                id_variacion: variation.id || '',  // Aquí aseguramos que el `id_variacion` correcto se incluya
                id_producto: variation.id_producto || product.id_producto,
                talla: variation.talla || '',
                color: variation.color || '',
                cantidad_disponible: variation.cantidad_disponible || '',
                sku: variation.sku || '',
            })),
            estado_variacion: tieneVariaciones ? 1 : "",
        });
        setIsModalVisible(true);
        form.setFieldsValue({
            ...product,
            estado_variacion: tieneVariaciones,
            imagenes: formattedImages,
            peso_producto: product.peso_producto || '',  // Asegúrate de que el peso se establezca en el formulario
        });
    };

    const handleOk = async () => {
        try {
            const updatedProduct = {
                ...newProductData,
                ...form.getFieldsValue(),
                imagenes: Array.isArray(newProductData.imagenes) ? newProductData.imagenes : [], // Asegúrate de que sea un array
                variaciones: Array.isArray(newProductData.variaciones) ? newProductData.variaciones : [] // Asegúrate de que sea un array
            };

            const dataToSend = new FormData();

            // Agregar campos básicos
            Object.keys(updatedProduct).forEach(key => {
                if (key !== 'imagenes' && key !== 'variaciones') {
                    dataToSend.append(key, updatedProduct[key]);
                }
            });

            // Agregar imágenes (nuevas y existentes)
            updatedProduct.imagenes.forEach((file, index) => {
                if (file.originFileObj) {
                    dataToSend.append(`imagenes[${index}]`, file.originFileObj);
                } else if (file.id_imagen) {
                    dataToSend.append(`imagenes[${index}][id_imagen]`, file.id_imagen);
                }
            });

            // Agregar variaciones con sus respectivos `id_variacion`
            if (newProductData.estado_variacion === 1) {
                updatedProduct.variaciones.forEach((variation, index) => {
                    Object.keys(variation).forEach(key => {
                        dataToSend.append(`variaciones[${index}][${key}]`, variation[key]);
                    });
                    // Aquí nos aseguramos de que el `id_variacion` correcto esté presente en el FormData
                    if (variation.id_variacion) {
                        dataToSend.append(`variaciones[${index}][id_variacion]`, variation.id_variacion);
                    }
                });
            }

            // URL con `id_producto`
            const url = `https://api.99envios.app/api/inventarios_update/${editingProduct.id_producto}`;
            await axios.post(url, dataToSend, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            // Actualizar el estado local después de la edición
            setProductos(prev =>
                prev.map(product =>
                    product.id_producto === editingProduct.id_producto ? { ...product, ...updatedProduct } : product
                )
            );
            message.success('Producto actualizado correctamente');
            setIsModalVisible(false);
            resetNewProductData();
        } catch (error) {
            console.error('Error al actualizar el producto:', error);
            message.error('Error al actualizar el producto');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsNewProductModalVisible(false);
        resetNewProductData();
    };

    const handleDelete = async (id_producto) => {
        const url = `https://api.99envios.app/api/inventarios/${id_producto}`;
        try {
            await axios.delete(url);
            setProductos(prev => prev.filter(product => product.id_producto !== id_producto));
            message.success('Producto eliminado correctamente');
        } catch (error) {
            message.error('Error al eliminar el producto');
        }
    };

    const showDeleteConfirm = (product) => {
        Modal.confirm({
            title: '¿Estás seguro de eliminar este producto?',
            content: `Nombre del producto: ${product.nombre_producto}`,
            okText: 'Sí, eliminar',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(product.id_producto);
            },
            onCancel() {
                console.log('Cancelado');
            },
        });
    };

    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProductData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = ({ fileList }) => {
        setNewProductData(prev => ({
            ...prev,
            imagenes: fileList.map(file => {
                if (file.originFileObj) {
                    return {
                        ...file,
                        status: 'done',
                        url: URL.createObjectURL(file.originFileObj)
                    };
                }
                return file;
            })
        }));
    };




    const handleVariationsChange = (field, value, index) => {
        const updatedVariations = [...newProductData.variaciones];
        updatedVariations[index][field] = value;
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleAddVariation = () => {
        setNewProductData(prev => ({
            ...prev,
            variaciones: [...prev.variaciones, { talla: '', color: '', cantidad_disponible: '', sku: '' }]
        }));
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = newProductData.variaciones.filter((_, i) => i !== index);
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleNewProductSave = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();

            // Agregar campos básicos, incluyendo "peso"
            Object.keys(values).forEach(key => {
                if (key !== 'imagenes' && key !== 'variaciones') {
                    formData.append(key, values[key]);
                }
            });

            // Verificar y agregar imágenes como binarios
            if (newProductData.imagenes.length > 0) {
                newProductData.imagenes.forEach((file, index) => {
                    if (file.originFileObj) {
                        formData.append(`imagenes[${index}]`, file.originFileObj);
                    }
                });
            }

            // Agregar variaciones si existen
            if (newProductData.estado_variacion === 1) {
                newProductData.variaciones.forEach((variation, index) => {
                    Object.keys(variation).forEach(key => {
                        formData.append(`variaciones[${index}][${key}]`, variation[key]);
                    });
                });
            }

            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            const response = await axios.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            setProductos(prev => [...prev, response.data]);
            message.success('Nuevo producto creado con éxito');
            setIsNewProductModalVisible(false);

            resetForm();

            // Volver a abrir el modal con campos vacíos
            setIsNewProductModalVisible(true);
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al crear el producto: ' + error.message);
        }
    };

    const handleCheckboxChange = (e) => {
        setNewProductData(prev => ({
            ...prev,
            estado_variacion: e.target.checked ? 1 : "" // Cambia a 1 o 0
        }));
    };


    const renderVariations = (variaciones) => {
        if (!variaciones.length) return 'Sin variaciones';
        return variaciones.map((variation, index) => (
            <div key={index}>
                <strong>Talla:</strong> {variation.talla} &nbsp;
                <strong>Color:</strong> {variation.color} &nbsp;
                <strong>Cantidad:</strong> {variation.cantidad_disponible} &nbsp;
                <strong>SKU:</strong> {variation.sku}
            </div>
        ));
    };

    const columns = [
        { title: 'ID', dataIndex: 'id_producto', key: 'id_producto' },
        { title: 'Nombre', dataIndex: 'nombre_producto', key: 'nombre_producto' },
        {
            title: 'Descripción',
            dataIndex: 'descripcion_producto',
            key: 'descripcion_producto',
            render: (text) => <ExpandableText text={text} maxLength={100} /> // Limita a 100 caracteres
        },
        { title: 'Precio Sugerido', dataIndex: 'precio_proveedor', key: 'precio_proveedor' },
        { title: 'Cantidad Disponible', dataIndex: 'cantidad_disponible', key: 'cantidad_disponible' },
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        {
            title: 'Variaciones',
            key: 'variaciones',
            render: (text, record) => renderVariations(record.variaciones),
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Button type="link" onClick={() => showModal(record)}>Editar</Button>
                    <Button type="link" onClick={() => showDeleteConfirm(record)} danger>Eliminar</Button>
                </div>
            ),
        },
    ];

    if (isLoading) return <Spin size="large" center />;

    return (
        <>
            <NavbarDropshipping />
            <div className="table-containerC" style={{ fontSize: "13px" }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                    <Col>
                        <Button style={{height: "44px"}} type="primary" onClick={() => { setIsNewProductModalVisible(true); resetNewProductData(); }}>
                            Agregar Producto
                        </Button>
                    </Col>
                    <Col>
                        <Input
                            placeholder="Buscar productos"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: 200, marginRight: 10 }}
                        />
                        <Select
                            placeholder="Filtrar por categoría"
                            value={selectedCategory}
                            onChange={(value) => setSelectedCategory(value)}
                            style={{ width: 200 }}
                        >
                            <Option value="">Todas las categorías</Option>
                            <Option value="1">Bisuteria</Option>
                            <Option value="2">Ropa Deportiva</Option>
                            <Option value="3">Vaporizadores</Option>
                            <Option value="4">Mascotas</Option>
                            <Option value="5">Moda</Option>
                            <Option value="6">Tecnologia</Option>
                            <Option value="7">Cocina</Option>
                            <Option value="8">Belleza</Option>
                            <Option value="9">Salud</Option>
                            <Option value="10">Hogar</Option>
                            <Option value="11">Natural Home</Option>
                            <Option value="12">Deportes</Option>
                            <Option value="13">Sex Shop</Option>
                            <Option value="14">Bebé</Option>
                            <Option value="15">Aseo</Option>
                            <Option value="16">Bienestar</Option>
                            <Option value="17">Camping</Option>
                            <Option value="18">Pesca</Option>
                            <Option value="19">Defensa Personal</Option>
                            <Option value="20">Vehiculos</Option>
                            <Option value="21">Jugueteria</Option>
                            <Option value="22">Otros</Option>
                        </Select>
                    </Col>
                </Row>
                <Table 
                    dataSource={filteredProducts} 
                    columns={columns} 
                    rowKey="id_producto" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                />
            </div>

            <Modal
                title={editingProduct ? "Editar producto" : "Agregar nuevo producto"}
                visible={isModalVisible || isNewProductModalVisible}
                onOk={editingProduct ? handleOk : handleNewProductSave}
                onCancel={handleCancel}
                centered
                width={1100}
                heigth={1400}
                style={{ fontSize: "13px" }}
                footer={[
                    <Button style={{height: "44px"}} key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button style={{height: "44px"}} key="submit" type="primary" onClick={editingProduct ? handleOk : handleNewProductSave}>
                        {editingProduct ? "Guardar" : "Agregar"}
                    </Button>,
                ]}
            >
                <div className="ant-modal-body" style={{ fontSize: "13px" }}>
                    <Form form={form} layout="vertical" style={{ width: '100%', fontSize: "13px" }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Categoría del producto" name="id_categoria" style={{ textAlign: 'left' }}>
                                    <Select
                                        onChange={(value) => handleNewProductChange({ target: { name: 'id_categoria', value } })}
                                        value={newProductData.id_categoria}
                                        placeholder="Escoge una categoría"
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    >
                                        <option value="1">Bisuteria</option>
                                        <option value="2">Ropa Deportiva</option>
                                        <option value="3">Vaporizadores</option>
                                        <option value="4">Mascotas</option>
                                        <option value="5">Moda</option>
                                        <option value="6">Tecnologia</option>
                                        <option value="7">Cocina</option>
                                        <option value="8">Belleza</option>
                                        <option value="9">Salud</option>
                                        <option value="10">Hogar</option>
                                        <option value="11">Natural Home</option>
                                        <option value="12">Deportes</option>
                                        <option value="13">Sex Shop</option>
                                        <option value="14">Bebé</option>
                                        <option value="15">Aseo</option>
                                        <option value="16">Bienestar</option>
                                        <option value="17">Camping</option>
                                        <option value="18">Pesca</option>
                                        <option value="19">Defensa Personal</option>
                                        <option value="20">Vehiculos</option>
                                        <option value="21">Jugueteria</option>
                                        <option value="22">Otros</option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Nombre del producto" name="nombre_producto" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="Nombre del producto"
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Descripción del producto" name="descripcion_producto" style={{ textAlign: 'left' }}>
                                    <Input.TextArea
                                        onChange={handleNewProductChange}
                                        placeholder="Descripción"
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cantidad disponible" name="cantidad_disponible" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Cantidad"
                                        min={1} // Evita el valor cero
                                        style={{ maxWidth: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Proveedor" name="precio_proveedor" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio Proveedor"
                                        min={1} // Evita el valor cero
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="SKU" name="sku" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="SKU"
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Sugerido" name="precio_sugerido" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio sugerido"
                                        min={1} // Evita el valor cero
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Peso" name="peso_producto" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Peso"
                                        min={1} // Evita el valor cero
                                        style={{ width: '100%' }} // Ajustar tamaño del campo
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item label="Fotografía del producto" name="imagenes">
                                    <Upload
                                        listType="picture-card"
                                        fileList={newProductData.imagenes}
                                        onChange={handleFileChange}
                                        beforeUpload={() => false}
                                        multiple={true}
                                    >
                                        {newProductData.imagenes.length >= 8 ? null : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item name="estado_variacion" valuePropName="checked" initialValue={newProductData.estado_variacion}>
                                    <Checkbox
                                        onChange={handleCheckboxChange}
                                        style={{ alignItems: 'center' }}
                                    >
                                        Añadir variaciones
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        {newProductData.estado_variacion && (
                            <>
                                {newProductData.variaciones.map((variation, index) => (
                                    <Row gutter={[16, 16]} key={index}>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Talla"
                                                name={['variaciones', index, 'talla']}
                                                initialValue={variation.talla}
                                            >
                                                <Select
                                                    value={variation.color}
                                                    onChange={(value) => handleVariationsChange('talla', value, index)}
                                                    placeholder="Selecciona una talla"
                                                    style={{ width: '80px' }}
                                                >
                                                    <Option value="3M">3M</Option>
                                                    <Option value="6M">6M</Option>
                                                    <Option value="9M">9M</Option>
                                                    <Option value="12M">12M</Option>
                                                    <Option value="18M">18M</Option>
                                                    <Option value="24M">24M</Option>
                                                    <Option value="XS">XS</Option>
                                                    <Option value="S">S</Option>
                                                    <Option value="M">M</Option>
                                                    <Option value="L">L</Option>
                                                    <Option value="XL">XL</Option>
                                                    <Option value="XXL">XXL</Option>
                                                    <Option value="XXXL">XXXL</Option>
                                                    <Option value="4XL">4XL</Option>
                                                    <Option value="5XL">5XL</Option>
                                                    <Option value="6XL">6XL</Option>
                                                    <Option value="6">6</Option>
                                                    <Option value="7">7</Option>
                                                    <Option value="8">8</Option>
                                                    <Option value="10">10</Option>
                                                    <Option value="12">12</Option>
                                                    <Option value="14">14</Option>
                                                    <Option value="16">16</Option>
                                                    <Option value="18">18</Option>
                                                    <Option value="19">19</Option>
                                                    <Option value="20">20</Option>
                                                    <Option value="21">21</Option>
                                                    <Option value="22">22</Option>
                                                    <Option value="23">23</Option>
                                                    <Option value="24">24</Option>
                                                    <Option value="25">25</Option>
                                                    <Option value="26">26</Option>
                                                    <Option value="27">27</Option>
                                                    <Option value="28">28</Option>
                                                    <Option value="29">29</Option>
                                                    <Option value="30">30</Option>
                                                    <Option value="31">31</Option>
                                                    <Option value="32">32</Option>
                                                    <Option value="33">33</Option>
                                                    <Option value="34">34</Option>
                                                    <Option value="35">35</Option>
                                                    <Option value="36">36</Option>
                                                    <Option value="37">37</Option>
                                                    <Option value="38">38</Option>
                                                    <Option value="39">39</Option>
                                                    <Option value="40">40</Option>
                                                    <Option value="41">41</Option>
                                                    <Option value="42">42</Option>
                                                    <Option value="43">43</Option>
                                                    <Option value="44">44</Option>
                                                    <Option value="45">45</Option>
                                                    <Option value="46">46</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Color"
                                                name={['variaciones', index, 'color']}
                                                initialValue={variation.talla}
                                            >
                                                <Select
                                                    value={variation.talla}
                                                    onChange={(value) => handleVariationsChange('color', value, index)}
                                                    placeholder="Selecciona un color"
                                                    style={{ width: '120px' }}
                                                >
                                                    <Option value="Negro">Negro</Option>
                                                    <Option value="Blanco">Blanco</Option>
                                                    <Option value="Gris">Gris</Option>
                                                    <Option value="Azul">Azul</Option>
                                                    <Option value="Rojo">Rojo</Option>
                                                    <Option value="Verde">Verde</Option>
                                                    <Option value="Amarillo">Amarillo</Option>
                                                    <Option value="Marrón">Marrón</Option>
                                                    <Option value="Rosa">Rosa</Option>
                                                    <Option value="Naranja">Naranja</Option>
                                                    <Option value="Violeta">Violeta</Option>
                                                    <Option value="Beige">Beige</Option>
                                                    <Option value="Celeste">Celeste</Option>
                                                    <Option value="Turquesa">Turquesa</Option>
                                                    <Option value="Dorado">Dorado</Option>
                                                    <Option value="Plateado">Plateado</Option>
                                                    <Option value="Coral">Coral</Option>
                                                    <Option value="Borgoña">Borgoña</Option>
                                                    <Option value="Lila">Lila</Option>
                                                    <Option value="Fucsia">Fucsia</Option>
                                                    <Option value="Oliva">Oliva</Option>
                                                    <Option value="Caqui">Caqui</Option>
                                                    <Option value="Índigo">Índigo</Option>
                                                    <Option value="Aqua">Aqua</Option>
                                                    <Option value="Lavanda">Lavanda</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Cantidad Disponible"
                                                name={['variaciones', index, 'cantidad_disponible']}
                                                initialValue={variation.cantidad_disponible}
                                            >
                                                <Input
                                                    type="number"
                                                    value={variation.cantidad_disponible}
                                                    onChange={(e) => handleVariationsChange('cantidad_disponible', e.target.value, index)}
                                                    placeholder="Cantidad"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="SKU"
                                                name={['variaciones', index, 'sku']}
                                                initialValue={variation.sku}
                                            >
                                                <Input
                                                    value={variation.sku}
                                                    onChange={(e) => handleVariationsChange('sku', e.target.value, index)}
                                                    placeholder="SKU"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                type="danger"
                                                icon={<MinusCircleOutlined />}
                                                onClick={() => handleRemoveVariation(index)}
                                            >
                                                Eliminar
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={handleAddVariation}
                                    icon={<PlusOutlined />}
                                    style={{ width: '100%', marginTop: '16px' }}
                                >
                                    Añadir variación
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            </Modal>

            <Footer />
        </>
    );
};

export default InventoryDrop;