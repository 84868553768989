import React, { useState, useEffect } from 'react';
import { Table, Input, Button, message } from 'antd';
import axios from 'axios';
import NavbarSales from '../../components/navbar/navbarSales';
import 'antd/dist/antd.css';
import './PagoVendedores.css';

const PagoVendedores = () => {
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [tableData, setTableData] = useState([]);
  const userId = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/vendedor-saldo-disponible/${userId}`);
        setBalance(response.data.saldo || 0);
      } catch (error) {
        message.error('Error al cargar el saldo disponible');
      }
    };

    const fetchTableData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/vendedor-listar-transacciones/${userId}`);
        setTableData(response.data.transacciones.map((item, index) => ({
          key: index,
          tipo_transaccion: item.tipo_transaccion,
          monto: item.monto,
          fecha: item.fecha,
          estado: item.estado,
        })));
      } catch (error) {
        message.error('Error al cargar los datos de las transacciones');
      }
    };

    fetchBalance();
    fetchTableData();
  }, [userId]);

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios.post(`https://99envios.app/api/online/vendedor-retirar-saldo/${userId}`, {
        monto_retiro: amount,
      });
      message.success('Retiro realizado con éxito');
      setAmount(0); // Reset the amount input
      // Optionally, you can refresh the balance after withdrawal
      const response = await axios.get(`https://99envios.app/api/online/vendedor-saldo-disponible/${userId}`);
      setBalance(response.data.saldo || 0);
    } catch (error) {
      message.error('Error al realizar el retiro');
    }
  };

  const columns = [
    {
      title: 'Tipo de Transacción',
      dataIndex: 'tipo_transaccion',
      key: 'tipo_transaccion',
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
    },
  ];

  return (
    <>
        <NavbarSales title="Pago a Vendedores" />
    <div className="pago-vendedores-container">
      <div className="pago-vendedores-header">
        ¡Qué emocionante ver tus avances! Solo un esfuerzo más y estarás celebrando el éxito.
      </div>

      <div className="pago-vendedores-module">
        <div className="pago-vendedores-amount">
          <span className="material-icons-sharp pago-vendedores-bank-icon">account_balance</span>
          <h2>Total Disponible</h2>
          <p>$ {balance}</p>
        </div>

        <div className="pago-vendedores-info">
          <span className="material-icons-sharp pago-vendedores-info-icon">info</span>
          <h2>¡Atención, equipo ventas!</h2>
          <p>
            ¡La cuenta regresiva para el retiro mensual ha comenzado! Solo tienen una
            oportunidad al mes para hacerlo, así que ¡hagámoslo con sabiduría y estilo!
          </p>
        </div>
      </div>
      <div className="pago-vendedores-withdraw">
        <label htmlFor="withdrawAmount">Cantidad a Retirar</label>
        <Input
          id="withdrawAmount"
          type="number"
          value={amount}
          onChange={handleInputChange}
        />
        <Button type="primary" style={{ height: "44px" }} onClick={handleSubmit}>Enviar</Button>
      </div>

      <h2>Lista de transacciones</h2>
      <Table columns={columns} dataSource={tableData} pagination={false} />
    </div>
    </>
  );
};

export default PagoVendedores;
