import React from "react";
import { Button, Input, Select, Upload, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const FormPQRterminado = () => {

  const buttonStyle = {
    backgroundColor: '#3F51B5',
    borderColor: '#3F51B5',
    color: 'white',
    fontSize: '16px',
    height: '40px',
    padding: '0 20px',
    borderRadius: '8px',
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item label="Número de ticket" name="ticketNumber">
          <Input placeholder="Ingrese el número de ticket" />
        </Form.Item>

        <Form.Item label="Indique la acción a realizar" name="action">
          <Select defaultValue="En proceso">
            <Option value="En proceso">En proceso</Option>
            <Option value="Resuelto">Resuelto</Option>
            <Option value="Cerrado">Cerrado</Option>
            <Option value="Rechazado">Rechazado</Option>
            <Option value="Correo Enviado">Correo Enviado</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Respuesta PQR" name="pqrResponse">
          <Input.TextArea rows={4} placeholder="Escriba la respuesta PQR" />
        </Form.Item>

        <Form.Item label="Adjuntar Archivo (PDF, foto, jpg)">
          <Upload>
            <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" style={buttonStyle} htmlType="submit">
            Procesar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormPQRterminado;