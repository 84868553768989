import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Card, Table, message } from 'antd';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './FormularioBodega.css';

const { Option } = Select;

const FormularioBodega = () => {
    const [form] = Form.useForm();
    const [bodegas, setBodegas] = useState([]);
    const [bodegasData, setBodegasData] = useState([]);

    useEffect(() => {
        const id_user = localStorage.getItem('usuario_id');

        // Fetch the list of warehouses
        fetch(`https://99envios.app/api/picking/bodega`)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setBodegas(data);
                } else {
                    console.error('Fetched data is not an array:', data);
                    setBodegas([]);
                }
            })
            .catch(error => {
                console.error('Error fetching bodegas:', error);
                setBodegas([]);
            });

        // Fetch the detailed list of warehouses
        fetch(`https://99envios.app/api/picking/bodega/listar-bodegas/${id_user}`)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setBodegasData(data);
                } else {
                    console.error('Fetched data is not an array:', data);
                    setBodegasData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching bodegas data:', error);
                setBodegasData([]);
            });
    }, [form]);

    const onFinish = (values) => {
        const id_user = localStorage.getItem('usuario_id');
        fetch(`https://99envios.app/api/picking/bodega/asignar-bodega/${id_user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_bodega: values.bodega }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                message.error(data.error);
            } else {
                message.success('Bodega asignada exitosamente.');
            }
        })
        .catch(error => {
            console.error('Error sending data to API:', error);
            message.error('Error al asignar la bodega.');
        });
    };

    const columns = [
        {
            title: 'Nombre Sucursal',
            dataIndex: 'nombre_sucursal',
            key: 'nombre_sucursal',
        },
        {
            title: 'ID Ciudad',
            dataIndex: 'id_ciudad',
            key: 'id_ciudad',
        },
        {
            title: 'Código Sucursal',
            dataIndex: 'codigo_sucursal',
            key: 'codigo_sucursal',
        },
        {
            title: 'Dirección',
            dataIndex: 'direccion',
            key: 'direccion',
        },
        {
            title: 'Teléfono',
            dataIndex: 'telefono',
            key: 'telefono',
        },
        {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (value) => (value ? 'Sí' : 'No'),
        },
    ];

    return (
        <>
            <NavbarPicking title="Selección de Bodega" />
            <div className="formulario-bodega-container">
                <Card title="Selecciona una Bodega" className="formulario-bodega-card">
                    <Form
                        form={form}
                        name="bodegaForm"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Selecciona Bodega:"
                            name="bodega"
                            rules={[{ required: true, message: 'Por favor selecciona una bodega' }]}
                        >
                            <Select placeholder="Selecciona una bodega">
                                {bodegas.map(bodega => (
                                    <Option key={bodega.id_users} value={bodega.id_users}>
                                        {bodega.nombre_sucursal}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button style={{ height: "44px" }} type="primary" htmlType="submit" className="formulario-bodega-submit-button">
                                Confirmar Selección
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Card title="Lista de Bodegas" className="formulario-bodega-card">
                    <Table
                        columns={columns}
                        dataSource={bodegasData}
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: false,
                            showQuickJumper: false,
                        }}
                        rowKey="id"
                        scroll={{ x: 'max-content' }} // Add horizontal scroll
                    />
                </Card>
            </div>
        </>
    );
};

export default FormularioBodega;
