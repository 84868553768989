import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import NavbarAdminMex from '../components/navbar';
import axios from 'axios';
import './ListarCuentaStyles.css'; 

const ListarCuenta = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://99envios.app/api/online/listar_estado_cuenta/1');
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const columnsMainTable = [
    {
      title: 'Sucursal',
      dataIndex: 'sucursal',
      key: 'sucursal',
      className: 'listar-cuenta-table-column-sucursal',
    },
    {
      title: 'Código Sucursal',
      dataIndex: 'codigo_sucursal',
      key: 'codigo_sucursal',
      className: 'listar-cuenta-table-column-codigo',
    },
    {
      title: 'Payments',
      dataIndex: 'Payments',
      key: 'Payments',
      className: 'listar-cuenta-table-column-payments',
    },
    {
      title: 'Total Envíos sin Contrapago',
      dataIndex: ['transacciones', 'total_envios_sin_contrapago'],
      key: 'total_envios_sin_contrapago',
      className: 'listar-cuenta-table-column-envios',
    },
    {
      title: 'Total Retiros',
      dataIndex: ['transacciones', 'total_retiros'],
      key: 'total_retiros',
      className: 'listar-cuenta-table-column-retiros',
    }
  ];

  const columnsDetailsTable = [
    {
      title: 'Sucursal',
      dataIndex: 'sucursal',
      key: 'sucursal',
    },
    {
      title: 'Total Consignado Preenvios',
      dataIndex: ['preenvios', 'total_consignado_preenvios'],
      key: 'total_consignado_preenvios',
    },
    {
      title: 'Total Servicio Sin Contrapago',
      dataIndex: ['preenvios', 'total_servicio_SinContrapago'],
      key: 'total_servicio_SinContrapago',
    },
    {
      title: 'Total Devolución Preenvios',
      dataIndex: ['preenvios', 'total_devolucion_preenvios'],
      key: 'total_devolucion_preenvios',
    },
    {
      title: 'Total Valor Declarado',
      dataIndex: ['preenvios', 'total_valor_declarado'],
      key: 'total_valor_declarado',
    },
    {
      title: 'Total Comisiones Retiro',
      dataIndex: ['transacciones', 'total_comision_retiro'],
      key: 'total_comision_retiro',
    },
    {
      title: 'Total Recargas',
      dataIndex: ['recargas', 'total_recargas'],
      key: 'total_recargas',
    },
    {
      title: 'Total Bonos',
      dataIndex: ['recargas', 'total_bono'],
      key: 'total_bono',
    },
    {
      title: 'Total Dueño',
      dataIndex: ['ordenes', 'total_dueño'],
      key: 'total_dueño',
    },
    {
      title: 'Total Vendedor',
      dataIndex: ['ordenes', 'total_vendedor'],
      key: 'total_vendedor',
    }
  ];

  return (
    <>
      <NavbarAdminMex title={"Estado de Cuenta"} />
      <div className="listar-cuenta-wrapper">
        <h1 className="listar-cuenta-title">Estado de Cuenta</h1>
        
        {/* Primera Tabla con datos principales */}
        <Table
          columns={columnsMainTable}
          dataSource={data}
          rowKey="codigo_sucursal"
          pagination={{ pageSize: 5 }}
          bordered
          className="listar-cuenta-table"
        />
        
        {/* Segunda Tabla con detalles adicionales */}
        <h2 className="listar-cuenta-subtitle">Detalles adicionales</h2>
        <Table
          columns={columnsDetailsTable}
          dataSource={data}
          rowKey="codigo_sucursal"
          pagination={{ pageSize: 5 }}
          bordered
          className="listar-cuenta-details-table"
        />
      </div>
    </>
  );
};

export default ListarCuenta;
