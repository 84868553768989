import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './guiaSimple.css';
import Ciudades from '../../ciudades/ciudades';
import { Select } from 'antd';

const { Option } = Select;

/**
 * Componente principal para gestionar la cotización de envíos.
 * @component
 */
function GuiaSimple() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    destinationCity: '',
    declaredValue: '50000',
    deliveryType: 'direccion',
    shipmentType: 'paquete',
    grossWeight: '1',
    length: '10',
    width: '10',
    height: '10',
    contentDescription: '',
    observations: '',
    cashOnDelivery: true,
    originCity: '', // Agregar nueva propiedad para ciudad de origen
  });

  const [cotizacionResult, setCotizacionResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cotizarDisabled, setCotizarDisabled] = useState(false);
  const [volumetricWeight, setVolumetricWeight] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [filteredCities, setFilteredCities] = useState(Ciudades);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showCityList, setShowCityList] = useState(false);

  /**
   * Calcula el peso volumétrico basado en las dimensiones del paquete.
   */
  useEffect(() => {
    const largo = parseFloat(formData.length) || 0;
    const ancho = parseFloat(formData.width) || 0;
    const alto = parseFloat(formData.height) || 0;
    const pesoVolumetrico = (largo * ancho * alto) / 6000;
    setVolumetricWeight(pesoVolumetrico.toFixed(2));
  }, [formData.length, formData.width, formData.height]);

  /**
   * Verifica el valor declarado mínimo basado en el peso del paquete.
   */
  useEffect(() => {
    const peso = parseFloat(formData.grossWeight);
    const PesoVolumetrico = parseFloat(volumetricWeight);
    const valorDeclarado = parseFloat(formData.declaredValue);
    let valorDeclaradoMinimo;

    if (peso > PesoVolumetrico) {
      if (peso >= 1 && peso <= 2) valorDeclaradoMinimo = 25000;
      else if (peso >= 3 && peso <= 5) valorDeclaradoMinimo = 40000;
      else if (peso >= 6) valorDeclaradoMinimo = 50000;

      if (valorDeclarado < valorDeclaradoMinimo) {
        setErrorMessage(`El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${peso} kg.`);
      } else {
        setErrorMessage('');
      }
    } else {
      if (PesoVolumetrico >= 1 && PesoVolumetrico <= 2) valorDeclaradoMinimo = 25000;
      else if (PesoVolumetrico >= 3 && PesoVolumetrico <= 5) valorDeclaradoMinimo = 40000;
      else if (PesoVolumetrico >= 6) valorDeclaradoMinimo = 50000;

      if (valorDeclarado < valorDeclaradoMinimo) {
        setErrorMessage(`El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${PesoVolumetrico} kg.`);
      } else {
        setErrorMessage('');
      }
    }
  }, [formData.grossWeight, formData.declaredValue, volumetricWeight]);

  /**
   * Maneja los cambios en los campos del formulario.
   * @param {Event} e - Evento de cambio del input.
   */
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  /**
   * Maneja el envío del formulario de cotización.
   * @param {Event} e - Evento de envío del formulario.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cotizarDisabled) return;
    
    setLoading(true);
    setCotizarDisabled(true);

    const pesoBruto = parseFloat(formData.grossWeight);
    const pesoVolumetrico = parseFloat(volumetricWeight);
    const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;

    const cotizacionData = {
      destino: {
        nombre: "",
        codigo: formData.destinationCity,
      },
      origen: {
        nombre: "",
        codigo: formData.originCity, // Usar la ciudad de origen seleccionada
      },
      IdTipoEntrega: formData.deliveryType === 'direccion' ? 1 : 2,
      IdServicio: formData.shipmentType === 'normal' ? 1 : 2,
      peso: pesoFinal,
      largo: parseInt(formData.length, 10),
      ancho: parseInt(formData.width, 10),
      alto: parseInt(formData.height, 10),
      fecha: "28-08-2024",
      AplicaContrapago: formData.cashOnDelivery,
      valorDeclarado: parseInt(formData.declaredValue, 10)
    };

    try {
      const response = await fetch('https://integration.99envios.app/api/sucursal/cotizar/21119', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cotizacionData),
      });

      if (!response.ok) {
        throw new Error('Error en la cotización');
      }

      const data = await response.json();
      setCotizacionResult(data);
      setShowModal(true); // Mostrar modal cuando tengamos resultados
    } catch (error) {
      setErrorMessage('Error al realizar la cotización');
    } finally {
      setLoading(false);
      setTimeout(() => setCotizarDisabled(false), 3000);
    }
  };

  /**
   * Maneja la selección de una transportadora.
   * @param {string} transportadora - Nombre de la transportadora seleccionada.
   */
  const handleSelectTransportadora = (transportadora) => {
    // Guardamos los datos de la cotización en localStorage para recuperarlos después del registro
    localStorage.setItem('cotizacionTemp', JSON.stringify({
      transportadora,
      cotizacion: cotizacionResult[transportadora],
      formData
    }));
    
    // Redirigimos al login-top con un query parameter para indicar que debe mostrar el registro
    navigate('/login-top?showRegister=true');
  };

  /**
   * Componente para mostrar los resultados de la cotización en un modal.
   */
  const ResultsModal = () => {
    if (!showModal) return null;

    return (
      <div className="modal-overlay-cotizador-externo">
        <div className="modal-content-cotizador-externo">
          <button 
            className="modal-close-cotizador-externo"
            onClick={() => setShowModal(false)}
          >
            ×
          </button>
          
          <div className="modal-header-cotizador-externo">
            <h2>¡Descubre los mejores precios en envíos!</h2>
            <p>Con 99 Envíos obtendrás acceso a tarifas preferenciales y podrás gestionar todos tus envíos desde un solo lugar.</p>
          </div>

          <table className="result-table-cotizador-externo">
            <thead>
              <tr>
                <th>Transportadora</th>
                <th>Valor Total</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(cotizacionResult).map(([transportadora, datos]) => {
                const imageSrc = {
                  interrapidisimo: '/Images/inter.png',
                  tcc: '/Images/tcc.svg',
                  servientrega: '/Images/servi.png',
                  coordinadora: '/Images/coordi.png'
                }[transportadora];

                return (
                  <tr key={transportadora}>
                    <td>
                      <img src={imageSrc} alt={transportadora} style={{maxHeight: '30px'}} />
                    </td>
                    <td>
                      ${(datos.valor + datos.sobreflete + (datos.valor_contrapago || 0)).toLocaleString()}
                    </td>
                    <td>
                      <button 
                        onClick={() => handleSelectTransportadora(transportadora)}
                        className="register-button-cotizador-externo"
                      >
                        Seleccionar
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="modal-footer-cotizador-externo">
            <p>¿Quieres acceder a estos precios y más beneficios?</p>
            <button 
              className="register-button-cotizador-externo"
              onClick={() => navigate('/login-top?showRegister=true')}
            >
              Regístrate en 99 Envíos
            </button>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Componente para seleccionar la ciudad de destino.
   */
  const CitiesSearchComponent = () => (
    <div className="form-group-cotizador-externo">
      <label htmlFor="destinationCity">Ciudad destino</label>
      <Select
        id="destinationCity"
        value={formData.destinationCity}
        onChange={(value) => handleChange({ target: { name: 'destinationCity', value } })}
        style={{
          width: '100%',
          padding: '3px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '14px',
          height: '44px',
          marginBottom: '10px',
        }}
        required
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        <Option value="" disabled>Seleccione una ciudad</Option>
        {Ciudades.map((ciudad) => (
          <Option key={ciudad.value} value={ciudad.value}>
            {ciudad.label}
          </Option>
        ))}
      </Select>
    </div>
  );

  /**
   * Componente para seleccionar la ciudad de origen.
   */
  const OriginCityComponent = () => (
    <div className="form-group-cotizador-externo">
      <label htmlFor="originCity">Ciudad origen</label>
      <Select
        id="originCity"
        value={formData.originCity}
        onChange={(value) => handleChange({ target: { name: 'originCity', value } })}
        style={{
          width: '100%',
          padding: '3px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '14px',
          height: '44px',
          marginBottom: '10px',
        }}
        required
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        <Option value="" disabled>Seleccione una ciudad</Option>
        {Ciudades.map((ciudad) => (
          <Option key={ciudad.value} value={ciudad.value}>
            {ciudad.label}
          </Option>
        ))}
      </Select>
    </div>
  );

  return (
    <div className="guia-simple-main-wrapper-cotizador-externo">
      <div className="guia-simple-form-container-cotizador-externo">
        <div className="info-banner-cotizador-externo">
          <div className="info-banner-content-cotizador-externo">
            <div className="info-banner-icon-cotizador-externo">
              ℹ️
            </div>
            <div className="info-banner-text-cotizador-externo">
              99envios te ofrece beneficios exclusivos como opciones personalizadas de transporte, 
              descuentos especiales y soporte dedicado para optimizar tus envíos. 
              ¡Regístrate para aprovechar todas las ventajas! Los valores y tarifas mostrados pueden variar. 
              
            </div>
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="shipping-form-cotizador-externo">
          <OriginCityComponent />
          <CitiesSearchComponent />
          
          <div className="form-row-cotizador-externo">
            <div className="form-group-cotizador-externo">
              <label htmlFor="grossWeight">Peso (kg)</label>
              <input
                type="number"
                id="grossWeight"
                name="grossWeight"
                value={formData.grossWeight}
                onChange={handleChange}
                required
                className="input-field-cotizador-externo"
                min="0.1"
                step="0.1"
              />
            </div>

            <div className="form-group-cotizador-externo">
              <label htmlFor="declaredValue">Valor declarado</label>
              <input
                type="number"
                id="declaredValue"
                name="declaredValue"
                value={formData.declaredValue}
                onChange={handleChange}
                required
                className="input-field-cotizador-externo"
                min="50000"
              />
            </div>
          </div>

          <div className="form-row-cotizador-externo">
            <div className="form-group-cotizador-externo">
              <label htmlFor="length">Largo (cm)</label>
              <input
                type="number"
                id="length"
                name="length"
                value={formData.length}
                onChange={handleChange}
                required
                className="input-field-cotizador-externo"
                min="1"
              />
            </div>

            <div className="form-group-cotizador-externo">
              <label htmlFor="width">Ancho (cm)</label>
              <input
                type="number"
                id="width"
                name="width"
                value={formData.width}
                onChange={handleChange}
                required
                className="input-field-cotizador-externo"
                min="1"
              />
            </div>

            <div className="form-group-cotizador-externo">
              <label htmlFor="height">Alto (cm)</label>
              <input
                type="number"
                id="height"
                name="height"
                value={formData.height}
                onChange={handleChange}
                required
                className="input-field-cotizador-externo"
                min="1"
              />
            </div>

            <div className="form-group-cotizador-externo">
              <label>Peso volumétrico</label>
              <input
                type="text"
                value={volumetricWeight}
                readOnly
                className="input-field-cotizador-externo"
              />
            </div>
          </div>

          <div className="form-group-cotizador-externo">
            <label>
              <input
                type="checkbox"
                name="cashOnDelivery"
                checked={formData.cashOnDelivery}
                onChange={handleChange}
              />
              Pago contra entrega
            </label>
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <button type="submit" className="submit-button-cotizador-externo" disabled={cotizarDisabled}>
            {cotizarDisabled ? 'Cotizando...' : 'Cotizar'}
          </button>

          {loading && <div className="loading-spinner-cotizador-externo">Cargando...</div>}
        </form>
        <ResultsModal />
      </div>
    </div>
  );
}

export default GuiaSimple;
