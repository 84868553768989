import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, message } from 'antd';
import './agregarProductoShopify.css';
import axios from 'axios';

const ListarProductosShopify = () => {
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const fetchProducts = async () => {
        try {
            const userID = localStorage.getItem('usuario_id');
            const response = await axios.get(`https://integration.99envios.app/api/integration/shopify/v1/productsShopify/${userID}`);
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
            message.error('Error fetching products.');
        }
    };

    const handleShowProductsModal = () => {
        fetchProducts();
        setIsNewProductModalVisible(true);
    };

    const handleSynchronizeProduct = async (product) => {
        try {
            const id_producto = product.id;
            const userID = localStorage.getItem('usuario_id');
            // Creamos el objeto con la nueva estructura de datos
            const productData = {
                id_producto: product.id,
                nombre_producto: product.title,
                id_categoria: product.product_type,
                descripcion_producto: product.body_html
            };
            
            await axios.get(`https://integration.99envios.app/api/integration/shopify/v1/productShopifyToLocal/${userID}/${id_producto}`, productData);
            message.success(`Producto ${product.title} sincronizado con éxito`);
        } catch (error) {
            console.error('Error synchronizing product:', error);
            message.error(`Error synchronizing ${product.title}`);
        }
    };

    const toggleDescription = (key) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
        },
        {
            title: 'Nombre Producto',
            dataIndex: 'title',
            key: 'title',
            width: '5%',
        },
        {
            title: 'Descripción',
            dataIndex: 'body_html',
            key: 'body_html',
            width: '20%',
            render: (text, record) => {
                const isExpanded = expandedDescriptions[record.id];
                return (
                    <div className="custom-details">
                        {isExpanded ? text : `${text.substring(0, 80)}...`}
                        <Button type="link" onClick={() => toggleDescription(record.id)}>
                            {isExpanded ? 'Ver menos' : 'Ver más'}
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Vendedor',
            dataIndex: 'vendor',
            key: 'vendor',
            width: '1%',
        },
        {
            title: 'Categoría',
            dataIndex: 'product_type',
            key: 'product_type',
            width: '1%',
        },
        {
            title: 'Acciones',
            key: 'acciones',
            width: '5%',
            render: (_, product) => (
                <Button style={{ height: 44 }} onClick={() => handleSynchronizeProduct(product)} type="primary">
                    Sincronizar
                </Button>
            ),
        },
    ];

    return (
        <>
            <Button
                type="primary"
                onClick={handleShowProductsModal}
                style={{
                    width: '196px',
                    height: '44px',
                    marginBottom: '10px',
                    backgroundColor: '#6F31F5',
                    borderColor: '#6F31F5',
                }}
            >
                Mis Productos Shopify
            </Button>

            <Modal
                title="Mis Productos Shopify"
                visible={isNewProductModalVisible}
                onCancel={() => setIsNewProductModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsNewProductModalVisible(false)}>
                        Cerrar
                    </Button>,
                ]}
                centered
                width={1100}
            >
                <Table
                    dataSource={products}
                    columns={columns}
                    rowKey="id"
                    className="custom-table"
                />
            </Modal>
        </>
    );
};

export default ListarProductosShopify;