// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useRole from './useRole';

const PrivateRoute = ({ children, roles }) => {
  const userRole = useRole();
  if (!roles.includes(parseInt(userRole))) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};
export default PrivateRoute;
