const Ciudades = [
  { value: "91263000", label: "EL ENCANTO - AMAZONAS" },
  { value: "91405000", label: "LA CHORRERA - AMAZONAS" },
  { value: "91407000", label: "LA PEDRERA - AMAZONAS" },
  { value: "91001000", label: "LETICIA - AMAZONAS" },
  { value: "91430000", label: "LA VICTORIA PACOA - AMAZONAS" },
  { value: "91460000", label: "MIRITI - PARANA - AMAZONAS" },
  { value: "91530000", label: "PUERTO ALEGRIA - AMAZONAS" },
  { value: "91536000", label: "PUERTO ARICA - AMAZONAS" },
  { value: "91540000", label: "PUERTO NARIÑO - AMAZONAS" },
  { value: "91669000", label: "PUERTO SANTANDER - AMAZONAS" },
  { value: "91798000", label: "TARAPACA - AMAZONAS" },
  { value: "05002000", label: "ABEJORRAL - ANTIOQUIA" },
  { value: "05004000", label: "ABRIAQUI - ANTIOQUIA" },
  { value: "05021000", label: "ALEJANDRIA - ANTIOQUIA" },
  { value: "05030000", label: "AMAGA - ANTIOQUIA" },
  { value: "05031000", label: "AMALFI - ANTIOQUIA" },
  { value: "05034000", label: "ANDES - ANTIOQUIA" },
  { value: "05036000", label: "ANGELOPOLIS - ANTIOQUIA" },
  { value: "05038000", label: "ANGOSTURA - ANTIOQUIA" },
  { value: "05040000", label: "ANORI - ANTIOQUIA" },
  { value: "05042000", label: "SANTAFE DE ANTIOQUIA - ANTIOQUIA" },
  { value: "05044000", label: "ANZA - ANTIOQUIA" },
  { value: "05837001", label: "CURRULAO - ANTIOQUIA" },
  { value: "05591004", label: "DORADAL - ANTIOQUIA" },
  { value: "05045000", label: "APARTADO - ANTIOQUIA" },
  { value: "05051000", label: "ARBOLETES - ANTIOQUIA" },
  { value: "05055000", label: "ARGELIA - ANTIOQUIA" },
  { value: "05059000", label: "ARMENIA - ANTIOQUIA" },
  { value: "05079000", label: "BARBOSA - ANTIOQUIA" },
  { value: "05086000", label: "BELMIRA - ANTIOQUIA" },
  { value: "05088000", label: "BELLO - ANTIOQUIA" },
  { value: "05091000", label: "BETANIA - ANTIOQUIA" },
  { value: "05093000", label: "BETULIA - ANTIOQUIA" },
  { value: "05101000", label: "CIUDAD BOLIVAR - ANTIOQUIA" },
  { value: "05107000", label: "BRICEÑO - ANTIOQUIA" },
  { value: "05113000", label: "BURITICA - ANTIOQUIA" },
  { value: "05120000", label: "CACERES - ANTIOQUIA" },
  { value: "05125000", label: "CAICEDO - ANTIOQUIA" },
  { value: "05129000", label: "CALDAS - ANTIOQUIA" },
  { value: "05134000", label: "CAMPAMENTO - ANTIOQUIA" },
  { value: "05138000", label: "CAÑASGORDAS - ANTIOQUIA" },
  { value: "05142000", label: "CARACOLI - ANTIOQUIA" },
  { value: "05145000", label: "CARAMANTA - ANTIOQUIA" },
  { value: "05147000", label: "CAREPA - ANTIOQUIA" },
  { value: "05148000", label: "EL CARMEN DE VIBORAL - ANTIOQUIA" },
  { value: "05150000", label: "CAROLINA - ANTIOQUIA" },
  { value: "05154000", label: "CAUCASIA - ANTIOQUIA" },
  { value: "05172000", label: "CHIGORODO - ANTIOQUIA" },
  { value: "05190000", label: "CISNEROS - ANTIOQUIA" },
  { value: "05197000", label: "COCORNA - ANTIOQUIA" },
  { value: "05206000", label: "CONCEPCION - ANTIOQUIA" },
  { value: "05209000", label: "CONCORDIA - ANTIOQUIA" },
  { value: "05212000", label: "COPACABANA - ANTIOQUIA" },
  { value: "05234000", label: "DABEIBA - ANTIOQUIA" },
  { value: "05237000", label: "DONMATIAS - ANTIOQUIA" },
  { value: "05240000", label: "EBEJICO - ANTIOQUIA" },
  { value: "05250000", label: "EL BAGRE - ANTIOQUIA" },
  { value: "05264000", label: "ENTRERRIOS - ANTIOQUIA" },
  { value: "05266000", label: "ENVIGADO - ANTIOQUIA" },
  { value: "05282000", label: "FREDONIA - ANTIOQUIA" },
  { value: "05284000", label: "FRONTINO - ANTIOQUIA" },
  { value: "05306000", label: "GIRALDO - ANTIOQUIA" },
  { value: "05308000", label: "GIRARDOTA - ANTIOQUIA" },
  { value: "05310000", label: "GOMEZ PLATA - ANTIOQUIA" },
  { value: "05313000", label: "GRANADA - ANTIOQUIA" },
  { value: "05315000", label: "GUADALUPE - ANTIOQUIA" },
  { value: "05318000", label: "GUARNE - ANTIOQUIA" },
  { value: "05321000", label: "GUATAPE - ANTIOQUIA" },
  { value: "05347000", label: "HELICONIA - ANTIOQUIA" },
  { value: "05353000", label: "HISPANIA - ANTIOQUIA" },
  { value: "05360000", label: "ITAGUI - ANTIOQUIA" },
  { value: "05361000", label: "ITUANGO - ANTIOQUIA" },
  { value: "05364000", label: "JARDIN - ANTIOQUIA" },
  { value: "05368000", label: "JERICO - ANTIOQUIA" },
  { value: "05376000", label: "LA CEJA - ANTIOQUIA" },
  { value: "05380000", label: "LA ESTRELLA - ANTIOQUIA" },
  { value: "05390000", label: "LA PINTADA - ANTIOQUIA" },
  { value: "05400000", label: "LA UNION - ANTIOQUIA" },
  { value: "05411000", label: "LIBORINA - ANTIOQUIA" },
  { value: "05425000", label: "MACEO - ANTIOQUIA" },
  { value: "05440000", label: "MARINILLA - ANTIOQUIA" },
  { value: "05001000", label: "MEDELLIN - ANTIOQUIA" },
  { value: "05467000", label: "MONTEBELLO - ANTIOQUIA" },
  { value: "05475000", label: "MURINDO - ANTIOQUIA" },
  { value: "05480000", label: "MUTATA - ANTIOQUIA" },
  { value: "05483000", label: "NARIÑO - ANTIOQUIA" },
  { value: "05490000", label: "NECOCLI - ANTIOQUIA" },
  { value: "05495000", label: "NECHI - ANTIOQUIA" },
  { value: "05501000", label: "OLAYA - ANTIOQUIA" },
  { value: "05541000", label: "PEÑOL - ANTIOQUIA" },
  { value: "05543000", label: "PEQUE - ANTIOQUIA" },
  { value: "05576000", label: "PUEBLORRICO - ANTIOQUIA" },
  { value: "05579000", label: "PUERTO BERRIO - ANTIOQUIA" },
  { value: "05585000", label: "PUERTO NARE - ANTIOQUIA" },
  { value: "05591000", label: "PUERTO TRIUNFO - ANTIOQUIA" },
  { value: "05604000", label: "REMEDIOS - ANTIOQUIA" },
  { value: "05607000", label: "RETIRO - ANTIOQUIA" },
  { value: "05615000", label: "RIONEGRO - ANTIOQUIA" },
  { value: "05628000", label: "SABANALARGA - ANTIOQUIA" },
  { value: "05631000", label: "SABANETA - ANTIOQUIA" },
  { value: "05642000", label: "SALGAR - ANTIOQUIA" },
  { value: "05647000", label: "SAN ANDRES DE CUERQUIA - ANTIOQUIA" },
  { value: "05649000", label: "SAN CARLOS - ANTIOQUIA" },
  { value: "05652000", label: "SAN FRANCISCO - ANTIOQUIA" },
  { value: "05656000", label: "SAN JERONIMO - ANTIOQUIA" },
  { value: "05658000", label: "SAN JOSE DE LA MONTAÑA - ANTIOQUIA" },
  { value: "05659000", label: "SAN JUAN DE URABA - ANTIOQUIA" },
  { value: "05660000", label: "SAN LUIS - ANTIOQUIA" },
  { value: "05664000", label: "SAN PEDRO DE LOS MILAGROS - ANTIOQUIA" },
  { value: "05665000", label: "SAN PEDRO DE URABA - ANTIOQUIA" },
  { value: "05667000", label: "SAN RAFAEL - ANTIOQUIA" },
  { value: "05670000", label: "SAN ROQUE - ANTIOQUIA" },
  { value: "05674000", label: "SAN VICENTE - ANTIOQUIA" },
  { value: "05679000", label: "SANTA BARBARA - ANTIOQUIA" },
  { value: "05686000", label: "SANTA ROSA DE OSOS - ANTIOQUIA" },
  { value: "05690000", label: "SANTO DOMINGO - ANTIOQUIA" },
  { value: "05697000", label: "EL SANTUARIO - ANTIOQUIA" },
  { value: "05736000", label: "SEGOVIA - ANTIOQUIA" },
  { value: "05756000", label: "SONSON - ANTIOQUIA" },
  { value: "05761000", label: "SOPETRAN - ANTIOQUIA" },
  { value: "05789000", label: "TAMESIS - ANTIOQUIA" },
  { value: "05790000", label: "TARAZA - ANTIOQUIA" },
  { value: "05792000", label: "TARSO - ANTIOQUIA" },
  { value: "05809000", label: "TITIRIBI - ANTIOQUIA" },
  { value: "05819000", label: "TOLEDO - ANTIOQUIA" },
  { value: "05837000", label: "TURBO - ANTIOQUIA" },
  { value: "05842000", label: "URAMITA - ANTIOQUIA" },
  { value: "05847000", label: "URRAO - ANTIOQUIA" },
  { value: "05854000", label: "VALDIVIA - ANTIOQUIA" },
  { value: "05856000", label: "VALPARAISO - ANTIOQUIA" },
  { value: "05858000", label: "VEGACHI - ANTIOQUIA" },
  { value: "05861000", label: "VENECIA - ANTIOQUIA" },
  { value: "05873000", label: "VIGIA DEL FUERTE - ANTIOQUIA" },
  { value: "05885000", label: "YALI - ANTIOQUIA" },
  { value: "05887000", label: "YARUMAL - ANTIOQUIA" },
  { value: "05890000", label: "YOLOMBO - ANTIOQUIA" },
  { value: "05893000", label: "YONDO - ANTIOQUIA" },
  { value: "05895000", label: "ZARAGOZA - ANTIOQUIA" },
  { value: "81001000", label: "ARAUCA - ARAUCA" },
  { value: "81065000", label: "ARAUQUITA - ARAUCA" },
  { value: "81220000", label: "CRAVO NORTE - ARAUCA" },
  { value: "81300000", label: "FORTUL - ARAUCA" },
  { value: "81591000", label: "PUERTO RONDON - ARAUCA" },
  { value: "81736000", label: "SARAVENA - ARAUCA" },
  { value: "81794000", label: "TAME - ARAUCA" },
  { value: "08001000", label: "BARRANQUILLA - ATLANTICO" },
  { value: "08078000", label: "BARANOA - ATLANTICO" },
  { value: "08137000", label: "CAMPO DE LA CRUZ - ATLANTICO" },
  { value: "08141000", label: "CANDELARIA - ATLANTICO" },
  { value: "08296000", label: "GALAPA - ATLANTICO" },
  { value: "08372000", label: "JUAN DE ACOSTA - ATLANTICO" },
  { value: "08421000", label: "LURUACO - ATLANTICO" },
  { value: "08433000", label: "MALAMBO - ATLANTICO" },
  { value: "08436000", label: "MANATI - ATLANTICO" },
  { value: "08520000", label: "PALMAR DE VARELA - ATLANTICO" },
  { value: "08549000", label: "PIOJO - ATLANTICO" },
  { value: "08558000", label: "POLONUEVO - ATLANTICO" },
  { value: "08560000", label: "PONEDERA - ATLANTICO" },
  { value: "08573000", label: "PUERTO COLOMBIA - ATLANTICO" },
  { value: "08606000", label: "REPELON - ATLANTICO" },
  { value: "08634000", label: "SABANAGRANDE - ATLANTICO" },
  { value: "08638000", label: "SABANALARGA - ATLANTICO" },
  { value: "08675000", label: "SANTA LUCIA - ATLANTICO" },
  { value: "08685000", label: "SANTO TOMAS - ATLANTICO" },
  { value: "08758000", label: "SOLEDAD - ATLANTICO" },
  { value: "08770000", label: "SUAN - ATLANTICO" },
  { value: "08832000", label: "TUBARA - ATLANTICO" },
  { value: "08849000", label: "USIACURI - ATLANTICO" },
  { value: "11001000", label: "BOGOTA D.C." },
  { value: "13001000", label: "CARTAGENA - BOLIVAR" },
  { value: "13006000", label: "ACHI - BOLIVAR" },
  { value: "13030000", label: "ALTOS DEL ROSARIO - BOLIVAR" },
  { value: "13042000", label: "ARENAL - BOLIVAR" },
  { value: "13052000", label: "ARJONA - BOLIVAR" },
  { value: "13062000", label: "ARROYOHONDO - BOLIVAR" },
  { value: "13074000", label: "BARRANCO DE LOBA - BOLIVAR" },
  { value: "13140000", label: "CALAMAR - BOLIVAR" },
  { value: "13160000", label: "CANTAGALLO - BOLIVAR" },
  { value: "13188000", label: "CICUCO - BOLIVAR" },
  { value: "13212000", label: "CORDOBA - BOLIVAR" },
  { value: "13222000", label: "CLEMENCIA - BOLIVAR" },
  { value: "13244000", label: "EL CARMEN DE BOLIVAR - BOLIVAR" },
  { value: "13248000", label: "EL GUAMO - BOLIVAR" },
  { value: "13268000", label: "EL PEÑON - BOLIVAR" },
  { value: "13300000", label: "HATILLO DE LOBA - BOLIVAR" },
  { value: "13430000", label: "MAGANGUE - BOLIVAR" },
  { value: "13433000", label: "MAHATES - BOLIVAR" },
  { value: "13440000", label: "MARGARITA - BOLIVAR" },
  { value: "13442000", label: "MARIA LA BAJA - BOLIVAR" },
  { value: "13458000", label: "MONTECRISTO - BOLIVAR" },
  { value: "13468000", label: "MOMPOS - BOLIVAR" },
  { value: "13473000", label: "MORALES - BOLIVAR" },
  { value: "13490000", label: "NOROSI - BOLIVAR" },
  { value: "13549000", label: "PINILLOS - BOLIVAR" },
  { value: "13580000", label: "REGIDOR - BOLIVAR" },
  { value: "13600000", label: "RIO VIEJO - BOLIVAR" },
  { value: "13620000", label: "SAN CRISTOBAL - BOLIVAR" },
  { value: "13647000", label: "SAN ESTANISLAO - BOLIVAR" },
  { value: "13650000", label: "SAN FERNANDO - BOLIVAR" },
  { value: "13654000", label: "SAN JACINTO - BOLIVAR" },
  { value: "13655000", label: "SAN JACINTO DEL CAUCA - BOLIVAR" },
  { value: "13657000", label: "SAN JUAN NEPOMUCENO - BOLIVAR" },
  { value: "13667000", label: "SAN MARTIN DE LOBA - BOLIVAR" },
  { value: "13670000", label: "SAN PABLO - BOLIVAR" },
  { value: "13673000", label: "SANTA CATALINA - BOLIVAR" },
  { value: "13683000", label: "SANTA ROSA - BOLIVAR" },
  { value: "13688000", label: "SANTA ROSA DEL SUR - BOLIVAR" },
  { value: "13744000", label: "SIMITI - BOLIVAR" },
  { value: "13760000", label: "SOPLAVIENTO - BOLIVAR" },
  { value: "13780000", label: "TALAIGUA NUEVO - BOLIVAR" },
  { value: "13810000", label: "TIQUISIO - BOLIVAR" },
  { value: "13836000", label: "TURBACO - BOLIVAR" },
  { value: "13838000", label: "TURBANA - BOLIVAR" },
  { value: "13873000", label: "VILLANUEVA - BOLIVAR" },
  { value: "13894000", label: "ZAMBRANO - BOLIVAR" },
  { value: "15001000", label: "TUNJA - BOYACA" },
  { value: "15022000", label: "ALMEIDA - BOYACA" },
  { value: "15047000", label: "AQUITANIA - BOYACA" },
  { value: "15051000", label: "ARCABUCO - BOYACA" },
  { value: "15087000", label: "BELEN - BOYACA" },
  { value: "15090000", label: "BERBEO - BOYACA" },
  { value: "15092000", label: "BETEITIVA - BOYACA" },
  { value: "15097000", label: "BOAVITA - BOYACA" },
  { value: "15104000", label: "BOYACA - BOYACA" },
  { value: "15106000", label: "BRICEÑO - BOYACA" },
  { value: "15109000", label: "BUENAVISTA - BOYACA" },
  { value: "15114000", label: "BUSBANZA - BOYACA" },
  { value: "15131000", label: "CALDAS - BOYACA" },
  { value: "15135000", label: "CAMPOHERMOSO - BOYACA" },
  { value: "15162000", label: "CERINZA - BOYACA" },
  { value: "15172000", label: "CHINAVITA - BOYACA" },
  { value: "15176000", label: "CHIQUINQUIRA - BOYACA" },
  { value: "15180000", label: "CHISCAS - BOYACA" },
  { value: "15183000", label: "CHITA - BOYACA" },
  { value: "15185000", label: "CHITARAQUE - BOYACA" },
  { value: "15187000", label: "CHIVATA - BOYACA" },
  { value: "15189000", label: "CIENEGA - BOYACA" },
  { value: "15204000", label: "COMBITA - BOYACA" },
  { value: "15212000", label: "COPER - BOYACA" },
  { value: "15215000", label: "CORRALES - BOYACA" },
  { value: "15218000", label: "COVARACHIA - BOYACA" },
  { value: "15223000", label: "CUBARA - BOYACA" },
  { value: "15224000", label: "CUCAITA - BOYACA" },
  { value: "15226000", label: "CUITIVA - BOYACA" },
  { value: "15232000", label: "CHIQUIZA - BOYACA" },
  { value: "15236000", label: "CHIVOR - BOYACA" },
  { value: "15238000", label: "DUITAMA - BOYACA" },
  { value: "15244000", label: "EL COCUY - BOYACA" },
  { value: "15248000", label: "EL ESPINO - BOYACA" },
  { value: "15272000", label: "FIRAVITOBA - BOYACA" },
  { value: "15276000", label: "FLORESTA - BOYACA" },
  { value: "15293000", label: "GACHANTIVA - BOYACA" },
  { value: "15296000", label: "GAMEZA - BOYACA" },
  { value: "15299000", label: "GARAGOA - BOYACA" },
  { value: "15317000", label: "GUACAMAYAS - BOYACA" },
  { value: "15322000", label: "GUATEQUE - BOYACA" },
  { value: "15325000", label: "GUAYATA - BOYACA" },
  { value: "15332000", label: "GUICAN - BOYACA" },
  { value: "15362000", label: "IZA - BOYACA" },
  { value: "15367000", label: "JENESANO - BOYACA" },
  { value: "15368000", label: "JERICO - BOYACA" },
  { value: "15377000", label: "LABRANZAGRANDE - BOYACA" },
  { value: "15380000", label: "LA CAPILLA - BOYACA" },
  { value: "15401000", label: "LA VICTORIA - BOYACA" },
  { value: "15403000", label: "LA UVITA - BOYACA" },
  { value: "15407000", label: "VILLA DE LEYVA - BOYACA" },
  { value: "15425000", label: "MACANAL - BOYACA" },
  { value: "15442000", label: "MARIPI - BOYACA" },
  { value: "15455000", label: "MIRAFLORES - BOYACA" },
  { value: "15464000", label: "MONGUA - BOYACA" },
  { value: "15466000", label: "MONGUI - BOYACA" },
  { value: "15469000", label: "MONIQUIRA - BOYACA" },
  { value: "15476000", label: "MOTAVITA - BOYACA" },
  { value: "15480000", label: "MUZO - BOYACA" },
  { value: "15491000", label: "NOBSA - BOYACA" },
  { value: "15494000", label: "NUEVO COLON - BOYACA" },
  { value: "15500000", label: "OICATA - BOYACA" },
  { value: "15507000", label: "OTANCHE - BOYACA" },
  { value: "15511000", label: "PACHAVITA - BOYACA" },
  { value: "15514000", label: "PAEZ - BOYACA" },
  { value: "15516000", label: "PAIPA - BOYACA" },
  { value: "15518000", label: "PAJARITO - BOYACA" },
  { value: "15522000", label: "PANQUEBA - BOYACA" },
  { value: "15531000", label: "PAUNA - BOYACA" },
  { value: "15533000", label: "PAYA - BOYACA" },
  { value: "15537000", label: "PAZ DE RIO - BOYACA" },
  { value: "15542000", label: "PESCA - BOYACA" },
  { value: "15550000", label: "PISBA - BOYACA" },
  { value: "15572000", label: "PUERTO BOYACA - BOYACA" },
  { value: "15580000", label: "QUIPAMA - BOYACA" },
  { value: "15599000", label: "RAMIRIQUI - BOYACA" },
  { value: "15600000", label: "RAQUIRA - BOYACA" },
  { value: "15621000", label: "RONDON - BOYACA" },
  { value: "15632000", label: "SABOYA - BOYACA" },
  { value: "15638000", label: "SACHICA - BOYACA" },
  { value: "15646000", label: "SAMACA - BOYACA" },
  { value: "15660000", label: "SAN EDUARDO - BOYACA" },
  { value: "15664000", label: "SAN JOSE DE PARE - BOYACA" },
  { value: "15667000", label: "SAN LUIS DE GACENO - BOYACA" },
  { value: "15673000", label: "SAN MATEO - BOYACA" },
  { value: "15676000", label: "SAN MIGUEL DE SEMA - BOYACA" },
  { value: "15681000", label: "SAN PABLO DE BORBUR - BOYACA" },
  { value: "15686000", label: "SANTANA - BOYACA" },
  { value: "15690000", label: "SANTA MARIA - BOYACA" },
  { value: "15693000", label: "SANTA ROSA DE VITERBO - BOYACA" },
  { value: "15696000", label: "SANTA SOFIA - BOYACA" },
  { value: "15720000", label: "SATIVANORTE - BOYACA" },
  { value: "15723000", label: "SATIVASUR - BOYACA" },
  { value: "15740000", label: "SIACHOQUE - BOYACA" },
  { value: "15753000", label: "SOATA - BOYACA" },
  { value: "15755000", label: "SOCOTA - BOYACA" },
  { value: "15757000", label: "SOCHA - BOYACA" },
  { value: "15759000", label: "SOGAMOSO - BOYACA" },
  { value: "15761000", label: "SOMONDOCO - BOYACA" },
  { value: "15762000", label: "SORA - BOYACA" },
  { value: "15763000", label: "SOTAQUIRA - BOYACA" },
  { value: "15764000", label: "SORACA - BOYACA" },
  { value: "15774000", label: "SUSACON - BOYACA" },
  { value: "15776000", label: "SUTAMARCHAN - BOYACA" },
  { value: "15778000", label: "SUTATENZA - BOYACA" },
  { value: "15790000", label: "TASCO - BOYACA" },
  { value: "15798000", label: "TENZA - BOYACA" },
  { value: "15804000", label: "TIBANA - BOYACA" },
  { value: "15806000", label: "TIBASOSA - BOYACA" },
  { value: "15808000", label: "TINJACA - BOYACA" },
  { value: "15810000", label: "TIPACOQUE - BOYACA" },
  { value: "15814000", label: "TOCA - BOYACA" },
  { value: "15816000", label: "TOGUI - BOYACA" },
  { value: "15820000", label: "TOPAGA - BOYACA" },
  { value: "15822000", label: "TOTA - BOYACA" },
  { value: "15832000", label: "TUNUNGUA - BOYACA" },
  { value: "15835000", label: "TURMEQUE - BOYACA" },
  { value: "15837000", label: "TUTA - BOYACA" },
  { value: "15839000", label: "TUTAZA - BOYACA" },
  { value: "15842000", label: "UMBITA - BOYACA" },
  { value: "15861000", label: "VENTAQUEMADA - BOYACA" },
  { value: "15879000", label: "VIRACACHA - BOYACA" },
  { value: "15897000", label: "ZETAQUIRA - BOYACA" },
  { value: "17001000", label: "MANIZALES - CALDAS" },
  { value: "17013000", label: "AGUADAS - CALDAS" },
  { value: "17042000", label: "ANSERMA - CALDAS" },
  { value: "17050000", label: "ARANZAZU - CALDAS" },
  { value: "17088000", label: "BELALCAZAR - CALDAS" },
  { value: "17174000", label: "CHINCHINA - CALDAS" },
  { value: "17272000", label: "FILADELFIA - CALDAS" },
  { value: "17380000", label: "LA DORADA - CALDAS" },
  { value: "17388000", label: "LA MERCED - CALDAS" },
  { value: "17433000", label: "MANZANARES - CALDAS" },
  { value: "17442000", label: "MARMATO - CALDAS" },
  { value: "17444000", label: "MARQUETALIA - CALDAS" },
  { value: "17446000", label: "MARULANDA - CALDAS" },
  { value: "17486000", label: "NEIRA - CALDAS" },
  { value: "17495000", label: "NORCASIA - CALDAS" },
  { value: "17513000", label: "PACORA - CALDAS" },
  { value: "17524000", label: "PALESTINA - CALDAS" },
  { value: "17541000", label: "PENSILVANIA - CALDAS" },
  { value: "17614000", label: "RIOSUCIO - CALDAS" },
  { value: "17616000", label: "RISARALDA - CALDAS" },
  { value: "17653000", label: "SALAMINA - CALDAS" },
  { value: "17662000", label: "SAMANA - CALDAS" },
  { value: "17665000", label: "SAN JOSE - CALDAS" },
  { value: "17777000", label: "SUPIA - CALDAS" },
  { value: "17867000", label: "VICTORIA - CALDAS" },
  { value: "17873000", label: "VILLAMARIA - CALDAS" },
  { value: "17877000", label: "VITERBO - CALDAS" },
  { value: "18001000", label: "FLORENCIA - CAQUETA" },
  { value: "18029000", label: "ALBANIA - CAQUETA" },
  { value: "18094000", label: "BELEN DE LOS ANDAQUIES - CAQUETA" },
  { value: "18150000", label: "CARTAGENA DEL CHAIRA - CAQUETA" },
  { value: "18205000", label: "CURILLO - CAQUETA" },
  { value: "18247000", label: "EL DONCELLO - CAQUETA" },
  { value: "18256000", label: "EL PAUJIL - CAQUETA" },
  { value: "18410000", label: "LA MONTAÑITA - CAQUETA" },
  { value: "18460000", label: "MILAN - CAQUETA" },
  { value: "18479000", label: "MORELIA - CAQUETA" },
  { value: "18592000", label: "PUERTO RICO - CAQUETA" },
  { value: "18610000", label: "SAN JOSE DEL FRAGUA - CAQUETA" },
  { value: "18753000", label: "SAN VICENTE DEL CAGUAN - CAQUETA" },
  { value: "18756000", label: "SOLANO - CAQUETA" },
  { value: "18785000", label: "SOLITA - CAQUETA" },
  { value: "18860000", label: "VALPARAISO - CAQUETA" },
  { value: "85001000", label: "YOPAL - CASANARE" },
  { value: "85010000", label: "AGUAZUL - CASANARE" },
  { value: "85015000", label: "CHAMEZA - CASANARE" },
  { value: "85125000", label: "HATO COROZAL - CASANARE" },
  { value: "85136000", label: "LA SALINA - CASANARE" },
  { value: "85139000", label: "MANI - CASANARE" },
  { value: "85162000", label: "MONTERREY - CASANARE" },
  { value: "85225000", label: "NUNCHIA - CASANARE" },
  { value: "85230000", label: "OROCUE - CASANARE" },
  { value: "85250000", label: "PAZ DE ARIPORO - CASANARE" },
  { value: "85263000", label: "PORE - CASANARE" },
  { value: "85279000", label: "RECETOR - CASANARE" },
  { value: "85300000", label: "SABANALARGA - CASANARE" },
  { value: "85315000", label: "SACAMA - CASANARE" },
  { value: "85325000", label: "SAN LUIS DE PALENQUE - CASANARE" },
  { value: "85400000", label: "TAMARA - CASANARE" },
  { value: "85410000", label: "TAURAMENA - CASANARE" },
  { value: "85430000", label: "TRINIDAD - CASANARE" },
  { value: "85440000", label: "VILLANUEVA - CASANARE" },
  { value: "19001000", label: "POPAYAN - CAUCA" },
  { value: "19022000", label: "ALMAGUER - CAUCA" },
  { value: "19050000", label: "ARGELIA - CAUCA" },
  { value: "19075000", label: "BALBOA - CAUCA" },
  { value: "19100000", label: "BOLIVAR - CAUCA" },
  { value: "19110000", label: "BUENOS AIRES - CAUCA" },
  { value: "19130000", label: "CAJIBIO - CAUCA" },
  { value: "19137000", label: "CALDONO - CAUCA" },
  { value: "19142000", label: "CALOTO - CAUCA" },
  { value: "19212000", label: "CORINTO - CAUCA" },
  { value: "19256000", label: "EL TAMBO - CAUCA" },
  { value: "19290000", label: "FLORENCIA - CAUCA" },
  { value: "19300000", label: "GUACHENE - CAUCA" },
  { value: "19318000", label: "GUAPI - CAUCA" },
  { value: "19355000", label: "INZA - CAUCA" },
  { value: "19364000", label: "JAMBALO - CAUCA" },
  { value: "19392000", label: "LA SIERRA - CAUCA" },
  { value: "19397000", label: "LA VEGA - CAUCA" },
  { value: "19418000", label: "LOPEZ - CAUCA" },
  { value: "19450000", label: "MERCADERES - CAUCA" },
  { value: "19455000", label: "MIRANDA - CAUCA" },
  { value: "19473000", label: "MORALES - CAUCA" },
  { value: "19513000", label: "PADILLA - CAUCA" },
  { value: "19517000", label: "PAEZ - CAUCA" },
  { value: "19532000", label: "PATIA - CAUCA" },
  { value: "19533000", label: "PIAMONTE - CAUCA" },
  { value: "19548000", label: "PIENDAMO - CAUCA" },
  { value: "19573000", label: "PUERTO TEJADA - CAUCA" },
  { value: "19585000", label: "PURACE - CAUCA" },
  { value: "19622000", label: "ROSAS - CAUCA" },
  { value: "19693000", label: "SAN SEBASTIAN - CAUCA" },
  { value: "19698000", label: "SANTANDER DE QUILICHAO - CAUCA" },
  { value: "19701000", label: "SANTA ROSA - CAUCA" },
  { value: "19743000", label: "SILVIA - CAUCA" },
  { value: "19760000", label: "SOTARA - CAUCA" },
  { value: "19780000", label: "SUAREZ - CAUCA" },
  { value: "19785000", label: "SUCRE - CAUCA" },
  { value: "19807000", label: "TIMBIO - CAUCA" },
  { value: "19809000", label: "TIMBIQUI - CAUCA" },
  { value: "19821000", label: "TORIBIO - CAUCA" },
  { value: "19824000", label: "TOTORO - CAUCA" },
  { value: "19845000", label: "VILLA RICA - CAUCA" },
  { value: "20001000", label: "VALLEDUPAR - CESAR" },
  { value: "20011000", label: "AGUACHICA - CESAR" },
  { value: "20013000", label: "AGUSTIN CODAZZI - CESAR" },
  { value: "20032000", label: "ASTREA - CESAR" },
  { value: "20045000", label: "BECERRIL - CESAR" },
  { value: "20060000", label: "BOSCONIA - CESAR" },
  { value: "20175000", label: "CHIMICHAGUA - CESAR" },
  { value: "20178000", label: "CHIRIGUANA - CESAR" },
  { value: "20228000", label: "CURUMANI - CESAR" },
  { value: "20238000", label: "EL COPEY - CESAR" },
  { value: "20250000", label: "EL PASO - CESAR" },
  { value: "20295000", label: "GAMARRA - CESAR" },
  { value: "20310000", label: "GONZALEZ - CESAR" },
  { value: "20383000", label: "LA GLORIA - CESAR" },
  { value: "20400000", label: "LA JAGUA DE IBIRICO - CESAR" },
  { value: "20443000", label: "MANAURE - CESAR" },
  { value: "20517000", label: "PAILITAS - CESAR" },
  { value: "20550000", label: "PELAYA - CESAR" },
  { value: "20570000", label: "PUEBLO BELLO - CESAR" },
  { value: "20614000", label: "RIO DE ORO - CESAR" },
  { value: "20621000", label: "LA PAZ - CESAR" },
  { value: "20710000", label: "SAN ALBERTO - CESAR" },
  { value: "20750000", label: "SAN DIEGO - CESAR" },
  { value: "20770000", label: "SAN MARTIN - CESAR" },
  { value: "20787000", label: "TAMALAMEQUE - CESAR" },
  { value: "27001000", label: "QUIBDO - CHOCO" },
  { value: "27006000", label: "ACANDI - CHOCO" },
  { value: "27025000", label: "ALTO BAUDO - CHOCO" },
  { value: "27050000", label: "ATRATO - CHOCO" },
  { value: "27073000", label: "BAGADO - CHOCO" },
  { value: "27075000", label: "BAHIA SOLANO - CHOCO" },
  { value: "27077000", label: "BAJO BAUDO - CHOCO" },
  { value: "27099000", label: "BOJAYA - CHOCO" },
  { value: "27135000", label: "EL CANTON DEL SAN PABLO - CHOCO" },
  { value: "27150000", label: "CARMEN DEL DARIEN - CHOCO" },
  { value: "27160000", label: "CERTEGUI - CHOCO" },
  { value: "27205000", label: "CONDOTO - CHOCO" },
  { value: "27245000", label: "EL CARMEN DE ATRATO - CHOCO" },
  { value: "27250000", label: "EL LITORAL DEL SAN JUAN - CHOCO" },
  { value: "27361000", label: "ISTMINA - CHOCO" },
  { value: "27372000", label: "JURADO - CHOCO" },
  { value: "27413000", label: "LLORO - CHOCO" },
  { value: "27425000", label: "MEDIO ATRATO - CHOCO" },
  { value: "27430000", label: "MEDIO BAUDO - CHOCO" },
  { value: "27450000", label: "MEDIO SAN JUAN - CHOCO" },
  { value: "27491000", label: "NOVITA - CHOCO" },
  { value: "27495000", label: "NUQUI - CHOCO" },
  { value: "27580000", label: "RIO IRO - CHOCO" },
  { value: "27600000", label: "RIO QUITO - CHOCO" },
  { value: "27615000", label: "RIOSUCIO - CHOCO" },
  { value: "27660000", label: "SAN JOSE DEL PALMAR - CHOCO" },
  { value: "27745000", label: "SIPI - CHOCO" },
  { value: "27787000", label: "TADO - CHOCO" },
  { value: "27800000", label: "UNGUIA - CHOCO" },
  { value: "27810000", label: "UNION PANAMERICANA - CHOCO" },
  { value: "23001000", label: "MONTERIA - CORDOBA" },
  { value: "23068000", label: "AYAPEL - CORDOBA" },
  { value: "23079000", label: "BUENAVISTA - CORDOBA" },
  { value: "23090000", label: "CANALETE - CORDOBA" },
  { value: "23162000", label: "CERETE - CORDOBA" },
  { value: "23168000", label: "CHIMA - CORDOBA" },
  { value: "23182000", label: "CHINU - CORDOBA" },
  { value: "23189000", label: "CIENAGA DE ORO - CORDOBA" },
  { value: "23300000", label: "COTORRA - CORDOBA" },
  { value: "23350000", label: "LA APARTADA - CORDOBA" },
  { value: "23417000", label: "LORICA - CORDOBA" },
  { value: "23419000", label: "LOS CORDOBAS - CORDOBA" },
  { value: "23464000", label: "MOMIL - CORDOBA" },
  { value: "23466000", label: "MONTELIBANO - CORDOBA" },
  { value: "23500000", label: "MOÑITOS - CORDOBA" },
  { value: "23555000", label: "PLANETA RICA - CORDOBA" },
  { value: "23570000", label: "PUEBLO NUEVO - CORDOBA" },
  { value: "23574000", label: "PUERTO ESCONDIDO - CORDOBA" },
  { value: "23580000", label: "PUERTO LIBERTADOR - CORDOBA" },
  { value: "23586000", label: "PURISIMA - CORDOBA" },
  { value: "23660000", label: "SAHAGUN - CORDOBA" },
  { value: "23670000", label: "SAN ANDRES SOTAVENTO - CORDOBA" },
  { value: "23672000", label: "SAN ANTERO - CORDOBA" },
  { value: "23675000", label: "SAN BERNARDO DEL VIENTO - CORDOBA" },
  { value: "23678000", label: "SAN CARLOS - CORDOBA" },
  { value: "23682000", label: "SAN JOSE DE URE - CORDOBA" },
  { value: "23686000", label: "SAN PELAYO - CORDOBA" },
  { value: "23807000", label: "TIERRALTA - CORDOBA" },
  { value: "23815000", label: "TUCHIN - CORDOBA" },
  { value: "23855000", label: "VALENCIA - CORDOBA" },
  { value: "95001000", label: "SAN JOSE DEL GUAVIARE - GUAVIARE" },
  { value: "95015000", label: "CALAMAR - GUAVIARE" },
  { value: "95025000", label: "EL RETORNO - GUAVIARE" },
  { value: "95200000", label: "MIRAFLORES - GUAVIARE" },
  { value: "25797000", label: "TENA - CUNDINAMARCA" },
  { value: "25799000", label: "TENJO - CUNDINAMARCA" },
  { value: "25805000", label: "TIBACUY - CUNDINAMARCA" },
  { value: "25807000", label: "TIBIRITA - CUNDINAMARCA" },
  { value: "25815000", label: "TOCAIMA - CUNDINAMARCA" },
  { value: "25817000", label: "TOCANCIPA - CUNDINAMARCA" },
  { value: "25823000", label: "TOPAIPI - CUNDINAMARCA" },
  { value: "25839000", label: "UBALA - CUNDINAMARCA" },
  { value: "25841000", label: "UBAQUE - CUNDINAMARCA" },
  { value: "25843000", label: "VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA" },
  { value: "25845000", label: "UNE - CUNDINAMARCA" },
  { value: "25851000", label: "UTICA - CUNDINAMARCA" },
  { value: "25862000", label: "VERGARA - CUNDINAMARCA" },
  { value: "25867000", label: "VIANI - CUNDINAMARCA" },
  { value: "25871000", label: "VILLAGOMEZ - CUNDINAMARCA" },
  { value: "25873000", label: "VILLAPINZON - CUNDINAMARCA" },
  { value: "25875000", label: "VILLETA - CUNDINAMARCA" },
  { value: "25878000", label: "VIOTA - CUNDINAMARCA" },
  { value: "25885000", label: "YACOPI - CUNDINAMARCA" },
  { value: "25898000", label: "ZIPACON - CUNDINAMARCA" },
  { value: "25899000", label: "ZIPAQUIRA - CUNDINAMARCA" },
  { value: "25001000", label: "AGUA DE DIOS - CUNDINAMARCA" },
  { value: "25019000", label: "ALBAN - CUNDINAMARCA" },
  { value: "25035000", label: "ANAPOIMA - CUNDINAMARCA" },
  { value: "25040000", label: "ANOLAIMA - CUNDINAMARCA" },
  { value: "25053000", label: "ARBELAEZ - CUNDINAMARCA" },
  { value: "25086000", label: "BELTRAN - CUNDINAMARCA" },
  { value: "25095000", label: "BITUIMA - CUNDINAMARCA" },
  { value: "25099000", label: "BOJACA - CUNDINAMARCA" },
  { value: "25120000", label: "CABRERA - CUNDINAMARCA" },
  { value: "25123000", label: "CACHIPAY - CUNDINAMARCA" },
  { value: "25126000", label: "CAJICA - CUNDINAMARCA" },
  { value: "25148000", label: "CAPARRAPI - CUNDINAMARCA" },
  { value: "25151000", label: "CAQUEZA - CUNDINAMARCA" },
  { value: "25154000", label: "CARMEN DE CARUPA - CUNDINAMARCA" },
  { value: "25168000", label: "CHAGUANI - CUNDINAMARCA" },
  { value: "25175000", label: "CHIA - CUNDINAMARCA" },
  { value: "25178000", label: "CHIPAQUE - CUNDINAMARCA" },
  { value: "25181000", label: "CHOACHI - CUNDINAMARCA" },
  { value: "25183000", label: "CHOCONTA - CUNDINAMARCA" },
  { value: "25200000", label: "COGUA - CUNDINAMARCA" },
  { value: "25214000", label: "COTA - CUNDINAMARCA" },
  { value: "25224000", label: "CUCUNUBA - CUNDINAMARCA" },
  { value: "25245000", label: "EL COLEGIO - CUNDINAMARCA" },
  { value: "25258000", label: "EL PEÑON - CUNDINAMARCA" },
  { value: "25260000", label: "EL ROSAL - CUNDINAMARCA" },
  { value: "25269000", label: "FACATATIVA - CUNDINAMARCA" },
  { value: "25279000", label: "FOMEQUE - CUNDINAMARCA" },
  { value: "25281000", label: "FOSCA - CUNDINAMARCA" },
  { value: "25286000", label: "FUNZA - CUNDINAMARCA" },
  { value: "25288000", label: "FUQUENE - CUNDINAMARCA" },
  { value: "25290000", label: "FUSAGASUGA - CUNDINAMARCA" },
  { value: "25293000", label: "GACHALA - CUNDINAMARCA" },
  { value: "25295000", label: "GACHANCIPA - CUNDINAMARCA" },
  { value: "25297000", label: "GACHETA - CUNDINAMARCA" },
  { value: "25299000", label: "GAMA - CUNDINAMARCA" },
  { value: "25307000", label: "GIRARDOT - CUNDINAMARCA" },
  { value: "25312000", label: "GRANADA - CUNDINAMARCA" },
  { value: "25317000", label: "GUACHETA - CUNDINAMARCA" },
  { value: "25320000", label: "GUADUAS - CUNDINAMARCA" },
  { value: "25322000", label: "GUASCA - CUNDINAMARCA" },
  { value: "25324000", label: "GUATAQUI - CUNDINAMARCA" },
  { value: "25326000", label: "GUATAVITA - CUNDINAMARCA" },
  { value: "25328000", label: "GUAYABAL DE SIQUIMA - CUNDINAMARCA" },
  { value: "25335000", label: "GUAYABETAL - CUNDINAMARCA" },
  { value: "25339000", label: "GUTIERREZ - CUNDINAMARCA" },
  { value: "25368000", label: "JERUSALEN - CUNDINAMARCA" },
  { value: "25372000", label: "JUNIN - CUNDINAMARCA" },
  { value: "25377000", label: "LA CALERA - CUNDINAMARCA" },
  { value: "25386000", label: "LA MESA - CUNDINAMARCA" },
  { value: "25394000", label: "LA PALMA - CUNDINAMARCA" },
  { value: "25398000", label: "LA PEÑA - CUNDINAMARCA" },
  { value: "25402000", label: "LA VEGA - CUNDINAMARCA" },
  { value: "25407000", label: "LENGUAZAQUE - CUNDINAMARCA" },
  { value: "25426000", label: "MACHETA - CUNDINAMARCA" },
  { value: "25430000", label: "MADRID - CUNDINAMARCA" },
  { value: "25436000", label: "MANTA - CUNDINAMARCA" },
  { value: "25438000", label: "MEDINA - CUNDINAMARCA" },
  { value: "25473000", label: "MOSQUERA - CUNDINAMARCA" },
  { value: "25483000", label: "NARIÑO - CUNDINAMARCA" },
  { value: "25486000", label: "NEMOCON - CUNDINAMARCA" },
  { value: "25488000", label: "NILO - CUNDINAMARCA" },
  { value: "25489000", label: "NIMAIMA - CUNDINAMARCA" },
  { value: "25491000", label: "NOCAIMA - CUNDINAMARCA" },
  { value: "25506000", label: "VENECIA - CUNDINAMARCA" },
  { value: "25513000", label: "PACHO - CUNDINAMARCA" },
  { value: "25518000", label: "PAIME - CUNDINAMARCA" },
  { value: "25524000", label: "PANDI - CUNDINAMARCA" },
  { value: "25530000", label: "PARATEBUENO - CUNDINAMARCA" },
  { value: "25535000", label: "PASCA - CUNDINAMARCA" },
  { value: "25572000", label: "PUERTO SALGAR - CUNDINAMARCA" },
  { value: "25580000", label: "PULI - CUNDINAMARCA" },
  { value: "25592000", label: "QUEBRADANEGRA - CUNDINAMARCA" },
  { value: "25594000", label: "QUETAME - CUNDINAMARCA" },
  { value: "25596000", label: "QUIPILE - CUNDINAMARCA" },
  { value: "25599000", label: "APULO - CUNDINAMARCA" },
  { value: "25612000", label: "RICAURTE - CUNDINAMARCA" },
  { value: "25645000", label: "SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA" },
  { value: "25649000", label: "SAN BERNARDO - CUNDINAMARCA" },
  { value: "25653000", label: "SAN CAYETANO - CUNDINAMARCA" },
  { value: "25658000", label: "SAN FRANCISCO - CUNDINAMARCA" },
  { value: "25662000", label: "SAN JUAN DE RIO SECO - CUNDINAMARCA" },
  { value: "25718000", label: "SASAIMA - CUNDINAMARCA" },
  { value: "25736000", label: "SESQUILE - CUNDINAMARCA" },
  { value: "25740000", label: "SIBATE - CUNDINAMARCA" },
  { value: "25743000", label: "SILVANIA - CUNDINAMARCA" },
  { value: "25745000", label: "SIMIJACA - CUNDINAMARCA" },
  { value: "25754000", label: "SOACHA - CUNDINAMARCA" },
  { value: "25758000", label: "SOPO - CUNDINAMARCA" },
  { value: "25769000", label: "SUBACHOQUE - CUNDINAMARCA" },
  { value: "25772000", label: "SUESCA - CUNDINAMARCA" },
  { value: "25777000", label: "SUPATA - CUNDINAMARCA" },
  { value: "25779000", label: "SUSA - CUNDINAMARCA" },
  { value: "25781000", label: "SUTATAUSA - CUNDINAMARCA" },
  { value: "25785000", label: "TABIO - CUNDINAMARCA" },
  { value: "25793000", label: "TAUSA - CUNDINAMARCA" },
  { value: "86757000", label: "LA DORADA - PUTUMAYO" },
  { value: "25386000", label: "LA MESA - CUNDINAMARCA" },
  { value: "25245001", label: "EL TRIUNFO (EL COLEGIO) - CUNDINAMARCA" },
  { value: "25662001", label: "CAMBAO - CUNDINAMARCA" },
  { value: "17524001", label: "ARAUCA - CALDAS" },
  { value: "17524000", label: "CALDAS - PALESTINA" },
  { value: "94001000", label: "INIRIDA - GUAINIA" },
  { value: "94343000", label: "BARRANCO MINAS - GUAINIA" },
  { value: "94663000", label: "MAPIRIPANA - GUAINIA" },
  { value: "94883000", label: "SAN FELIPE - GUAINIA" },
  { value: "94884000", label: "PUERTO COLOMBIA - GUAINIA" },
  { value: "94885000", label: "LA GUADALUPE - GUAINIA" },
  { value: "94886000", label: "CACAHUAL - GUAINIA" },
  { value: "94887000", label: "PANA PANA - GUAINIA" },
  { value: "94888000", label: "MORICHAL - GUAINIA" },
  { value: "95001000", label: "SAN JOSE DEL GUAVIARE - GUAVIARE" },
  { value: "95015000", label: "CALAMAR - GUAVIARE" },
  { value: "95025000", label: "EL RETORNO - GUAVIARE" },
  { value: "95200000", label: "MIRAFLORES - GUAVIARE" },
  { value: "41001000", label: "NEIVA - HUILA" },
  { value: "41006000", label: "ACEVEDO - HUILA" },
  { value: "41013000", label: "AGRADO - HUILA" },
  { value: "41016000", label: "AIPE - HUILA" },
  { value: "41020000", label: "ALGECIRAS - HUILA" },
  { value: "41026000", label: "ALTAMIRA - HUILA" },
  { value: "41078000", label: "BARAYA - HUILA" },
  { value: "41132000", label: "CAMPOALEGRE - HUILA" },
  { value: "41206000", label: "COLOMBIA - HUILA" },
  { value: "41244000", label: "ELIAS - HUILA" },
  { value: "41298000", label: "GARZON - HUILA" },
  { value: "41306000", label: "GIGANTE - HUILA" },
  { value: "41319000", label: "GUADALUPE - HUILA" },
  { value: "41349000", label: "HOBO - HUILA" },
  { value: "41357000", label: "IQUIRA - HUILA" },
  { value: "41359000", label: "ISNOS - HUILA" },
  { value: "41378000", label: "LA ARGENTINA - HUILA" },
  { value: "41396000", label: "LA PLATA - HUILA" },
  { value: "41483000", label: "NATAGA - HUILA" },
  { value: "41503000", label: "OPORAPA - HUILA" },
  { value: "41518000", label: "PAICOL - HUILA" },
  { value: "41524000", label: "PALERMO - HUILA" },
  { value: "41530000", label: "PALESTINA - HUILA" },
  { value: "41548000", label: "PITAL - HUILA" },
  { value: "41551000", label: "PITALITO - HUILA" },
  { value: "41615000", label: "RIVERA - HUILA" },
  { value: "41660000", label: "SALADOBLANCO - HUILA" },
  { value: "41668000", label: "SAN AGUSTIN - HUILA" },
  { value: "41676000", label: "SANTA MARIA - HUILA" },
  { value: "41770000", label: "SUAZA - HUILA" },
  { value: "41791000", label: "TARQUI - HUILA" },
  { value: "41797000", label: "TESALIA - HUILA" },
  { value: "41799000", label: "TELLO - HUILA" },
  { value: "41801000", label: "TERUEL - HUILA" },
  { value: "41807000", label: "TIMANA - HUILA" },
  { value: "41872000", label: "VILLAVIEJA - HUILA" },
  { value: "41885000", label: "YAGUARA - HUILA" },
  { value: "44001000", label: "RIOHACHA - LA GUAJIRA" },
  { value: "44035000", label: "ALBANIA - LA GUAJIRA" },
  { value: "44078000", label: "BARRANCAS - LA GUAJIRA" },
  { value: "44090000", label: "DIBULLA - LA GUAJIRA" },
  { value: "44098000", label: "DISTRACCION - LA GUAJIRA" },
  { value: "44110000", label: "EL MOLINO - LA GUAJIRA" },
  { value: "44279000", label: "FONSECA - LA GUAJIRA" },
  { value: "44378000", label: "HATONUEVO - LA GUAJIRA" },
  { value: "44420000", label: "LA JAGUA DEL PILAR - LA GUAJIRA" },
  { value: "44430000", label: "MAICAO - LA GUAJIRA" },
  { value: "44560000", label: "MANAURE - LA GUAJIRA" },
  { value: "44650000", label: "SAN JUAN DEL CESAR - LA GUAJIRA" },
  { value: "44847000", label: "URIBIA - LA GUAJIRA" },
  { value: "44855000", label: "URUMITA - LA GUAJIRA" },
  { value: "44874000", label: "VILLANUEVA - LA GUAJIRA" },
  { value: "47001000", label: "SANTA MARTA - MAGDALENA" },
  { value: "47030000", label: "ALGARROBO - MAGDALENA" },
  { value: "47053000", label: "ARACATACA - MAGDALENA" },
  { value: "47058000", label: "ARIGUANI - MAGDALENA" },
  { value: "47161000", label: "CERRO SAN ANTONIO - MAGDALENA" },
  { value: "47170000", label: "CHIVOLO - MAGDALENA" },
  { value: "47189000", label: "CIENAGA - MAGDALENA" },
  { value: "47205000", label: "CONCORDIA - MAGDALENA" },
  { value: "47245000", label: "EL BANCO - MAGDALENA" },
  { value: "47258000", label: "EL PIÑON - MAGDALENA" },
  { value: "47268000", label: "EL RETEN - MAGDALENA" },
  { value: "47288000", label: "FUNDACION - MAGDALENA" },
  { value: "47318000", label: "GUAMAL - MAGDALENA" },
  { value: "47460000", label: "NUEVA GRANADA - MAGDALENA" },
  { value: "47541000", label: "PEDRAZA - MAGDALENA" },
  { value: "47545000", label: "PIJIÑO DEL CARMEN - MAGDALENA" },
  { value: "47551000", label: "PIVIJAY - MAGDALENA" },
  { value: "47555000", label: "PLATO - MAGDALENA" },
  { value: "47570000", label: "PUEBLOVIEJO - MAGDALENA" },
  { value: "47605000", label: "REMOLINO - MAGDALENA" },
  { value: "47660000", label: "SABANAS DE SAN ANGEL - MAGDALENA" },
  { value: "47675000", label: "SALAMINA - MAGDALENA" },
  { value: "47692000", label: "SAN SEBASTIAN DE BUENAVISTA - MAGDALENA" },
  { value: "47703000", label: "SAN ZENON - MAGDALENA" },
  { value: "47707000", label: "SANTA ANA - MAGDALENA" },
  { value: "47720000", label: "SANTA BARBARA DE PINTO - MAGDALENA" },
  { value: "47745000", label: "SITIONUEVO - MAGDALENA" },
  { value: "47798000", label: "TENERIFE - MAGDALENA" },
  { value: "47960000", label: "ZAPAYAN - MAGDALENA" },
  { value: "47980000", label: "ZONA BANANERA - MAGDALENA" },
  { value: "50001000", label: "VILLAVICENCIO - META" },
  { value: "50006000", label: "ACACIAS - META" },
  { value: "50110000", label: "BARRANCA DE UPIA - META" },
  { value: "50124000", label: "CABUYARO - META" },
  { value: "50150000", label: "CASTILLA LA NUEVA - META" },
  { value: "50223000", label: "CUBARRAL - META" },
  { value: "50226000", label: "CUMARAL - META" },
  { value: "50245000", label: "EL CALVARIO - META" },
  { value: "50251000", label: "EL CASTILLO - META" },
  { value: "50270000", label: "EL DORADO - META" },
  { value: "50287000", label: "FUENTE DE ORO - META" },
  { value: "50313000", label: "GRANADA - META" },
  { value: "50318000", label: "GUAMAL - META" },
  { value: "50325000", label: "MAPIRIPAN - META" },
  { value: "50330000", label: "MESETAS - META" },
  { value: "50350000", label: "LA MACARENA - META" },
  { value: "50370000", label: "URIBE - META" },
  { value: "50400000", label: "LEJANIAS - META" },
  { value: "50450000", label: "PUERTO CONCORDIA - META" },
  { value: "50568000", label: "PUERTO GAITAN - META" },
  { value: "50573000", label: "PUERTO LOPEZ - META" },
  { value: "50577000", label: "PUERTO LLERAS - META" },
  { value: "50590000", label: "PUERTO RICO - META" },
  { value: "50606000", label: "RESTREPO - META" },
  { value: "50680000", label: "SAN CARLOS DE GUAROA - META" },
  { value: "50683000", label: "SAN JUAN DE ARAMA - META" },
  { value: "50686000", label: "SAN JUANITO - META" },
  { value: "50689000", label: "SAN MARTIN - META" },
  { value: "50711000", label: "VISTAHERMOSA - META" },
  { value: "52001000", label: "PASTO - NARIÑO" },
  { value: "52019000", label: "ALBAN - NARIÑO" },
  { value: "52022000", label: "ALDANA - NARIÑO" },
  { value: "52036000", label: "ANCUYA - NARIÑO" },
  { value: "52051000", label: "ARBOLEDA - NARIÑO" },
  { value: "52079000", label: "BARBACOAS - NARIÑO" },
  { value: "52083000", label: "BELEN - NARIÑO" },
  { value: "52110000", label: "BUESACO - NARIÑO" },
  { value: "52203000", label: "COLON - NARIÑO" },
  { value: "52207000", label: "CONSACA - NARIÑO" },
  { value: "52210000", label: "CONTADERO - NARIÑO" },
  { value: "52215000", label: "CORDOBA - NARIÑO" },
  { value: "52224000", label: "CUASPUD - NARIÑO" },
  { value: "52227000", label: "CUMBAL - NARIÑO" },

  { value: "52233000", label: "CUMBITARA - NARIÑO" },
  { value: "52240000", label: "CHACHAGUI - NARIÑO" },
  { value: "52250000", label: "EL CHARCO - NARIÑO" },
  { value: "52254000", label: "EL PEÑOL - NARIÑO" },
  { value: "52256000", label: "EL ROSARIO - NARIÑO" },
  { value: "52258000", label: "EL TABLON DE GOMEZ - NARIÑO" },
  { value: "52260000", label: "EL TAMBO - NARIÑO" },
  { value: "52287000", label: "FUNES - NARIÑO" },
  { value: "52317000", label: "GUACHUCAL - NARIÑO" },
  { value: "52320000", label: "GUAITARILLA - NARIÑO" },
  { value: "52323000", label: "GUALMATAN - NARIÑO" },
  { value: "52352000", label: "ILES - NARIÑO" },
  { value: "52354000", label: "IMUES - NARIÑO" },
  { value: "52356000", label: "IPIALES - NARIÑO" },
  { value: "52378000", label: "LA CRUZ - NARIÑO" },
  { value: "52381000", label: "LA FLORIDA - NARIÑO" },
  { value: "52385000", label: "LA LLANADA - NARIÑO" },
  { value: "52390000", label: "LA TOLA - NARIÑO" },
  { value: "52399000", label: "LA UNION - NARIÑO" },
  { value: "52405000", label: "LEIVA - NARIÑO" },
  { value: "52411000", label: "LINARES - NARIÑO" },
  { value: "52418000", label: "LOS ANDES - NARIÑO" },
  { value: "52427000", label: "MAGUI - NARIÑO" },
  { value: "52435000", label: "MALLAMA - NARIÑO" },
  { value: "52473000", label: "MOSQUERA - NARIÑO" },
  { value: "52480000", label: "NARIÑO - NARIÑO" },
  { value: "52490000", label: "OLAYA HERRERA - NARIÑO" },
  { value: "52506000", label: "OSPINA - NARIÑO" },
  { value: "52520000", label: "FRANCISCO PIZARRO - NARIÑO" },
  { value: "52540000", label: "POLICARPA - NARIÑO" },
  { value: "52560000", label: "POTOSI - NARIÑO" },
  { value: "52565000", label: "PROVIDENCIA - NARIÑO" },
  { value: "52573000", label: "PUERRES - NARIÑO" },
  { value: "52585000", label: "PUPIALES - NARIÑO" },
  { value: "52612000", label: "RICAURTE - NARIÑO" },
  { value: "52621000", label: "ROBERTO PAYAN - NARIÑO" },
  { value: "52678000", label: "SAMANIEGO - NARIÑO" },
  { value: "52683000", label: "SANDONA - NARIÑO" },
  { value: "52685000", label: "SAN BERNARDO - NARIÑO" },
  { value: "52687000", label: "SAN LORENZO - NARIÑO" },
  { value: "52693000", label: "SAN PABLO - NARIÑO" },
  { value: "52694000", label: "SAN PEDRO DE CARTAGO - NARIÑO" },
  { value: "52696000", label: "SANTA BARBARA - NARIÑO" },
  { value: "52699000", label: "SANTACRUZ - NARIÑO" },
  { value: "52720000", label: "SAPUYES - NARIÑO" },
  { value: "52786000", label: "TAMINANGO - NARIÑO" },
  { value: "52788000", label: "TANGUA - NARIÑO" },
  { value: "52835000", label: "TUMACO - NARIÑO" },
  { value: "52838000", label: "TUQUERRES - NARIÑO" },
  { value: "52885000", label: "YACUANQUER - NARIÑO" },
  { value: "54001000", label: "CUCUTA - NORTE DE SANTANDER" },
  { value: "54003000", label: "ABREGO - NORTE DE SANTANDER" },
  { value: "54051000", label: "ARBOLEDAS - NORTE DE SANTANDER" },
  { value: "54099000", label: "BOCHALEMA - NORTE DE SANTANDER" },
  { value: "54109000", label: "BUCARASICA - NORTE DE SANTANDER" },
  { value: "54125000", label: "CACOTA - NORTE DE SANTANDER" },
  { value: "54128000", label: "CACHIRA - NORTE DE SANTANDER" },
  { value: "54172000", label: "CHINACOTA - NORTE DE SANTANDER" },
  { value: "54174000", label: "CHITAGA - NORTE DE SANTANDER" },
  { value: "54206000", label: "CONVENCION - NORTE DE SANTANDER" },
  { value: "54223000", label: "CUCUTILLA - NORTE DE SANTANDER" },
  { value: "54239000", label: "DURANIA - NORTE DE SANTANDER" },
  { value: "54245000", label: "EL CARMEN - NORTE DE SANTANDER" },
  { value: "54250000", label: "EL TARRA - NORTE DE SANTANDER" },
  { value: "54261000", label: "EL ZULIA - NORTE DE SANTANDER" },
  { value: "54313000", label: "GRAMALOTE - NORTE DE SANTANDER" },
  { value: "54344000", label: "HACARI - NORTE DE SANTANDER" },
  { value: "54347000", label: "HERRAN - NORTE DE SANTANDER" },
  { value: "54377000", label: "LABATECA - NORTE DE SANTANDER" },
  { value: "54385000", label: "LA ESPERANZA - NORTE DE SANTANDER" },
  { value: "54398000", label: "LA PLAYA - NORTE DE SANTANDER" },
  { value: "54405000", label: "LOS PATIOS - NORTE DE SANTANDER" },
  { value: "54418000", label: "LOURDES - NORTE DE SANTANDER" },
  { value: "54480000", label: "MUTISCUA - NORTE DE SANTANDER" },
  { value: "54498000", label: "OCAÑA - NORTE DE SANTANDER" },
  { value: "54518000", label: "PAMPLONA - NORTE DE SANTANDER" },
  { value: "54520000", label: "PAMPLONITA - NORTE DE SANTANDER" },
  { value: "54553000", label: "PUERTO SANTANDER - NORTE DE SANTANDER" },
  { value: "54599000", label: "RAGONVALIA - NORTE DE SANTANDER" },
  { value: "54660000", label: "SALAZAR - NORTE DE SANTANDER" },
  { value: "54670000", label: "SAN CALIXTO - NORTE DE SANTANDER" },
  { value: "54673000", label: "SAN CAYETANO - NORTE DE SANTANDER" },
  { value: "54680000", label: "SANTIAGO - NORTE DE SANTANDER" },
  { value: "54720000", label: "SARDINATA - NORTE DE SANTANDER" },
  { value: "54743000", label: "SILOS - NORTE DE SANTANDER" },
  { value: "54800000", label: "TEORAMA - NORTE DE SANTANDER" },
  { value: "54810000", label: "TIBU - NORTE DE SANTANDER" },
  { value: "54820000", label: "TOLEDO - NORTE DE SANTANDER" },
  { value: "54871000", label: "VILLA CARO - NORTE DE SANTANDER" },
  { value: "54874000", label: "VILLA DEL ROSARIO - NORTE DE SANTANDER" },
  { value: "63690000", label: "SALENTO - QUINDIO" },
  { value: "63001000", label: "ARMENIA - QUINDIO" },
  { value: "63001001", label: "EL CAIMO - QUINDIO" },
  { value: "63001002", label: "MURILLO - QUINDIO" },
  { value: "63001008", label: "CASERIO SANTA HELENA - QUINDIO" },
  { value: "63001009", label: "CONDOMINIO EL EDEN - QUINDIO" },
  { value: "63001010", label: "CONDOMINIOS LAS VEGAS, IRAKA Y LAGOS DE IRAKA - QUINDIO" },
  { value: "63001011", label: "CONDOMINIO PALO DE AGUA - QUINDIO" },
  { value: "63001012", label: "CONDOMINIO PONTEVEDRA - QUINDIO" },
  { value: "63001013", label: "CONDOMINIO SENIORS CLUB - QUINDIO" },
  { value: "63001015", label: "NUEVO HORIZONTE - SAPERA - QUINDIO" },
  { value: "63001016", label: "SECTOR CENEXPO - QUINDIO" },
  { value: "63111000", label: "BUENAVISTA - QUINDIO" },
  { value: "63111001", label: "RÍO VERDE - QUINDIO" },
  { value: "63130000", label: "CALARCÁ - QUINDIO" },
  { value: "63130001", label: "BARCELONA - QUINDIO" },
  { value: "63130003", label: "LA BELLA - QUINDIO" },
  { value: "63130004", label: "LA VIRGINIA - QUINDIO" },
  { value: "63130005", label: "QUEBRADANEGRA - QUINDIO" },
  { value: "63130008", label: "LA PRADERA - QUINDIO" },
  { value: "63130010", label: "LA MARÍA - QUINDIO" },
  { value: "63130013", label: "BARRAGÁN - QUINDIO" },
  { value: "63130015", label: "CONDOMINIO LOS ALMENDROS - QUINDIO" },
  { value: "63130016", label: "CONDOMINIO VALLE DEL SOL - QUINDIO" },
  { value: "63130018", label: "CONDOMINIO AGUA BONITA - QUINDIO" },
  { value: "63130019", label: "CONDOMINIO LA MICAELA - QUINDIO" },
  { value: "63190000", label: "CIRCASIA - QUINDIO" },
  { value: "63190001", label: "LA POLA - QUINDIO" },
  { value: "63190002", label: "LA SIRIA - QUINDIO" },
  { value: "63190003", label: "PIAMONTE - QUINDIO" },
  { value: "63190004", label: "SANTA RITA - QUINDIO" },
  { value: "63190006", label: "VILLARAZO - SAN LUIS - QUINDIO" },
  { value: "63190007", label: "LA JULIA - QUINDIO" },
  { value: "63190008", label: "LA FRONTERA - QUINDIO" },
  { value: "63190009", label: "EL TRIUNFO - QUINDIO" },
  { value: "63190010", label: "EL PLANAZO - QUINDIO" },
  { value: "63190011", label: "LA 18 GUAYABAL - QUINDIO" },
  { value: "63190012", label: "URBANIZACIÓN EL CANEY - QUINDIO" },
  { value: "63190013", label: "CONDOMINIO BOSQUES DE TOSCANA - QUINDIO" },
  { value: "63190014", label: "CONDOMINIO LA ALDEA - QUINDIO" },
  { value: "63190016", label: "CONDOMINIO LOS ABEDULES Y YARUMOS I - QUINDIO" },
  { value: "63190017", label: "CONDOMINIO LOS ROBLES - QUINDIO" },
  { value: "63190018", label: "CONDOMINIO LOS ROSALES - QUINDIO" },
  { value: "63190020", label: "CONDOMINIO QUINTAS DEL BOSQUE - QUINDIO" },
  { value: "63190022", label: "VILLA LIGIA - QUINDIO" },
  { value: "63190024", label: "LA CABAÑA - QUINDIO" },
  { value: "63190028", label: "CONDOMINIO MONTERREY - QUINDIO" },
  { value: "63190030", label: "CONDOMINIO ANGELES DEL BOSQUE - QUINDIO" },
  { value: "63190031", label: "CONDOMINIO CEDRO NEGRO - QUINDIO" },
  { value: "63190032", label: "CONDOMINIO RESERVAS DEL BOSQUE - QUINDIO" },
  { value: "63190033", label: "EL CONGAL - QUINDIO" },
  { value: "63190034", label: "LA CRISTALINA - QUINDIO" },
  { value: "63190035", label: "HACIENDA HORIZONTES - QUINDIO" },
  { value: "63212000", label: "CÓRDOBA - QUINDIO" },
  { value: "63272000", label: "FILANDIA - QUINDIO" },
  { value: "63272002", label: "LA INDIA - QUINDIO" },
  { value: "63302000", label: "GÉNOVA - QUINDIO" },
  { value: "63401000", label: "LA TEBAIDA - QUINDIO" },
  { value: "63401003", label: "LA SILVIA - QUINDIO" },
  { value: "63401004", label: "FUNDACIÓN AMANECER - QUINDIO" },
  { value: "63401005", label: "CONDOMINIO LA ESTACIÓN - QUINDIO" },
  { value: "63401006", label: "CONDOMINIO MORACAWA - QUINDIO" },
  { value: "63401007", label: "MURILLO - QUINDIO" },
  { value: "63470000", label: "MONTENEGRO - QUINDIO" },
  { value: "63470001", label: "EL CUZCO - QUINDIO" },
  { value: "63470003", label: "PUEBLO TAPADO - QUINDIO" },
  { value: "63470004", label: "ONCE CASAS - QUINDIO" },
  { value: "63470007", label: "EL CASTILLO - QUINDIO" },
  { value: "63470008", label: "EL GIGANTE - QUINDIO" },
  { value: "63470009", label: "BARAYA - QUINDIO" },
  { value: "63470010", label: "LA MONTAÑA - QUINDIO" },
  { value: "63470013", label: "CALLE LARGA - QUINDIO" },
  { value: "63470014", label: "CONDOMINIO LA HACIENDA - QUINDIO" },
  { value: "63548000", label: "PIJAO - QUINDIO" },
  { value: "63548002", label: "LA MARIELA - QUINDIO" },
  { value: "63594000", label: "QUIMBAYA - QUINDIO" },
  { value: "63594002", label: "EL LAUREL - QUINDIO" },
  { value: "63594003", label: "LA ESPAÑOLA - QUINDIO" },
  { value: "63594005", label: "PUEBLO RICO - QUINDIO" },
  { value: "63594006", label: "PUERTO ALEJANDRÍA - QUINDIO" },
  { value: "63594007", label: "EL NARANJAL - QUINDIO" },
  { value: "63594009", label: "CONDOMINIO CAMPESTRE INCAS PANACA - QUINDIO" },
  { value: "63690000", label: "SALENTO - QUINDIO" },
  { value: "63690001", label: "BOQUÍA - QUINDIO" },
  { value: "63690005", label: "LA EXPLANACIÓN - QUINDIO" },
  { value: "63690007", label: "CONDOMINIO LAS COLINAS - QUINDIO" },
  { value: "63690009", label: "SAN JUAN DE CAROLINA - QUINDIO" },
  { value: "63690010", label: "SAN ANTONIO - QUINDIO" },
  { value: "63690011", label: "LOS PINOS - QUINDIO" },

  { value: "66001000", label: "PEREIRA - RISARALDA" },
  { value: "66045000", label: "APIA - RISARALDA" },
  { value: "66075000", label: "BALBOA - RISARALDA" },
  { value: "66088000", label: "BELEN DE UMBRIA - RISARALDA" },
  { value: "66170000", label: "DOSQUEBRADAS - RISARALDA" },
  { value: "66318000", label: "GUATICA - RISARALDA" },
  { value: "66383000", label: "LA CELIA - RISARALDA" },
  { value: "66400000", label: "LA VIRGINIA - RISARALDA" },
  { value: "66440000", label: "MARSELLA - RISARALDA" },
  { value: "66456000", label: "MISTRATO - RISARALDA" },
  { value: "66572000", label: "PUEBLO RICO - RISARALDA" },
  { value: "66594000", label: "QUINCHIA - RISARALDA" },
  { value: "66682000", label: "SANTA ROSA DE CABAL - RISARALDA" },
  { value: "66687000", label: "SANTUARIO - RISARALDA" },
  { value: "88001000", label: "SAN ANDRES - SAN ANDRES" },
  { value: "88564000", label: "PROVIDENCIA - SAN ANDRES" },
  { value: "68001000", label: "BUCARAMANGA - SANTANDER" },
  { value: "68013000", label: "AGUADA - SANTANDER" },
  { value: "68020000", label: "ALBANIA - SANTANDER" },
  { value: "68051000", label: "ARATOCA - SANTANDER" },
  { value: "68077000", label: "BARBOSA - SANTANDER" },
  { value: "68079000", label: "BARICHARA - SANTANDER" },
  { value: "68081000", label: "BARRANCABERMEJA - SANTANDER" },
  { value: "68092000", label: "BETULIA - SANTANDER" },
  { value: "68101000", label: "BOLIVAR - SANTANDER" },
  { value: "68121000", label: "CABRERA - SANTANDER" },
  { value: "68132000", label: "CALIFORNIA - SANTANDER" },
  { value: "68147000", label: "CAPITANEJO - SANTANDER" },
  { value: "68152000", label: "CARCASI - SANTANDER" },
  { value: "68160000", label: "CEPITA - SANTANDER" },
  { value: "68162000", label: "CERRITO - SANTANDER" },
  { value: "68167000", label: "CHARALA - SANTANDER" },
  { value: "68169000", label: "CHARTA - SANTANDER" },
  { value: "68176000", label: "CHIMA - SANTANDER" },
  { value: "68179000", label: "CHIPATA - SANTANDER" },
  { value: "68190000", label: "CIMITARRA - SANTANDER" },
  { value: "68207000", label: "CONCEPCION - SANTANDER" },
  { value: "68209000", label: "CONFINES - SANTANDER" },
  { value: "68211000", label: "CONTRATACION - SANTANDER" },
  { value: "68217000", label: "COROMORO - SANTANDER" },
  { value: "68229000", label: "CURITI - SANTANDER" },
  { value: "68235000", label: "EL CARMEN DE CHUCURI - SANTANDER" },
  { value: "68245000", label: "EL GUACAMAYO - SANTANDER" },
  { value: "68250000", label: "EL PEÑON - SANTANDER" },
  { value: "68255000", label: "EL PLAYON - SANTANDER" },
  { value: "68264000", label: "ENCINO - SANTANDER" },
  { value: "68266000", label: "ENCISO - SANTANDER" },
  { value: "68271000", label: "FLORIAN - SANTANDER" },
  { value: "68276000", label: "FLORIDABLANCA - SANTANDER" },
  { value: "68296000", label: "GALAN - SANTANDER" },
  { value: "68298000", label: "GAMBITA - SANTANDER" },
  { value: "68307000", label: "GIRON - SANTANDER" },
  { value: "68318000", label: "GUACA - SANTANDER" },
  { value: "68320000", label: "GUADALUPE - SANTANDER" },
  { value: "68322000", label: "GUAPOTA - SANTANDER" },
  { value: "68324000", label: "GUAVATA - SANTANDER" },
  { value: "68327000", label: "GUEPSA - SANTANDER" },
  { value: "68344000", label: "HATO - SANTANDER" },
  { value: "68368000", label: "JESUS MARIA - SANTANDER" },
  { value: "68370000", label: "JORDAN - SANTANDER" },
  { value: "68377000", label: "LA BELLEZA - SANTANDER" },
  { value: "68385000", label: "LANDAZURI - SANTANDER" },
  { value: "68397000", label: "LA PAZ - SANTANDER" },
  { value: "68406000", label: "LEBRIJA - SANTANDER" },
  { value: "68418000", label: "LOS SANTOS - SANTANDER" },
  { value: "68425000", label: "MACARAVITA - SANTANDER" },
  { value: "68432000", label: "MALAGA - SANTANDER" },
  { value: "68444000", label: "MATANZA - SANTANDER" },
  { value: "68464000", label: "MOGOTES - SANTANDER" },
  { value: "68468000", label: "MOLAGAVITA - SANTANDER" },
  { value: "68498000", label: "OCAMONTE - SANTANDER" },
  { value: "68500000", label: "OIBA - SANTANDER" },
  { value: "68502000", label: "ONZAGA - SANTANDER" },
  { value: "68522000", label: "PALMAR - SANTANDER" },
  { value: "68524000", label: "PALMAS DEL SOCORRO - SANTANDER" },
  { value: "68533000", label: "PARAMO - SANTANDER" },
  { value: "68547000", label: "PIEDECUESTA - SANTANDER" },
  { value: "68549000", label: "PINCHOTE - SANTANDER" },
  { value: "68572000", label: "PUENTE NACIONAL - SANTANDER" },
  { value: "68573000", label: "PUERTO PARRA - SANTANDER" },
  { value: "68575000", label: "PUERTO WILCHES - SANTANDER" },
  { value: "68615000", label: "RIONEGRO - SANTANDER" },
  { value: "68655000", label: "SABANA DE TORRES - SANTANDER" },
  { value: "68669000", label: "SAN ANDRES - SANTANDER" },
  { value: "68673000", label: "SAN BENITO - SANTANDER" },
  { value: "68679000", label: "SAN GIL - SANTANDER" },
  { value: "68682000", label: "SAN JOAQUIN - SANTANDER" },
  { value: "68684000", label: "SAN JOSE DE MIRANDA - SANTANDER" },
  { value: "68686000", label: "SAN MIGUEL - SANTANDER" },
  { value: "68689000", label: "SAN VICENTE DE CHUCURI - SANTANDER" },
  { value: "68705000", label: "SANTA BARBARA - SANTANDER" },
  { value: "68720000", label: "SANTA HELENA DEL OPON - SANTANDER" },
  { value: "68745000", label: "SIMACOTA - SANTANDER" },
  { value: "68755000", label: "SOCORRO - SANTANDER" },
  { value: "68770000", label: "SUAITA - SANTANDER" },
  { value: "68773000", label: "SUCRE - SANTANDER" },
  { value: "68780000", label: "SURATA - SANTANDER" },
  { value: "68820000", label: "TONA - SANTANDER" },
  { value: "68855000", label: "VALLE DE SAN JOSE - SANTANDER" },
  { value: "68861000", label: "VELEZ - SANTANDER" },
  { value: "68867000", label: "VETAS - SANTANDER" },
  { value: "68872000", label: "VILLANUEVA - SANTANDER" },
  { value: "68895000", label: "ZAPATOCA - SANTANDER" },
  { value: "70001000", label: "SINCELEJO - SUCRE" },
  { value: "70110000", label: "BUENAVISTA - SUCRE" },
  { value: "70124000", label: "CAIMITO - SUCRE" },
  { value: "70204000", label: "COLOSO - SUCRE" },
  { value: "70215000", label: "COROZAL - SUCRE" },
  { value: "70221000", label: "COVEÑAS - SUCRE" },
  { value: "70230000", label: "CHALAN - SUCRE" },
  { value: "70233000", label: "EL ROBLE - SUCRE" },
  { value: "70235000", label: "GALERAS - SUCRE" },
  { value: "70265000", label: "GUARANDA - SUCRE" },
  { value: "70400000", label: "LA UNION - SUCRE" },
  { value: "70418000", label: "LOS PALMITOS - SUCRE" },
  { value: "70429000", label: "MAJAGUAL - SUCRE" },
  { value: "70473000", label: "MORROA - SUCRE" },
  { value: "70508000", label: "OVEJAS - SUCRE" },
  { value: "70523000", label: "PALMITO - SUCRE" },
  { value: "70670000", label: "SAMPUES - SUCRE" },
  { value: "70678000", label: "SAN BENITO ABAD - SUCRE" },
  { value: "70702000", label: "SAN JUAN DE BETULIA - SUCRE" },
  { value: "70708000", label: "SAN MARCOS - SUCRE" },
  { value: "70713000", label: "SAN ONOFRE - SUCRE" },
  { value: "70717000", label: "SAN PEDRO - SUCRE" },
  { value: "70742000", label: "SAN LUIS DE SINCE - SUCRE" },
  { value: "70771000", label: "SUCRE - SUCRE" },
  { value: "70820000", label: "SANTIAGO DE TOLU - SUCRE" },
  { value: "70823000", label: "TOLU VIEJO - SUCRE" },
  { value: "73001000", label: "IBAGUE - TOLIMA" },
  { value: "73024000", label: "ALPUJARRA - TOLIMA" },
  { value: "73026000", label: "ALVARADO - TOLIMA" },
  { value: "73030000", label: "AMBALEMA - TOLIMA" },
  { value: "73043000", label: "ANZOATEGUI - TOLIMA" },
  { value: "73055000", label: "ARMERO - TOLIMA" },
  { value: "73067000", label: "ATACO - TOLIMA" },
  { value: "73124000", label: "CAJAMARCA - TOLIMA" },
  { value: "73148000", label: "CARMEN DE APICALA - TOLIMA" },
  { value: "73152000", label: "CASABIANCA - TOLIMA" },
  { value: "73168000", label: "CHAPARRAL - TOLIMA" },
  { value: "73200000", label: "COELLO - TOLIMA" },
  { value: "73217000", label: "COYAIMA - TOLIMA" },
  { value: "73226000", label: "CUNDAY - TOLIMA" },
  { value: "73236000", label: "DOLORES - TOLIMA" },
  { value: "73268000", label: "ESPINAL - TOLIMA" },
  { value: "73270000", label: "FALAN - TOLIMA" },
  { value: "73275000", label: "FLANDES - TOLIMA" },
  { value: "73283000", label: "FRESNO - TOLIMA" },
  { value: "73319000", label: "GUAMO - TOLIMA" },
  { value: "73347000", label: "HERVEO - TOLIMA" },
  { value: "73349000", label: "HONDA - TOLIMA" },
  { value: "73352000", label: "ICONONZO - TOLIMA" },
  { value: "73408000", label: "LERIDA - TOLIMA" },
  { value: "73411000", label: "LIBANO - TOLIMA" },
  { value: "73443000", label: "SAN SEBASTIAN DE MARIQUITA - TOLIMA" },
  { value: "73449000", label: "MELGAR - TOLIMA" },
  { value: "73461000", label: "MURILLO - TOLIMA" },
  { value: "73483000", label: "NATAGAIMA - TOLIMA" },
  { value: "73504000", label: "ORTEGA - TOLIMA" },
  { value: "73520000", label: "PALOCABILDO - TOLIMA" },
  { value: "73547000", label: "PIEDRAS - TOLIMA" },
  { value: "73555000", label: "PLANADAS - TOLIMA" },
  { value: "73563000", label: "PRADO - TOLIMA" },
  { value: "73585000", label: "PURIFICACION - TOLIMA" },
  { value: "73616000", label: "RIOBLANCO - TOLIMA" },
  { value: "73622000", label: "RONCESVALLES - TOLIMA" },
  { value: "73624000", label: "ROVIRA - TOLIMA" },
  { value: "73671000", label: "SALDAÑA - TOLIMA" },
  { value: "73675000", label: "SAN ANTONIO - TOLIMA" },
  { value: "73678000", label: "SAN LUIS - TOLIMA" },
  { value: "73686000", label: "SANTA ISABEL - TOLIMA" },
  { value: "73770000", label: "SUAREZ - TOLIMA" },
  { value: "73854000", label: "VALLE DE SAN JUAN - TOLIMA" },
  { value: "73861000", label: "VENADILLO - TOLIMA" },
  { value: "73870000", label: "VILLAHERMOSA - TOLIMA" },
  { value: "73873000", label: "VILLARRICA - TOLIMA" },
  { value: "76001000", label: "CALI - VALLE DEL CAUCA" },
  { value: "76020000", label: "ALCALA - VALLE DEL CAUCA" },
  { value: "76036000", label: "ANDALUCIA - VALLE DEL CAUCA" },
  { value: "76041000", label: "ANSERMANUEVO - VALLE DEL CAUCA" },
  { value: "76054000", label: "ARGELIA - VALLE DEL CAUCA" },
  { value: "76100000", label: "BOLIVAR - VALLE DEL CAUCA" },
  { value: "76109000", label: "BUENAVENTURA - VALLE DEL CAUCA" },
  { value: "76111000", label: "GUADALAJARA DE BUGA - VALLE DEL CAUCA" },
  { value: "76113000", label: "BUGALAGRANDE - VALLE DEL CAUCA" },
  { value: "76122000", label: "CAICEDONIA - VALLE DEL CAUCA" },
  { value: "76126000", label: "CALIMA - VALLE DEL CAUCA" },
  { value: "76130000", label: "CANDELARIA - VALLE DEL CAUCA" },
  { value: "76147000", label: "CARTAGO - VALLE DEL CAUCA" },
  { value: "76233000", label: "DAGUA - VALLE DEL CAUCA" },
  { value: "76243000", label: "EL AGUILA - VALLE DEL CAUCA" },
  { value: "76246000", label: "EL CAIRO - VALLE DEL CAUCA" },
  { value: "76248000", label: "EL CERRITO - VALLE DEL CAUCA" },
  { value: "76250000", label: "EL DOVIO - VALLE DEL CAUCA" },
  { value: "76275000", label: "FLORIDA - VALLE DEL CAUCA" },
  { value: "76306000", label: "GINEBRA - VALLE DEL CAUCA" },
  { value: "76318000", label: "GUACARI - VALLE DEL CAUCA" },
  { value: "76364000", label: "JAMUNDI - VALLE DEL CAUCA" },
  { value: "76377000", label: "LA CUMBRE - VALLE DEL CAUCA" },
  { value: "76400000", label: "LA UNION - VALLE DEL CAUCA" },
  { value: "76403000", label: "LA VICTORIA - VALLE DEL CAUCA" },
  { value: "76497000", label: "OBANDO - VALLE DEL CAUCA" },
  { value: "76520000", label: "PALMIRA - VALLE DEL CAUCA" },
  { value: "76563000", label: "PRADERA - VALLE DEL CAUCA" },
  { value: "76606000", label: "RESTREPO - VALLE DEL CAUCA" },
  { value: "76616000", label: "RIOFRIO - VALLE DEL CAUCA" },
  { value: "76622000", label: "ROLDANILLO - VALLE DEL CAUCA" },
  { value: "76520026", label: "ROZO - VALLE DEL CAUCA" },
  { value: "76670000", label: "SAN PEDRO - VALLE DEL CAUCA" },
  { value: "76736000", label: "SEVILLA - VALLE DEL CAUCA" },
  { value: "76823000", label: "TORO - VALLE DEL CAUCA" },
  { value: "76828000", label: "TRUJILLO - VALLE DEL CAUCA" },
  { value: "76834000", label: "TULUA - VALLE DEL CAUCA" },
  { value: "76845000", label: "ULLOA - VALLE DEL CAUCA" },
  { value: "76863000", label: "VERSALLES - VALLE DEL CAUCA" },
  { value: "76869000", label: "VIJES - VALLE DEL CAUCA" },
  { value: "76890000", label: "YOTOCO - VALLE DEL CAUCA" },
  { value: "76892000", label: "YUMBO - VALLE DEL CAUCA" },
  { value: "76895000", label: "ZARZAL - VALLE DEL CAUCA" },
  { value: "97001000", label: "MITU - VAUPES" },
  { value: "97161000", label: "CARURU - VAUPES" },
  { value: "97511000", label: "PACOA - VAUPES" },
  { value: "97666000", label: "TARAIRA - VAUPES" },
  { value: "97777000", label: "PAPUNAUA - VAUPES" },
  { value: "97889000", label: "YAVARATE - VAUPES" },
  { value: "99001000", label: "PUERTO CARREÑO - VICHADA" },
  { value: "99524000", label: "LA PRIMAVERA - VICHADA" },
  { value: "99624000", label: "SANTA ROSALIA - VICHADA" },
  { value: "99773000", label: "CUMARIBO - VICHADA" },
  { value: "1000001", label: "Ciudad de Mexico" },
  { value: "20250002", label: "LA LOMA - CESAR" },
  { value: "52215002", label: "LLORENTE - NARIÑO" },
  { value: "76130008", label: "VILLA GORGONA - VALLE DEL CAUCA" },
  { value: "81065008", label: "LA ESMERALDA - ARAUCA" },
  { value: "08832000", label: "TUBARA - ATLÁNTICO" },
  { value: "86865000", label: "LA HORMIGA - PUTUMAYO" },
  { value: "86885000", label: "VILLAGARZÓN - PUTUMAYO" },
  { value: "86001000", label: "MOCOA - PUTUMAYO" },
  { value: "86001002", label: "EL PEPINO - PUTUMAYO" },
  { value: "86001003", label: "PUEBLO VIEJO - PUTUMAYO" },
  { value: "86001004", label: "PUERTO LIMÓN - PUTUMAYO" },
  { value: "86001006", label: "SAN ANTONIO - PUTUMAYO" },
  { value: "86001009", label: "YUNGUILLO - PUTUMAYO" },
  { value: "86001014", label: "LA TEBAIDA - PUTUMAYO" },
  { value: "86001016", label: "ALTO AFAN - PUTUMAYO" },
  { value: "86001017", label: "BRISAS DEL SOL - PUTUMAYO" },
  { value: "86001018", label: "PLANADAS - PUTUMAYO" },
  { value: "86001019", label: "RUMIYACO - PUTUMAYO" },
  { value: "86001020", label: "SAN ANTONIO 2 - PUTUMAYO" },
  { value: "86219000", label: "COLÓN - PUTUMAYO" },
  { value: "86219001", label: "SAN PEDRO - PUTUMAYO" },
  { value: "86219002", label: "LAS PALMAS - PUTUMAYO" },
  { value: "86219003", label: "MICHUACAN - PUTUMAYO" },
  { value: "86320000", label: "ORITO - PUTUMAYO" },
  { value: "86320001", label: "TESALIA - PUTUMAYO" },
  { value: "86320004", label: "LUCITANIA - PUTUMAYO" },
  { value: "86320008", label: "BUENOS AIRES - PUTUMAYO" },
  { value: "86320009", label: "SAN VICENTE DE LUZÓN - PUTUMAYO" },
  { value: "86320010", label: "SIBERIA - PUTUMAYO" },
  { value: "86320011", label: "SIMÓN BOLÍVAR - PUTUMAYO" },
  { value: "86320013", label: "EL ACHIOTE - PUTUMAYO" },
  { value: "86320014", label: "EL LÍBANO - PUTUMAYO" },
  { value: "86320015", label: "EL PARAISO - PUTUMAYO" },
  { value: "86320016", label: "EL YARUMO - PUTUMAYO" },
  { value: "86568000", label: "PUERTO ASÍS - PUTUMAYO" },
  { value: "86569000", label: "PUERTO CAICEDO - PUTUMAYO" },
  { value: "86571000", label: "PUERTO GUZMÁN - PUTUMAYO" },
  { value: "73616001", label: "HERRERA - TOLIMA" },
  { value: "54810002", label: "LA GABARRA - N DE SANTANDER" },
  { value: "50370001", label: "LA JULIA - META" },
  { value: "05861002", label: "BOLOMBO - ANTIOQUIA" },
  { value: "25843000", label: "VILLA DE SAN DIEGO DE UBATÉ - CUNDINAMARCA" },
  { value: "25645001", label: "SANTANDERCITO - CUNDINAMARCA" },
];

export default Ciudades;
