import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './listarNovedadesAdmin.css';
import NavbarAdminMex from '../components/navbar';
import { utils, writeFile } from 'xlsx';

const ListarNovedadesAdmin = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  useEffect(() => {
    axios.get(`https://api.99envios.app/public/api/online/novedades-beta`)
      .then(response => {
        const novedades = response.data.map(novedad => ({
          numero_preenvio: novedad.numero_preenvio,
          tipo_novedad: novedad.tipo_novedad,
          dice_contener: novedad.dice_contener,
          nombre_destinatario: novedad.nombre_destinatario,
          direccion_destinatario: novedad.direccion_destinatario,
          telefono_destinatario: novedad.telefono_destinatario,
          ciudad_destinatario: novedad.ciudad_destinatario,
          observaciones: novedad.observaciones,
          updated_at: novedad.updated_at
        }));
        setData(novedades);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    item.numero_preenvio.toLowerCase().includes(search.toLowerCase()) ||
    item.tipo_novedad.toLowerCase().includes(search.toLowerCase()) ||
    item.dice_contener.toLowerCase().includes(search.toLowerCase()) ||
    item.nombre_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.direccion_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.telefono_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.ciudad_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.observaciones?.toLowerCase().includes(search.toLowerCase()) ||
    item.updated_at.toLowerCase().includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(totalPages);
  }

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginationRange = () => {
    const totalNumbers = 5;
    const totalBlocks = totalNumbers + 2;
  
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      let pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  
      if (startPage > 2) {
        pages = ['...', ...pages];
      }
      if (endPage < totalPages - 1) {
        pages = [...pages, '...'];
      }
      return [1, ...pages, totalPages];
    }
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    // Crear una copia de los datos filtrados excluyendo la columna pdf
    const exportData = filteredData.map(item => {
      const { pdf, ...rest } = item;
      return rest;
    });

    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Envíos Completos");

    writeFile(workbook, `Tabla_Usuarios_${formatDateForDisplay(new Date())}.xlsx`);
  };

  return (
    <>
      <NavbarAdminMex title="Novedades" />
      <div className="table-container-novedades-admin">
        <div className="table-controls">
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          <div className="export-group">
            <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
          </div>
        </div>
        <table className="custom-table-novedades-admin">
          <thead>
            <tr>
              <th onClick={() => handleSort('numero_preenvio')}>Numero Preenvio</th>
              <th onClick={() => handleSort('tipo_novedad')}>Tipo Novedad</th>
              <th onClick={() => handleSort('dice_contener')}>Dice Contener</th>
              <th onClick={() => handleSort('nombre_destinatario')}>Nombre Destinatario</th>
              <th onClick={() => handleSort('direccion_destinatario')}>Direccion Destinatario</th>
              <th onClick={() => handleSort('telefono_destinatario')}>Telefono Destinatario</th>
              <th onClick={() => handleSort('ciudad_destinatario')}>Ciudad Destinatario</th>
              <th onClick={() => handleSort('observaciones')}>Observaciones</th>
              <th onClick={() => handleSort('updated_at')}>Fecha Actualización</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((item, index) => (
              <tr key={index}>
                <td>{item.numero_preenvio}</td>
                <td>{item.tipo_novedad}</td>
                <td>{item.dice_contener}</td>
                <td>{item.nombre_destinatario}</td>
                <td>{item.direccion_destinatario}</td>
                <td>{item.telefono_destinatario}</td>
                <td>{item.ciudad_destinatario}</td>
                <td>{item.observaciones}</td>
                <td>{item.updated_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-novedades-admin">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {paginationRange().map((page, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === page ? 'active' : ''}`}
              onClick={() => typeof page === 'number' && setCurrentPage(page)}
              disabled={typeof page !== 'number'}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </button>
        </div>
      </div>
    </>
  );
};

export default ListarNovedadesAdmin;