import React, { useState } from 'react';

export default function Component({ title }) {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Cambia el estado de visibilidad del sidebar
    };

    return (
        <>
            <nav key="1" className="navbar" style={{ display: 'flex', alignItems: 'center', }}>
                <div className="container" style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                    <a href="/dashboard" className="sidebar-item" style={{ marginRight: 'auto' }}>
                        <img alt="ENVÍOS" className="logo" src="/images/download.png"  />
                    </a>
                    
                    <span className="dashboard-title">{title}</span>
                </div>
            </nav>
        </>
    );
}
