import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Dropdown, Menu, Button, Select, Modal } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './ordenesEnviosBodegas.css';

const { Option } = Select;

const OrdenesEnviosBodegas = () => {
  const [showBodegaModal, setShowBodegaModal] = useState(false);
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    estadoOrden: '',
    transportadora: '',
    estadoEnvio: '',
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/picking/bodega/listar-ordenes-sucursal/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: index + 1,
          id_producto: item.id_producto,
          id_variacion: item.id_variacion,
          talla: item.talla,
          color: item.color,
          nombre_producto: item.nombre_producto,
          fecha_estado: item.fecha_estado,
          estado_envio: item.estado_envio,
          transportadora: item.transportadora,
          valor_envio: item.valor_envio,
          cantidad: item.cantidad,
          numero_guia: item.numero_guia,
          pdf: item.pdf,
          nombre_bodega: item.nombre_bodega,
          telefono_bodega: item.telefono_bodega,
          direccion_bodega: item.direccion_bodega,
          ciudad_bodega: item.ciudad_bodega[0],
          selected: false,
        })).reverse(); // Reverse the order
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Producto',
      dataIndex: 'id_producto',
      key: 'id_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Fecha de estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
    },
    {
      title: 'Valor del Envío',
      dataIndex: 'valor_envio',
      key: 'valor_envio',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: ' Numero de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Guía',
      dataIndex: 'numero_guia',
      key: 'guia',
      render: (_, record) => {
        if (record.pdf && typeof record.pdf === 'string' && record.pdf.startsWith('http')) {
          return (
            <Button
              type="link"
              onClick={() => window.open(record.pdf, '_blank')}
            >
              Ver PDF
            </Button>
          );
        }
        return <span>No disponible</span>;
      },
    },
    {
      title: 'Bodega',
      dataIndex: 'nombre_bodega',
      key: 'nombre_bodega',
      render: (_, record) => (
        <div>
          <p><strong>{record.nombre_bodega}</strong></p>
          <p>{record.telefono_bodega}</p>
          <p>{record.direccion_bodega}</p>
          <p>{record.ciudad_bodega}</p>
        </div>
      ),
    },
  ].filter(col => !['nombre_destinatario', 'telefono_destinatario', 'direccion_destinatario', 'valor_utilidad'].includes(col.dataIndex));

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.estadoOrden) {
      result = result.filter((item) => item.estado_orden === parseInt(filters.estadoOrden));
    }
    if (filters.transportadora) {
      result = result.filter((item) => item.transportadora.toLowerCase() === filters.transportadora.toLowerCase());
    }
    if (filters.estadoEnvio) {
      result = result.filter((item) => item.estado_envio.toLowerCase() === filters.estadoEnvio.toLowerCase());
    }
    setFilteredData(result);
  }, [searchText, filters, data]);

  const downloadCombinedPDF = async () => {
    const selectedData = data.filter(item => selectedItems.includes(item.key));
    const pdfDocs = await Promise.all(
      selectedData.map(async (item) => {
        if (item.pdf && item.pdf.startsWith('http')) {
          const response = await fetch(item.pdf);
          return response.arrayBuffer();
        }
        return null;
      }).filter(Boolean)
    );

    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfDocs) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={downloadCombinedPDF}>Descargar seleccionados como PDF combinado</Menu.Item>
    </Menu>
  );

  const bodegasData = [
    { id: 1, nombre: 'Bodega Principal' },
    { id: 2, nombre: 'Bodega Secundaria' },
    { id: 3, nombre: 'Bodega Satélite' },
  ];

  const showBodegasList = () => {
    setShowBodegaModal(true);
    setSelectedBodega(null);
  };

  const handleBodegaSelection = (bodega) => {
    setSelectedBodega(bodega);
  };

  const handleBodegaModalSubmit = () => {
    // Aquí puedes implementar la lógica para actualizar el registro con la bodega seleccionada
    console.log('Bodega seleccionada:', selectedBodega);
    setShowBodegaModal(false);
  };

  const handleBodegaModalCancel = () => {
    setShowBodegaModal(false);
    setSelectedBodega(null);
  };

  return (
    <>
      <NavbarPicking title="Translado y Envío de Inventarios" />
      <div className='ordenes-envios-bodegas'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Dropdown overlay={menu}>
            <Button>
              Acciones <DownOutlined />
            </Button>
          </Dropdown>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Select
            style={{ width: 200, marginRight: '8px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('transportadora', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
          <Select
            style={{ width: 200 }}
            placeholder="Filtrar por Estado de Envío"
            onChange={(value) => handleFilterChange('estadoEnvio', value)}
          >
            <Option value="">Todos</Option>
            <Option value="creado">Creado</Option>
            <Option value="en_transito">En tránsito</Option>
            <Option value="entregado">Entregado</Option>
          </Select>
        </div>
        <div className="ordenes-envios-bodegas-tabla" style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
      <Modal
        visible={showBodegaModal}
        onCancel={handleBodegaModalCancel}
        footer={
          <>
            <Button style={{height: 44}} onClick={handleBodegaModalCancel}>Cancelar</Button>
            <Button style={{height: 44}} type="primary" onClick={handleBodegaModalSubmit}>
              Enviar
            </Button>
          </>
        }
      >
        <h2>Seleccione una Bodega</h2>
        <Select
          showSearch
          style={{ width: '100%', marginBottom: '16px' }}
          value={selectedBodega?.id}
          onChange={handleBodegaSelection}
          placeholder="Seleccione una bodega"
        >
          {bodegasData.map((bodega) => (
            <Option key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default OrdenesEnviosBodegas;