import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarSales from '../../components/navbar/navbarSales';
import './dashboardSales.css';
import { message } from 'antd';
import 'antd/dist/antd.css'; // o la versión que corresponda de antd

const DashboardSales = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const userId = localStorage.getItem('usuario_id'); // Get user ID from localStorage

  const fetchData = async () => {
    try {
      const [dashboardResponse] = await Promise.all([
        axios.get(`https://99envios.app/api/online/vendedor/${userId}`),
      ]);
      setDashboardData(dashboardResponse.data);
    } catch (error) {
      message.error('No hay sucursales asignadas a este vendedor.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <NavbarSales title={"Vendedor"}/>
      <div className="dashboard-containerAD">
        {dashboardData ? (
          <div className="cards-containerAD">
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD local-shipping-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  store  </span>
                </div>
                <h3>Total Sucursales</h3>
                <p>{dashboardData["$totalSucursales"]}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD check-circle-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  remove_shopping_cart  </span>
                </div>
                <h3>Sucursales sin Envios</h3>
                <p>{dashboardData.sucursalesCeroEnvios}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD check-circle-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  calendar_today  </span>
                </div>
                <h3>Total sin envíos mes</h3>
                <p>{dashboardData.totalsinenviosmes}</p>
              </div>
            </div>
            <div className="rowAD">
            <div className="cardAD">
                <div className="icon-circle-AD report-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  local_shipping  </span>
                </div>
                <h3>Sucursales con 10 Envios</h3>
                <p>{dashboardData["$totalSucursales10"]}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD report-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  local_shipping  </span>
                </div>
                <h3>Sucursales con 20 Envios</h3>
                <p>{dashboardData["$totalSucursales20"]}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD public-icon">
                  <span className="material-icons-sharp" style={{ fontSize:"29px"}}>  local_shipping  </span>
                </div>
                <h3>Sucursales con 30 Envios</h3>
                <p>{dashboardData["$totalSucursales30"]}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
        
        {/* Remove the table-containerAD div */}
      </div>
    </>
  );
}

export default DashboardSales;