import React, { useEffect, useState } from 'react'
import confetti from 'canvas-confetti';
import { Form, Input, Button, Select, Typography, message, Modal, Radio, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Ciudades from '../../ciudades/ciudades';
import axios from 'axios';
import './SendClient.css';

const { Option } = Select;
const { Text } = Typography;

const SendClientPage = (props) => {
  const [form] = Form.useForm();
  const [costo, setCosto] = useState(null);
  const [precioProveedor, setPrecioProveedor] = useState(null);
  const [transportadora, setTransportadora] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState(1);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para controlar la rueda de carga
  const [subtotal, setSubtotal] = useState(0);
  const [idsucursal, setIdsucursal] = useState('');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [selectedBodegaStock, setSelectedBodegaStock] = useState(null);

  const { estadoBodega, inventarioBodega } = props;

  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  const [cotizaciones, setCotizaciones] = useState({
    coordinadora: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    interrapidisimo: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    servientrega: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    tcc: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 }
  });
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    notes: '',
    payment: '',
    product: '',
    quantity: 1,
    salePrice: 0,
    store: '',
    aplicaContrapago: true  // Nuevo campo para AplicaContrapago
  });
  const [producto, setProducto] = useState({
    id_producto: '',
    id_sucursal: '',
    id_categoria: '',
    precio_proveedor: '',
    precio_sugerido: '',
    cantidad_disponible: '',
    created_at: '',
    descripcion_producto: '',
    estado_variacion: '',
    imagenes: [],
    informacion_adicional: '',
    nombre_producto: '',
    peso_producto: '',
    sku: '',
    updated_at: '',
    variaciones: []
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);

  const cotizacionData = {
    destino: {
      nombre: "",
      codigo: formValues.city,
    },
    origen: {
      nombre: "",
      codigo: "",
    },
    IdTipoEntrega: 1,
    IdServicio: 1,
    peso: producto.peso_producto,
    largo: 1,
    ancho: 1,
    alto: 1,
    fecha: "28-06-2024",
    AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
    valorDeclarado: 15000,
  };

  const images = [
    '/Images/coordi.png',
    '/Images/inter.png',
    '/Images/servi.png',
    '/Images/tcc.svg'
  ];

  const envios = [
    'coordinadora',
    'interrapidisimo',
    'servientrega',
    'tcc'
  ];

  const handleChange = (value) => {
    const maxStock = estadoBodega
      ? selectedBodegaStock
      : producto.cantidad_disponible;

    if (!maxStock) {
      message.error('Seleccione una bodega o revise el stock disponible');
      return;
    }

    if (value < 1 || value > maxStock) {
      message.error(`La cantidad debe estar entre 1 y ${maxStock}`);
      return;
    }

    setInputValue(value);
    form.setFieldsValue({ quantity: value });
  };


  function formatCurrency(value, locale = 'es-CO', currency = 'COP') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const id = localStorage.getItem('usuario_id');
      const url = `https://api.99envios.app/api/inventarios`;
      try {
        const { data } = await axios.get(url);
        const foundProduct = data.find(prod => prod.id_producto === props.prodID);
        setProducto(foundProduct);
        setCosto(foundProduct['precio_sugerido']);
        setPrecioProveedor(foundProduct['precio_proveedor']);
        form.setFieldsValue({
          salePrice: foundProduct['precio_sugerido'],
          product: foundProduct.nombre_producto,
          notes: foundProduct.descripcion_producto
        });
      } catch (error) {
        console.error('Error al cargar productos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const onValuesChangePedido = (changedValues, allValues) => {
    if (changedValues.salePrice || changedValues.quantity) {
      const newSalePrice = Number(changedValues.salePrice || allValues.salePrice);
      const newQuantity = Number(changedValues.quantity || allValues.quantity);
      const newSubtotal = newSalePrice * newQuantity;
      setSubtotal(newSubtotal);
      setFormValues({ ...allValues, salePrice: newSalePrice, quantity: newQuantity });
      handleAutomaticRequote(newSubtotal, allValues);
    } else {
      setFormValues(allValues);
    }
    calculateTotal(allValues);
  };

  const handleAutomaticRequote = async (newSubtotal, currentFormValues) => {
    setLoading(true);
    try {
      const pesoTotal = producto.peso_producto * Number(currentFormValues.quantity);

      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: currentFormValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: currentFormValues.aplicaContrapago,
        valorDeclarado: newSubtotal,
      };

      console.log('Datos para la nueva cotización:', cotizacionData);

      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        cotizacionData
      );
      console.log('Respuesta de la API (requote):', response.data);
      setCotizaciones(response.data);
    } catch (error) {
      console.error('Error al generar la nueva cotización:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotal = (values) => {
    const subtotal = values.salePrice * values.quantity;
    const shippingCost = transportadora; // Este es el costo de envío seleccionado
    const supplierCost = producto.precio_proveedor * values.quantity;
    const total = subtotal - supplierCost - shippingCost;
    return total;
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
    const cotizacion = cotizaciones[envios[index]] || {};
    const valor = cotizacion.valor || 0;
    const valorContrapago = cotizacion.valor_contrapago || cotizacion.valorContrapago || 0;
    const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
    const sobreflete = cotizacion.sobreflete || 0;
    const totalCotizacion = valor + valorContrapago + comisionInterna + sobreflete;

    setTransportadora(totalCotizacion);
    form.setFieldsValue({ total: calculateTotal({ ...formValues, salePrice: formValues.salePrice, quantity: inputValue }) });
  };

  const handleCotizarClick = async () => {
    setLoading(true);
    try {
      // Mostrar valores antes de la validación
      console.log('Valores antes de la validación:', form.getFieldsValue());

      // Convertir valores a números antes de validar
      form.setFieldsValue({
        salePrice: Number(formValues.salePrice),
        quantity: Number(formValues.quantity),
      });

      // Mostrar valores después de convertir
      console.log('Valores después de la conversión:', form.getFieldsValue());

      // Validar todos los campos antes de cotizar
      await form.validateFields();

      // Calcular el subtotal con valores numéricos
      const subtotal = Number(formValues.salePrice) * Number(formValues.quantity);
      console.log('Subtotal calculado:', subtotal);

      const pesoTotal = producto.peso_producto * Number(formValues.quantity);

      // Actualizar cotizacionData con el valor del subtotal
      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: formValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: formValues.aplicaContrapago, // Usar el valor del formulario
        valorDeclarado: subtotal, // Usar el valor del subtotal
      };

      // Mostrar datos que se enviarán en la petición
      console.log('Datos para la cotización:', cotizacionData);

      // Intentar la petición POST a la API
      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        cotizacionData
      );
      console.log('Respuesta de la API:', response.data);
      setCotizaciones(response.data);
      message.success('Cotización generada con éxito');
    } catch (error) {
      // Mostrar mensaje específico si la validación es la que falla
      if (error.errorFields) {
        message.error('Error de validación: revise los campos del formulario.');
        console.error('Error de validación:', error.errorFields);
        console.log('Campos que fallaron la validación:', error.errorFields);
      } else {
        message.error('Error al generar la cotización');
        console.error('Error al generar la cotización:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.5 }, // Ajustar la altura para que el confeti aparezca en el modal
        zIndex: 9999, // Asegúrate de que el confeti esté por encima del modal
      });
    }
  }, [isModalVisible]);

  const downloadPDF = (base64String, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };




  const handleConfirmarClick = async () => {
    setLoading(true);
    try {
      const totalGanar = calculateTotal(formValues);

      if (totalGanar < 0) {
        message.error('El monto a ganar es negativo. No puedes confirmar y enviar el pedido.');
        return;
      }
      const maxStock = estadoBodega
        ? selectedBodegaStock
        : producto.cantidad_disponible;

      if (!maxStock || formValues.quantity < 1 || formValues.quantity > maxStock) {
        message.error(`La cantidad debe estar entre 1 y ${maxStock}`);
        return;
      }


      await form.validateFields();

      const orderData = {
        IdProducto: producto.id_producto,
        IdVariacion: selectedVariation || '',
        Cantidad: formValues.quantity,
        AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
        valorDeclarado: formValues.salePrice,
        Destinatario: {
          nombre: formValues.firstName,
          primerApellido: formValues.lastName.split(' ')[0] || '',
          segundoApellido: formValues.lastName.split(' ')[1] || '',
          telefono: formValues.phone,
          direccion: formValues.address,
          idLocalidad: formValues.city,
          correo: formValues.email
        },
        Observaciones: formValues.notes,
        transportadora: {
          pais: 'colombia',
          nombre: envios[selectedImage] || ''
        },
        origenCreacion: estadoBodega ? 4 : 2, // Cambiar el origen si el estado_bodega es true

      };
      // Agregar el ID de la bodega si estado_bodega es true
      if (estadoBodega && selectedBodega) {
        orderData.id_bodega = selectedBodega.id_sucursal_bodega;
      }
      
      const id = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Obtener el token del localStorage

      // Configurar los encabezados
      const config = {
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',  // Incluir el token si existe
          'Content-Type': 'application/json'
        }
      };

      // Realizar la solicitud POST con los datos de la orden y la configuración de encabezados
      const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);

      if (response.status === 200) {
        setOrderResponse(response.data);
        setIsModalVisible(true);
        message.success('Pedido confirmado y enviado con éxito');
      } else {
        throw new Error('Error al enviar el pedido');
      }
    } catch (error) {
      message.error('Error al enviar el pedido');
    } finally {
      setLoading(false);
    }
  };


  const handleVariationChange = (value) => {
    setSelectedVariation(value);
    const selectedVar = producto.variaciones.find(variation => variation.id === value);
    setInputValue(1);  // Reinicia la cantidad a 1 al cambiar de variación
    form.setFieldsValue({ quantity: 1, salePrice: producto.precio_sugerido });
    message.info(`Seleccionaste la variación con ID: ${value}, Talla: ${selectedVar.talla}, Color: ${selectedVar.color}`);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setOrderResponse(null); // Reseteamos la respuesta al cerrar el modal
  };


  // Determinar cantidad disponible
  const cantidadDisponible = estadoBodega
    ? selectedBodegaStock || inventarioBodega.reduce((acc, b) => acc + b.cantidad_disponible, 0)
    : producto.cantidad_disponible;

  const handleBodegaChange = (value) => {
    const bodegaSeleccionada = inventarioBodega.find((bodega) => bodega.id === parseInt(value, 10));
    setSelectedBodega(bodegaSeleccionada);
    setSelectedBodegaStock(bodegaSeleccionada?.cantidad_disponible || 0);
  };


  return (
    <Spin spinning={loading}>
      <div className="enviosDrop">
        <div className="formularioEnvio">
          <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
            <Form.Item
              name="firstName"
              label="Nombre(s) destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el nombre del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Apellido(s) destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el apellido del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Teléfono destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el teléfono del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email destinatario (Opcional)"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label="Dirección destinatario"
              rules={[{ required: true, message: 'Por favor ingrese la dirección del destinatario' }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="city"
              label="Ciudad destinatario"
              rules={[{ required: true, message: 'Seleccione la ciudad del destinatario' }]}
            >
              <Select
                showSearch
                placeholder="Seleccione una ciudad"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                 {Ciudades.map((ciudad) => (
                  <Option key={ciudad.value} value={ciudad.value}>
                    {ciudad.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notas para el Proveedor"
              rules={[{ required: true, message: 'Por favor ingrese alguna nota' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Button type="primary" onClick={handleCotizarClick} block>
              Cotizar
            </Button>
          </Form>
        </div>

        <div className="transportadora">
          {images.map((image, index) => {
            const cotizacion = cotizaciones[envios[index]] || {};
            const valor = cotizacion.valor || 0;
            const valorContrapago = cotizacion.valor_contrapago || cotizacion.valorContrapago || 0;
            const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
            const sobreflete = cotizacion.sobreflete || 0;
            const totalCotizacion = valor + valorContrapago + comisionInterna + sobreflete;

            return (
              <div
                key={index}
                className="flete"
                style={{
                  border: selectedImage === index ? '1px solid rgba(247, 118, 67, 0.5)' : '1px solid rgba(128, 128, 128, 0.5)',
                  cursor: 'pointer'
                }}
                onClick={() => handleImageClick(index)}
              >
                <img src={image} alt={`Imagen ${index + 1}`} />
                <p>{formatCurrency(totalCotizacion)}</p>
              </div>
            );
          })}
        </div>

        <div className="pedidoEnvio">
          <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
            <Form.Item
              label="Producto"
              name="product"
              rules={[{ required: true, message: 'Por favor selecciona un producto' }]}
            >
              <Select placeholder="Selecciona un producto">
                <Option value={producto.nombre_producto}>{producto.nombre_producto}</Option>
              </Select>
            </Form.Item>

            {/* Select para Variaciones */}
            {producto.variaciones.length > 0 && (
              <Form.Item
                name="variation"
                label="Selecciona una variación"
                rules={[{ required: true, message: 'Por favor selecciona una variación' }]}
              >
                <Select placeholder="Selecciona una variación" onChange={handleVariationChange}>
                  {producto.variaciones.map(variation => (
                    <Option key={variation.id} value={variation.id}>
                      {`ID: ${variation.id}, Talla: ${variation.talla}, Color: ${variation.color}, Stock: ${variation.cantidad_disponible}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Text>Precio sugerido: ${costo}</Text>
            <Form.Item
              label="Precio de venta"
              name="salePrice"
              initialValue={producto.precio_sugerido}
              rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
            >
              <Input type="number" />
            </Form.Item>

            <Text>
              Existencia: {estadoBodega
                ? (selectedBodegaStock !== null ? selectedBodegaStock : 'Seleccione una bodega')
                : producto.cantidad_disponible || 'No disponible'}
            </Text>

            <Form.Item
              label="Cantidad"
              name="quantity"
              initialValue={1}
              rules={[
                { required: true, message: 'Por favor ingresa la cantidad' },
                {
                  validator: (_, value) => {
                    const maxStock = estadoBodega
                      ? selectedBodegaStock
                      : producto.cantidad_disponible;

                    if (!maxStock) {
                      return Promise.reject('Seleccione una bodega o revise el stock disponible');
                    }

                    return value < 1 || value > maxStock
                      ? Promise.reject(`La cantidad debe estar entre 1 y ${maxStock}`)
                      : Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                value={inputValue}
                onChange={(e) => handleChange(Number(e.target.value))}
              />
            </Form.Item>


            <Form.Item
              name="aplicaContrapago"
              label="¿Aplica Contrapago?"
              initialValue={true}
            >
              <Radio.Group disabled>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {estadoBodega && (
              <div>
                <label name="bodega" rules={[{ required: estadoBodega, message: 'Seleccione una bodega' }]}>Bodega disponible:</label>
                <Select placeholder="Seleccione una bodega" onChange={handleBodegaChange}>
                  {inventarioBodega.map((bodega) => (
                    <Option key={bodega.id} value={bodega.id}>
                      {bodega.ciudad_bodega} (Stock: {bodega.cantidad_disponible})
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            {/* {estadoBodega && inventarioBodega.length > 0 && (
              <div>
                <label>Selecciona bodega:</label>
                <select onChange={handleBodegaChange} defaultValue="">
                  <option value="" disabled>-- Elige una bodega --</option>
                  {inventarioBodega.map((bodega) => (
                    <option key={bodega.id} value={bodega.id}>
                      {bodega.ciudad_bodega} (Stock: {bodega.cantidad_disponible})
                    </option>
                  ))}
                </select>
              </div>
            )} */}

          </Form>
          <div className="order-summary">
            <div className="order-summary-header">Resumen de la orden</div>
            <div className="order-summary-content">
              <div className="order-summary-item">
                <div className="item-description">{formValues.product}</div>
                <div className="item-price">{formatCurrency(formValues.salePrice)} x {formValues.quantity}</div>
              </div>
              <div className="order-summary-total">
                <div className="total-label">Sub Total :</div>
                <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
              </div>
              {/* precio provedor */}
              <div className="order-summary-total">
                <div className="total-label">Precio Proveedor :</div>
                <div className="total-amount">{formatCurrency(precioProveedor)}</div>
              </div>
              {/* precio de envio con las sumas */}

              <div className="order-summary-total">
                <div className="total-label">Precio de Envío :</div>
                <div className="total-amount">{formatCurrency(transportadora)}</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Comisión de la plataforma :</div>
                <div className="total-amount">$ 0</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Monto a ganar cuando esté entregado :</div>
                <div className="total-amount" style={{ color: calculateTotal(formValues) < 0 ? 'red' : 'black' }}>
                  {formatCurrency(calculateTotal(formValues))}
                </div>
              </div>
              {calculateTotal(formValues) < 0 && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  <strong>Advertencia:</strong> El monto a ganar es negativo. No puedes confirmar y enviar el pedido.
                </div>
              )}

              <div className="order-summary-total">
                <div className="total-label">Total:</div>
                <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
              </div>
              <div className="order-summary-footer">
                <Button
                  type="primary"
                  onClick={handleConfirmarClick}
                  block
                  disabled={calculateTotal(formValues) < 0} // Deshabilita el botón si el total es negativo
                >
                  Confirmar y Enviar Pedido
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Orden</span>}
          visible={isModalVisible}
          onCancel={handleModalOk}
          footer={[
            <Button
              key="download"
              type="primary"
              onClick={() => downloadPDF(orderResponse.pdf, `Guia_Orden_${orderResponse.numero_orden}.pdf`)}
            >
              Descargar Guía
            </Button>,
          ]}
          centered
          width={600}
          bodyStyle={{
            padding: '20px',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px',
          }}
        >
          {orderResponse && (
            <div style={{ lineHeight: '1.6', fontSize: '16px' }}>
              <div style={{ marginBottom: '16px', textAlign: 'center' }}>
                <strong style={{ fontSize: '18px', textDecoration: 'underline' }}>Resumen de la Orden</strong>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <p><strong>Mensaje:</strong> {orderResponse.mensaje}</p>
                <p><strong>Número de Orden:</strong> {orderResponse.numero_orden}</p>
              </div>
              <div style={{
                padding: '10px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                marginBottom: '16px'
              }}>
                <p><strong>Valor de Venta:</strong> {formatCurrency(orderResponse.valor_venta)}</p>
                <p><strong>Valor de Proveedor:</strong> {formatCurrency(orderResponse.valor_proveedro)}</p>
                <p><strong>Valor de Envío:</strong> {formatCurrency(transportadora)}</p>
                <p><strong>Valor de Comisión:</strong> $ 0</p>
                <p><strong>Valor de Utilidad menos 4x1000:</strong> {formatCurrency(calculateTotal(formValues))}</p>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </Spin>
  );
};

export default SendClientPage;
