import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Select, message, Table, Popconfirm } from 'antd';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import Ciudades from '../../ciudades/ciudades';
import './crearBodega.css';

const { Option } = Select;

const CrearBodega = () => {
  const [bodegas, setBodegas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBodegas();
  }, []);

  const fetchBodegas = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/picking/bodega');
      if (response.status !== 200) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = response.data;
      const bodegasFormateadas = data.map((bodega, index) => ({
        key: (index + 1).toString(),
        id: bodega.id_users,
        nombre: bodega.nombre,
        direccion: bodega.direccion,
        telefono: bodega.telefono,
        nombreSucursal: bodega.nombre_sucursal,
        tipoIdentificacion: bodega.tipo_identificacion,
        numeroIdentificacion: bodega.numero_identificacion,
      }));
      setBodegas(bodegasFormateadas);
    } catch (error) {
      console.error('Error al obtener las bodegas:', error);
      message.error('No se pudieron cargar las bodegas');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(`https://99envios.app/api/picking/bodega/delete/${id}`);
      message.success('Bodega eliminada exitosamente');
      fetchBodegas(); // Reload the table
    } catch (error) {
      console.error('Error al eliminar la bodega:', error);
      if (error.response && error.response.data && error.response.data.error === "No se puede eliminar la bodega porque tiene relaciones en la tabla bodega_sucursal.") {
        message.error('No se puede eliminar la bodega porque tiene relaciones en la tabla bodega_sucursal.');
      } else {
        message.error('Error al eliminar la bodega');
      }
    }
  };

  const handleEdit = async (id, values) => {
    try {
      await axios.put(`https://99envios.app/api/picking/bodega/${id}`, values);
      message.success('Bodega modificada exitosamente');
      fetchBodegas(); // Reload the table
    } catch (error) {
      console.error('Error al modificar la bodega:', error);
      message.error('Error al modificar la bodega');
    }
  };

  const columns = [
    { title: '#', dataIndex: 'key', key: 'key' },
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'Dirección', dataIndex: 'direccion', key: 'direccion' },
    { title: 'Teléfono', dataIndex: 'telefono', key: 'telefono' },
    { title: 'Nombre Bodega', dataIndex: 'nombreSucursal', key: 'nombreSucursal' },
    { title: 'Tipo Identificación', dataIndex: 'tipoIdentificacion', key: 'tipoIdentificacion' },
    { title: 'Número Identificación', dataIndex: 'numeroIdentificacion', key: 'numeroIdentificacion' },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record.id, record)}>Modificar</Button>
          <Popconfirm
            title="¿Está seguro de eliminar esta bodega?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sí"
            cancelText="No"
          >
            <Button type="link" danger>Eliminar</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const onFinish = async (values) => {
    try {
      const response = await axios.post('https://99envios.app/api/picking/bodega', values);
      console.log('Success:', response.data);
      message.success('Bodega creada exitosamente');
      fetchBodegas(); // Reload the table
    } catch (error) {
      console.error('Error:', error);
      message.error('Error al crear la bodega');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <NavbarAdminMex title="Gestionar Bodegas" />
      <div className="formulario-creacion-bodega-container">
        <Form
          name="crear_bodega"
          initialValues={{
            remember: true,
            pais: 1 // Set Colombia as default
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="formulario-creacion-bodega-form-grid">
            <Form.Item
              label="Nombre y Apellidos"
              name="nombre_apellidos"
              rules={[{ required: true, message: 'Por favor ingrese su nombre y apellidos!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>

            <Form.Item
              label="Tipo de Identificación"
              name="tipo_identificacion"
              rules={[{ required: true, message: 'Por favor seleccione su tipo de identificación!' }]}
            >
              <Select style={{ width: '200px' }}>
                <Option value="cc">Cédula de Ciudadanía</Option>
                <Option value="ti">Tarjeta de Identidad</Option>
                <Option value="ce">Cédula de Extranjería</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Número"
              name="num_identificacion"
              rules={[{ required: true, message: 'Por favor ingrese su número de identificación!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>

            <Form.Item
              label="Dirección"
              name="direccion"
              rules={[{ required: true, message: 'Por favor ingrese su dirección!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>

            <Form.Item
              label="País"
              name="pais"
              rules={[{ required: true, message: 'Por favor seleccione su país!' }]}
            >
              <Select style={{ width: '200px' }}>
                <Option value={1}>Colombia</Option>
                {/* Agregar más opciones según sea necesario */}
              </Select>
            </Form.Item>

            <Form.Item
              label="Ciudad"
              name="ciudad_id"
              rules={[{ required: true, message: 'Por favor ingrese su ciudad!' }]}
            >
              <Select
                showSearch
                placeholder="Seleccione una ciudad"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {Ciudades.map((ciudad) => (
                  <Option key={ciudad.value} value={ciudad.value}>
                    {ciudad.label}
                  </Option>
                ))}


              </Select>
            </Form.Item>

            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[{ required: true, message: 'Por favor ingrese su teléfono!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>

            <Form.Item
              label="Correo Electrónico"
              name="correo"
              rules={[{ required: true, message: 'Por favor ingrese su correo electrónico!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="contrasena"
              rules={[{ required: true, message: 'Por favor ingrese su contraseña!' }]}
            >
              <Input.Password className="form-control-width" style={{ width: "200px" }} />
            </Form.Item>

            <Form.Item
              label="Nombre de la Bodega"
              name="nombre_sucursal"
              rules={[{ required: true, message: 'Por favor ingrese el nombre de la bodega!' }]}
            >
              <Input className="form-control-width" />
            </Form.Item>
          </div>

          <div className="formulario-creacion-bodega-checkbox-section">
            <Form.Item
              name="terminos_condiciones"
              valuePropName="checked"
              rules={[{ required: true, message: 'Debe aceptar los términos y condiciones!' }]}
            >
              <Checkbox>Acepto los términos y condiciones</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginTop: "20px", height: "44px" }}>
                Crear Bodega
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="crear-bodega-lista-de-bodegas-container">
        <h2>Listado de Bodegas</h2>
        <div className="crear-bodega-lista-de-bodegas-scroll">
          <Table
            columns={columns}
            dataSource={bodegas}
            pagination={{ pageSize: 10 }}
            loading={loading}
            locale={{
              emptyText: 'No hay bodegas disponibles'
            }}
            scroll={{ x: 700 }}
          />
        </div>
      </div>
    </>
  );
};

export default CrearBodega;