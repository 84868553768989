import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Select, Modal } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import './misPedidos.css';

const { Option } = Select;

const ProductTablePedidos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    orderStatus: '',
    carrier: '',
    warehouse: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isTableScrolled, setIsTableScrolled] = useState(false);
  
  const tableContainerRef = useRef(null);
  const topScrollRef = useRef(null);

  // Mapeo de los estados de la orden
  const orderStatusMap = {
    1: 'Creado',
    2: 'Distribucion',
    3: 'Entregado',
    4: 'Rechazado',
    5: 'Devolucion',
    6: 'Anulado',
    7: 'Otro',
    8: 'Error'
  };

  // Función para consumir la API
  const user_id = localStorage.getItem('usuario_id');
  const fetchOrders = async () => {
    try {
      const response = await fetch(`https://api.99envios.app/api/listar_ordenes/${user_id}`);
      const result = await response.json();
      const formattedData = result
        .filter(item => item.origen_creacion === 2 || item.origen_creacion === 4) // Modified filter condition
        .map((item, index) => ({
          key: index.toString(),
          id: index + 1,
          productName: item.nombre_producto,
          orderDate: item.fecha_estado,
          client: `${item.preeenvio.destinatario.nombre} ${item.preeenvio.destinatario.primer_apellido} ${item.preeenvio.destinatario.segundo_apellido || ''}`.trim(),
          phone: item.preeenvio.destinatario.telefono,
          orderStatus: item.preeenvio.estado?.nombre || 'Desconocido',
          carrier: item.transportadora,
          shippingType: item.estado_envio,
          rawData: item,
        }));
      setData(formattedData);
      setFilteredData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Carga los datos al montar el componente
  useEffect(() => {
    fetchOrders();
  }, []);

  // Sincronizar el desplazamiento de las barras
  const syncScroll = (sourceRef, targetRef) => {
    if (sourceRef.current && targetRef.current) {
      targetRef.current.scrollLeft = sourceRef.current.scrollLeft;
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.productName.toLowerCase().includes(value.toLowerCase()) ||
        item.client.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.productName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.client.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.orderStatus) {
      result = result.filter((item) => item.orderStatus === filters.orderStatus);
    }
    if (filters.carrier) {
      result = result.filter((item) => item.carrier === filters.carrier);
    }
    if (filters.warehouse) {
      result = result.filter((item) => item.warehouse === filters.warehouse);
    }
    setFilteredData(result);
  }, [searchText, filters, data]);



  const showModal = (record) => {
    setSelectedOrder(record.rawData);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getStatusBadgeClass = (status) => {
    const statusMap = {
      'Creado': 'status-creado',
      'Distribucion': 'status-distribucion',
      'Entregado': 'status-entregado',
      'Rechazado': 'status-rechazado',
      'Devolucion': 'status-devolucion',
      'Anulado': 'status-anulado',
      'Otro': 'status-otro',
      'Error': 'status-error'
    };
    return `status-badge ${statusMap[status] || 'status-otro'}`;
  };

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: () => <input type="checkbox" />,
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Fecha de la orden',
      dataIndex: 'orderDate',
      key: 'orderDate',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Estatus de la Orden',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      render: (status) => (
        <span className={getStatusBadgeClass(status)}>
          {status}
        </span>
      ),
    },
    {
      title: 'Transportadora',
      dataIndex: 'carrier',
      key: 'carrier',
    },
    {
      title: 'Tipo de Envío',
      dataIndex: 'shippingType',
      key: 'shippingType',
    },
    {
      title: 'Detalles',
      key: 'details',
      render: (_, record) => <a onClick={() => showModal(record)}>Ver detalles</a>,
    },
  ];

  const exportToExcel = () => {
    // Prepare the data for export
    const exportData = filteredData.map(item => ({
      'ID': item.id,
      'Nombre del producto': item.productName,
      'Fecha de la orden': item.orderDate,
      'Cliente': item.client,
      'Teléfono': item.phone,
      'Estatus de la Orden': item.orderStatus,
      'Transportadora': item.carrier,
      'Tipo de Envío': item.shippingType
    }));

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Pedidos');

    // Save file
    XLSX.writeFile(wb, 'pedidos.xlsx');
  };

  const handleTableScroll = (e) => {
    setIsTableScrolled(e.target.scrollTop > 0);
    syncScroll(tableContainerRef, topScrollRef);
  };

  const getResponsiveColumns = () => {
    const isMobile = window.innerWidth <= 480;
    return columns.filter(col => {
      if (isMobile) {
        return !['checkbox', 'id'].includes(col.key);
      }
      return true;
    });
  };

  return (
    <>
      <NavbarDropshipping title="Envíos Dropshipping"/>
      <div className='misPedidos'>
        <div className="filters">
          <Button 
            type="primary" 
            icon={<DownloadOutlined />}
            onClick={exportToExcel}
            className="export-button"
          >
            Exportar Excel
          </Button>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: '200px' }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Select
            style={{ width: '200px', marginRight: '10px' }}
            placeholder="Filtrar por Estatus"
            onChange={(value) => handleFilterChange('orderStatus', value)}
          >
            <Option value="">Todos</Option>
            <Option value="Creado">Creado</Option>
            <Option value="Distribucion">Distribucion</Option>
            <Option value="Entregado">Entregado</Option>
            <Option value="Rechazado">Rechazado</Option>
            <Option value="Devolucion">Devolucion</Option>
            <Option value="Anulado">Anulado</Option>
            <Option value="Otro">Otro</Option>
            <Option value="Error">Error</Option>
          </Select>
          <Select
            style={{ width: '200px', marginRight: '10px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('carrier', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
        </div>

        <div className="scroll-container" ref={topScrollRef} onScroll={() => syncScroll(topScrollRef, tableContainerRef)}>
          <div className="scroll-bar"></div>
        </div>

        <div className={`misPedidos-tabla ${isTableScrolled ? 'scrolled' : ''}`} 
             ref={tableContainerRef} 
             onScroll={handleTableScroll}>
          <Table
            columns={getResponsiveColumns()}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              size: window.innerWidth <= 768 ? 'small' : 'default',
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 'max-content' }}  // Habilitar desplazamiento horizontal
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
      <Modal
        title="Detalles del Envío"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Cerrar
          </Button>
        ]}
        width={600}
      >
        {selectedOrder && (
          <div className="order-details">
            <div className="order-section">
              <h3>Información del Producto</h3>
              <p><strong>Nombre del producto:</strong> {selectedOrder?.nombre_producto || 'N/A'}</p>
              <p><strong>Valor de venta:</strong> ${selectedOrder?.valor_venta?.toLocaleString() || '0'}</p>
              <p><strong>Valor envío:</strong> ${selectedOrder?.valor_envio || '0'}</p>
            </div>
            
            <div className="order-section">
              <h3>Información del Envío</h3>
              <p><strong>Transportadora:</strong> {selectedOrder?.transportadora || 'N/A'}</p>
              <p><strong>Estado envío:</strong> 
                <span className={getStatusBadgeClass(selectedOrder?.estado_envio)}>
                  {selectedOrder?.estado_envio || 'N/A'}
                </span>
              </p>
            </div>
            
            <div className="order-section">
              <h3>Información del Destinatario</h3>
              <p><strong>Nombre:</strong> {`${selectedOrder?.preeenvio?.destinatario?.nombre || ''} ${selectedOrder?.preeenvio?.destinatario?.primer_apellido || ''} ${selectedOrder?.preeenvio?.destinatario?.segundo_apellido || ''}`.trim() || 'N/A'}</p>
              <p><strong>Teléfono:</strong> {selectedOrder?.preeenvio?.destinatario?.telefono || 'N/A'}</p>
              <p><strong>Dirección:</strong> {selectedOrder?.preeenvio?.destinatario?.direccion || 'N/A'}</p>
              <p><strong>Correo:</strong> {selectedOrder?.preeenvio?.destinatario?.correo || 'N/A'}</p>
              <p><strong>Ciudad:</strong> {selectedOrder?.preeenvio?.destinatario?.ciudad || 'N/A'}</p>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductTablePedidos;
