import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Button, Upload, Select, Row, Col, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import NavbarMex from "../components/navbarMex";
import "./PanelUsuario.css";

const PanelUsuario = () => {
  const [form] = Form.useForm();
  const [logoUrl, setLogoUrl] = useState(null);
  const API_BASE_URL = "https://99envios.app/api/online";
  const [userData, setUserData] = useState(null);
  const [nonEditableFields, setNonEditableFields] = useState({});

  const { Option } = Select;

  useEffect(() => {
    const fetchUserData = async () => {
      const usuarioId = localStorage.getItem("usuario_id");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/sucursal/${usuarioId}`
        );
        setUserData(response.data);

        // Pre-cargar los datos del formulario con los valores obtenidos de la API
        form.setFieldsValue({
          nombre: response.data.nombre,
          apellido: response.data.apellido,
          email: response.data.email,
          telefono: response.data.telefono,
          direccion: response.data.direccion,
          tipo_identificacion: response.data.tipo_identificacion,
          num_identificacion: response.data.numero_identificacion,
          pais: response.data.id_pais || 1, // Valor por defecto para 'pais' si viene como null
          ciudad_id: response.data.id_ciudad,
          nombre_sucursal: response.data.nombre_sucursal,
        });
      } catch (error) {
        console.error("Error al cargar los datos del usuario", error);
      }
    };
    fetchUserData();
  }, [form]);

  // Esta función se ejecuta cuando el componente se monta y carga los datos desde el localStorage
  useEffect(() => {
    const usuarioId = localStorage.getItem("usuario_id");
    const email = localStorage.getItem("email");
    const rolId = localStorage.getItem("rol_id");

    // Pre-cargar el formulario con los datos del localStorage
    form.setFieldsValue({
      nombre: `Usuario ${usuarioId}`, // Puedes personalizar este valor
      email: email,
      rol: rolId,
    });
  }, [form]);

  const handleFinish = async (values) => {
    const usuarioId = localStorage.getItem("usuario_id");
    try {
      const paisValue = values.pais ? parseInt(values.pais) : null;
      const updatedData = {
        ...nonEditableFields,
        nombre: values.nombre,
        email: values.email,
        telefono: values.telefono,
        direccion: values.direccion,
        tipo_identificacion: values.tipo_identificacion,
        num_identificacion: values.num_identificacion,
        pais: paisValue,
        ciudad_id: values.ciudad_id,
        nombre_sucursal: values.nombre_sucursal,
      };

      // Mostrar los datos que se enviarán
      console.log("Datos enviados a la API:", updatedData);

      const response = await axios.put(
        `${API_BASE_URL}/sucursal/${usuarioId}`,
        updatedData
      );
      message.success("Información guardada exitosamente");
    } catch (error) {
      console.error("Error al guardar los datos del usuario", error);
      message.error("Error al guardar la información");
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Error: ", errorInfo);
    message.error("Error al guardar la información");
  };

  const handleLogoChange = (info) => {
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoUrl(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  return (
    <>
      <NavbarMex />
      <div className="panel-usuario-wrapper">
        <h2 className="panel-usuario-title">Registro de Usuario</h2>
        <div className="panel-usuario-logo-row">
          <Upload
            showUploadList={false}
            onChange={handleLogoChange}
            beforeUpload={() => false} // Para evitar la subida automática
          >
            <div className="panel-usuario-logo-upload">
              {logoUrl ? (
                <img src={logoUrl} alt="Logo" />
              ) : (
                <UploadOutlined style={{ fontSize: "32px", color: "#999" }} />
              )}
            </div>
          </Upload>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          className="panel-usuario-form"
        >
          {/* Fila 1: Nombre y Apellido */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="nombre"
                label={<span className="panel-usuario-label">Nombre</span>}
                rules={[
                  { required: true, message: "Por favor ingrese su nombre" },
                ]}
              >
                <Input className="panel-usuario-input" placeholder="Nombre" />
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="apellido"
                label={<span className="panel-usuario-label">Apellido</span>}
                rules={[
                  { required: true, message: "Por favor ingrese su apellido" },
                ]}
              >
                <Input className="panel-usuario-input" placeholder="Apellido" />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 2: Email y Teléfono */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="email"
                label={
                  <span className="panel-usuario-label">Email de Contacto</span>
                }
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Por favor ingrese un correo válido",
                  },
                  
                ]}
              >
                <Input
                  className="panel-usuario-input"
                  placeholder="Correo Electrónico"
                  disabled={true} 
                />
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="telefono"
                label={<span className="panel-usuario-label">Teléfono</span>}
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su número de teléfono",
                  },
                ]}
              >
                <Input
                  addonBefore={
                    <Select defaultValue="57">
                      <Option value="57">+57</Option>
                    </Select>
                  }
                  className="panel-usuario-input"
                  placeholder="Número de teléfono"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 3: Dirección */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={24}>
              <Form.Item
                name="direccion"
                label={<span className="panel-usuario-label">Dirección</span>}
                rules={[
                  { required: true, message: "Por favor ingrese su dirección" },
                ]}
              >
                <Input.TextArea
                  className="panel-usuario-textarea"
                  placeholder="Dirección"
                  disabled={true} 
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 4: Tipo de Identificación y Número de Identificación */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="tipo_identificacion"
                label={
                  <span className="panel-usuario-label">
                    Tipo de Identificación
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Por favor seleccione el tipo de identificación",
                  },
                ]}
              >
                <Select
                  className="panel-usuario-select"
                  placeholder="Selecciona un tipo"
                  disabled={true} 
                >
                  <Option value="CC">Cédula de Ciudadanía</Option>
                  <Option value="NIT">NIT</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="num_identificacion"
                label={
                  <span className="panel-usuario-label">
                    Número de Identificación
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el número de identificación",
                  },
                ]}
              >
                <Input
                  className="panel-usuario-input"
                  placeholder="Número de Identificación"
                  disabled={true} 
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 5: País y Ciudad - Campos Deshabilitados */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="pais"
                label={<span className="panel-usuario-label">País</span>}
                rules={[
                  { required: true, message: "Por favor seleccione un país" },
                ]}
              >
                <Input
                  className="panel-usuario-input"
                  placeholder="País"
                  disabled
                />{" "}
                {/* Campo deshabilitado */}
              </Form.Item>
            </Col>
            <Col span={12} className="panel-usuario-col">
              <Form.Item
                name="ciudad_id"
                label={<span className="panel-usuario-label">Ciudad</span>}
                rules={[
                  { required: true, message: "Por favor ingrese la ciudad" },
                ]}
              >
                <Input
                  className="panel-usuario-input"
                  placeholder="Ciudad"
                  disabled
                />{" "}
                {/* Campo deshabilitado */}
              </Form.Item>
            </Col>
          </Row>

          {/* Fila 6: Nombre de Sucursal */}
          <Row gutter={16} className="panel-usuario-row">
            <Col span={24}>
              <Form.Item
                name="nombre_sucursal"
                label={
                  <span className="panel-usuario-label">Nombre Sucursal</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el nombre de la sucursal",
                  },
                ]}
              >
                <Input
                  className="panel-usuario-input"
                  placeholder="Nombre de Sucursal"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Botón Guardar */}
          <div className="panel-usuario-button-section">
            <Button
              type="primary"
              htmlType="submit"
              className="panel-usuario-submit-button"
            >
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PanelUsuario;
