import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './pedidosExternos.css';
import moment from 'moment';

const PedidosExternos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.99envios.app/api/picking/bodega/listar-ordenes-externas/${usuario_id}`);
        const formattedData = response.data
          .filter(item => item.estado_enlistment === 0)
          .map((item, index) => ({
            key: index,
            id: item.id,
            id_variacion: item.id_variacion_inventario_bodega,
            talla: item.nombre_variacion ? item.nombre_variacion : 'Sin talla',
            color: item.nombre_variacion ? item.nombre_variacion : 'Sin color',
            nombre_producto: item.nombre_producto,
            fecha_estado: `${item.fecha_reporte} ${item.hora_reporte}`,
            estado_envio: item.estatus,
            transportadora: item.transportadora,
            valor_proveedor: parseFloat(item.precio_proveedor),
            valor_total: parseFloat(item.total_orden),
            valor_utilidad: parseFloat(item.total_orden) - parseFloat(item.precio_proveedor) - parseFloat(item.precio_flete),
            numero_guia: item.numero_preenvio,
            nombre_destinatario: item.nombre_destinatario ? item.nombre_destinatario : 'Sin destinatario',
            telefono_destinatario: item.telefonos_destinatario ? item.telefonos_destinatario : 'Sin teléfono',
            direccion_destinatario: item.direccion_destinatario ? item.direccion_destinatario : 'Sin dirección',
            peso: item.peso ? item.peso : 'N/A',
            largo: item.largo ? item.largo : 'N/A',
            ancho: item.ancho ? item.ancho : 'N/A',
            alto: item.alto ? item.alto : 'N/A',
            valor_declarado: item.valor_declarado ? item.valor_declarado : 'N/A',
            cantidad: item.cantidad,
            selected: false,
          }))
          .sort((a, b) => new Date(b.fecha_estado) - new Date(a.fecha_estado)); // Sort from latest to earliest
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Fecha de Estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
      sorter: (a, b) => a.estado_envio.localeCompare(b.estado_envio),
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
      sorter: (a, b) => a.transportadora.localeCompare(b.transportadora),
    },
    {
      title: 'Valor del Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
    {
      title: 'Número de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Destinatario',
      dataIndex: 'nombre_destinatario',
      key: 'nombre_destinatario',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono_destinatario',
      key: 'telefono_destinatario',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion_destinatario',
      key: 'direccion_destinatario',
    },
    {
      title: 'Peso',
      dataIndex: 'peso',
      key: 'peso',
    },
    {
      title: 'Dimensiones',
      dataIndex: 'dimensiones',
      key: 'dimensiones',
      render: (_, record) => {
        const { largo, ancho, alto } = record;
        return `${largo} x ${ancho} x ${alto}`;
      },
    },
    {
      title: 'Valor Declarado',
      dataIndex: 'valor_declarado',
      key: 'valor_declarado',
      render: (value) => value ? `$${value.toLocaleString()}` : 'N/A',
    },
  ];

  const formatDate = (dateString) => moment(dateString).format('DD/MM/YYYY HH:mm');

  const handleCheckboxChange = (key) => {
    const updatedData = data.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setFilteredData(updatedData);
    setSelectedItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSelectAll = () => {
    const allSelected = data.every(item => item.selected);
    const updatedData = data.map(item => ({ ...item, selected: !allSelected }));
    setData(updatedData);
    setFilteredData(updatedData);
    setSelectedItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredData(result);
  }, [searchText, data]);

  const downloadPDF = (base64String, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadCombinedPDF = async () => {
    const selectedData = data.filter(item => selectedItems.includes(item.key));
    const pdfDocs = await Promise.all(
      selectedData.map(async (item) => {
        const pdfBytes = await fetch(`data:application/pdf;base64,${item.pdf}`).then(res => res.arrayBuffer());
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfDocs) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'combined.pdf';
    a.click();
  };

  const handleStartProcess = async () => {
    const selectedData = data.filter(item => selectedItems.includes(item.key));
    const idOrdenes = selectedData.map(item => item.id);

    try {
      const response = await axios.post(`https://99envios.app/api/picking/enlistment/ordenes-enlistment-externa/${usuario_id}`, {
        id_ordenes: idOrdenes
      });
      message.success('Proceso iniciado con éxito');
      
      // Fetch new data after starting the process
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.99envios.app/api/picking/bodega/listar-ordenes-externas/${usuario_id}`);
          const formattedData = response.data
            .filter(item => item.estado_enlistment === 0)
            .map((item, index) => ({
              key: index,
              id: item.id,
              id_variacion: item.id_variacion_inventario_bodega,
              talla: item.nombre_variacion ? item.nombre_variacion : 'Sin talla',
              color: item.nombre_variacion ? item.nombre_variacion : 'Sin color',
              nombre_producto: item.nombre_producto,
              fecha_estado: `${item.fecha_reporte} ${item.hora_reporte}`,
              estado_envio: item.estatus,
              transportadora: item.transportadora,
              valor_proveedor: parseFloat(item.precio_proveedor),
              valor_total: parseFloat(item.total_orden),
              valor_utilidad: parseFloat(item.total_orden) - parseFloat(item.precio_proveedor) - parseFloat(item.precio_flete),
              numero_guia: item.numero_preenvio,
              nombre_destinatario: item.nombre_destinatario ? item.nombre_destinatario : 'Sin destinatario',
              telefono_destinatario: item.telefonos_destinatario ? item.telefonos_destinatario : 'Sin teléfono',
              direccion_destinatario: item.direccion_destinatario ? item.direccion_destinatario : 'Sin dirección',
              peso: item.peso ? item.peso : 'N/A',
              largo: item.largo ? item.largo : 'N/A',
              ancho: item.ancho ? item.ancho : 'N/A',
              alto: item.alto ? item.alto : 'N/A',
              valor_declarado: item.valor_declarado ? item.valor_declarado : 'N/A',
              cantidad: item.cantidad,
              selected: false,
            }))
            .sort((a, b) => new Date(b.fecha_estado) - new Date(a.fecha_estado)); // Sort from latest to earliest
          setData(formattedData);
          setFilteredData(formattedData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } catch (error) {
      message.error('Error al iniciar el proceso');
      console.error('Error al iniciar el proceso:', error);
    }
  };

  return (
    <>
      <NavbarUsuarioBodega title="Pedidos Externos" />
      <div className='misPedidos-picking'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Button onClick={handleSelectAll}>
            {data.every(item => item.selected) ? 'Deseleccionar Todos' : 'Seleccionar Todos'}
          </Button>
          <Button onClick={handleStartProcess}>
            Iniciar Proceso
          </Button>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="misPedidos-picking-tabla" style={{ overflowX: 'auto' }}>
          <h2>Pedidos</h2>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
    </>
  );
};

export default PedidosExternos;
