import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Alert, Card, Switch, message } from 'antd';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './SuscriptorForm.css';

const SubscriberForm = () => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const id_user = localStorage.getItem('usuario_id');
      if (!id_user) {
        console.error('codigoSucursal no encontrado en localStorage');
        return;
      }

      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal/${id_user}`);
        if (response.status === 200) {
          const { estatus_suscripccion } = response.data;
          const isActive = estatus_suscripccion === 1;
          setIsActive(isActive);
          localStorage.setItem('recoleccionActive', isActive.toString());
        }
      } catch (error) {
        console.error('Error al recuperar el estado de suscripción', error);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const handleSwitchChange = async (checked) => {
    const id_user = localStorage.getItem('usuario_id');
    if (!id_user) {
      console.error('id_user no encontrado en localStorage');
      return;
    }

    const url = checked
      ? `https://99envios.app/api/picking/bodega/activar-suscripccion/${id_user}`
      : `https://99envios.app/api/picking/bodega/desactivar-suscripccion/${id_user}`;

    try {
      const response = await axios.post(url, {
        recoleccion_activa: checked
      });
      
      if (response.status === 200) {
        // Update both state and localStorage
        setIsActive(checked);
        localStorage.setItem('recoleccionActive', checked.toString());
        if (checked) {
          message.success('Suscripccion activada exitosamente.');
        } else {
          message.success('Suscripccion desactivada exitosamente.');
        }
      }
    } catch (error) {
      console.error('Error al activar/desactivar la recolección', error);
      // Revert the state and localStorage if there's an error
      setIsActive(!checked);
      localStorage.setItem('recoleccionActive', (!checked).toString());
      setErrorMessage('Error al actualizar el estado de suscripción.');
    }
  };

  return (
    <>
      <NavbarPicking title="Registro de Suscriptor" />
      <div className="subscriber-form-container">
        <Card title="FORMULARIO DE REGISTRO DE SUSCRIPTOR" className="subscriber-form-card">
          <Form
            form={form}
            name="subscriber"
            layout="vertical"
            autoComplete="off"
          >
            <Alert
              message={
                <>
                  Este botón permite activar o desactivar la suscripción en la bodega.
                </>
              }
              type="info"
              showIcon
              style={{
                marginBottom: "10px",
                marginTop: "-10px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            <Form.Item>
            
            <Switch
              checked={isActive}
              onChange={handleSwitchChange}
              checkedChildren="Encendido"
              unCheckedChildren="Apagado"
              style={{
                backgroundColor: isActive ? '#52c41a' : '#f5222d',
              }}
            />
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default SubscriberForm;