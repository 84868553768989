import React, { useState, useEffect } from 'react';
import { Table, Input, message, Spin, Select, Col } from 'antd';
import axios from 'axios';
import './enviosEntrantes.css';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';

const { Option } = Select;

const EnviosEntrantes = () => {
    const [productos, setProductos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const filteredProducts = productos.filter((product) => {
        const matchesSearch = (
            (product.nombre_producto && product.nombre_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.descripcion_producto && product.descripcion_producto.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.sku && product.sku.toLowerCase().includes(searchText.toLowerCase()))
        );
    
        const matchesCategory = selectedCategory === '' || product.id_categoria === Number(selectedCategory);
    
        return matchesSearch && matchesCategory;
    });

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const url = `https://99envios.app/api/picking/bodega/listar-ordenes-bodega/${userId}`;
            try {
                const { data } = await axios.get(url);
                setProductos(data);
            } catch (error) {
                message.error('Error al cargar productos');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const columns = [
        { title: 'ID', dataIndex: ['preeenvio', 'id'], key: 'id' },
        { title: 'Nombre Producto', dataIndex: 'nombre_producto', key: 'nombre_producto' },
        { title: 'Valor Real', dataIndex: 'valor_real', key: 'valor_real' },
        { title: 'Valor Envío', dataIndex: 'valor_envio', key: 'valor_envio' },
        { title: 'Transportadora', dataIndex: 'transportadora', key: 'transportadora' },
        { title: 'Estado Envío', dataIndex: 'estado_envio', key: 'estado_envio' },
        { title: 'Fecha Estado', dataIndex: 'fecha_estado', key: 'fecha_estado' },
    ];

    if (isLoading) return <Spin size="large" center />;

    return (
        <>
            <NavbarUsuarioBodega title="Envíos Entrantes" />
            <div className="EEB-table-container" style={{ fontSize: "13px" }}>
                <Col>
                    <Input
                        placeholder="Buscar producto"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Select
                        placeholder="Filtrar por categoría"
                        value={selectedCategory}
                        onChange={(value) => setSelectedCategory(value)}
                        style={{ width: 200 }}
                    >
                        <Option value="">Todas las categorías</Option>
                        <Option value="1">Bisuteria</Option>
                        <Option value="2">Ropa Deportiva</Option>
                        <Option value="3">Vaporizadores</Option>
                        <Option value="4">Mascotas</Option>
                        <Option value="5">Moda</Option>
                        <Option value="6">Tecnologia</Option>
                        <Option value="7">Cocina</Option>
                        <Option value="8">Belleza</Option>
                        <Option value="9">Salud</Option>
                        <Option value="10">Hogar</Option>
                        <Option value="11">Natural Home</Option>
                        <Option value="12">Deportes</Option>
                        <Option value="13">Sex Shop</Option>
                        <Option value="14">Bebé</Option>
                        <Option value="15">Aseo</Option>
                        <Option value="16">Bienestar</Option>
                        <Option value="17">Camping</Option>
                        <Option value="18">Pesca</Option>
                        <Option value="19">Defensa Personal</Option>
                        <Option value="20">Vehiculos</Option>
                        <Option value="21">Jugueteria</Option>
                        <Option value="22">Otros</Option>
                    </Select>
                </Col>
                <Table 
                    dataSource={filteredProducts} 
                    columns={columns} 
                    rowKey="id_producto" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                />
            </div>
        </>
    );
};

export default EnviosEntrantes;