import React from 'react';
import axios from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Estilos/pago.css'; // Asegúrate de importar el archivo CSS
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import { message } from 'antd';

/**
 * Componente principal para gestionar el pago mixto de un pedido específico.
 * @component
 */
function PagoMixto() {
  const [paymentProofImage, setPaymentProofImage] = useState(null);
  const [deliveryProofImage, setDeliveryProofImage] = useState(null);
  const [efectivo, setEfectivo] = useState(null);
  const [virtual, setVirtual] = useState(null);
  const navigate = useNavigate(); // Hook para usar la navegación
  const pedidoId = useParams(); // Hook para capturar el ID del pedido desde la URL
  const [orderInfo, setOrderInfo] = useState(null);
  const [sumTotal, setSumTotal] = useState(0);
  const id = pedidoId.id;

  /**
   * Obtiene la información del pedido desde la API.
   * @function
   */
  const fetchOrderInfo = useCallback(async () => {
    try {
      console.log('Fetching order info for:', pedidoId);
      const idPedido = pedidoId.id;
      console.log('idPedido:', idPedido);
      const url = `https://api.99envios.app/api/pedidos/${idPedido}`;
      const response = await axios.get(url);
      console.log('GET Response:', response.data);
      setOrderInfo(response.data);
    } catch (error) {
      console.error('Error fetching order info:', error);
    }
  }, [pedidoId]);

  /**
   * Hook de efecto para obtener la información del pedido cuando el ID del pedido cambia.
   */
  useEffect(() => {
    if (pedidoId) {
      fetchOrderInfo();
    }
  }, [pedidoId, fetchOrderInfo]);

  /**
   * Maneja el cambio de la imagen del comprobante de pago.
   * @param {Event} e - Evento de cambio del input de archivo.
   */
  const handlePaymentProofImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split('/')[0];
      if (fileType !== 'image') {
        message.error('Solo se permiten archivos de imagen.');
        return;
      }
      setPaymentProofImage(file);
    }
  };

  /**
   * Maneja el cambio de la imagen del comprobante de entrega.
   * @param {Event} e - Evento de cambio del input de archivo.
   */
  const handleDeliveryProofImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split('/')[0];
      if (fileType !== 'image') {
        message.error('Solo se permiten archivos de imagen.');
        return;
      }
      setDeliveryProofImage(file);
    }
  };

  /**
   * Marca el pedido como entregado.
   * @function
   */
  const markAsDelivered = async () => {
    try {
      const response = await axios.put(`https://api.99envios.app/api/pedidos/${id}/actualizar-a-entregado`);
      if (response.status === 200) {
        message.success('Pedido actualizado a entregado');
        fetchOrderInfo(); // Refrescar detalles del pedido
      }
    } catch (error) {
      console.error('Error updating order to delivered:', error);
    }
  };

  const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99))
    : null;

  /**
   * Envía la imagen del comprobante de pago a la API.
   * @function
   */
  const sendPaymentProofImage = async () => {
    const pagoFinal = parseFloat(efectivo) + parseFloat(virtual);
    if (pagoFinal !== total) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El pago no coincide con el total del pedido.',
      });
      return;
    }

    const formData = new FormData();
    formData.append('foto_comprobante_efectivo', paymentProofImage);
    formData.append('foto_comprobante_virtual', deliveryProofImage);
    formData.append('ID_pedido', id);
    formData.append('estado_pago', 'Entregado');
    formData.append('pago_efectivo', efectivo);
    formData.append('pago_digital', virtual);
    console.log('Efectivo', efectivo);
    console.log('Virtual', virtual);

    try {
      console.log('Sending payment proof image:', formData);
      const response = await axios.post(`https://api.99envios.app/api/pago_digital`, formData);
      if (response.status === 200) {
        message.success('Imagen de comprobante de pago enviada correctamente');
        navigate('/conductor/entrega-check');
        return true;
      } else {
        throw new Error('Error en la respuesta de la API');
      }
    } catch (error) {
      message.error('Error al enviar la imagen del comprobante de pago');
      console.error('Error sending payment proof image:', error);
      return false;
    }
  };

  /**
   * Maneja el clic en el botón de detalles.
   * @function
   */
  const handleDetailsClick = async () => {
    if (!paymentProofImage || !deliveryProofImage || efectivo === null || virtual === null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, asegúrate de llenar todos los campos y seleccionar todas las imágenes antes de enviar.',
      });
      return;
    }

    try {
      const paymentProofResult = await sendPaymentProofImage();
      if (paymentProofResult) {
        const markAsDeliveredResult = await markAsDelivered();
        if (markAsDeliveredResult) {
          navigate('/conductor/entrega-check');
        }
      }
    } catch (error) {
      console.error('Error updating order to delivered:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al actualizar el pedido y enviar el comprobante de pago.',
      });
    }
  };

  if (!orderInfo) {
    return <div>Cargando información del pedido...</div>;
  }

  return (
    <div>
      <Navbar title={"Pago mixto"} />
      <div>
        <div className='head'>
          <Head title={"Proceso de Pago"} />
        </div>
        <div className="contenedor">
          <div className="information">
            <p>Fecha: {orderInfo.fecha_pedido} </p>
            <p>Estado: {orderInfo.estado_pedido}</p>
            <p className="net-total"><span>Total a cobrar:</span> {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
          </div>

          <div className="section">
            <h3>Comprobante de entrega</h3>
            <p>Anexa una fotografía de la entrega del producto</p>
            <input type="file" onChange={handlePaymentProofImageChange} accept="image/*" />
            {paymentProofImage && <img src={URL.createObjectURL(paymentProofImage)} alt="Vista previa" className="image-preview" />}
          </div>

          <div className="section">
            <h3>Comprobante pago virtual</h3>
            <p>Anexa una captura o fotografía de la transacción virtual</p>
            <input type="file" onChange={handleDeliveryProofImageChange} accept="image/*" />
            {deliveryProofImage && <img src={URL.createObjectURL(deliveryProofImage)} alt="Vista previa" className="image-preview" />}
          </div>

          <div className="section">
            <h3>Pago digital</h3>
            <p>Cantidad recibida</p>
            <input type="number" onChange={(e) => setVirtual(e.target.value)} />
          </div>
          <div className="section">
            <h3>Pago en efectivo</h3>
            <p>Cantidad recibida</p>
            <input type="number" onChange={(e) => setEfectivo(e.target.value)} />
          </div>
          <button onClick={handleDetailsClick} className="submit-button">
            Enviar
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PagoMixto;
