import React from 'react';
import { Menu, Dropdown, Button, Space, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';



const { RangePicker } = DatePicker; // Asegúrate de que esta línea esté incluida




const TableToolbar = ({ onExport, onFilter, onDateChange }) => {
  // Función para manejar la selección en el menú de acciones en lote
  const handleActionMenuClick = (e) => {
    // Aquí iría tu lógica cuando alguien selecciona una acción en el menú
    console.log(`Acción en lote seleccionada: ${e.key}`);
  };

  

  
  



  // Menús desplegables
  const menuAccionesEnLote = (
    <Menu onClick={handleActionMenuClick}>
      <Menu.Item key="exportarXLSX">Exportar como XLSX</Menu.Item>
      <Menu.Item key="marcarExportados">Marcar exportados</Menu.Item>
      {/* más opciones de acciones en lote */}
    </Menu>
  );

  

  // Se omiten los menús adicionales por brevedad

  return (
    <div className="table-toolbar"  >
      <Space wrap style={{maxHeight:"2000px"}}> 
        
        {/* Botones adicionales, como 'Todas las fechas', se podrían agregar aquí */}
        
        {/* Otros botones o desplegables según sea necesario */}
        
      </Space>
    </div>
  );
};

export default TableToolbar;
