import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './recoleccionModal.css';

const RecoleccionModal = ({ initialNumerosPreenvios = [], selectedCarrier, onClose }) => {
  const [formData, setFormData] = useState({
    numerosPreenvios: initialNumerosPreenvios.length > 0 ? initialNumerosPreenvios.map(Number) : [''],
    fecha_recogida: '',
    hora_recogida: '',
    transportadora: {
      pais: 'colombia',
      nombre: selectedCarrier
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [idsucursal, setIdsucursal] = useState('');

  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
        setError('Error al obtener el ID de la sucursal');
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'transportadoraNombre') {
      setFormData({
        ...formData,
        transportadora: { ...formData.transportadora, nombre: value }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePreenvioChange = (index, value) => {
    const newNumerosPreenvios = [...formData.numerosPreenvios];
    newNumerosPreenvios[index] = Number(value);
    setFormData({ ...formData, numerosPreenvios: newNumerosPreenvios });
  };

  const addPreenvioField = () => {
    setFormData({
      ...formData,
      numerosPreenvios: [...formData.numerosPreenvios, '']
    });
  };

  const removePreenvioField = (index) => {
    const newNumerosPreenvios = formData.numerosPreenvios.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      numerosPreenvios: newNumerosPreenvios
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    const listaNumPreenvios = formData.numerosPreenvios.filter(num => num !== '');

    try {
      if (!idsucursal) {
        throw new Error('ID de sucursal no disponible');
      }

      const response = await fetch(`https://integration.99envios.app/api/sucursal/recogidas/${idsucursal}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          listaNumPreenvios: listaNumPreenvios,
          fecha_recogida: formData.fecha_recogida,
          hora_recogida: formData.hora_recogida,
          transportadora: formData.transportadora
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.errors && data.errors.fecha_hora) {
          setError(data.errors.fecha_hora[0]);
        } else if (data.errors && Array.isArray(data.errors) && data.errors.length > 0) {
          const errorMessage = data.errors[0][0];
          const actualErrorMessage = errorMessage.replace('$numPreenvio', formData.numerosPreenvios[0]);
          setError(actualErrorMessage);
        } else {
          setError('El numero de preenvio no esta asociado a esta sucursal o transportadora.');
        }
      } else {
        setSuccess(true);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } catch (err) {
      setError('El numero de preenvio no esta asociado a esta sucursal o transportadora.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay-recoleccion">
      <div className="modal-content-recoleccion">
        <button className="modal-close-recoleccion" onClick={onClose}>&times;</button>
        <h2>Solicitud de Recolección</h2>
        <form className="formulario" onSubmit={handleSubmit}>
          <label>Lista de Números Preenvíos:</label>
          <div>
            {formData.numerosPreenvios.map((num, index) => (
              <div key={index} className="preenvio-input">
                <label>
                  Número de Preenvío {index + 1}:
                  <div className="input-group">
                    <input
                      type="text"
                      value={num}
                      onChange={(e) => handlePreenvioChange(index, e.target.value)}
                      required
                    />
                    {index > 0 && (
                      <button type="button" onClick={() => removePreenvioField(index)} className="delete-button">
                        X
                      </button>
                    )}
                  </div>
                </label>
              </div>
            ))}
          </div>
          <label>
            Fecha de Recolección:
            <input type="date" name="fecha_recogida" value={formData.fecha_recogida} onChange={handleChange} required />
          </label>
          <label>
            Hora de Recolección:
            <input type="time" name="hora_recogida" value={formData.hora_recogida} onChange={handleChange} required />
          </label>
          <label>
            Transportadora:
            <input
              type="text"
              name="transportadoraNombre"
              value={formData.transportadora.nombre}
              readOnly
            />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">Datos enviados con éxito</p>}
        </form>
      </div>
    </div>
  );
};

export default RecoleccionModal;