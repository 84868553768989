import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faUser, faMapMarkerAlt, faCalendar, faCircle } from '@fortawesome/free-solid-svg-icons';
import './Estilos/EsperaStyle.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import Carga from '../../../components/carga';
import axios from 'axios';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente funcional que gestiona la lista de pedidos en espera para un conductor.
 * Incluye paginación, visualización de datos y navegación a detalles de un pedido.
 *
 * @function
 * @returns {JSX.Element} Vista que muestra los pedidos en espera.
 */
function Espera() {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [ordersPerPage] = useState(10);

    let navigate = useNavigate();

    /**
     * Función asíncrona que obtiene los pedidos desde la API y actualiza el estado.
     * Maneja la carga inicial y muestra errores si ocurren al hacer la solicitud.
     *
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        const usuario_id = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/reporte-pedidos-espera/${usuario_id}`;
        try {
            const response = await axios.get(url);
            setOrders(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
        }
    }, []);

    /**
     * Hook que se ejecuta cuando el componente se monta y llama a fetchOrders
     * para recuperar la lista de pedidos actual.
     *
     * @returns {void}
     */
    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    /**
     * Da formato a una cadena de fecha para mostrarla en español con día, mes, año, hora y minutos.
     *
     * @function
     * @param {string} dateString Cadena de fecha a formatear.
     * @returns {string} Fecha formateada en español.
     */
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    // Calcula los índices de los pedidos que deben mostrarse en la página actual.
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

    // Cambia la página actual al número de página seleccionado.
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Genera los números de las páginas para la paginación.
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(orders.length / ordersPerPage); i++) {
        pageNumbers.push(i);
    }

    /**
     * Maneja el evento de clic para mostrar los detalles de un pedido específico.
     *
     * @function
     * @param {number} orderId ID del pedido.
     * @returns {void}
     */
    function handleDetailsClick(orderId) {
        navigate(`/conductor/pedidos/${orderId}`);
    }

    return (
        <div>
            <Navbar title={"En espera"} />
            <div className="app-container">
                <Head title={"En espera"} />
                <p className="instructions">
                    <b>Pedidos pendientes de entrega</b>
                    <br />
                    Seleccione un pedido para ver más detalles o comenzar la entrega
                </p>
                {isLoading ? (
                    <div className="loading-container">
                        <Carga />
                    </div>
                ) : (
                    <div className="orden">
                        <div className="orders-list">
                            {currentOrders.map((order) => (
                                <div key={order.ID_pedido} className="order-card">
                                    <div className="order-details">
                                        <h2>
                                            <FontAwesomeIcon icon={faBox} style={{marginRight: '10px'}} />
                                            Pedido #{order.ID_pedido}
                                        </h2>
                                        <p>
                                            <FontAwesomeIcon icon={faUser} style={{marginRight: '10px'}} />
                                            {order.nombre_cliente}
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: '10px'}} />
                                            {order.direccion}
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faCalendar} style={{marginRight: '10px'}} />
                                            {formatDate(order.fecha_pedido)}
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faCircle} style={{marginRight: '10px', color: '#7380ec'}} />
                                            {order.estado_pedido}
                                        </p>
                                    </div>
                                    <button className="details-button" onClick={() => handleDetailsClick(order.ID_pedido)}>
                                        Ver detalles
                                    </button>
                                </div>
                            ))}
                        </div>
                        
                        <div className="pagination">
                            {pageNumbers.map(number => (
                                <button 
                                    key={number} 
                                    onClick={() => paginate(number)} 
                                    className={currentPage === number ? 'active' : ''}
                                >
                                    {number}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
};

export default Espera;