import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import './Estilos/detalles.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente principal para gestionar los detalles de un pedido específico.
 * @component
 */
function Detalles() {

    const { id } = useParams(); // Captura el ID del pedido desde la URL

    const [orderInfo, setOrderInfo] = useState(null); // Estado para almacenar la información del pedido
    let navigate = useNavigate();

    /**
     * Obtiene los detalles del pedido desde la API.
     * @function
     */
    const fetchOrderDetails = useCallback(async () => {
        try {
            const response = await axios.get(`https://api.99envios.app/api/pedidos/${id}`);
            setOrderInfo(response.data);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    }, [id]);

    /**
     * Hook de efecto para obtener los detalles del pedido cuando el ID del pedido cambia.
     */
    useEffect(() => {
        fetchOrderDetails();
    }, [fetchOrderDetails]);

    /**
     * Maneja la acción de llamar al cliente.
     * @param {string} phoneNumber - Número de teléfono del cliente.
     */
    const handleCall = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    /**
     * Maneja la acción de buscar la dirección en Google Maps.
     * @param {string} address - Dirección del pedido.
     */
    const handleSearchAddress = (address) => {
        if (address) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(address)}`, '_blank');
        }
    };

    /**
     * Maneja la acción de iniciar la entrega del pedido.
     */
    const handleStartDelivery = () => {
        // Asegúrate de que orderInfo y orderInfo.ID_pedido están disponibles
        if(id) {
            message.success('Iniciando entrega...');
            navigate(`/conductor/iniciar-entrega/${id}?refresh=true`);
            console.log('Iniciando entrega para el pedido:', id);
        } else {
            console.error("Información del pedido no disponible.");
        }
    };

    /**
     * Cancela el pedido y actualiza su estado a "devuelto".
     * @function
     */
    const cancelOrder = async () => {
        try {
            const response = await axios.put(`https://api.99envios.app/api/pedidos/${id}/actualizar-a-devuelto`);
            if (response.status === 200) {
                message.success('Pedido cancelado correctamente');
                navigate('/conductor/entrega-fail?refresh=true');
            }
        } catch (error) {
            console.error('Error canceling order:', error);
        }
    }


    

    if (!orderInfo) {
        console.log('no llega')
        console.log('id:', id);
        console.log('orderInfo:', orderInfo);
        return <div>No se encontro el pedido</div>;
    }
    return (
        <div>
            <Navbar title={"Detalles"} />

            <div className="order-summary">
                <Head title={`Pedido #${orderInfo.ID_pedido || ''}`} />
                {/* Información del pedido */}
                <div className="card order-info">
                    <h2>Información</h2>
                    <p>Fecha: {orderInfo.fecha_pedido || 'No disponible'}</p>
                    <p>Estado: {orderInfo.estado_pedido || 'No disponible'}</p>
                    <p>Método de pago: {orderInfo.metodo_pago || 'No disponible'}</p>
                    <p>Total: {orderInfo.costo_envio || 'No disponible'}</p>
                </div>

                {/* Información de envío */}
                <div className="card shipping-info">
                <h2>Dirección de Envio</h2>
                    <p>Direccion de entrega: {orderInfo?.direccion_entrega || 'Cargando...'}</p>
                    <p>Ciudad: {orderInfo?.ciudad || 'Cargando...'}</p>
                    <p>País: {orderInfo?.pais || 'Cargando'}</p>
                    <p>Codigo de ciudad: {orderInfo?.codigo_ciudad || 'Cargando...'} </p>
                    <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                    <button onClick={() => handleCall("31854522456")} className="navigate-button">Llamar</button>
                </div>


                {/* Información de recogida */}
                <div className="card shipping-info">
                    <h2>Dirección de Recogida</h2>
                    <p>Id cliente: {orderInfo.id_cliente}</p>
                    <p>Dirección de revogida:{orderInfo.direccion_recogida}</p>
                    <p>Ciudad:{orderInfo.ciudad}</p>
                    {/* <p>{orderInfo.dropin[0].phone}</p> */}

                    {/* Agrega los botones aquí, aplicando las clases de estilo */}
                    <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                    <button onClick={() => handleCall("31854522456")} className="navigate-button">Llamar</button>
                </div>


                {/* Detalles del producto */}
                <div className="card product-list">
                    <h2>Productos</h2>
                    <p>Descripción: {orderInfo.detalles_producto || 'No disponible'}</p>
                    <p>Valor: {orderInfo.valor_producto ? `${parseFloat(orderInfo.valor_producto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}` : '$0'}</p>
                </div>

                {/* Resumen total */}
                <div className="card total-summary">
                    <p><span>Total:</span> <span>{orderInfo.costo_envio}</span></p>
                    <p className="red-text"><span>Valor Producto:</span> <span>-{orderInfo.valor_producto}</span></p>
                    <p className="net-total"><span>Total Neto:</span> <span>{orderInfo.netTotal}</span></p>
                </div>

                {/* Botones de acción */}
                <div className="delivery-person-buttons">
                    <button onClick={handleStartDelivery} className="button deliver-button">Iniciar Entrega</button>
                    <button onClick={cancelOrder} className="button cancel-button">Cancelar</button>
                    
                </div>



            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
}

export default Detalles;