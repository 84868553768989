import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './NavbarPicking.css';

const NavbarPicking = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState('');

  const menuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  
  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }

    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      const mobileView = window.innerWidth < 768;
      setIsMobile(mobileView);

      // Si el ancho supera los 768px (vista normal), abrimos el menú automáticamente
      if (!mobileView) {
        setMenuOpen(true);
      }
    };

    // Detectar clic fuera de la barra de navegación solo en pantallas móviles
    const handleClickOutside = (event) => {
      if (isMobile && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Añadir el evento de clic y redimensionamiento de la ventana
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    // Limpiar eventos al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };


  const rol_id = localStorage.getItem('rol_id');


  useEffect(() => {
    const fetchSucursal = async () => {
      const userId = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Asumiendo que necesitas el token para la autorización

      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener la sucursal');
        }
        const data = await response.json();
        setNombreSucursal(data.nombre_sucursal);
      } catch (error) {
        console.error('Error al obtener la sucursal:', error);
      }
    };

    fetchSucursal();
  }, []);


  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-tittle">
          <p>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, {nombreSucursal}</p>
          <NavLink to="/panel-usuario" className="panel-link">
            <p className="panel-link">99Panel</p>
          </NavLink>
          <div className="user-avatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`} ref={menuRef}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
            <li>
              <NavLink to="/dashboardMex" activeClassName="active">
                <span className="material-icons">dashboard</span>Volver al Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={`/picking/registro-suscriptor`} activeClassName="active">
                <span className="material-icons">how_to_reg</span>Registro Suscriptor
              </NavLink>
            </li>
            <li>
              <NavLink to="/picking/formulario-bodega" activeClassName="active">
                <span className="material-icons">article</span>Formulario Bodega
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/picking/gestion-bodegas" activeClassName="active">
                <span className="material-icons">store</span>Gestión Bodegas
              </NavLink>
            </li> */}

            {/* <Route path="/picking/envios-entrantes" element={<GestionInventarioBodega />} /> */}
            <li>
              <NavLink to="/picking/gestion-inventario-bodega" activeClassName="active">
                <span className="material-icons">inbox</span>Gestión Inventario Bodega
              </NavLink>
            </li>
            <li>
              <NavLink to="/picking/translado-envio-inventarios" activeClassName="active">
                <span className="material-icons">local_shipping</span>Translado y Envío de Inventarios
              </NavLink>
            </li>
            <li>
              <NavLink to="/picking/inventarios-bodega" activeClassName="active">
                <span className="material-icons">inventory</span>Inventario Bodega
              </NavLink>
            </li>
            {rol_id === '1' && (
              <li>
                <NavLink exact to="/dashboardAdmin" activeClassName="active">
                  <span className="material-icons">admin_panel_settings</span>Dashboard Admin
                </NavLink>
              </li>
            )}
            {/* inventarioBodega */}
            <li>
              {/* /picking/ordenes-picking */}
              <NavLink to="/picking/ordenes-picking" activeClassName="active">
                <span className="material-icons">assignment</span>Órdenes Picking
              </NavLink>
            </li>
            {/* /picking/devolucion-bodega */}
            {rol_id === '8' && (
              <li>
                <NavLink to="/picking/devolucion-bodega" activeClassName="active">
                  <span className="material-icons">reply</span>Devolución Bodega
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to="/" onClick={handleLogout} activeClassName="active">
                <span className="material-icons">exit_to_app</span>Cerrar sesión
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavbarPicking;