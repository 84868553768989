import React, { useState, useEffect } from 'react';
import { Form, Select, Button, message, Table, Input, Modal } from 'antd';
import Ciudades from '../../ciudades/ciudades';
import axios from 'axios';
import './SendClientBulkShop.css';

const { Option } = Select;

const SendClientBulkPage = ({ selectedOrders }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [carriers, setCarriers] = useState(['coordinadora', 'interrapidisimo', 'servientrega', 'tcc']);
    const [ordersData, setOrdersData] = useState([]);
    const [cotizaciones, setCotizaciones] = useState({});
    const [selectedCarriers, setSelectedCarriers] = useState({});
    const [loadingCotizar, setLoadingCotizar] = useState(false);
    const codigoSucursal = localStorage.getItem('codigoSucursal');
    const [numeroPreenvio, setNumeroPreenvio] = useState({});
    const [generarGuiaDisabled, setGenerarGuiaDisabled] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [errorSummary, setErrorSummary] = useState({
        success: [],
        errors: [],
        pdfUrls: {}
    });

    useEffect(() => {
        const fetchBulkOrderData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://integration.99envios.app/api/ordersShopify/${codigoSucursal}`);
                const fetchedData = response.data;

                const updatedOrders = selectedOrders.map(order => {
                    const orderData = fetchedData.find(data => data.order_id === order.order_id);
                    return {
                        ...order,
                        ...orderData,
                        first_name: orderData?.customer_first_name || '',
                        last_name: orderData?.customer_last_name || '',
                        city: null, // Remove default city value
                        address: orderData?.shipping_address || '',
                        phone: orderData?.customer_phone || '',
                        email: orderData?.customer_email || '',
                        product_weight: orderData?.product_weight || '0.00' // Changed from weight to product_weight
                    };
                });

                setOrdersData(updatedOrders);
                message.success('Datos de todas las órdenes recuperados exitosamente.');
            } catch (error) {
                console.error('Error al consumir la API masivamente:', error);
                message.error('Hubo un problema al recuperar los datos de las órdenes.');
            } finally {
                setLoading(false);
            }
        };

        if (selectedOrders.length > 0) {
            fetchBulkOrderData();
        }
    }, [selectedOrders]);

    const handleInputChange = (orderId, field, value) => {
        const updatedOrders = ordersData.map(order => {
            if (order.order_id === orderId) {
                return { ...order, [field]: value };
            }
            return order;
        });
        setOrdersData(updatedOrders);
    };

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
            className: 'bulk-shop-col-id',
            fixed: 'left',
        },
        {
            title: 'Número de Orden',
            dataIndex: 'order_number',
            key: 'order_number',
            className: 'bulk-shop-col-order',
            fixed: 'left',
        },
        {
            title: 'Producto',
            dataIndex: 'product_title',
            key: 'product_title',
        },
        {
            title: 'Precio Total',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (price) => `$${parseFloat(price).toLocaleString()}`,
        },
        {
            title: 'Estado Financiero',
            dataIndex: 'financial_status',
            key: 'financial_status',
        },
        {
            title: 'Estado de Envío',
            dataIndex: 'fulfillment_status',
            key: 'fulfillment_status',
        },
        {
            title: 'Cliente',
            key: 'cliente',
            render: (_, record) => (
                <div className="client-inputs">
                    <Input
                        className="first-name-input"
                        value={record.first_name}
                        onChange={(e) => handleNameChange(record.order_id, 'first_name', e.target.value)}
                        placeholder="Nombre"
                        required
                    />
                    <Input
                        className="last-name-input"
                        value={record.last_name}
                        onChange={(e) => handleNameChange(record.order_id, 'last_name', e.target.value)}
                        placeholder="Apellido"
                        required
                    />
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'customer_email',
            key: 'customer_email',
        },
        {
            title: 'Teléfono',
            dataIndex: 'customer_phone',
            key: 'customer_phone',
        },
        {
            title: 'Ciudad de Envío',
            dataIndex: 'shipping_city',
            key: 'shipping_city',
        },
        {
            title: 'Ciudad',
            key: 'ciudad',
            render: (_, record) => (
                <Select
                    showSearch
                    className="bulk-shop-select-city"
                    value={record.city}
                    onChange={(value) => handleCityChange(record.order_id, value)}
                    placeholder="Buscar ciudad"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {Ciudades.map((ciudad) => (
                        <Option key={ciudad.value} value={ciudad.value}>
                            {ciudad.label}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Cotizaciones',
            key: 'cotizaciones',
            render: (_, record) => (
                <div>
                    {carriers.map(carrier => (
                        <div key={carrier}>
                            <strong>{carrier}:</strong> {cotizaciones[record.order_id]?.[carrier]?.valor || 'N/A'}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Transportadora',
            key: 'transportadora',
            render: (_, record) => (
                <Select
                    className="bulk-shop-select-carrier"
                    value={selectedCarriers[record.order_id]}
                    onChange={(value) => handleCarrierChange(record.order_id, value)}
                >
                    {carriers.map(carrier => (
                        <Option key={carrier} value={carrier}>
                            {carrier}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Cantidad',
            key: 'cantidad',
            render: (_, record) => (
                <Input
                    value={record.product_quantity}
                    onChange={(e) => handleInputChange(record.order_id, 'product_quantity', e.target.value)}
                    placeholder="Cantidad"
                    required
                />
            ),
        },
        {
            title: 'Observaciones',
            key: 'observaciones',
            render: (_, record) => (
                <Input
                    value={record.notes}
                    onChange={(e) => handleInputChange(record.order_id, 'notes', e.target.value)}
                    placeholder="Observaciones"
                    required
                />
            ),
        },
        {
            title: 'Peso Producto',
            key: 'pesoProducto',
            render: (_, record) => (
                <Input
                    type="number"
                    step="0.01"
                    value={record.product_weight}
                    onChange={(e) => handleInputChange(record.order_id, 'product_weight', e.target.value)}
                    placeholder="Peso en kg"
                    required
                    style={{ width: '100px' }}
                />
            )
        },
    ].filter(col => col.key !== 'error'); // Remove error column

    const handleNameChange = (orderId, field, value) => {
        const updatedOrders = ordersData.map(order => {
            if (order.order_id === orderId) {
                return { ...order, [field]: value };
            }
            return order;
        });
        setOrdersData(updatedOrders);
    };

    const handleCityChange = (orderId, value) => {
        const updatedOrders = ordersData.map(order => {
            if (order.order_id === orderId) {
                return { ...order, city: value };
            }
            return order;
        });
        setOrdersData(updatedOrders);
    };

    const handleQuotation = async (order) => {
        setLoadingCotizar(true);
        try {
            if (!order.city || !Number(order.product_price) || !Number(order.product_quantity)) {
                message.error(`Complete todos los campos para la orden ${order.order_number}`);
                return;
            }

            const salePrice = Number(order.product_price);
            const quantity = Number(order.product_quantity);
            const subtotal = salePrice * quantity;
            const pesoTotal = parseFloat(order.product_weight) * quantity; // Use product_weight from API

            const allQuotes = {};
            for (const carrier of carriers) {
                try {
                    const cotizacionData = {
                        destino: {
                            nombre: "",
                            codigo: order.city,
                        },
                        origen: {
                            nombre: "",
                            codigo: "",
                        },
                        IdTipoEntrega: 1,
                        IdServicio: 1,
                        peso: pesoTotal,
                        largo: 1,
                        ancho: 1,
                        alto: 1,
                        fecha: "28-06-2024",
                        AplicaContrapago: true,
                        valorDeclarado: String(subtotal),
                        carrier: carrier
                    };

                    const response = await axios.post(
                        `https://integration.99envios.app/api/sucursal/cotizar/${codigoSucursal}`,
                        cotizacionData
                    );

                    allQuotes[carrier] = response.data[carrier] || {
                        exito: true,
                        valor: response.data.valor || 0
                    };

                } catch (error) {
                    console.error(`Error quoting ${carrier}:`, error);
                    allQuotes[carrier] = {
                        exito: false,
                        error: 'Error en la cotización'
                    };
                }

                setCotizaciones(prev => ({
                    ...prev,
                    [order.order_id]: {
                        ...prev[order.order_id],
                        ...allQuotes
                    }
                }));
            }

            message.success(`Cotizaciones completadas para la orden ${order.order_number}`);

        } catch (error) {
            message.error(`Error al cotizar la orden ${order.order_number}`);
            console.error('Error al cotizar:', error);
        } finally {
            setLoadingCotizar(false);
        }
    };

    const handleCarrierChange = (orderId, value) => {
        setSelectedCarriers(prev => ({
            ...prev,
            [orderId]: value,
        }));
    };

    const handleBulkSubmit = async () => {
        setLoadingSubmit(true);
        const successOrders = [];
        const failedOrders = [];
        const pdfUrls = {};

        try {
            // Validar órdenes
            const incompleteOrders = [];
            for (const order of ordersData) {
                if (
                    !order.first_name ||
                    !order.last_name ||
                    !order.city ||
                    !cotizaciones[order.order_id] ||
                    !selectedCarriers[order.order_id]
                ) {
                    incompleteOrders.push(order.order_number);
                }
            }

            if (incompleteOrders.length > 0) {
                message.warning(
                    `No se puede generar el envío de manera masiva para las órdenes: ${incompleteOrders.join(
                        ', '
                    )}. Por favor, hágalas individualmente ya que faltan datos.`
                );
                setLoadingSubmit(false);
                return;
            }

            // Crear todas las solicitudes
            const requests = ordersData.map(order => {
                return new Promise(async (resolve) => {
                    try {
                        // ...preparación de datos de la orden...
                        const carrier = selectedCarriers[order.order_id];
                        const orderData = {
                            IdProducto: order.product_id,
                            IdVariacion: order.variation_id || '',
                            Cantidad: order.product_quantity,
                            AplicaContrapago: true,
                            valorDeclarado: String(order.product_price),
                            pesoProducto: parseInt(order.weight, 10), // Ensure weight is an integer
                            nombreProducto: order.product_title,
                            Destinatario: {
                                nombre: order.first_name,
                                primerApellido: order.last_name ? order.last_name.split(' ')[0] : '',
                                segundoApellido:
                                    order.last_name && order.last_name.split(' ').length > 1
                                        ? order.last_name.split(' ')[1]
                                        : '',
                                telefono: order.phone, // Ensure phone number is sent
                                direccion: order.address,
                                idLocalidad: order.city,
                                correo: order.email,
                            },
                            Observaciones: order.notes || '',
                            transportadora: {
                                pais: 'colombia',
                                nombre: carrier || '',
                            },
                            // Verificar que esto es correcto
                            origenCreacion: 6,
                            idShopy: order.id,

                        };
                        const id = localStorage.getItem('usuario_id');
                        const token = localStorage.getItem('token');
                        const config = {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '',
                                'Content-Type': 'application/json',
                            },
                        };

                        const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);

                        // Actualizar la condición de éxito para verificar 'numero_orden'
                        if (response.status === 200 && response.data.numero_orden) {
                            successOrders.push(order.order_number);
                            // Almacena la URL del PDF asociada al número de orden
                            if (response.data.pdf) {
                                pdfUrls[order.order_number] = response.data.pdf;
                            }
                        } else {
                            console.error(`Error en la respuesta de la orden ${order.order_number}:`, response);
                            failedOrders.push(order.order_number);
                        }
                    } catch (error) {
                        console.error(`Error al enviar la orden ${order.order_number}:`, error);
                        failedOrders.push(order.order_number);
                    } finally {
                        resolve();
                    }
                });
            });

            await Promise.all(requests);

            setErrorSummary({
                success: successOrders,
                errors: failedOrders,
                pdfUrls: pdfUrls
            });

            setIsErrorModalVisible(true);

        } catch (error) {
            message.error('Error al enviar los pedidos');
            console.error('Error al enviar los pedidos:', error);
        } finally {
            setLoadingSubmit(false);
        }
    };


    const handleBulkQuotation = async () => {
        setLoadingCotizar(true);
        try {
            const allQuotes = {};
            for (const order of ordersData) {
                if (!order.city || !Number(order.product_price) || !Number(order.product_quantity)) {
                    message.error(`Complete todos los campos para la orden ${order.order_number}`);
                    continue;
                }

                const salePrice = Number(order.product_price);
                const quantity = Number(order.product_quantity);
                const subtotal = salePrice * quantity;
                const pesoTotal = parseFloat(order.product_weight) * quantity; // Use product_weight from API

                const cotizacionData = {
                    destino: {
                        nombre: "",
                        codigo: order.city,
                    },
                    origen: {
                        nombre: "",
                        codigo: "",
                    },
                    IdTipoEntrega: 1,
                    IdServicio: 1,
                    peso: pesoTotal,
                    largo: 1,
                    ancho: 1,
                    alto: 1,
                    fecha: "28-06-2024",
                    AplicaContrapago: true,
                    valorDeclarado: String(subtotal),
                };

                try {
                    const response = await axios.post(
                        `https://integration.99envios.app/api/sucursal/cotizar/${codigoSucursal}`,
                        cotizacionData
                    );

                    allQuotes[order.order_id] = response.data;

                } catch (error) {
                    console.error(`Error quoting for order ${order.order_id}:`, error);
                    allQuotes[order.order_id] = carriers.reduce((acc, carrier) => {
                        acc[carrier] = { exito: false, error: 'Error en la cotización' };
                        return acc;
                    }, {});
                }
            }

            setCotizaciones(allQuotes);
            message.success('Cotizaciones completadas para todas las órdenes');

        } catch (error) {
            message.error('Error al cotizar las órdenes');
            console.error('Error al cotizar:', error);
        } finally {
            setLoadingCotizar(false);
        }
    };

    const ErrorModal = () => (
        <Modal
            title="Resumen de Envíos"
            visible={isErrorModalVisible}
            onOk={() => setIsErrorModalVisible(false)}
            onCancel={() => setIsErrorModalVisible(false)}
            footer={[
                <Button key="close" type="primary" onClick={() => setIsErrorModalVisible(false)}>
                    Cerrar
                </Button>
            ]}
        >
            <div>
                <h3>Envíos exitosos: {errorSummary.success.length}</h3>
                {errorSummary.success.length > 0 && (
                    <>
                        <p>Puedes descargar las guías generadas a continuación:</p>
                        <ul>
                            {errorSummary.success.map((orderNumber) => (
                                <li key={orderNumber}>
                                    Orden #{orderNumber}{' '}
                                    {errorSummary.pdfUrls[orderNumber] && (
                                        <Button
                                            type="link"
                                            onClick={() => window.open(errorSummary.pdfUrls[orderNumber], '_blank')}
                                        >
                                            Descargar Guía
                                        </Button>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                <h3>Envíos con error: {errorSummary.errors.length}</h3>
                {errorSummary.errors.length > 0 && (
                    <>
                        <p style={{ color: 'red' }}>
                            Las siguientes órdenes tuvieron errores y deben procesarse individualmente:
                        </p>
                        <ul>
                            {errorSummary.errors.map((orderNumber) => (
                                <li key={orderNumber}>Orden #{orderNumber}</li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </Modal>
    );

    return (
        <div className="bulk-shop-page">
            {/* Añadir instrucciones aquí */}
            <div className="instructions">
                <h2>Instrucciones</h2>
                <ol>
                    <li>Verifique y actualice los datos de las órdenes si es necesario.</li>
                    <li>Seleccione la ciudad de destino para cada orden.</li>
                    <li>Seleccione la transportadora deseada para cada orden.</li>
                    <li>Haga clic en "Cotizar Todas" para obtener las cotizaciones de envío.</li>
                    <li>Revise las cotizaciones obtenidas.</li>
                    <li>Haga clic en "Generar Guías" para procesar los envíos.</li>
                    <li>Descargue las guías generadas desde el resumen que aparecerá.</li>
                </ol>
            </div>
            <div className="bulk-shop-table-wrapper">
                <Table
                    className="bulk-shop-table"
                    dataSource={ordersData}
                    columns={columns}
                    rowKey="order_id"
                    pagination={false} // Remove pagination for better scrolling
                    scroll={{
                        x: 2800,
                        y: undefined // Remove fixed height
                    }}
                    sticky
                />
            </div>
            <div className="bulk-shop-buttons">
                <Button
                    className="bulk-shop-btn-quote"
                    type="primary"
                    onClick={handleBulkQuotation}
                    loading={loadingCotizar}
                    disabled={ordersData.some(order => !order.city)}
                >
                    Cotizar Todas
                </Button>
                <Button
                    className="bulk-shop-btn-generate"
                    type="primary"
                    onClick={handleBulkSubmit}
                    loading={loadingSubmit}
                    disabled={ordersData.some(order => !order.first_name || !order.last_name || !order.city || !cotizaciones[order.order_id] || !selectedCarriers[order.order_id])}
                >
                    Generar Guías
                </Button>
            </div>
            <ErrorModal />
        </div>
    );
};

export default SendClientBulkPage;