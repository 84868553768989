import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import './maps.css';

const mapContainerStyle = {
    height: '100vh',
    width: '100%'
};
const center = {
    lat: 19.432608,
    lng: -99.133209
};

const direcciones = [
    { id: 1, nombre: 'Mario', lat: 19.4326, lng: -99.1332 },
    { id: 2, nombre: 'David', lat: 19.4346, lng: -99.1382 },
    // ... más direcciones ...
];

const VisitasM = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAbErc-9C5rqyrGudtyKlk7LJOfqhbu9c0', // Asegúrate de reemplazar esto con tu clave de API real.
        libraries: ['places']
    });

    if (loadError) {
        console.error('Error loading Google Maps:', loadError);
        return "Error loading maps";
    }
    if (!isLoaded) {
        console.log('Loading Google Maps...');
        return "Loading Maps";
    }

    console.log('Google Maps loaded.');

    return (
        <>
            <Navbar />
            <div className="containerMap" style={{ marginTop: '55px' }}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={12}
                >
                    {direcciones.map((direccion) => {
                        console.log('Rendering marker for:', direccion);
                        return (
                            <Marker
                                key={direccion.id}
                                position={{ lat: direccion.lat, lng: direccion.lng }}
                                label={direccion.nombre}
                            />
                        );
                    })}
                </GoogleMap>
                <Sidebar />
            </div>
            <Footer />
        </>
    );
};

export default VisitasM;
