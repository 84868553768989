import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Estilos/procesoPago.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

/**
 * Componente principal para gestionar el proceso de pago de un pedido específico.
 * @component
 */
function ProcesoPago() {
    const navigate = useNavigate(); // Hook para usar la navegación
    const pedidoId = useParams(); // Hook para capturar el ID del pedido desde la URL
    const [orderInfo, setOrderInfo] = useState(null);
    const id = pedidoId.id;

    /**
     * Navega a la página de pago mixto.
     */
    function handleDetailsClickMixto() {
        navigate(`/conductor/pago-mixto/${id}`);
    }

    /**
     * Navega a la página de pago en efectivo.
     */
    function handleDetailsClickEfectivo() {
        navigate(`/conductor/pago-efectivo/${id}`);
    }

    /**
     * Navega a la página de pago digital.
     */
    function handleDetailsClickDigital() {
        navigate(`/conductor/pago-digital/${id}`);
    }

    /**
     * Obtiene la información del pedido desde la API.
     * @function
     */
    const fetchOrderInfo = useCallback(async () => {
        try {
            console.log('Fetching order info for:', pedidoId);
            const idPedido = pedidoId.id;
            console.log('idPedido:', idPedido);
            const url = `https://api.99envios.app/api/pedidos/${idPedido}`;
            const response = await axios.get(url);
            console.log('GET Response:', response.data);
            setOrderInfo(response.data);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    /**
     * Hook de efecto para obtener la información del pedido cuando el ID del pedido cambia.
     */
    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);

    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }

    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null 
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99)) 
    : null;

    return (
        <div>
            <Navbar title={"Proceso de pago"} />
            <div className='head'>
                <Head title={`Proceso de pago #${pedidoId.id}`} />
            </div>
            <div className="contenedores">
                <div className="information">
                    <p>Fecha: {orderInfo.fecha_pedido || 'No disponible'}</p>
                    <p>Estado: {orderInfo.estado_pedido || 'No disponible'}</p>
                    <p className="net-total"><span>Total a cobrar:</span> {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                </div>
                <div className="buttons-container">
                    <button onClick={handleDetailsClickEfectivo} className="custom-button">Pago Efectivo Total</button>
                    <button onClick={handleDetailsClickDigital} className="custom-button">Pago digital Total</button>
                    <button onClick={handleDetailsClickMixto} className="custom-button">Pago Mixto</button>
                </div>
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
}

export default ProcesoPago;