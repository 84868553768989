import React, { useState, useEffect } from "react";
import NavbarAdminMex from '../components/navbar';
import { Card, Row, Col, message, Button, Alert, Modal } from 'antd';
import axios from 'axios';
import './modalTransportadoras.css';

const ModalTransportadoras = () => {
  const [estados, setEstados] = useState({
    interrapidisimo: 'Inactivo',
    servientrega: 'Inactivo',
    tcc: 'Inactivo',
    coordinadora: 'Inactivo',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const transportadoras = [
    { 
      nombre: 'TCC', 
      imagen: '/Images/tcc.svg', 
      apis: ['/rastreo_automatico_tcc']
    },
    { 
      nombre: 'Servientrega', 
      imagen: '/Images/servi.png', 
      apis: ['/rastreo_automatico_servientrega']
    },
    { 
      nombre: 'Interrapidisimo', 
      imagen: '/Images/inter.png', 
      apis: [
        '/rastreo_automatico_interrapidisimo/1',
        '/rastreo_automatico_interrapidisimo/2',
        '/rastreo_automatico_interrapidisimo/3',
        '/rastreo_automatico_interrapidisimo/4',
        '/rastreo_automatico_interrapidisimo/5',
        '/rastreo_automatico_interrapidisimo/6'
      ]
    },
    { 
      nombre: 'Coordinadora', 
      imagen: '/Images/coordi.png', 
      apis: ['/rastreo_automatico_coordinadora']
    }
  ];

  useEffect(() => {
    let timer;
    if (isButtonDisabled && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setIsButtonDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled, timeLeft]);

  const ejecutarValorEstadoSecuencial = async () => {
    setIsButtonDisabled(true);
    setTimeLeft(1800); // 30 minutes in seconds

    for (const transportadora of transportadoras) {
      try {
        // Ejecutar APIs de la transportadora en orden
        for (const api of transportadora.apis) {
          const baseUrl = 'https://integration.99envios.app/api/sucursal';
          let success = false;
          while (!success) {
            try {
              const response = await axios.post(`${baseUrl}${api}`, {});
              
              if (response.status === 200) {
                success = true;
                // Procesar respuesta exitosa
                if (response.data && response.data.estado) {
                  setEstados(prevEstados => ({
                    ...prevEstados,
                    [transportadora.nombre.toLowerCase()]: response.data.estado
                  }));
                }
                // Send WhatsApp notification after successful API call
                try {
                  await axios.post('https://99envios.app/api/online/whatsapp/mensaje_transito_novedades');
                  console.log('WhatsApp notification sent successfully');
                } catch (whatsappError) {
                  console.error('Error sending WhatsApp notification:', whatsappError);
                }
                
                console.log(`Respuesta para ${transportadora.nombre} (${api}):`, response.data);
                message.success(`Llamada a la API exitosa para ${transportadora.nombre} (${api})`);
              } else {
                // Reintentar si la respuesta no es 200
                message.info(`Reintentando llamada a la API para ${transportadora.nombre} (${api})`);
              }
            } catch (error) {
              // Informar al usuario y reintentar en caso de error
              message.info(`Reintentando llamada a la API para ${transportadora.nombre} (${api})`);
            }
          }
        }
      } catch (error) {
        console.error(`Error al llamar a las APIs para ${transportadora.nombre}:`, error);
        message.error(`Error en las llamadas a la API para ${transportadora.nombre}`);
        break; // Detener si hay un error
      }
    }

    message.info("Ejecución secuencial de APIs completada");
  };

  const showConfirm = () => {
    setIsConfirmVisible(true);
  };

  const handleConfirmOk = async () => {
    setIsConfirmVisible(false);
    await ejecutarNovedades();
  };

  const handleConfirmCancel = () => {
    setIsConfirmVisible(false);
  };

  const ejecutarNovedades = async () => {
    setIsButtonDisabled(true);
    setTimeLeft(800);

    try {
      const response = await axios.post('https://integration.99envios.app/api/sucursal/rastreo_automatico_interrapidisimo/7', {});
      
      if (response.status === 200) {
        message.success('Llamada a la API exitosa para Interrapidisimo (Novedades)');
        
        // Send WhatsApp notification after successful novedades API call
        try {
          await axios.post('https://99envios.app/api/online/whatsapp/mensaje_transito_novedades');
          console.log('WhatsApp notification sent successfully');
        } catch (whatsappError) {
          console.error('Error sending WhatsApp notification:', whatsappError);
        }
      } else {
        message.error('Error en la llamada a la API para Interrapidisimo (Novedades)');
      }
    } catch (error) {
      console.error('Error al llamar a la API para Interrapidisimo (Novedades):', error);
      message.error('Error en la llamada a la API para Interrapidisimo (Novedades)');
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Actualizar Estados" />
      <Card className="modal-transportadoras-main-card">
        <Row gutter={[16, 16]} className="modal-transportadoras-row">
          {transportadoras.map((transportadora) => (
            <Col xs={24} sm={12} md={8} lg={6} key={transportadora.nombre} className="modal-transportadoras-col">
              <Card
                title={transportadora.nombre}
                className="modal-transportadoras-transporter-card"
              >
                <img 
                  alt={transportadora.nombre} 
                  src={transportadora.imagen} 
                  className="modal-transportadoras-transporter-image"
                />
                <p className="modal-transportadoras-estado">
                  Estado: {estados[transportadora.nombre.toLowerCase()]}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
        <Row justify="center" className="modal-transportadoras-execute-all-row">
          <Col>
          <Button
            type="primary"
            onClick={ejecutarValorEstadoSecuencial}
            disabled={isButtonDisabled}
            style={{ 
              backgroundColor: '#8c8cff', 
              color: 'white', 
              border: 'none', 
              padding: '8px 16px', 
              borderRadius: '4px', 
              cursor: 'pointer', 
              height: '44px', 
              display: 'block',
              margin: '0 auto' }}
          >
            {isButtonDisabled 
              ? `Espere ${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')}`
              : 'Ejecutar valor de estado para todas'}
          </Button>
          </Col>
        </Row>
      </Card>
      <Card className="modal-transportadoras-main-card">        
        <Row justify="center" align="middle" className="modal-transportadoras-execute-all-row">
          <Col>
            <Button
              type="primary"
              onClick={showConfirm}
              disabled={isButtonDisabled}
              style={{ 
                backgroundColor: 'white', 
                color: 'black',
                border: '1px solid #8c8cff',
                padding: '8px 16px', 
                borderRadius: '4px', 
                cursor: 'pointer', 
                height: '57px', 
                display: 'block',
                margin: '0 auto'}}
            >
              {isButtonDisabled 
                ? `Espere ${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')}`
                : (
                  <>
                    <img 
                      alt="Interrapidisimo" 
                      src="/Images/inter.png" 
                      style={{ width: '40px', marginRight: '8px' }}
                    />
                    Ejecutar Novedades
                  </>
                )}
            </Button>
          </Col>
          <Col>
            <Alert
              message={
                <>
                  Al generar novedades, se eliminará todo el contenido de la tabla actual de novedades y se actualizará con los datos más recientes que tengan novedades para ser tramitadas por los usuarios. Esta acción debe realizarse únicamente una vez, después de haber actualizado los estados de las transportadoras.
                </>
              }
              type="info"
              style={{ marginBottom: '16px' }}
              showIcon
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="Confirmación"
        visible={isConfirmVisible}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        okText="Aceptar"
        cancelText="Cancelar"
        okButtonProps={{ style: { height: '44px' } }}
        cancelButtonProps={{ style: { height: '44px' } }}
      >
        <p>Todas las novedades se borrarán y se reemplazarán con las nuevas actualizaciones. ¿Desea continuar?</p>
      </Modal>
    </>
  );
};

export default ModalTransportadoras;