import React, { useState, useEffect } from 'react';
import { message, DatePicker, Card, Statistic, Table } from 'antd';
import { WhatsAppOutlined, DollarOutlined } from '@ant-design/icons';
import NavbarMex from '../newInterface/components/navbarMex';
import './whatsapp.css';
import moment from 'moment';

const { RangePicker } = DatePicker;

const WhatsappComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [facturacionData, setFacturacionData] = useState(null);
  const [sucursalId, setSucursalId] = useState(null);
  const [mensajesMensuales, setMensajesMensuales] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState(0);
  const userId = localStorage.getItem('usuario_id');

  // Reemplazar los tres useEffect anteriores por uno solo
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const id = localStorage.getItem('usuario_id');
        console.log('IsdasD:', id);
        if (!id) return;

        const sucursalResponse = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (!sucursalResponse.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        
        const sucursalId = await sucursalResponse.json();
        setSucursalId(sucursalId);

        // Hacer las otras solicitudes en paralelo
        const [mensajesResponse, totalResponse] = await Promise.all([
          fetch(`https://99envios.app/api/online/whatsapp/total_mensajes_mes_sucursal/${sucursalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          }),
          fetch(`https://99envios.app/api/online/whatsapp/total_mensajes_sucursal/${sucursalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })
        ]);

        const mensajesData = await mensajesResponse.json();
        const totalData = await totalResponse.json();

        setMensajesMensuales(mensajesData);
        setTotalMensajes(totalData);

      } catch (error) {
        console.error('Error fetching data:', error);
        message.error('Error al cargar los datos');
      }
    };

    fetchAllData();
  }, []); // Solo se ejecuta al montar el componente

  const handleWhatsappAction = async (action) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://99envios.app/api/online/whatsapp/message_template_${action}/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `Error al ${action === 'activar' ? 'activar' : 'desactivar'} WhatsApp`);
      }

      message.success({
        content: data.success || `WhatsApp ${action === 'activar' ? 'activado' : 'desactivado'} exitosamente.`,
        className: 'custom-message'
      });
    } catch (error) {
      message.error({
        content: error.message,
        className: 'custom-message'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Modificar handleFechasChange para usar sucursalId
  const handleFechasChange = async (dates) => {
    if (!dates || !dates[0] || !dates[1]) return;
    if (!sucursalId) {
      message.error('Código de sucursal no disponible');
      return;
    }

    try {
      const response = await fetch('https://99envios.app/api/online/whatsapp/facturacion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          codigoSucursal: String(sucursalId), // Convertir a string
          fechaInicio: dates[0].format('YYYY-MM-DD HH:mm:ss'),
          fechaFin: dates[1].format('YYYY-MM-DD HH:mm:ss')
        })
      });

      const data = await response.json();
      setFacturacionData(data);
    } catch (error) {
      message.error('Error al obtener datos de facturación');
    }
  };

  const columns = [
    {
      title: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
    },
    {
      title: 'Mensajes',
      dataIndex: 'cantidad_mensajes',
      key: 'cantidad_mensajes',
    },
    {
      title: 'Valor Total',
      dataIndex: 'total_valor_mensajes',
      key: 'total_valor_mensajes',
      render: (value) => `$${value}`,
    },
  ];

  return (
    <>
      <NavbarMex title="Gestión de WhatsApp" />
      <div className="whatsapp-page">
        <div className="cards-container">
          {/* Primera tarjeta - WhatsApp */}
          <Card className="whatsapp-card" bordered={false}>
            <div className="whatsapp-content">
              <WhatsAppOutlined className="whatsapp-icon" />
              <h2 className="whatsapp-title">Notificaciones</h2>
              <div className="total-mensajes">
                <Statistic 
                  title="Total Mensajes Enviados"
                  value={totalMensajes}
                  prefix={<WhatsAppOutlined />}
                />
              </div>
              <div className="buttons-container">
                <button
                  onClick={() => handleWhatsappAction('activar')}
                  className={`whatsapp-button activate ${isLoading ? 'loading' : ''}`}
                  disabled={isLoading}
                >
                  Activar
                </button>
                <button
                  onClick={() => handleWhatsappAction('desactivar')}
                  className={`whatsapp-button deactivate ${isLoading ? 'loading' : ''}`}
                  disabled={isLoading}
                >
                  Desactivar
                </button>
              </div>
            </div>
          </Card>

          {/* Segunda tarjeta - Facturación por rango de fechas */}
          <Card className="facturacion-card" bordered={false}>
            <div className="facturacion-content">
              <DollarOutlined className="facturacion-icon" />
              <h2 className="facturacion-title">Facturación por Fecha</h2>
              <RangePicker
                className="date-picker"
                onChange={handleFechasChange}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
              />
              {facturacionData && (
                <div className="facturacion-stats">
                  <Statistic 
                    title="Sucursal"
                    value={facturacionData.codigo_sucursal}
                  />
                  <Statistic
                    title="Registros"
                    value={facturacionData.total_registros}
                  />
                  <Statistic
                    title="Total"
                    value={facturacionData.suma_total || 0}
                    prefix="$"
                  />
                </div>
              )}
            </div>
          </Card>

          {/* Tercera tarjeta - Historial Mensual */}
          <Card className="facturacion-card" bordered={false}>
            <div className="facturacion-content">
              <DollarOutlined className="facturacion-icon" />
              <h2 className="facturacion-title">Historial Mensual</h2>
              <Table
                dataSource={mensajesMensuales}
                columns={columns}
                pagination={false}
                size="small"
                className="mensajes-table"
                summary={data => {
                  const totalMensajes = data.reduce((sum, item) => sum + item.cantidad_mensajes, 0);
                  const totalValor = data.reduce((sum, item) => sum + parseFloat(item.total_valor_mensajes || 0), 0);
                  
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>Total</Table.Summary.Cell>
                      <Table.Summary.Cell>{totalMensajes}</Table.Summary.Cell>
                      <Table.Summary.Cell>${totalValor.toFixed(0)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default WhatsappComponent;
