import React from 'react';
import { Link } from 'react-router-dom';
import './noAuth.css'; // Asegúrate de importar tu hoja de estilo CSS

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-container">
      <h1 className="unauthorized-header">403</h1>
      <p className="unauthorized-text">No Autorizado</p>
      <p>No tienes permiso para acceder a esta página.</p>
      <Link to="/" className="unauthorized-link">Volver a la página de inicio</Link>
    </div>
  );
};

export default UnauthorizedPage;
