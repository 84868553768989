import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const BotonWhatsapp = () => {
  return (
    <FloatingWhatsApp
      phoneNumber="573162519103"
      accountName="99 Envíos"
      avatar="/Images/logo.png"
      statusMessage="En línea"
      chatMessage="Bienvenido a 99 Envíos                   ¿En qué podemos ayudarte?"
      placeholder="Escribe tu mensaje..."
      darkMode={true}
      allowClickAway={true}
      color="black" 
    />
    );
};

export default BotonWhatsapp;