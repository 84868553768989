import React from 'react';
import './toggle.css';

// Añade props para recibir el estado y la función de cambio del componente padre
export default function Carga() {
  
  return (
    <div className="spinner"></div>
  );
}
