import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Input, Checkbox, Select, InputNumber } from 'antd';
import NavbarAdminMex from '../components/navbar';
import './tablaUsuariosAdmin.css';
import { utils, writeFile } from 'xlsx';

const { Option } = Select;

const API_BASE_URL = 'https://99envios.app/api/online';

const TablaUsuariosAdmin = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingCodigoSucursal, setEditingCodigoSucursal] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sucursales-con-correos`);
      if (!response.ok) throw new Error('La respuesta de la red no fue correcta');
      const result = await response.json();
      console.log('Respuesta de la API:', result);
      setData(result);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
      Object.values(item).some(val =>
        val && val.toString().toLowerCase().includes(search.toLowerCase())
      ) ||
      (item.user && item.user.email && item.user.email.toLowerCase().includes(search.toLowerCase()))
    );
  }, [sortedData, search]);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const currentEntries = useMemo(() => {
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    return filteredData.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filteredData, currentPage, entriesPerPage]);

  const renderPaginationButtons = () => {
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 4) {
      startPage = 1;
      endPage = Math.min(5, totalPages);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(totalPages - 4, 1);
      endPage = totalPages;
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => setCurrentPage(1)}
          className={`tabla-usuarios-admin-page-button ${currentPage === 1 ? 'active' : ''}`}
        >
          1
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`tabla-usuarios-admin-page-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          className={`tabla-usuarios-admin-page-button ${currentPage === totalPages ? 'active' : ''}`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleEdit = (id_users) => {
    const itemToEdit = data.find(item => item.id_users === id_users);
    form.setFieldsValue({
      ...itemToEdit,
      num_identificacion: itemToEdit.numero_identificacion,
      pais: itemToEdit.id_pais,
      ciudad_id: itemToEdit.id_ciudad,
      correo: itemToEdit.user?.email || '',
      activo: itemToEdit.activo === 1
    });
    setEditingCodigoSucursal(id_users);
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await handleSave(editingCodigoSucursal, values);
      setModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error de validación:", error);
    }
  };

  const handleSave = async (id_users, values) => {
    try {
      const response = await fetch(`${API_BASE_URL}/sucursal/${id_users}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          nombre_apellidos: values.nombre,
          numero_identificacion: values.num_identificacion,
          id_pais: Number(values.pais),
          id_ciudad: values.ciudad_id,
          correo: values.correo,
          activo: values.activo ? 1 : 0,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData.errors));
      }
      await fetchData();
      setModalVisible(false);
    } catch (error) {
      console.error("Error al actualizar el elemento:", error);
      alert(`Error al actualizar: ${error.message}`);
    }
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    // Crear una copia de los datos filtrados excluyendo la columna pdf
    const exportData = filteredData.map(item => {
      const { pdf, ...rest } = item;
      return rest;
    });

    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Envíos Completos");

    writeFile(workbook, `Tabla_Usuarios_${formatDateForDisplay(new Date())}.xlsx`);
  };

  return (
    <>
      <NavbarAdminMex title="Tabla Usuarios" />
      <div className="tabla-usuarios-admin-table-container">
        <div className="table-controls">
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          <div className="export-group">
            <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
          </div>
        </div>
        <div className="tabla-usuarios-admin-table-wrapper">
          <table className="tabla-usuarios-admin-custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('id')}>ID</th>
                <th onClick={() => handleSort('id_users')}>ID Users</th>
                <th onClick={() => handleSort('id_administrador')}>ID Administrador</th>
                <th onClick={() => handleSort('codigo_sucursal')}>Código Sucursal</th>
                <th onClick={() => handleSort('codigo_inter')}>Código Inter</th>
                <th onClick={() => handleSort('id_pais')}>ID País</th>
                <th onClick={() => handleSort('id_ciudad')}>ID Ciudad</th>
                <th onClick={() => handleSort('nombre_sucursal')}>Nombre Sucursal</th>
                {/*<th onClick={() => handleSort('producto_sucursal')}>Producto Sucursal</th>
                <th onClick={() => handleSort('cantidad')}>Cantidad</th>*/}
                <th onClick={() => handleSort('direccion')}>Dirección</th>
                <th onClick={() => handleSort('telefono')}>Teléfono</th>
                <th onClick={() => handleSort('nombre')}>Nombre</th>
                <th onClick={() => handleSort('tipo_identificacion')}>Tipo Identificación</th>
                <th onClick={() => handleSort('numero_identificacion')}>Número Identificación</th>
                <th onClick={() => handleSort('id_nivel_credito')}>Nivel de Crédito</th>
                <th onClick={() => handleSort('activo')}>Activo</th>
                <th onClick={() => handleSort('created_at')}>Fecha Creación</th>
                <th onClick={() => handleSort('updated_at')}>Fecha Actualización</th>
                <th onClick={() => handleSort('user.email')}>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.id_users}</td>
                  <td>{item.id_administrador}</td>
                  <td>{item.codigo_sucursal}</td>
                  <td>{item.codigo_inter}</td>
                  <td>{item.id_pais}</td>
                  <td>{item.id_ciudad}</td>
                  <td>{item.nombre_sucursal}</td>
                  {/*<td>{item.producto_sucursal || 'N/A'}</td>
                  <td>{item.cantidad || 'N/A'}</td>*/}
                  <td>{item.direccion}</td>
                  <td>{item.telefono}</td>
                  <td>{item.nombre || 'N/A'}</td>
                  <td>{item.tipo_identificacion}</td>
                  <td>{item.numero_identificacion}</td>
                  <td>{item.id_nivel_credito}</td>
                  <td>{item.activo === 1 ? 'Sí' : 'No'}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>{item.user?.email || 'N/A'}</td>
                  <td>
                    <button onClick={() => handleEdit(item.id_users)} className='tabla-usuarios-admin-edit-button'>Editar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="tabla-usuarios-admin-pagination">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            className={`tabla-usuarios-admin-page-button ${currentPage === 1 ? 'disabled' : ''}`}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {renderPaginationButtons()}
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            className={`tabla-usuarios-admin-page-button ${currentPage === totalPages ? 'disabled' : ''}`}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </button>
        </div>
      </div>

      <Modal
        title="Editar Sucursal"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
        okText="Guardar"
        cancelText="Cancelar"
        width={900}
        className="tabla-usuarios-admin-modal"
        okButtonProps={{ className: 'tabla-usuarios-admin-modal-button tabla-usuarios-admin-modal-button-primary' }}
        cancelButtonProps={{ className: 'tabla-usuarios-admin-modal-button tabla-usuarios-admin-modal-button-default' }}
      >
        <Form form={form} layout="vertical" className="tabla-usuarios-admin-form">
          <div className="modal-form-columns">
            <div className="form-column">
              <Form.Item name="id" label="ID" rules={[{ required: true, message: 'Por favor ingrese el ID' }]}>
                <Input style={{ maxWidth: "40%" }} disabled className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="id_users" label="ID Users" rules={[{ required: true, message: 'Por favor ingrese el ID Users' }]}>
                <Input style={{ maxWidth: "40%" }} disabled className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="id_administrador" label="ID Administrador" rules={[{ required: true, message: 'Por favor ingrese el ID Administrador' }]}>
                <InputNumber style={{ maxWidth: "40%" }} disabled className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="id_nivel_credito" label="Nivel de Crédito" rules={[{ required: true, message: 'Por favor ingrese el Nivel de Crédito' }]}>
                <InputNumber style={{ maxWidth: "40%" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              
            </div>

            <div className="form-column">
              <Form.Item name="ciudad_id" label="Ciudad" rules={[{ required: true, message: 'Por favor ingrese la ciudad' }]}>
                <Input style={{ maxWidth: "70%" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              {/*<Form.Item name="producto_sucursal" label="Producto Sucursal">
                <Input className="tabla-usuarios-admin-input" />
              </Form.Item>*/}
              <Form.Item style={{ marginLeft: "-12px" }} name="tipo_identificacion" label="Tipo de Identificación" rules={[{ required: true, message: 'Por favor seleccione el Tipo de Identificación' }]}>
                <Select style={{ maxWidth: "70%" }} className="tabla-usuarios-admin-select">
                  <Option value="CC">CC</Option>
                  <Option value="CE">CE</Option>
                  <Option value="NIT">NIT</Option>
                </Select>
              </Form.Item>
              <Form.Item name="codigo_sucursal" label="Código Sucursal" rules={[{ required: true, message: 'Por favor ingrese el Código Sucursal' }]}>
                <Input disabled className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="pais" label="País" rules={[{ required: true, message: 'Por favor ingrese el país' }]}>
                <InputNumber style={{ maxWidth: "40%" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
            </div>

            <div className="form-column">
              <Form.Item name="telefono" label="Teléfono" rules={[{ required: true, message: 'Por favor ingrese el Teléfono' }]}>
                <Input className="tabla-usuarios-admin-input" />
              </Form.Item>
              {/*<Form.Item style={{ maxWidth: "40%" }} name="cantidad" label="Cantidad">
                <InputNumber className="tabla-usuarios-admin-input" />
              </Form.Item>*/}
              <Form.Item name="num_identificacion" label="Número Identificación" rules={[{ required: true, message: 'Por favor ingrese el número de identificación' }]}>
                <Input className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="codigo_inter" label="Código Inter">
                <Input className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="activo" valuePropName="checked">
                <Checkbox className="tabla-usuarios-admin-checkbox">Activo</Checkbox>
              </Form.Item>
            </div>

            <div className="form-column">
              <Form.Item name="nombre_sucursal" label="Nombre Sucursal" rules={[{ required: true, message: 'Por favor ingrese el Nombre Sucursal' }]}>
                <Input style={{ width: "250px" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="direccion" label="Dirección" rules={[{ required: true, message: 'Por favor ingrese la Dirección' }]}>
                <Input style={{ width: "250px" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="nombre" label="Nombre">
                <Input style={{ width: "250px" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              <Form.Item name="correo" label="Correo" rules={[{ required: true, message: 'Por favor ingrese el Correo' }]}>
                <Input style={{ width: "250px" }} className="tabla-usuarios-admin-input" />
              </Form.Item>
              
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TablaUsuariosAdmin;