import React from 'react';
import NavbarAdminMex from '../components/navbar';
import { Form, Input, Button, Card } from 'antd';

const VendedorForm = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Valores del formulario:', values);
    // Aquí puedes manejar el envío del formulario
  };

  return (
    <>
    <NavbarAdminMex title={"Nuevo Vendedor"}/>
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
     
      height: '100vh', 
      background: '#f0f2f5' 
    }}>
      <Card
        style={{
        maxWidth:'90vh',
        maxHeight:'55%',
        marginTop: '2%',
        marginLeft: '-30%',
          borderRadius: '15px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
          border: 'none',
        }}
        bodyStyle={{ padding: '30px' }}
      >
        <Form
          form={form}
          name="vendedor_form"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="vendedor"
            label={<span style={{ fontWeight: 'bold' }}>Vendedor:</span>}
            rules={[{ required: true, message: 'Por favor ingrese el vendedor' }]}
          >
            <Input style={{ borderRadius: '5px' }} />
          </Form.Item>

          <Form.Item
            name="identificacion"
            label={<span style={{ fontWeight: 'bold' }}>Identificación vendedor:</span>}
            rules={[{ required: true, message: 'Por favor ingrese la identificación' }]}
          >
            <Input style={{ borderRadius: '5px' }} />
          </Form.Item>

          <Form.Item
            name="codigoSucursal"
            label={<span style={{ fontWeight: 'bold' }}>Codigo Sucursal:</span>}
            rules={[{ required: true, message: 'Por favor ingrese el código de sucursal' }]}
          >
            <Input style={{ borderRadius: '5px' }} />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              style={{ 
                backgroundColor: '#7e82f7', 
                borderColor: '#7e82f7',
                borderRadius: '5px',
                width: '100%',
                height: '40px',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
    </>
  );
};

export default VendedorForm;
