import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Upload, Select, Checkbox, Row, Col, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';

const { Option } = Select;

const FormularioRegistroConductor = () => {
    const [form] = Form.useForm();
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const id = localStorage.getItem('usuario_id');  // Asegúrate de que este ID es el correcto para el usuario actual

    const handleFileChange = (info) => {
        const lastFile = info.fileList.slice(-1)[0];
        if (lastFile) {
            setImage(lastFile.originFileObj);
            setImagePreviewUrl(URL.createObjectURL(lastFile.originFileObj));
        } else {
            setImage(null);
            setImagePreviewUrl('');
        }
    };

    const handleSubmit = async () => {
        form.validateFields().then(async (values) => {
            let formData = new FormData();
            formData.append('nombre_usuario', values.nombre);
            formData.append('phone', values.celular);
            formData.append('direccion', values.direccion);
            formData.append('documento_identidad', values.documento_identidad);
            formData.append('email', values.email);
            formData.append('pais', values.pais);
            if (image instanceof File) {
                formData.append('foto_cara', image);
            }
            formData.append('capacidad_carga', values.cantidad);
            formData.append('tipo_vehiculo', values.tipo_vehiculo);
            formData.append('placa_vehiculo', values.placa_vehiculo);
            formData.append('ciudad', values.ciudad);
            formData.append('password', values.password);
            formData.append('password_confirmation', values.password_confirmation);
            formData.append('id_rol', 4);
            formData.append('id_usuario', id);
            formData.append('estado', 0);

            try {
                const response = await axios.post('https://api.99envios.app/api/registroSucursalRepartidor', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Registro exitoso!', response.data.message);
            } catch (error) {
                message.error('Error al registrar. Por favor, verifica tus datos y tu conexión.');
            }
        }).catch(errorInfo => {
            message.error('Error en la validación del formulario. Revisa los errores.', errorInfo);
        });
    };

    const ciudades = [
        'Bogotá',
        'Medellín',
        'Cali',
        'Barranquilla',
        'Tunja',
        'Bucaramanga',
        'Manizales',
        'Pereira',
        'Popayan',
        'Armenia',
        'Cartagena',
        'Ibague',
        'Neiva',
        'Pasto',
        'Santa Marta',
        'Villavicencio',
        'Yopal',
        'Cucuta',
        'Monteria',
        'Valledupar',
    ];


    return (
        <div className='contenedorConductor' style={{ height: '100vh', display: 'flex', flexDirection: 'column', background: 'rgb(241, 239, 239)' }}>
            <Navbar />
            <div style={{ flex: 1, backgroundColor: 'rgb(241, 239, 239)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '2%' }}>
                <h2 style={{ color: 'black', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Añadir nuevo conductor</h2>
                <Form form={form} name="registro" layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Por favor ingresa tu nombre!' }]}>
                                <Input placeholder="Ingresa tu nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="celular" label="Celular" rules={[{ required: true, message: 'Por favor ingresa tu celular!' }, { pattern: /^[0-9]+$/, message: 'Por favor ingresa un número válido' }]}>
                                <Input placeholder="Ingresa el celular" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="direccion" label="Dirección" rules={[{ required: true, message: 'Por favor ingresa tu dirección!' }]}>
                                <Input placeholder="Ingresa tu dirección" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="documento_identidad" label="Documento de identidad" rules={[{ required: true, message: 'Por favor ingresa tu documento de identidad!' }]}>
                                <Input placeholder="Ingresa tu documento de identidad" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="email" label="Correo electrónico" rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico!' }, { type: 'email', message: 'Por favor ingresa un correo válido' }]}>
                                <Input placeholder="Ingresa tu correo electrónico" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="pais" label="País" rules={[{ required: true, message: 'Por favor ingresa tu país!' }]}>
                                <Input placeholder="Ingresa tu país" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="ciudad" label="Ciudad" rules={[{ required: true, message: 'Por favor ingresa tu ciudad!' }]}>
                                <Select placeholder="Selecciona tu ciudad">
                                    {ciudades.map(ciudad => (
                                        <Option key={ciudad} value={ciudad}>{ciudad}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cantidad" label="Capacidad de carga" rules={[{ required: true, message: 'Por favor ingresa la capacidad de carga!' }]}>
                                <Input placeholder="Ingresa la capacidad de carga" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item name="tipo_vehiculo" label="Tipo de vehículo" rules={[{ required: true, message: 'Por favor ingresa el tipo de vehículo!' }]}>
                                <Select placeholder="Selecciona el tipo de vehículo">
                                    <Option value="moto">Moto</Option>
                                    <Option value="carro">Carro</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="placa_vehiculo" label="Placa del vehículo" rules={[{ required: true, message: 'Por favor ingresa la placa del vehículo!' }]}>
                                <Input placeholder="Ingresa la placa del vehículo" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}>
                                <Input.Password placeholder="Ingresa tu contraseña" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="password_confirmation" label="Confirmar contraseña" rules={[{ required: true, message: 'Por favor confirma tu contraseña!' }]}>
                                <Input.Password placeholder="Confirma tu contraseña" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="foto_cara" label="Foto de perfil">
                                <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={() => false} // Prevent automatic upload
                                    onChange={handleFileChange}
                                >
                                    {imagePreviewUrl ? <img src={imagePreviewUrl} alt="avatar" style={{ width: '100%' }} /> : <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Registrar</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
};

export default FormularioRegistroConductor;