import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarMex from '../newInterface/components/navbarMex';
import SendClientPage from '../dropShipingInterface/SendClientPage/SendClientShop';
import SendClientBulkPage from '../dropShipingInterface/SendClientPage/SendClientBulkShop';
import AgregarProductoShopify from './shopifyInventario/agregarProductoShopify';
import { Table, Button, Modal, Select } from 'antd';
import 'antd/dist/antd.css'; // Importa los estilos de antd
import { useNavigate } from 'react-router-dom';
import './Shopify.css';

const { Option } = Select;

const ShopSubscription = () => {
  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState(ordersData);
  const [formData, setFormData] = useState({
    apiKey: '',
    password: '',
    sharedSecret: '',
    apiVersion: '',
    shopName: '', 
    codigo_sucursal: '',
    userId: ''
  });
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [mostrarEnviosMasivos, setMostrarEnviosMasivos] = useState(false);

  useEffect(() => {
    setFilteredOrders(ordersData);
  }, [ordersData]);

  const handleFilterByOrderStatus = (value) => {
    if (value === 'all') {
      setFilteredOrders(ordersData);
    } else {
      const filtered = ordersData.filter((order) => order.estado_order == value);
      setFilteredOrders(filtered);
    }
  };

  const handleFilterByFinancialStatus = (value) => {
    if (value === 'all') {
      setFilteredOrders(ordersData);
    } else {
      const filtered = ordersData.filter((order) => order.financial_status === value);
      setFilteredOrders(filtered);
    }
  };

  const handleFilterByFulfillmentStatus = (value) => {
    if (value === 'all') {
      setFilteredOrders(ordersData);
    } else {
      const filtered = ordersData.filter((order) => order.fulfillment_status === value);
      setFilteredOrders(filtered);
    }
  };

  const navigate = useNavigate();

  // Función para verificar si la sucursal del usuario está suscrita
  const verificarSucursalUsuario = async () => {
    const userId = localStorage.getItem('usuario_id');
    if (!userId) {
      console.error('No se encontró el ID de usuario en localStorage');
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      userId: userId,
    }));

    try {
      const response = await axios.get(`https://integration.99envios.app/api/integration/shopify/v1/verificar-sucursal/${userId}`);
      const data = response.data;

      if (data.message ==
        'El suscriptor está activo.') {
        setIsSubscribed(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          sucursalId: data.codigo_sucursal
        }));
        localStorage.setItem('codigoSucursal', data.codigo_sucursal);
      } else {
        setIsSubscribed(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          codigo_sucursal: data.codigo_sucursal,
        }));
        Swal.fire({
          icon: 'warning',
          title: 'Sucursal no suscrita',
          text: 'Complete el formulario para suscribir la sucursal.',
        });
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Función para obtener los datos de las órdenes de Shopify
  const fetchOrdersData = async () => {
    try {
      const codigoSucursal = localStorage.getItem('codigoSucursal') || formData.codigo_sucursal;
      const response = await axios.get(`https://integration.99envios.app/api/ordersShopify/${codigoSucursal}`);
      // Ordenar las órdenes por ID de forma descendente antes de establecerlas
      const sortedOrders = response.data.sort((a, b) => b.id - a.id);
      // Map the orders to include additional fields
      const mappedOrders = sortedOrders.map(order => ({
        ...order,
        first_name: order.customer?.first_name || '',
        last_name: order.customer?.last_name || '',
        phone: order.customer?.phone || '',
      }));
      setOrdersData(mappedOrders);
    } catch (error) {
      console.error('Error al obtener los datos de la API:', error);
    }
  };

  useEffect(() => {
    verificarSucursalUsuario();
  }, []);

  useEffect(() => {
    if (isSubscribed) {

      fetchOrdersData();
    }
  }, [isSubscribed]);

  // Función para manejar el envío del formulario de suscripción
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const codigoSucursal = localStorage.getItem('codigoSucursal') || formData.codigo_sucursal;
      await axios.post(`https://integration.99envios.app/api/integration/shopify/v1/integration/${codigoSucursal}`, formData);

      await Swal.fire({
        icon: 'success',
        title: 'Suscripción exitosa',
        text: 'La sucursal ha sido suscrita correctamente.',
      });

      // Recargar la página después de mostrar el mensaje de éxito
      window.location.reload();

    } catch (error) {
      console.error('Error al enviar los datos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al registrar la suscripción.',
      });
    }
  };

  // Función para generar la guía
  const handleGenerateGuide = (order) => {
    setSelectedOrder({
      ...order,
      total_weight: order.total_weight ?? 0, // Asigna 0 si total_weight es undefined
    });
    setMostrarEnvios(true);
  };

  const handleBulkGenerateGuide = () => {
    if (selectedOrders.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No hay órdenes seleccionadas',
        text: 'Por favor seleccione al menos una orden para generar las guías.',
      });
      return;
    }
    setMostrarEnviosMasivos(true);
  };


  const rowSelection = {
    selectedRowKeys: selectedOrders.map(order => order.id),
    onChange: (_, selectedRows) => {
      setSelectedOrders(selectedRows);
    },
    
  };

  // Definición de las columnas para la tabla de antd

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      key: 'order_id',
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: 'Número de Orden',
      dataIndex: 'order_number',
      key: 'order_number',
      sorter: (a, b) => a.order_number - b.order_number,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Producto',
      dataIndex: 'product_title',
      key: 'product_title',
      sorter: (a, b) => a.product_title.localeCompare(b.product_title),
    },
    {
      title: 'Precio Unitario',
      dataIndex: 'product_price',
      key: 'product_price',
      render: (price) => `$${parseFloat(price).toLocaleString()}`,
    },
    {
      title: 'Cantidad',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
      sorter: (a, b) => a.product_quantity - b.product_quantity,
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal_price',
      key: 'subtotal_price',
      render: (subtotal) => `$${parseFloat(subtotal).toLocaleString()}`,
    },
    {
      title: 'Total',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total) => `$${parseFloat(total).toLocaleString()}`,
    },
    {
      title: 'Estado Financiero',
      dataIndex: 'financial_status',
      key: 'financial_status',
      sorter: (a, b) => a.financial_status.localeCompare(b.financial_status),
    },
    {
      title: 'Estado de Envío',
      dataIndex: 'fulfillment_status',
      key: 'fulfillment_status',
      sorter: (a, b) => a.fulfillment_status.localeCompare(b.fulfillment_status),
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      title: 'Acciones',
      key: 'actions',
      fixed: 'right',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleGenerateGuide(record)}>
          Generar Guía
        </Button>
      ),
    }
  ];

  if (isSubscribed) {
    return (
      <>
        <Modal
          open={mostrarEnvios}
          title="Generar Guía de Envío"
          onCancel={() => setMostrarEnvios(false)}
          footer={null}
          width={1000}
        >
          {selectedOrder && (
            <SendClientPage selectedOrder={selectedOrder} />
          )}
        </Modal>

        <Modal
          open={mostrarEnviosMasivos}
          title="Generar Guías de Envío Masivamente"
          onCancel={() => setMostrarEnviosMasivos(false)}
          footer={null}
          width={1000}
        >
          {selectedOrders.length > 0 && (
            <SendClientBulkPage selectedOrders={selectedOrders} />
          )}
        </Modal>


        <NavbarMex title="Órdenes de Shopify" />
        <div className="orders-container centered-container">
          {/* Botones superiores */}
          <div className="articulos-container centered-container">
            <AgregarProductoShopify />
          </div>

          {/* Título y tabla con scroll y botón fijo */}
          <h2>Lista de Órdenes</h2>
          <div className="filter-container">
            <div className="filter-group">
              <p>Filtrar por estado de guía creada:</p>
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={handleFilterByOrderStatus}
              >
                <Option value="all">Todos</Option>
                <Option value="0">Sin Imprimir</Option>
                <Option value="1">Impreso</Option>
              </Select>
            </div>
            <div className="filter-group">
              <p>Filtrar por estado financiero:</p>
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={handleFilterByFinancialStatus}
              >
                <Option value="all">Todos</Option>
                <Option value="paid">Pagado</Option>
                <Option value="pending">Pendiente</Option>
                <Option value="refunded">Reembolsado</Option>
              </Select>
            </div>
            <div className="filter-group">
              <p>Filtrar por estado de cumplimiento:</p>
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={handleFilterByFulfillmentStatus}
              >
                <Option value="all">Todos</Option>
                <Option value="fulfilled">Cumplido</Option>
                <Option value="unfulfilled">No cumplido</Option>
              </Select>
            </div>
          </div>
          <div className={`bulk-actions ${selectedOrders.length > 0 ? 'has-selected' : ''}`}>
            {selectedOrders.length > 0 && (
              <span className="selection-counter">
                <span role="img" aria-label="check">✓</span>
                {selectedOrders.length} {selectedOrders.length === 1 ? 'orden seleccionada' : 'órdenes seleccionadas'}
              </span>
            )}
            <Button
              type="primary"
              onClick={handleBulkGenerateGuide}
              disabled={selectedOrders.length === 0}
              icon={<span role="img" aria-label="document">📄</span>}
            >
              Generar {selectedOrders.length > 0 ? `${selectedOrders.length} Guías` : 'Guías Masivamente'}
            </Button>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredOrders}
            rowKey="id"
            scroll={{ x: 1500, y: 500 }} // Permite el scroll horizontal y vertical
            pagination={{
              position: ["bottomCenter"],
              pageSize: 20,
              showSizeChanger: false
            }}
            sticky
          />
        </div>
      </>
    );
  }

  return (
    <>
      <NavbarMex title="Integración a tienda" />


      <div className="shopify-container centered-container">
        <h2>Formulario de Integración a tienda</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label>Código Sucursal:</label>
            <input
              type="text"
              name="codigo_sucursal"
              value={formData.codigo_sucursal}
              onChange={(e) => setFormData({ ...formData, codigo_sucursal: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>API Key:</label>
            <input
              type="text"
              name="apiKey"
              value={formData.apiKey}
              onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shared Secret:</label>
            <input
              type="text"
              name="sharedSecret"
              value={formData.sharedSecret}
              onChange={(e) => setFormData({ ...formData, sharedSecret: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>API Version:</label>
            <input
              type="text"
              name="apiVersion"
              value={formData.apiVersion}
              onChange={(e) => setFormData({ ...formData, apiVersion: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shop Name:</label>
            <input
              type="text"
              name="shopName"
              value={formData.shopName}
              onChange={(e) => setFormData({ ...formData, shopName: e.target.value })}
              required
            />
          </div>
          <div className="button-container">
            <button type="submit" className="submit-button">
              Registrar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ShopSubscription;
