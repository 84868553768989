import React, { useState } from 'react';
import { Form, Input, Button, Upload, Card } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './DevolucionBodegaForm.css';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';

const DevolucionBodegaForm = () => {
  const [form] = Form.useForm();  // Crear referencia al formulario
  const [loading, setLoading] = useState(false);  // Estado de loading para manejar el registro

  const onFinish = (values) => {
    console.log('Valores del formulario:', values);
    // Aquí se puede manejar el envío de datos al servidor
  };

  return (
    <>
      <NavbarPicking title="Consultar Devolución" />
      <div className="devolucion-bodega-form-container">
        <Card title="CONSULTAR DEVOLUCIÓN" className="devolucion-bodega-form-card">
          <Form
            form={form}
            name="devolucionBodegaForm"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Ingrese el ID Preenvío:"
              name="idPreenvio"
              rules={[{ required: true, message: 'Por favor ingrese el ID de preenvío' }]}
            >
              <Input placeholder="ID Preenvío" />
            </Form.Item>

            <Form.Item
              label="Motivo de Devolución:"
              name="motivoDevolucion"
              rules={[{ required: true, message: 'Por favor ingrese el motivo de devolución' }]}
            >
              <Input.TextArea rows={3} placeholder="Motivo de devolución" />
            </Form.Item>

            <Form.Item
              label="Adjuntar foto (Inconformidad):"
              name="foto"
            >
              <Upload
                beforeUpload={() => false}  // Evitar que suba directamente, manejar la carga manualmente si es necesario
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <div className="devolucion-bodega-form-buttons">
                <Button type="primary" htmlType="submit" loading={loading} className="devolucion-bodega-submit-button">
                  Registrar Devolución
                </Button>
                <Button type="default" className="devolucion-bodega-volver-button">
                  Volver
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default DevolucionBodegaForm;
